import { Component, OnDestroy, Input } from  '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

// Platform
import { NavigationService } from '../navigation.service';

// Store
import { getWindowState, selectNavigationRoles } from '../store/navigation.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector:'side-nav-component',
  template:require('./side-nav.component.html')
})

export class SideNavComponent implements OnDestroy{

  public ROUTE_PATHS:any = null;
  public FULL_ROUTES:any = null;
  public ROUTE_PERMISSIONS = null;

  @Input() set active(value){
    this.isVisible = value;
  };

  public ngUnsubscribe: Subject<any> = new Subject();
  public currentRoles:any = null;
  public isVisible:boolean = true;
  public pages:any = [];
  public isMobile:boolean = false;

  get Store(){
    return this.ngrxstore;
  }

  constructor(
    public navigationService:NavigationService,
    public ngrxstore:Store<any>
    ){

      this.ROUTE_PATHS = navigationService.Paths;
      this.FULL_ROUTES = navigationService.Routes;
      this.ROUTE_PERMISSIONS = navigationService.Permissions;

      this.ngrxstore.select(selectNavigationRoles).subscribe((roles)=>{
        this.currentRoles = roles;
      });

      this.ngrxstore.select(getWindowState()).subscribe((state)=>{
        this.isMobile = (state.size === 'isTablet' || state.size === 'isMobile');
      })

      this.navigationService.$NavigationConfiguration.subscribe((configuration)=>{
        this.pages = configuration.sideNav;
      });
  }

  // Lifecycle Hooks
  //------------------
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  routeIsPermitted(route:string){
    return this.navigationService.isPermitted(route,this.currentRoles,this.ROUTE_PERMISSIONS,'/'+this.ROUTE_PATHS.SHELL+'/');
  }

}
