import { AUTH_PATHS } from '../../../platform/modules/auth/constants/auth.constants';

export const PORTAL_PATHS = Object.assign({},{
    USER_PORTAL:'',
    USER_LOGIN:AUTH_PATHS.LOGIN,
    USER_FORGOT_PASSWORD:AUTH_PATHS.FORGOT_PASSWORD,
    USER_RESET_PASSWORD:AUTH_PATHS.RESET_PASSWORD,
    DASHBOARD_PORTAL:'dashboard',
    DASHBOARD_LOGIN:'dashboard/'+AUTH_PATHS.LOGIN,
    DASHBOARD_FORGOT_PASSWORD:'dashboard/'+AUTH_PATHS.FORGOT_PASSWORD,
    DASHBOARD_RESET_PASSWORD:'dashboard/'+AUTH_PATHS.RESET_PASSWORD,
  },AUTH_PATHS
);
  