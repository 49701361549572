import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule} from '@ngrx/store';


import { NavigationService } from './navigation.service';
import { WindowRef } from './windowRef/window-ref.service';
import { AuthGuard } from './authguard/auth-guard.service';

import { SideNavComponent } from './side-nav/side-nav.component';
import { TopNavComponent } from './top-nav/top-nav.component';

import { NavigationConfig, NAVIGATION_CONFIG } from './navigation.interface';
import { PageView } from './components/page-view.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FooterComponent } from './footer/footer.component';
import { DrawerComponent } from './drawer/drawer.component';
import { Error404 } from './error-pages/error.404';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbTooltipModule,
    NgxPageScrollModule,
    PerfectScrollbarModule,
  ],
  providers:[
    {
      provide:NAVIGATION_CONFIG,
      useValue:{
        routes:{},
        paths:{},
        permissions:{},
        pageNames:{},
        navigationRoot:'/',
        rolesSelector:null
      }
    },
    {
      provide:PERFECT_SCROLLBAR_CONFIG,
      useValue: {
				suppressScrollX: true
			}
    },
    NavigationService,
    WindowRef
  ],
  declarations: [
    SideNavComponent,
    TopNavComponent,
    DrawerComponent,
    PageView,
    FooterComponent,
    Error404
  ],
  exports:[
    CommonModule,
    SideNavComponent,
    DrawerComponent,
    TopNavComponent,
    PageView,
    FooterComponent,
    Error404
  ]
})
export class NavigationModule {
  static forRoot(): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [ NavigationService, WindowRef, AuthGuard ]
    };
  }
}
