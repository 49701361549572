import { Component, Inject } from '@angular/core';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ACCOUNT_PAGE_NAMES } from '../constants/account.constants';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatchValidator } from '../../../platform/utilities/validators/match.validator';
import { RequestResetPasswordAction, SaveProfileChanges } from '../../../platform/modules/auth';
import { FULL_ROUTES } from '../../../constants/routes.constants';
import {USER_ROLES} from '../../../constants/permissions.constants';
import { AccountService } from '../account.service';
import { TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { ValidEmailValidator } from '../../../platform/utilities/validators/valid-email.validator';

@Component({
    selector:'account-create',
    template:require('./create-new.component.html'),
})
export class CreateNewUser extends KillZombies(PageView){

    public profile:any = {};
    public updatedProfile:any = {};
    public profileForm:FormGroup = new FormGroup({});
    public roleKeys: any= null;
    public roleValues: any= null;
    public roleDisabled: any= null;
    public showPassword: Boolean= false;
    public showMaxAppeals: Boolean= false;

    constructor(
        public activatedRoute:ActivatedRoute, 
        public ngrxstore:Store<any>,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        navigationService:NavigationService,
        public formBuilder:FormBuilder,
        public accountService: AccountService
    ){
        super(configuration,navigationService);

        this.Name = ACCOUNT_PAGE_NAMES.CREATE_USER;

        //this.activatedRoute.snapshot.data.groups.results = this.activatedRoute.snapshot.data.groups.results.filter(({ name }) => name !== 'Appellant' && name != 'Appellant Counsel');        

        let _groups = this.activatedRoute.snapshot.data.groups.results;
        this.roleKeys= _groups.map((key)=>{
            let _temp= key.name;
            return _temp.toLowerCase().replace(' ','_');
        });
        this.roleValues= _groups.map((key)=>{
            return key;
        });
        this.roleDisabled= _groups.map((key)=>{
            if(key.name==='Appellant' || key.name=== 'Appellant Counsel'){
                return true;
            }else{
                return false;
            }
        });
        this.profileForm = this.formBuilder.group({
            username:['',Validators.required],
            name:['',Validators.required],
            email:['',[Validators.required,Validators.email,ValidEmailValidator()]],
            phone:['',[Validators.required,Validators.maxLength(10)]],
            password:['',[Validators.required]],
            confirm_password:['',[Validators.required]],
            appeals_assignment_max:[0,[Validators.required]],
            first_name:['',Validators.required],
            last_name:['',Validators.required],
            is_active: false,
            is_staff: false,
            is_superuser: false,
        },{ 
            validator: MatchValidator('password', 'confirm_password')
        });   
        _groups.map((key)=>{
            let _temp= key.name;
            this.profileForm.addControl(_temp.toLowerCase().replace(' ','_'), new FormControl(false));
        });     
    }

    get formContols() { return this.profileForm.controls; }

    formFullName(){
        this.profileForm.patchValue({
            'name': this.profileForm.controls.first_name.value+" "+this.profileForm.controls.last_name.value
        });
    }

    addUser(){
        if (this.profileForm.invalid) {
            return;
        }       
        let _groupDicts = [];
        let _data= this.profileForm.value;
        _data['is_staff'] = (_data['is_staff'] !== null) ? _data['is_staff'] : false;
        _data['is_superuser'] = (_data['is_superuser'] !== null) ? _data['is_superuser'] : false;
        this.roleKeys.map((key,index) => {
            if(this.profileForm.controls[key].value){
                _groupDicts.push(this.roleValues[index].id)
            }
        });
        _data['groups']=_groupDicts;
        this.accountService.addUser(_data).subscribe((response)=>{
            this.ngrxstore.dispatch(new TriggerToast({
                message:this.profileForm.controls.username.value+" is created successfully.",
                style:'success'
            }));
            this.profileForm.reset();
        },(err)=>{
            let _message="";
            if(err){
                if(err.error.non_field_errors){
                    err.error.non_field_errors.map((message) => {
                        _message+= message;
                    });
                }
                Object.keys(this.profileForm.controls).forEach(key => {
                    if(err.error[key]){
                        _message+= err.error[key][0];
                    }
                });
                 this.ngrxstore.dispatch(new TriggerModal({
                     content:{
                         title:"Error Creating the user",
                         message: _message,
                     }
                 }));
            }
        });
    }
    togglePasswordVisibility(){
        this.showPassword = !this.showPassword;
    }

    assignUserName($event){
        this.profileForm.patchValue({
            username: $event.target.value
        });
    }

    handleRolesSelection($event,formElement){
        if(this.profileForm.controls[formElement].value){
            if((formElement === "judge" || formElement === "attorney") && this.profileForm.controls['external_oha staff'].value){
                this.showMaxAppeals=true;
                if(formElement === "judge"){
                    this.profileForm.controls["attorney"].disable();
                }else{
                    this.profileForm.controls["judge"].disable();
                }
            }else if(formElement != 'external_oha staff'){
                if(formElement === "judge" || formElement === "attorney" || formElement === "ogc"){
                    this.showMaxAppeals=true;
                }else{
                    this.showMaxAppeals=false;
                }
                this.roleKeys.forEach(key=>{
                    if(key != formElement){
                        this.profileForm.controls[key].disable();
                    }
                });
            }else{
                this.showMaxAppeals=false;
                this.roleKeys.forEach(key=>{
                    if(key != 'judge' && key != 'attorney' && key != formElement){
                        this.profileForm.controls[key].disable();
                    }
                });
            }
        }else{
            this.showMaxAppeals=false;
            if((formElement === "judge" || formElement === "attorney") && this.profileForm.controls['external_oha staff'].value){
                if(formElement === "judge"){
                    this.profileForm.controls["attorney"].enable();
                }else{
                    this.profileForm.controls["judge"].enable();
                }
            }else{
                this.roleKeys.forEach(key=>{
                    if(key != 'appellant' && key != 'appellant_counsel'){
                        this.profileForm.controls[key].enable();
                    }
                });
            }
            
        }        
    }

}