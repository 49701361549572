import { Action } from '@ngrx/store';
import { TICKETS_ACTIONS } from '../constants/tickets.constants';


export class SaveAppealTicket implements Action {
    readonly type = TICKETS_ACTIONS.SAVE_APPEAL_TICKET;
    constructor(
        public payload:any, 
        public id:string, 
        public callback?:any,
        public request_type?:string
    ) {}
}

export class AssignUserToTicket implements Action {
    readonly type = TICKETS_ACTIONS.ASSIGN_USER_TO_TICKET;
    constructor(public payload:{
        type:string,
        user:string,
        appealId:string,
        callback?:any
    }){}
}

export class UnAssignUserToTicket implements Action {
    readonly type = TICKETS_ACTIONS.UNASSIGN_USER_TO_TICKET;
    constructor(public payload:{
        type:string,
        user:string,
        appealId:string,
        callback?:any
    }){}
}

export class AssignUsersToTicket implements Action {
    readonly type = TICKETS_ACTIONS.ASSIGN_USERS_TO_TICKET;
    constructor(public payload:{
        users?:string[],
        appealId:string,
        callback?:any
    }){}
}

export class RequestAppealStatusChange implements Action{
    readonly type = TICKETS_ACTIONS.REQUEST_APPEAL_STATUS_CHANGE;
    constructor(public payload:{
        reference_id:string,
        slug:string,
        statusLabel:string,
        callback?:any
    }){}
}