export const APPEALS_APPLICATIONS_ACTIONS = {
    START_LINK_LOAN_ACTION:'START_LINK_LOAN_ACTION',
    VERIFY_LINK_LOAN_ACTION:'VERIFY_LINK_LOAN_ACTION',
    VERIFY_LINK_LOAN_SUCCESS_ACTION:'VERIFY_LINK_LOAN_SUCCESS_ACTION',
    FINALIZE_LINK_LOAN_ACTION:'FINALIZE_LINK_LOAN_ACTION',
    LINK_LOAN_TO_USER:'LINK_LOAN_TO_USER',
    REJECT_LINK_LOAN_TO_USER:'REJECT_LINK_LOAN_TO_USER',
    START_NEW_WIZARD:'START_NEW_WIZARD',
    REQUEST_NEW_APPEAL:'REQUEST_NEW_APPEAL',
    CURRENT_FILTER_VALUES:'CURRENT_FILTER_VALUES',
    CLEAR_FILTER_VALUE:'CLEAR_FILTER_VALUE'    
}

export const APPEALS_APPLICATIONS_PATHS = {
    LINK_LOAN_WIZARD:'link-loan-to-account',
    APPEAL_APPLICATIONS_LIST:'appeal-applications-list',
    YOUR_APPEALS : 'your-appeals-list',
    YOUR_APPEAL : 'your-appeal',
    START_NEW_APPEAL:'new-appeal'
}

export const APPEALS_APPLICATIONS_ROOT_PATH = 'appeals-applications';

export const APPEALS_APPLICATIONS_PAGE_NAMES = {
    APPEAL_APPLICATIONS_LIST:'Appeals',
    LINK_LOAN_WIZARD:'Link a Forgiveness Loan',
    YOUR_APPEALS : 'Your Appeals',
    YOUR_APPEAL : 'Your Appeal'
}

export const APPEAL_LIST_COLUMNS_SORTING = {
    ogc:{
        "appellant":'title',
        "judge":'judge__name',
        "ogc_poc":'primary_contact_ogc__name',
        "status":'action__display_name',
        "sba_ppp_loan_number":'loan_number',
        "last_modified":'modified',
        "deadline":'close_of_record',
        "appeal_filed":'filing_date'
    },
    oha:{
        "appellant":"title",
        "judge":"judge__name",
        "status":'action__display_name',
        "sba_ppp_loan_number":'loan_number',
        "last_modified":"modified",
        "deadline":"close_of_record",
        "appeal_filed":'filing_date'
    },
    appellant_submitted:{
        "appellant":"title",
        "date_filed":"filing_date_ordering",
        "status":'action__display_name',
        "sba_ppp_loan_number":'loan_number',
        "judge":"judge__name",
        "appeal_filed":'filing_date'
    },
    appellant_draft:{
        "appellant":"title",
        "date_created":"created",
        "status":'action__display_name',
        "sba_ppp_loan_number":'loan_number',
        "judge":"judge__name",
        "appeal_filed":'filing_date'
    }
}