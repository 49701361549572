import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store'
import {ROUTER_ANIMATIONS} from '../../constants/animations.constants';

import { getShellFrozenState } from '../../store/shell.selectors';

import {NavigationService, CloseNavigationDrawer} from '../../../platform/modules/navigation';
import {AuthService} from '../../../platform/modules/auth/auth.service';

import { scrollToTop } from '../../../platform/utilities/scroll-to-top';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';


@Component({
  selector: 'shell',
  template:require('./shell.component.html'),
  animations:ROUTER_ANIMATIONS.FADE
})
export class ShellComponent extends KillZombies(){

  // State Cleanup
  public ngUnsubscribe:Subject<boolean> = new Subject();
  public toolBarConfiguration = {}
  public scrollToTop = scrollToTop;
  public drawerIsOpen:boolean = null;
  public viewIsFrozen:boolean = false;

  closeDrawer(){
    return this.ngrxstore.dispatch(new CloseNavigationDrawer());
  }

  constructor(
    public ngrxstore:Store<any>,
    public navigationService:NavigationService,
    public authService:AuthService,
    public activatedRoute:ActivatedRoute
  ){

    super();

    let shellFrozenState = this.ngrxstore.select(getShellFrozenState).subscribe((frozen)=>{
      // console.log(frozen);
      this.viewIsFrozen = frozen;
    })
    
    this.storeZombies.push(shellFrozenState);
  }

}
