import { Component, Inject, InjectionToken} from '@angular/core';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { SignCertificateOfService, StoreAppealValues } from '../store/appeal-wizard.actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { ActivatedRoute } from '@angular/router';
import { WIZARD_FIELDS, APPEAL_WIZARD_STEPS } from '../constants/wizard_steps.constants';
import { getWindowState } from '../../../platform/modules/navigation';
import { startCase } from 'lodash';
import { SaveAppealAndSignCOS } from '../store/appeal-wizard.actions';
import { inject } from '@angular/core/testing';

export const STEP_TITLE = new InjectionToken<string>('STEP_TITLE');

@Component({
  selector:'wizard-step',
  template:``
})
export class WizardStep extends KillZombies(){

  // Provides base functionality every step needs, keeping it DRY

  public isMobile:boolean = false;
  public isTablet:boolean = false;
  public isDesktop:boolean = false;
  public isWidescreen:boolean = false;
  public isFullHD:boolean = false;

  public formGroup = new FormGroup({});
  public name:string = null;
  public fields:any = WIZARD_FIELDS;
  public steps:any = APPEAL_WIZARD_STEPS;
  public appealId:string = null; 
  public stepComplete:boolean = false;

  public refreshing:any = {};
  public frozen:any = {};
  public hidden:any = {};

  constructor(
    @Inject(STEP_TITLE) public title:string,
    public ngrxstore:Store,
    public formBuilder:FormBuilder,
    public appealWizardService:PPPAppealWizardService,
    public activatedRoute:ActivatedRoute){
    super();
    this.title = title;
    this.appealId = this.activatedRoute.snapshot.paramMap.get('id');
    let _windowState = this.ngrxstore.select(getWindowState()).subscribe((state)=>{
      this.isMobile = ( state.size === 'isMobile' );
      this.isTablet = ( state.size === 'isTablet' );
      this.isDesktop = ( state.size === 'isDesktop' );
      this.isWidescreen = ( state.size === 'isWidescreen' );
      this.isFullHD = ( state.size === 'isFullHD' );
    });
    this.storeZombieByKey(
      'wizards_window_state',_windowState
    );
  }

  resetValues(){
    this.appealWizardService.getSavedValuesForStep(this.name).pipe(take(1)).subscribe((values)=>{
        this.ngrxstore.dispatch( new StoreAppealValues(values) );
    });
  }

  watchChanges(callback?:any){
    let _changes = this.formGroup.valueChanges.subscribe((values)=>{
      this.ngrxstore.dispatch( new StoreAppealValues(values) );
      if(callback){
        if(callback.apply) callback(values);
      }

      this.appealWizardService.updateFormValidation(this.formGroup.valid);
    });
    this.storeZombies.push(_changes);
  }

  refresh(target:string){
    this.refreshing = Object.assign({},this.refreshing,{
      [target]:true
    })
  }

  completeRefresh(target:string){
    let _refreshing = {}
    Object.keys(this.refreshing).map((key)=>{
      if(key !== target){
        _refreshing['key'] = true;
      }
    });
    this.refreshing = Object.assign({},_refreshing)
  }

  thaw(target:string){
    let _frozen = {}
    Object.keys(this.frozen).map((key)=>{
      if(key !== target){
        _frozen['key'] = true;
      }
    });
    this.frozen = Object.assign({},_frozen)
  }

  freeze(target:string){
    this.frozen = Object.assign({},this.frozen,{
      [target]:true
    })
  }

  hide(target:string){
    this.hidden = Object.assign({},this.hidden,{
      [target]: true
    });
  }

  show(target:string){
    let _hidden = {}
    Object.keys(this.hidden).map((key)=>{
      if(key !== target){
        _hidden['key'] = true;
      }
    });
    this.hidden = Object.assign({},_hidden)
  }
  
}
