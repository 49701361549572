import { Component, Inject } from '@angular/core';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig, FreezeNavigation, ThawNavigation } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ACCOUNT_PAGE_NAMES } from '../constants/account.constants';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthConfiguration, AUTH_CONFIG, getSessionState, RequestResetMFAAction, RequestResetPasswordAction } from '../../../platform/modules/auth';
import { AccountService } from '../account.service';
import { take } from 'rxjs/operators';
import { AccountProfile } from '../profile/profile.component';
import { TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { FULL_ROUTES } from '../../../constants/routes.constants';

interface UsersRows{
	data:any;
	total:number;
}

interface UsersColumns{
	raw:any[];
	display:any[];
}

@Component({
    selector:'user-list',
    template:require('./user-list.component.html'),
})
export class UserList extends KillZombies(PageView){

    // private rows:any = null;
    // private columns:any = null;
    rows:UsersRows = {
		data:[],
		total:0
	};

	columns:UsersColumns = {
		raw:[],
		display:[]
	};
	emptyColumn:any = {
		name: "Actions",
		prop: "emptyColumn",
		sortable: false
	};

    public roleOptions = [];
    public rowsPerPageOptions= [];
    public usersData: any = null;
    public defaultSorts = [];

    public usersPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
	public usersPageOffset:any = 0;

    public columnsForDisplay:any = [];
    public searchForm:FormGroup = new FormGroup({});

    public sortType = SortType;
	public columnMode = ColumnMode;
	public currentOrderedBy:any = {ordering:'full_name'};
	
	public loadingUsersList:boolean = false;
	public showAddUser:boolean= false;
    constructor(
        public activatedRoute:ActivatedRoute,
        public ngrxstore:Store<any>,
        public formBuilder:FormBuilder,
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        navigationService:NavigationService,
        public accountService:AccountService
    ){
        // Provide PageView's dependencies
		super(configuration,navigationService);
		
		let _role = ""; 
		this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
			_role = session.roles[0];
		});
		if(_role === this.authConfig.roles.OHA_ADMIN.slug){
			this.showAddUser=true;
		}

        // Name is Inherited from PageView. It should be assigned a constant in the module's constants file
        this.Name = ACCOUNT_PAGE_NAMES.USER_LIST;
        this.usersData = this.activatedRoute.snapshot.data.users;
        this.roleOptions = Object.values(this.authConfig.roles); 
        this.rowsPerPageOptions = this.appDefaults.ui.USERS_PER_PAGE_OPTIONS || [{label:'10',value:"10"}];

        this.searchForm = this.formBuilder.group({
			search:['']
        });
        
        this.handleColumns();
		this.handleRows();
    }

    handleRows(){
		this.rows.total = this.usersData.totalCount;
		this.rows.data = this.usersData.rows;
		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.rows = Object.assign({},this.rows)
	}

	handleColumns(){

		this.columns.display = [];
		this.columns.raw = this.usersData.columns;

		let _role = ""; 
		this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
			_role = session.roles[0];
		});
		if(_role === this.authConfig.roles.OHA_ADMIN.slug){
			this.columns.raw.push(this.emptyColumn);
		}

		this.columns.raw.map((value)=>{
			this.columns.display.push(value);
		});

		this.defaultSorts = [{prop: this.columns.raw[0].prop, dir: 'asc'}];

		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.columns = Object.assign({},this.columns);
		//console.log(">>",this.columns);
	}

    goToUserProfile(id:string){
        this.navigationService.navigate([this.Routes['USER_LIST'],id]);
    }

    queryUsers(){
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.loadingUsersList = true;
		let _filterSearch = this.searchForm.controls.search.value;
 		this.accountService.getUsersList(
			 0, this.usersPerPage,this.currentOrderedBy,_filterSearch).pipe(take(1)).subscribe((response)=>{
 				this.usersData = response;
 				this.handleColumns()
 				this.handleRows()
 				this.loadingUsersList = false;
				this.ngrxstore.dispatch( new ThawNavigation() );
 		})

    }

    updateRows(pageInfo) {
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.loadingUsersList = true;

		let _offsetValue = 0;

		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.usersPerPage;
		}

		this.accountService.getUsersList(_offsetValue, this.usersPerPage,this.currentOrderedBy,this.searchForm.controls.search.value).pipe(take(1)).subscribe((response)=>{
				//console.log(response)
				this.usersData = response;
				this.usersPageOffset = pageInfo.offset;
				this.handleColumns()
				this.handleRows()
				this.loadingUsersList = false;
				this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}
	
	resetFilter(){
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.loadingUsersList = true;
		this.searchForm = this.formBuilder.group({
			search:['']
        });
		this.accountService.getUsersList(
			0, this.usersPerPage,this.currentOrderedBy).pipe(take(1)).subscribe((response)=>{
				this.usersData = response;
				this.handleColumns()
				this.handleRows()
				this.loadingUsersList = false;
			   this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}

	createNewUser() {
		this.navigationService.navigate([this.Routes['CREATE_USER']]);
	}
	modifyUser(row){
		this.navigationService.navigate([this.Routes['USER_LIST'],row.data.reference_id,'modify']);
	}
	deactivateUser(row){
		this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Are you sure you want to deactivate the user?",
              approveLabel: "Deactivate",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                let _temp = {
					"username": row.data.username,
					"is_active": 0
				}
				this.accountService.removeUser(_temp, row.data.reference_id).subscribe((response) => {
					this.accountService.getUsersList(0,this.appDefaults.ui.LIST_PAGE_LIMIT,"+full_name").pipe(take(1)).subscribe((response)=>{
						//console.log(response);
						this.usersData = response;
						this.handleColumns()
						this.handleRows()
						this.loadingUsersList = false;
						this.ngrxstore.dispatch(new TriggerToast({
							message:"Successfully deactivated User "+row.data.username,
							style:'success'
						}));
						this.ngrxstore.dispatch( new ThawNavigation() );
					})
				},(err)=>{
					if(err){
						 this.ngrxstore.dispatch(new TriggerModal({
							 content:{
								 title:"Error deactivating the user",
								 message:"Error deactivating the user",
							 }
						 }));
					}
				});
            }
          }))
	}
	activateUser(row){
		this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Are you sure you want to activate the user?",
              approveLabel: "Activate",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                let _temp = {
					"username": row.data.username,
					"is_active": 1
				}
				this.accountService.removeUser(_temp, row.data.reference_id).subscribe((response) => {
					this.accountService.getUsersList(0,this.appDefaults.ui.LIST_PAGE_LIMIT,"+full_name").pipe(take(1)).subscribe((response)=>{
						//console.log(response);
						this.usersData = response;
						this.handleColumns()
						this.handleRows()
						this.loadingUsersList = false;
						this.ngrxstore.dispatch(new TriggerToast({
							message:"Successfully Activated User "+row.data.username,
							style:'success'
						}));
						this.ngrxstore.dispatch( new ThawNavigation() );
					})
				},(err)=>{
					if(err){
						 this.ngrxstore.dispatch(new TriggerModal({
							 content:{
								 title:"Error Activating the user",
								 message:"Error Activating the user",
							 }
						 }));
					}
				});
            }
          }))
    }
	
    resetUserPassword(row){
        this.ngrxstore.dispatch( new RequestResetPasswordAction({
            email: row.data.email,
           	redirect: "/account/users/"+row.data.reference_id
        }));
	}

	resetUserMFA(row){
        this.ngrxstore.dispatch( new RequestResetMFAAction({
            ref: row.data.reference_id,
           	redirect: "/account/users/"+row.data.reference_id
        }));
	}
	
    onSort(event) {
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.loadingUsersList = true;

		let _orderBy = '';
		let _queryParams = {
			
		};

		let _usersCount = null;
		let _usersOffset = null;

		_usersCount = this.usersPerPage;
		_usersOffset = this.usersPageOffset;

		let _columnDict = {
			"user_name":"username",
			"full_name":"name",
			"email":'email',
			"role":'groups__name'
		}

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === event.sorts[0].prop
		})

		if(event.sorts.length > 0){
			switch(event.sorts[0].dir){
				case 'asc':
					_orderBy =  _columnDict[_serverColumn[0]]
				break;
				case 'desc':
					_orderBy = "-" +  _columnDict[_serverColumn[0]]
				break;
				default:
				break;					
			}
		}

		if(_usersCount){
			this.accountService.getUsersList(_usersOffset,_usersCount,_orderBy,this.searchForm.controls.search.value ).pipe(take(1)).subscribe((response)=>{
					//console.log(response);
					this.usersData = response;
					this.currentOrderedBy = _orderBy;
					this.handleColumns()
					this.handleRows()
					this.loadingUsersList = false;
					this.ngrxstore.dispatch( new ThawNavigation() );
			})
		}
	}
}