import {Inject, Injectable} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MOCK_MESSAGE, MOCK_MESSAGES } from '../../../constants/mock.data';
import { getSessionState } from '../../../platform/modules/auth';

@Injectable()
export class InboxResolver implements Resolve<any> {

  constructor(
    @Inject(Store) public ngrxstore:Store
  ){}

  public $Messages:Observable<any> = new Observable((obs)=>{
    this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((state)=>{
        let _sampleData = (()=>{
            let _sample = [];
            for(let i = 0; i<20 ; i++){
                let _thread = [];
                for(let j = 0; j<4; j++){
                    let  _message = Object.assign({},MOCK_MESSAGE,{
                        id:j,
                        thread:i
                    });
                    _thread.push(_message)
                }
                _sample.push(_thread);
            }
            return _sample;
        })();
        //console.log(_sampleData);
        obs.next(_sampleData);
        obs.complete();
    })
  });

  resolve(route:ActivatedRouteSnapshot){
      return this.$Messages.pipe(take(1));
  }

}
