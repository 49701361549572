import { ModuleWithProviders, InjectionToken }  from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, PreloadAllModules } from '@angular/router';

// App Constants
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

// App Components
import { ShellComponent } from './shell';
import { Error404 } from './platform/modules/navigation/error-pages/error.404';

export const appRoutes: Routes = [
	{path: 'externalRedirect', component:ShellComponent, resolve:{url:externalUrlProvider}},
	{path: '**', component:Error404}
];
// Injection token to aid in external routing assignment
export const appRoutingProviders: any[] = [{

	provide: externalUrlProvider,
  useValue: (route: ActivatedRouteSnapshot) => {
      const externalUrl = route.paramMap.get('externalUrl');
      window.open(externalUrl, '_blank');
  },

}];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes,
	{
		paramsInheritanceStrategy:'always',
		preloadingStrategy: PreloadAllModules
	}
);
