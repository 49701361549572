export const ACCOUNT_PATHS = {
    ACCOUNT_PROFILE:'profile',
    USER_LIST:'users',
    CREATE_USER:'createUser',
    MESSAGE_THREAD:'message',
    MESSAGE_INBOX:'inbox'
}

export const ACCOUNT_ROOT_PATH = 'account';

export const ACCOUNT_PAGE_NAMES = {
    PROFILE:'Your Profile',
    MODIFY:'Modify Profile',
    USER_LIST:'Users',
    CREATE_USER: 'Create User',
    INBOX:'Your Inbox',
    THREAD:'Message'
}