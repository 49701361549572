import { Action } from '@ngrx/store';
import { NAVIGATION_CONSTANTS } from '../constants/navigation.constants';

interface CurrentPageInformation{
  section_name:string,
  section_index:number,
  page_name:string,
  page_index:number
}

export class StoreCurrentURL implements Action {
  readonly type = NAVIGATION_CONSTANTS.SET_CURRENT_ROUTE_URL;
  constructor(public payload:string){}
}

export class StorePreviousURL implements Action {
  readonly type = NAVIGATION_CONSTANTS.SET_PREVIOUS_ROUTE_URL;
  constructor(public payload:string){}
}

export class SetCurrentPage implements Action {
  readonly type = NAVIGATION_CONSTANTS.SET_PAGE;
  constructor(public payload:CurrentPageInformation){}
}

export class SetCurrentPageTab implements Action {
  readonly type = NAVIGATION_CONSTANTS.SET_CURRENT_PAGE_TAB;
  constructor(public payload:string){}
}

export class CloseNavigationDrawer implements Action{
  readonly type = NAVIGATION_CONSTANTS.SET_DRAWER_COLLAPSE;
  constructor(public payload:boolean = true){}
}

export class OpenNavigationDrawer implements Action{
  readonly type = NAVIGATION_CONSTANTS.SET_DRAWER_COLLAPSE;
  constructor(public payload:boolean = false){}
}

export class SetDrawerType implements Action{
  readonly type = NAVIGATION_CONSTANTS.SET_DRAWER_TYPE;
  constructor(public payload:string){}
}

export class SetNavigationRoles implements Action {
  readonly type = NAVIGATION_CONSTANTS.SET_ROLES;
  constructor(public payload:string[]){}
}

export class FreezeNavigation implements Action {
  readonly type = NAVIGATION_CONSTANTS.FREEZE_NAVIGATION
  constructor(){}
}

export class ThawNavigation implements Action {
  readonly type = NAVIGATION_CONSTANTS.THAW_NAVIGATION
  constructor(){}
}
