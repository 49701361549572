import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { tap , take, map } from 'rxjs/operators';
import { Router} from '@angular/router';

import {
  LoginAction,
  LogoutAction, 
  selectSessionState,
  RegisterUser, RegisterPhoneWithTwoFactor, SetOrigin, RequestResetPasswordAction, ResetPasswordAction, LoginWithMFATokenAction, AUTH_PATHS, RequestLogoutAction, SaveProfileChanges, InviteUser, ScanQRCode, RegisterPhone, RequestResetMFAAction, LoginWithInviteToken, LoginWithAppealID, SaveAppealProfileChanges, DirectLoginWithAppealID, AUTH_CONFIG, AuthConfiguration, SelectSMSTwilio2fa, TwilioLoginWithMFATokenAction, RegisterPhoneWithTwilio} from '../../platform/modules/auth';
import { NotificationService, TriggerModal, TriggerToast } from '../../platform/modules/notification';
import { AuthService } from '../../platform/modules/auth';
import { NAVIGATION_CONSTANTS, SetNavigationRoles, StoreCurrentURL, SetCurrentPageTab } from '../../platform/modules/navigation';

import { AUTH_ACTIONS } from '../../platform/modules/auth/constants/auth.constants';
import { APPEALS_APPLICATIONS_ACTIONS } from '../../../app/features/appeals-applications/constants/appeals-applications.constants';
import { PORTAL_PATHS } from '../../features/portal/constants/portal.constants';
import { USER_ORIGINS, USER_HOMES } from '../../constants/permissions.constants';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

// An effect class is essentially a service. So we can treat it as such, except we would not inject it into components.
@Injectable()
export class AuthEffects{

  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public logoutSubscription: any;

  constructor(
    public actions$: Actions,
    public router:Router,
    public notificationService:NotificationService,
    public ngrxstore:Store<any>,
    public idle: Idle, public keepalive: Keepalive,
    @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
    public auth:AuthService) {
      
  }

  // Shared Functions
  // --------------------------------------------
  public refreshTokenSuccess:any = (response) =>{
    this.ngrxstore.dispatch({
      type:AUTH_ACTIONS.REFRESH_TOKEN,
      payload:{
        token:response.body['access'],
        refresh_token:response.body['refresh']
      }
    });
  }

  public onLoginSuccess:any = (action,response)=>{
   this.ngrxstore.dispatch({
      type:AUTH_ACTIONS.COMPLETE_LOGIN,
      payload:{
        user:response.body['name'] || null,
        roles:[response.body['role']],
        user_id:(response.body['id']) ? parseInt(response.body['id']) : 1,
        token:response.body['access'],
        refresh_token:response.body['refresh'],
        origin:USER_ORIGINS[response.body['role']]
      }
    });
    this.ngrxstore.dispatch( new SetNavigationRoles( [response.body['role']] ));
    if(action.payload.toastConfig){
      this.ngrxstore.dispatch( new TriggerToast( action.payload.toastConfig ));
    }
    //this.router.navigateByUrl('/'+action.payload.redirect);
    this.router.navigateByUrl(USER_HOMES[response.body['role']]);

    // sets an idle timeout of 15 minutes.
    this.idle.setIdle(1680);
    // sets a timeout period of 120 seconds. after 900 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(120);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.ngrxstore.select(selectSessionState()).pipe(take(1)).subscribe((state)=>{
        if(state.status === 'signedIn' && state.token && state.refresh_token){
          this.auth.refreshAccess(state.refresh_token).subscribe((response)=>{
            this.refreshTokenSuccess(response);
          },(err)=>{
            if(err){
              // this.triggerErrorAsModal(err);
            }
          });
        }
      })
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.ngrxstore.dispatch( new LogoutAction() );
      this.ngrxstore.dispatch(new TriggerModal({
        content:{
          title:"Session Timeout",
          message:"Your Session has been timed out due to inactivity!"
        }
      }))
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:"Session Timeout",
            message:"Your Session will been timed out due to inactivity!",
            approveLabel: "Log Out",
            cancelLabel: "Continue Session"
          },
          onCancel:()=>{
            this.reset();
          },
          onApprove:()=>{
            this.ngrxstore.dispatch( new LogoutAction() );
            this.idleState = 'Timed out!';
            this.idle.stop();
            this.timedOut = true;
            
          }
        }))
    });

     // sets the ping interval to 15 seconds
     this.keepalive.interval(15);

     this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
 
     this.reset();

  }

  public onLogout:any = (redirect)=>{
    this.router.navigateByUrl(redirect);
  }

  public triggerErrorAsToast:any = (err)=>{
    this.ngrxstore.dispatch( new TriggerToast({
      message:this.auth.interpretErrorsFromAPI(err).message,
      style:'danger',
      ttl:5
    }));
    throw err;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.ngrxstore.select(selectSessionState()).pipe(take(1)).subscribe((state)=>{
      if(state.status === 'signedIn' && state.token && state.refresh_token){
        this.auth.refreshAccess(state.refresh_token).subscribe((response)=>{
          this.refreshTokenSuccess(response);
        },(err)=>{
          if(err){
            // this.triggerErrorAsModal(err);
          }
        });
      }
    })
  }

  triggerErrorAsModal(err:any){
    if(err){
      this.ngrxstore.dispatch(new TriggerModal({
        content:{
          title:this.auth.interpretErrorsFromAPI(err).title,
          message:this.auth.interpretErrorsFromAPI(err).message,
        },
        type:'message'
      }))
      throw err;
    }
  }

  triggerSuccessAsToast(message:any){
    this.ngrxstore.dispatch( new TriggerToast({
      message:message,
      style:'success',
      ttl:4
    }));
  }

  // Effects
  // -----------`----------------------------------------
  @Effect({dispatch:false})
  onRegisterUser$: Observable<RegisterUser> = this.actions$.pipe(
    ofType<RegisterUser>(AUTH_ACTIONS.REGISTER_USER),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.registerUser({
        first_name:action.payload.first_name,
        last_name:action.payload.last_name,
        name:action.payload.name,
        username:action.payload.email,
        email:action.payload.email,
        role:action.payload.role,
        password1:action.payload.password,
        password2:action.payload.confirm_password
      }).subscribe((response)=>{
        //console.log(response.status,response.body);
        switch(response.status){
          case 202:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('phone')){
              if(response.body['phone'] === null){
                //console.log('register phone')
                this.router.navigate([PORTAL_PATHS.TWO_FA_METHOD],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    qrCode:response.body['qr_code'],
                    inviteToken:action.payload.inviteToken
                  }
                });
              }else{
                //console.log('mfa')
                this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    phone:response.body['phone'],
                    inviteToken:action.payload.inviteToken
                  }
                });
              }
            }
          break;

          case 201:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
              if(response.body['access'] !== null && response.body['refresh'] !== null){
               
                if(action.payload.inviteToken != null){
                  this.auth.updatePointOfContact(action.payload).subscribe((response1)=> {
                    this.onLoginSuccess(action,response);
                  }, 
                  (error) => { this.triggerErrorAsModal(error); });
                }else{
                  this.onLoginSuccess(action,response);
                }
              }
            }else{
              this.triggerErrorAsModal({
                error:'Something is wrong, your account may need to be reviewed, please contact us.'
              });
            }
          break;
            
          case 200:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
              this.onLoginSuccess(action,response);
            }else{
              this.triggerErrorAsModal({
                error:'Something is wrong, your account may need to be reviewed, please contact us.'
              });
            }
          break;
           
          default:
            this.ngrxstore.dispatch( new LogoutAction() );
          break;
        }
      },((err)=>{
        if(err){
          if(action.payload.inviteToken != null){
            //this.ngrxstore.dispatch( new LogoutAction() );
            this.router.navigate([PORTAL_PATHS.LOGIN + '/' + action.payload.inviteToken],{
              state:{
                origin:action.payload.origin,
                redirect:action.payload.redirect,
                email:action.payload.email,
                inviteToken:action.payload.inviteToken
              }
            });
            err.error.email=null;
            err.error.loginRedirectMsg = "A user is already registered with this e-mail address.You will be redirected to the login screen."
            this.triggerErrorAsModal(err);
          }else{
            this.triggerErrorAsModal(err);
            this.ngrxstore.dispatch( new LogoutAction() );
          }
          throw err;
        }
      }))
    })
  )

  @Effect({dispatch:false})
  onLoginWithInviteToken$: Observable<LoginWithInviteToken> = this.actions$.pipe(
    ofType<LoginWithInviteToken>(AUTH_ACTIONS.LOGIN_WITH_INVITE),
    tap((action)=>{
      this.router.navigate([PORTAL_PATHS.LOGIN + '/' + action.payload.inviteToken],{
        state:{
          origin:action.payload.origin,
          redirect:action.payload.redirect,
          email:action.payload.email,
          inviteToken:action.payload.inviteToken
        }
      });
    })
  )

  @Effect({dispatch:false})
  onDirectLoginWithAppealID$: Observable<DirectLoginWithAppealID> = this.actions$.pipe(
    ofType<DirectLoginWithAppealID>(AUTH_ACTIONS.DIRECT_LOGIN_WITH_APPEAL_ID),
    tap((action)=>{
      this.onLoginSuccessWithID(action.payload,null,action.payload.appealId,true);
    })
  )

  @Effect({dispatch:false})
  onLoginWithAppealID$: Observable<LoginWithAppealID> = this.actions$.pipe(
    ofType<LoginWithAppealID>(AUTH_ACTIONS.LOGIN_WITH_APPEAL_ID),
    tap((action)=>{
      this.auth.performLogin({
        email:action.payload.email,
        password:action.payload.password,
      }).subscribe((response)=>{
        switch(response.status){
          case 202:
            if(response.body.hasOwnProperty('phone')){
              if(response.body['auth_type'] === 'sms'){
                this.auth.sendTwilioRequest({
                  email:action.payload.email,
                  auth_type:'sms'
                }).pipe(take(1)).subscribe((resp)=>{
          
                  this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
                    state:{
                      email:action.payload.email,
                      password:action.payload.password,
                      origin:action.payload.origin,
                      redirect:action.payload.redirect,
                      //phone:response.body['phone'],
                      auth_type: 'sms',
                      verification_id: resp.body['verification_id'],
                      appealID:action.payload.appealId
                    }
                  });
                },((err)=>{
                  if(err){
                    this.ngrxstore.dispatch( new TriggerToast({
                      message:err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
                      style:'danger',
                      ttl:4
                    }));
                  }
                }));
              }else if(response.body['auth_type'] === 'totp'){
                this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    auth_type:response.body['auth_type'],
                    appealID:action.payload.appealId
                   }
                });
              }else {
                this.router.navigate([PORTAL_PATHS.TWO_FA_METHOD],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    qrCode:response.body['qr_code']
                  }
                });
              }
            }
          break;
          case 200:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
              let _payload = {...action, 
                        payload : {
                          origin : action.payload.origin,
                          redirect: this.auth.Redirects[response.body.role],
                          email:action.payload.email,
                          password:action.payload.password
                        }
                      }
              if(action.payload.appealId != null){
                this.onLoginSuccessWithID(_payload,response,action.payload.appealId);
              }else{
                this.onLoginSuccess(_payload,response);
              }
            }else{
              this.triggerErrorAsModal({
                error:'Something is wrong, your account may need to be reviewed, please contact us.'
              });
            }
          break;
          default:            
            this.ngrxstore.dispatch( new LogoutAction() );
            this.triggerErrorAsToast({err:response.body});
          break;
        }
      },((err)=>{
        console.log(err)
        if(err){
          this.ngrxstore.dispatch( new LogoutAction() );
          this.triggerErrorAsModal(err);
        }
      }))
    })
  )

  public onLoginSuccessWithID:any = (action,response,appealId,isDirectLogin=false)=>{
    this.ngrxstore.dispatch({
       type:AUTH_ACTIONS.COMPLETE_LOGIN,
       payload:{
         user:isDirectLogin ? action.name : response.body['name'] || null,
         roles:isDirectLogin ? action.role :[response.body['role']],
         user_id:isDirectLogin ? ((action['id']) ? parseInt(action['id']) : 1) : ((response.body['id']) ? parseInt(response.body['id']) : 1),
         token:isDirectLogin ? action.access :response.body['access'],
         refresh_token:isDirectLogin ? action.refresh :response.body['refresh'],
         origin:isDirectLogin ? USER_ORIGINS[action['role']] :USER_ORIGINS[response.body['role']]
       }
     });
     let _role = isDirectLogin ? action.role :response.body['role'];
     this.ngrxstore.dispatch( new SetNavigationRoles( [_role] ));
     if(action.payload && action.payload.toastConfig){
       this.ngrxstore.dispatch( new TriggerToast( action.payload.toastConfig ));
     }
     if((!isDirectLogin && (response.body['role'] === this.authConfig.roles.APPELLANT.slug || response.body['role'] === this.authConfig.roles.APPELLANT_COUNCEL.slug)) 
     || (isDirectLogin && (action['role'][0] === this.authConfig.roles.APPELLANT.slug || action['role'][0] === this.authConfig.roles.APPELLANT_COUNCEL.slug))){
      this.router.navigateByUrl(PORTAL_PATHS.APPEALS_APPLICATIONS_ROOT_PATH + '/' + PORTAL_PATHS.YOUR_APPEAL + '/' + appealId);
     }else{
      this.router.navigateByUrl(PORTAL_PATHS.TICKETS + '/' + PORTAL_PATHS.APPEAL_TICKETS + '/' + appealId);
     }
     
   }

// Effects
  // -----------`----------------------------------------
  @Effect({dispatch:false})
  onScanQRCode$: Observable<ScanQRCode> = this.actions$.pipe(
    ofType<ScanQRCode>(AUTH_ACTIONS.TWO_FA_SCAN_CODE),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.router.navigate([PORTAL_PATHS.TWO_FA_SCAN_QR],{
        state:{
          auth_type:action.payload.auth_type,
          qrCode:action.payload.qr_code,
          origin:action.payload.origin,
          redirect:action.payload.redirect,
          email:action.payload.email,
          password:action.payload.password,
          inviteToken:action.payload.inviteToken
        }
      });
    })
  )
  
  // Effects
  // -----------`----------------------------------------
  @Effect({dispatch:false})
  onRegisterPhone$: Observable<RegisterPhone> = this.actions$.pipe(
    ofType<RegisterPhone>(AUTH_ACTIONS.REGISTER_PHONE),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.router.navigate([PORTAL_PATHS.TWO_FA_REGISTER_PHONE],{
        state:{
          auth_type:action.payload.auth_type,
          origin:action.payload.origin,
          redirect:action.payload.redirect,
          email:action.payload.email,
          password:action.payload.password,
          inviteToken:action.payload.inviteToken
        }
      });
    })
  )

  @Effect({dispatch:false})
  onSelectSMSTwilio2fa$: Observable<SelectSMSTwilio2fa> = this.actions$.pipe(
    ofType<SelectSMSTwilio2fa>(AUTH_ACTIONS.SELECT_SMS_TWILIO_2FA),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.sendTwilioRequest({
        email:action.payload.email,
        auth_type:action.payload.auth_type
      }).pipe(take(1)).subscribe((response)=>{

        this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
          state:{
            email:action.payload.email,
            password:action.payload.password,
            origin:action.payload.origin,
            redirect:action.payload.redirect,
            //phone:response.body['phone'],
            auth_type: 'sms',
            verification_id: response.body['verification_id'],
            inviteToken:action.payload.inviteToken
          }
        });
      },((err)=>{
        if(err){
          if(err.error["error_code"] === 'phone_not_set') {
            this.router.navigate([PORTAL_PATHS.TWO_FA_REGISTER_PHONE],{
              state:{
                auth_type: 'sms',
                origin:action.payload.origin,
                redirect:action.payload.redirect,
                email:action.payload.email,
                password:action.payload.password,
                inviteToken:action.payload.inviteToken
              }
            });
          }else {
            if((err.error["error_code"] === 'landline_not_supported') || (err.error["error_code"] === 'twilio_error_60205')) {
              this.ngrxstore.dispatch(new TriggerModal({
                content:{
                  title:"Attention",
                  message: 'Landlines are not supported for the SMS verification method. Please register your mobile phone number.',
                  approveLabel: "Ok",
                  cancelLabel: "Cancel"
                },
                onCancel:()=>{this.ngrxstore.dispatch( new LogoutAction() );},
                onApprove: () => {
                  this.router.navigate([PORTAL_PATHS.TWO_FA_REGISTER_PHONE],{
                    state:{
                      auth_type: 'sms',
                      origin:action.payload.origin,
                      redirect:action.payload.redirect,
                      email:action.payload.email,
                      password:action.payload.password,
                      inviteToken:action.payload.inviteToken
                    }
                  });
                }
              }))
            } else {
              this.ngrxstore.dispatch( new LogoutAction() );
              this.ngrxstore.dispatch( new TriggerToast({
                message:err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
                style:'danger',
                ttl:4
              }));
            }
          }
        }
      }))
    })
  )

  @Effect({dispatch:false})
  onRegisterPhoneWithTwilio$: Observable<RegisterPhoneWithTwilio> = this.actions$.pipe(
    ofType<RegisterPhoneWithTwilio>(AUTH_ACTIONS.TWILIO_REGISTER_PHONE),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.setTwiloPhoneNumber({
        email:action.payload.email,
        phone_number:action.payload.phone,
        country_code: "US"
      }).pipe(take(1)).subscribe((response)=>{
        console.log("resposne>>", response);

        this.auth.sendTwilioRequest({
          email:action.payload.email,
          auth_type:action.payload.auth_type
        }).pipe(take(1)).subscribe((resp)=>{
          console.log("resposne>>", resp);
  
          this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
            state:{
              email:action.payload.email,
              password:action.payload.password,
              origin:action.payload.origin,
              redirect:action.payload.redirect,
              //phone:response.body['phone'],
              auth_type: 'sms',
              verification_id: resp.body['verification_id'],
              inviteToken:action.payload.inviteToken
            }
          });
        },((err)=>{
          if(err){
            if((err.error["error_code"] === 'landline_not_supported') || (err.error["error_code"] === 'twilio_error_60205')) {
              this.ngrxstore.dispatch(new TriggerModal({
                content:{
                  title:"Attention",
                  message: 'Landlines are not supported for the SMS verification method. Please register your mobile phone number.',
                  approveLabel: "Ok",
                  cancelLabel: "Cancel"
                },
                onCancel:()=>{this.ngrxstore.dispatch( new LogoutAction() );},
                onApprove: () => {
                  this.router.navigate([PORTAL_PATHS.TWO_FA_REGISTER_PHONE],{
                    state:{
                      auth_type: 'sms',
                      origin:action.payload.origin,
                      redirect:action.payload.redirect,
                      email:action.payload.email,
                      password:action.payload.password,
                      inviteToken:action.payload.inviteToken
                    }
                  });
                }
              }))
            } else {
              this.ngrxstore.dispatch( new LogoutAction() );
              this.ngrxstore.dispatch( new TriggerToast({
                message:err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
                style:'danger',
                ttl:4
              }));
            }
          }
        }))
      },((err)=>{
        if(err){
          if((err.error["error_code"] === 'landline_not_supported') || (err.error["error_code"] === 'twilio_error_60205')) {
            this.ngrxstore.dispatch(new TriggerModal({
              content:{
                title:"Attention",
                message: 'Landlines are not supported for the SMS verification method. Please register your mobile phone number.',
                approveLabel: "Ok",
                cancelLabel: "Cancel"
              },
              onCancel:()=>{this.ngrxstore.dispatch( new LogoutAction() );},
              onApprove: () => {
                this.router.navigate([PORTAL_PATHS.TWO_FA_REGISTER_PHONE],{
                  state:{
                    auth_type: 'sms',
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    email:action.payload.email,
                    password:action.payload.password,
                    inviteToken:action.payload.inviteToken
                  }
                });
              }
            }))
          } else {
            this.ngrxstore.dispatch( new LogoutAction() );
            this.ngrxstore.dispatch( new TriggerToast({
              message:err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
              style:'danger',
              ttl:4
            }));
          }
        }
      }))
    })
  )

  // @Effect({dispatch:false})
  // onRegisterPhoneWithTwoFactor$: Observable<RegisterPhoneWithTwoFactor> = this.actions$.pipe(
  //   ofType<RegisterPhoneWithTwoFactor>(AUTH_ACTIONS.TWO_FA_REGISTER_PHONE),
  //   tap((action)=>{
  //     this.auth.performLogin({
  //         email:action.payload.email,
  //         password:action.payload.password,
  //         phone:action.payload.phone,
  //         auth_type:action.payload.auth_type,
  //         last_2fa_check_success:new Date().toISOString()
  //     }).pipe(take(1)).subscribe((response)=>{
  //       switch(response.status){
  //         case 202:
  //           if(response.body.hasOwnProperty('detail') && response.body.hasOwnProperty('phone')){
  //             if(response.body['phone'] !== null){
  //               this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
  //                 state:{
  //                   email:action.payload.email,
  //                   password:action.payload.password,
  //                   origin:action.payload.origin,
  //                   redirect:action.payload.redirect,
  //                   phone:response.body['phone'],
  //                   auth_type: response.body['auth_type'],
  //                   inviteToken:action.payload.inviteToken
  //                 }
  //               });
  //             }else{
  //               this.ngrxstore.dispatch ( new LogoutAction() );
  //             }
  //           }
  //         break;
  //         case 200:
  //           if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
  //             if(action.payload.inviteToken != null){
  //               this.auth.updatePointOfContact(action.payload).subscribe((response1)=> {
  //                 this.onLoginSuccess(action,response);
  //               }, 
  //               (error) => { this.triggerErrorAsModal(error); });
  //             }else{
  //               this.onLoginSuccess(action,response);
  //             }
  //           }else{
  //             this.triggerErrorAsModal({
  //               error:'Something is wrong, your account may need to be reviewed, please contact us.'
  //             });
  //           }
  //         break;
  //         default:
  //           this.ngrxstore.dispatch( new TriggerToast({
  //             message:response.message,
  //             style:'danger',
  //             ttl:5
  //           }));
  //           this.ngrxstore.dispatch ( new LogoutAction() );
  //         break;
  //       }
  //     },((err)=>{
  //       if(err){
  //         this.ngrxstore.dispatch( new LogoutAction() );
  //         this.triggerErrorAsModal(err);
  //       }
  //     }))
  //   })
  // )

  @Effect({dispatch:false})
  onInviteUser$:Observable<InviteUser> = this.actions$.pipe(
    ofType<InviteUser>(AUTH_ACTIONS.INVITE_USER),
    tap((action)=>{
      //alert("INVITE USER"+ JSON.stringify(action));
      let _group = action.payload.group;
      if(_group === 'Appellant'){
        _group = 'Borrower'
      }
      this.auth.inviteAppellantPOC({...action.payload})
        .pipe(take(1)).subscribe((response)=> {
          this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Invite Sent",
              message: 'An invitation has been sent to the new Point of Contact.',
            }
          }))
          if(action.callback){
            action.callback(true);
          }

        }, (error) => { this.triggerErrorAsModal(error); });
  }));

  @Effect({dispatch:false})
  onTwilioLoginWithToken$: Observable<TwilioLoginWithMFATokenAction> = this.actions$.pipe(
    ofType<TwilioLoginWithMFATokenAction>(AUTH_ACTIONS.TWILIO_TWO_FA_PROVIDE),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.performTwilioLoginWithToken({
          token:action.payload.token,
          email:action.payload.email,
          verification_id:action.payload.verification_id,
      }).pipe(take(1)).subscribe((response)=>{
          if(response.body.hasOwnProperty('verified') && response.body["verified"]){
            this.auth.performLogin({
              email:action.payload.email,
              password:action.payload.password,
            }).subscribe((response)=>{
              switch(response.status){
                case 200:
                  if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
                    let _payload = {...action, 
                              payload : {
                                origin : action.payload.origin,
                                redirect: this.auth.Redirects[response.body.role],
                                email:action.payload.email,
                                password:action.payload.password
                              }
                            }
                    if(action.payload.inviteToken != null){
                      this.auth.updatePointOfContact(action.payload).subscribe((response1)=> {
                        this.onLoginSuccess(action,response);
                      }, 
                      (error) => { this.triggerErrorAsModal(error); });
                    }else if(action.payload.appealID){
                      this.onLoginSuccessWithID(action.payload,response,action.payload.appealID);
                    }else{
                      this.onLoginSuccess(action,response);
                    }
                  }else{
                    this.triggerErrorAsModal({
                      error:'Something is wrong, your account may need to be reviewed, please contact us.'
                    });
                  }
                break;
                default:            
                  this.ngrxstore.dispatch( new LogoutAction() );
                  this.triggerErrorAsToast({err:response.body});
                break;
              }
            },((err)=>{
              console.log(err)
              if(err){
                this.ngrxstore.dispatch( new LogoutAction() );
                this.triggerErrorAsModal(err);
              }
            }))
          }else {
            this.ngrxstore.dispatch(new TriggerModal({
              content:{
                title:"Invalid Token",
                message: "Please enter correct token.",
              }
            }))
            if(action.payload.callback){
              action.payload.callback();
            }
          }
      },((err)=>{
        if(err){
          this.ngrxstore.dispatch( new LogoutAction() );
          this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Attention",
              message: err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
            }
          }))
        }
      }))
    })
  )

  @Effect({dispatch:false})
  onLoginWithToken$: Observable<LoginWithMFATokenAction> = this.actions$.pipe(
    ofType<LoginWithMFATokenAction>(AUTH_ACTIONS.TWO_FA_PROVIDE),
    tap((action)=>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.performLoginWithToken({
          token:action.payload.token,
          email:action.payload.email,
          password:action.payload.password,
          auth_type:action.payload.auth_type
      }).pipe(take(1)).subscribe((response)=>{
        //console.log(response);
        switch(response.status){
          case 200:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
              if(action.payload.inviteToken != null){
                this.auth.updatePointOfContact(action.payload).subscribe((response1)=> {
                  this.onLoginSuccess(action,response);
                }, 
                (error) => { this.triggerErrorAsModal(error); });
              }else if(action.payload.appealID){
                this.onLoginSuccessWithID(action.payload,response,action.payload.appealID);
              }else{
                this.onLoginSuccess(action,response);
              }
            }else{
              this.triggerErrorAsModal({
                error:'Something is wrong, your account may need to be reviewed, please contact us.'
              });
            }
          break;
          default:
            this.ngrxstore.dispatch( new TriggerToast({
              message:JSON.stringify(response.body),
              style:'danger',
              ttl:5
            }));
            this.ngrxstore.dispatch( new LogoutAction() );
          break;
        }
      },((err)=>{
        if(err){
          this.ngrxstore.dispatch( new LogoutAction() );
          this.triggerErrorAsModal(err)
        }
      }))
    })
  )

  @Effect({dispatch:false})
  onLogin$: Observable<LoginAction> = this.actions$.pipe(
    ofType<LoginAction>(AUTH_ACTIONS.LOGIN),
    tap((action) =>{
      this.ngrxstore.dispatch(new SetOrigin(action.payload.origin));
      this.auth.performLogin({
        email:action.payload.email,
        password:action.payload.password,
      }).subscribe((response)=>{
        switch(response.status){
          case 202:
            if(response.body.hasOwnProperty('phone')){
              if(response.body['auth_type'] === 'sms'){
                this.auth.sendTwilioRequest({
                  email:action.payload.email,
                  auth_type:'sms'
                }).pipe(take(1)).subscribe((resp)=>{
          
                  this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
                    state:{
                      email:action.payload.email,
                      password:action.payload.password,
                      origin:action.payload.origin,
                      redirect:action.payload.redirect,
                      //phone:response.body['phone'],
                      auth_type: 'sms',
                      verification_id: resp.body['verification_id'],
                      inviteToken:action.payload.inviteToken
                    }
                  });
                },((err)=>{
                  if(err){
                    this.ngrxstore.dispatch( new TriggerToast({
                      message:err.error["display"] ? err.error["display"]: "Unable to verify at this time.",
                      style:'danger',
                      ttl:4
                    }));
                  }
                }));
              }else if(response.body['auth_type'] === 'totp'){
                this.router.navigate([PORTAL_PATHS.TWO_FA_PROVIDE],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    auth_type:response.body['auth_type'],
                    inviteToken:action.payload.inviteToken 
                  }
                });
              }else {
                this.router.navigate([PORTAL_PATHS.TWO_FA_METHOD],{
                  state:{
                    email:action.payload.email,
                    password:action.payload.password,
                    origin:action.payload.origin,
                    redirect:action.payload.redirect,
                    qrCode:response.body['qr_code'],
                    inviteToken:action.payload.inviteToken
                  }
                });
              }
            }
          break;
          case 200:
            if(response.body.hasOwnProperty('access') && response.body.hasOwnProperty('refresh')){
              let _payload = {...action, 
                        payload : {
                          origin : action.payload.origin,
                          redirect: this.auth.Redirects[response.body.role],
                          email:action.payload.email,
                          password:action.payload.password
                        }
                      }
              if(action.payload.inviteToken != null){
                this.auth.updatePointOfContact(action.payload).subscribe((response1)=> {
                  this.onLoginSuccess(_payload,response);
                }, 
                (error) => { this.triggerErrorAsModal(error); });
              }else{
                this.onLoginSuccess(_payload,response);
              }
            }else{
              this.triggerErrorAsModal({
                error:'Something is wrong, your account may need to be reviewed, please contact us.'
              });
            }
          break;
          default:            
            this.ngrxstore.dispatch( new LogoutAction() );
            this.triggerErrorAsToast({err:response.body});
          break;
        }
      },((err)=>{
        console.log(err)
        if(err){
          this.ngrxstore.dispatch( new LogoutAction() );
          this.triggerErrorAsModal(err);
        }
      }))
    }),
    map((value,index)=>{
      return value;
    })
  );

  @Effect({dispatch:false})
  onLogout$: Observable<LogoutAction> = this.actions$.pipe(
    ofType<LogoutAction>(AUTH_ACTIONS.LOGOUT),
    tap((action) =>{
      this.ngrxstore.select(selectSessionState()).pipe(take(1)).subscribe((state)=>{
        
        this.logoutSubscription = this.auth.performLogout().subscribe(() =>{
          this.onLogout(state.origin);
          this.ngrxstore.dispatch({
            type:AUTH_ACTIONS.COMPLETE_LOGOUT
          });
          this.ngrxstore.dispatch({
            type:APPEALS_APPLICATIONS_ACTIONS.CLEAR_FILTER_VALUE
          });
          this.ngrxstore.dispatch( new SetNavigationRoles(['None']));
          this.ngrxstore.dispatch( new SetCurrentPageTab(''));
          if(action['toastConfig']){
            this.ngrxstore.dispatch(new TriggerToast(action['toastConfig']));
          }
        },(err)=>{
            this.logoutSubscription.unsubscribe();
            this.onLogout(state.origin);
            this.ngrxstore.dispatch({
              type:AUTH_ACTIONS.COMPLETE_LOGOUT
            });
            this.ngrxstore.dispatch({
              type:APPEALS_APPLICATIONS_ACTIONS.CLEAR_FILTER_VALUE
            });
            this.ngrxstore.dispatch( new SetNavigationRoles(['None']));
            this.ngrxstore.dispatch( new SetCurrentPageTab(''));
        });
      })
    })
  )

  @Effect({dispatch:false})
  onStoreCurrentURL$: Observable<StoreCurrentURL> = this.actions$.pipe(
    ofType<StoreCurrentURL>(NAVIGATION_CONSTANTS.SET_CURRENT_ROUTE_URL),
    tap((actions)=>{
      this.ngrxstore.select(selectSessionState()).pipe(take(1)).subscribe((state)=>{
        if(state.status === 'signedIn' && state.token && state.refresh_token){
          this.auth.refreshAccess(state.refresh_token).subscribe((response)=>{
            this.refreshTokenSuccess(response);
          },(err)=>{
            if(err){
              // this.triggerErrorAsModal(err);
            }
          });
        }
      })
    })
  )


  @Effect({dispatch:false})
  onRequestLogout$: Observable<RequestLogoutAction> = this.actions$.pipe(
    ofType<RequestLogoutAction>(AUTH_ACTIONS.REQUEST_LOGOUT),
    tap((action) =>{
      this.ngrxstore.dispatch(new TriggerModal({
        content:{
          title:"Are you sure you want to logout?",
          approveLabel: "Logout",
          cancelLabel: "Stay Logged In"
        },
        onCancel:()=>{},
        onApprove:()=>{
          this.ngrxstore.dispatch( new LogoutAction() );
        }
      }))
     
    })
  )

  @Effect({dispatch:false})
    onSaveProfileChanges$: Observable<SaveProfileChanges> = this.actions$.pipe(
      ofType<SaveProfileChanges>(AUTH_ACTIONS.SAVE_PROFILE_CHANGES),
      tap((action) =>{
          this.auth.saveProfileChanges(action.payload).pipe(take(1)).subscribe((response)=>{
            if(action.msgBool){
              //no action if true
            }else{
              //if undefined or false
              this.ngrxstore.dispatch( new TriggerToast({
                message:"Changes Saved",
                style:'success',
                ttl:4
              }));
            }
           
            if(action.callback){
              action.callback(response.body,true);
            }
          },((err)=>{
            if(err){
              let _message="";
              if(err.error.non_field_errors){
                err.error.non_field_errors.map((message) => {
                    _message+= message;
                });
              }
              this.ngrxstore.dispatch( new TriggerToast({
                message:_message,
                style:'danger',
                ttl:4
              }));
              if(action.callback){
                action.callback({},false);
              }
            }
          }))
      })
    );

  @Effect({dispatch:false})
  onSaveAppealProfileChanges$: Observable<SaveAppealProfileChanges> = this.actions$.pipe(
    ofType<SaveAppealProfileChanges>(AUTH_ACTIONS.SAVE_APPEAL_PROFILE_CHANGES),
    tap((action) =>{
        this.auth.saveAppealProfileChanges(action.payload).pipe(take(1)).subscribe((response)=>{
          if(action.msgBool){
            //no action if true
          }else{
            //if undefined or false
            this.ngrxstore.dispatch( new TriggerToast({
              message:"Changes Saved",
              style:'success',
              ttl:4
            }));
          }
          
          if(action.callback){
            action.callback(response.body,true);
          }
        },((err)=>{
          if(err){
            let _message="";
            if(err.error.non_field_errors){
              err.error.non_field_errors.map((message) => {
                  _message+= message;
              });
            }
            this.ngrxstore.dispatch( new TriggerToast({
              message:_message,
              style:'danger',
              ttl:4
            }));
            if(action.callback){
              action.callback({},false);
            }
          }
        }))
    })
  );

  @Effect({dispatch:false})
  onRequestResetPasswordAction$: Observable<RequestResetPasswordAction> = this.actions$.pipe(
    ofType<RequestResetPasswordAction>(AUTH_ACTIONS.REQUEST_RESET_PASSWORD),
    tap((action)=>{
      this.auth.requestPasswordReset({
        "email":action.payload.email
      }).toPromise().then((res)=>{
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:"Check Your Email",
            message:"We have sent a reset link to your accounts email address. Please follow that link to reset your password."
          },
          type:'message'
        }))
        this.router.navigateByUrl(action.payload.redirect);
      }).catch((err)=>{
        if(err){
          this.triggerErrorAsModal(err);
        }
      })
    })
  );

  @Effect({dispatch:false})
  onRequestMFAPasswordAction$: Observable<RequestResetMFAAction> = this.actions$.pipe(
    ofType<RequestResetMFAAction>(AUTH_ACTIONS.REQUEST_RESET_MFA),
    tap((action)=>{
      this.auth.requestMFAReset({
        "ref":action.payload.ref
      }).toPromise().then((res)=>{
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:"MFA settings have been reset",
            message:"We have reset your MFA settings."
          },
          type:'message'
        }))
        this.router.navigateByUrl(action.payload.redirect);
      }).catch((err)=>{
        if(err){
          this.triggerErrorAsModal(err);
        }
      })
    })
  );
  
  @Effect({dispatch:false})
  onResetPasswordAction$: Observable<ResetPasswordAction> = this.actions$.pipe(
    ofType<ResetPasswordAction>(AUTH_ACTIONS.RESET_PASSWORD),
    tap((action)=>{
      this.auth.resetPassword({
        "token":action.payload.resetToken,
        "password":action.payload.password,
        "confirm_password":action.payload.confirm_password,
      }).toPromise().then((res)=>{
        if(res.status === "OK"){
          this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Success",
              message:"Your password has been reset, please login with your new password."
            },
            type:'message'
          }))
          this.ngrxstore.dispatch(new LogoutAction())
        }
      }).catch((res)=>{
        if(res.error.status === "notfound"){
            this.ngrxstore.dispatch(new TriggerModal({
              content:{
                title:"We cannot Reset Your Password",
                message:"Your attempting to use an old or invalid reset code. Please resubmit your password reset request."
              },
              type:'message'
            }))
        }
        else if(res.error.password){
            this.ngrxstore.dispatch(new TriggerModal({
              content:{
                title:"We cannot Reset Your Password",
                message:res.error["password"]
              },
              type:'message'
            }))
        }else {
          this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"We cannot Reset Your Password",
              message:"An error occured that needs adminstrator attention, please contact us for futher assistance."
            },
            type:'message'
          }))
        }
        // if(res.ok === false){
        //   if(res.error){
        //     if(res.error.type === 'invalid_code'){
        //       this.ngrxstore.dispatch(new TriggerModal({
        //         content:{
        //           title:"We cannot Reset Your Password",
        //           message:"Your attempting to use an old or invalid reset code. Please resubmit your password reset request."
        //         },
        //         type:'message'
        //       }))
        //     }else{
        //       this.ngrxstore.dispatch(new TriggerModal({
        //         content:{
        //           title:"We cannot Reset Your Password",
        //           message:"An error occured that needs adminstrator attention, please contact us for futher assistance."
        //         },
        //         type:'message'
        //       }))
        //     }
        //   }
        // }
      }
      )
    })
  )

}
