import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TriggerToast } from '../../../../platform/modules/notification';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { SignCertificateOfService } from '../../store/appeal-wizard.actions';
import { WizardStep } from '../wizard-step.component';

@Component({
    selector:'certificate-of-service',
    template:require('./certificate-of-service.component.html'),
})
export class CertificateOfService extends WizardStep{

    public signatureForm:FormGroup = new FormGroup({});
    
    constructor(
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService
    ){
        super(
            "Certificate Of Service",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        this.signatureForm = this.formBuilder.group({
            signature_for_electronic_filing:[null,Validators.required]
        })

        this.watchChanges();
    }
    
    completeStep(){
        this.ngrxstore.dispatch( new SignCertificateOfService({
            signature_for_electronic_filing:this.signatureForm.controls['signature_for_electronic_filing'].value,
            documents:[]
        }, ""));
    }
}