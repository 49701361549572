import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { selectPPPAppealWizardValues, selectPPPAppealWizardStatus } from './store/appeal-wizard.selectors';
import { Observable, BehaviorSubject, observable, of, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { APPEAL_WIZARD_POST_TABS, APPEAL_WIZARD_STEPS, WIZARD_FIELDS } from './constants/wizard_steps.constants';
import { zip } from 'lodash';
import { ApiConfig, API_CONFIG, AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../app.interface';
import { SaveAppeal } from './store/appeal-wizard.actions';
import { FileItem } from '@wkoza/ngx-upload';
import { _RESOLVED_META_REDUCERS } from '@ngrx/store/src/tokens';
import { mapSlugToId, mapValueToId} from '../../platform/utilities/helpers';

@Injectable({
    providedIn:'root'
})
export class PPPAppealWizardService{

    public $missingFields:BehaviorSubject<any> = new BehaviorSubject([]);
    public currentLoanId:string = null;
    public $formValid = new BehaviorSubject(false);

    public get ForgivenessEstimate(){
        return this.$forgivenessEstimate;
    }
    public $forgivenessEstimate:BehaviorSubject<any> = new BehaviorSubject({
        forgivenAmount:1000,
        forgivenAmountPercent:50,
        notionalValue:2000,
        amountToBeRepaid:1000,
        amountToBeRepaidPercent:50
    });

    public $RefreshTrigger:BehaviorSubject<boolean> = new BehaviorSubject(false);

    updateFormValidation(flag: boolean) {
        this.$formValid.next(flag)
    }

    get $MissingFields(){
        return new Observable((obs:any)=>{
            this.ngrxstore.select(
                selectPPPAppealWizardValues()
            ).pipe(take(1)).subscribe((values)=>{
                this.trackMissingFields(values);
                this.$missingFields.pipe(take(1)).subscribe((missing_fields)=>{
                    obs.next(missing_fields)
                    obs.complete();
                })
            });
        })
    }

    get WizardFields(){
        return WIZARD_FIELDS;
    }

    get WizardSteps(){
        return APPEAL_WIZARD_STEPS;
    }

    get PostWizardSteps(){
        return APPEAL_WIZARD_POST_TABS;
    }

    constructor(
        @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
        @Inject(API_CONFIG) public api:ApiConfig,
        public ngrxstore:Store,
        public http:HttpClient
    ){
        this.ngrxstore.select(selectPPPAppealWizardStatus).subscribe((state)=>{
            this.currentLoanId = state.current_loan_id;
        })
    }

    interpretErrorsFromAPI(err):any{
        let col = err.error;
        let title = 'An Error Occured';
        let message = 'An unexpected error occured, check to make sure you submitted information is correct and valid.';

        if(Object.values(col).length>0){

            title = 'Invalid Submission'
            message = '';

            if(col.phone_number){
                message += 'You have supplied an invalid U.S. Phone Number. '
            }

            if(col.detail){
                message += col.detail+' This could do with an attempt to submit a duplicate application. Please try making a new application and provide us with feedback. We apologize for the inconvience.';
            }

            if(message.length < 1){
                message += 'Please ensure all your information is correct and valid.'
            }
        }

        return {
            title,
            message
        }
    }

    getSavedValuesForStep(stepName:string):Observable<any>{
        // return saved values from api
        return new Observable((obj)=>{
            obj.next(stepName);
            obj.complete();
        });
    }

    removeDocumentFromAppeal(appealSlug:string,documentSlug:string){
        if(appealSlug !== undefined){
            return this.http.delete(this.api.endpoints.APPEAL_ROOT+'/'+appealSlug+this.api.endpoints.APPEAL_DOCUMENTS+'/'+documentSlug).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    getUploadedDocuments(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT + '/' + id + this.api.endpoints.APPEAL_DOCUMENTS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    
                    let _notesList = response['results'];
                    let _serverData = [];
                    
                    _notesList.map((note)=>{
                        _serverData.push(note.documents);
                    });
                    
                    obs.next( [].concat.apply([],_serverData) );
                    obs.complete();
                })
        });
    }

    getLenders(offset?:number,limit?:number){
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_LENDERS,{
                params:_params 
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getCOSSetting(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.SETTINGS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    console.log(response['certificate_of_service_enabled']);
                    obs.next(response['certificate_of_service_enabled']);
                    obs.complete();
                })
        });
    }

    getIndustry(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_INDUSTRY)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getIndustryCode(description:string){

        let _params = {};
        if(description !== undefined) _params['description'] = description.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_INDUSTRY,{
                params:_params
            }).pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    changeAppealState(appealSlug:string,action:any){
        if(appealSlug !== undefined){
            return this.http.put(
                this.api.endpoints.APPEAL_ROOT+'/'
                +appealSlug
                +this.api.endpoints.APPEAL_ACTIONS+'/'
                +action.slug,{}
            ).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    getAppealApplication(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT+'/'+id).pipe(
                take(1))
            .subscribe((response)=>{
                let _response = Object.assign({},response,{
                    primary_contact:response['primary_contact'] != null ? response['primary_contact'].username: "",
                    name:response['primary_contact'] != null ? response['primary_contact'].name : "",
                    lender:response['lender'].id,
                    lender_name:response['lender'].name,
                    primary_contact_reference_id : response['primary_contact'] != null ? response['primary_contact'].reference_id: ""
                });
                obs.next(_response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    postAppeal(payload:any):Observable<any>{
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT'],
            payload,{observe:'response'}
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    uploadAppealDocument(appealId:string,document:FileItem,details:{
        reference_id?:string,
        is_privileged?:boolean,
    }){
        let _formData = new FormData();
        
        _formData.append('filename',document.file['given_name']);
        _formData.append('category',document.file['slug']);
        _formData.append('name',document.file['name']);
        _formData.append('file',document.file);

        if(details.is_privileged){
            _formData.append('is_privileged','true');
        }

        if(details.reference_id){
            _formData.append('attach_to_note_reference_id',details.reference_id);
        }
        
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENTS'],
            _formData
        ).pipe(take(1))
    }

    updateDemographicDetails(slug:string,demoSlug:string,details:any){
        return this.http.put(this.api.endpoints['APPEAL_ROOT'] + "/" + slug + 
        this.api.endpoints['PRINCIPAL']+'/'+demoSlug, details).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    saveDemographicDetails(slug:string,details:any){
        return this.http.post(this.api.endpoints['APPEAL_ROOT'] + "/" + slug + 
        this.api.endpoints['PRINCIPAL'], details).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    removeDemographicDetails(appealSlug:string,demographicSlug:string){
        return this.http.delete(this.api.endpoints.APPEAL_ROOT+'/'+appealSlug+
        this.api.endpoints.PRINCIPAL+'/'+demographicSlug).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getDemographicData(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT']+'/'+id 
            + this.api.endpoints['PRINCIPAL']).pipe(
                take(1))
            .subscribe((response)=>{
                let _results = [];
                response['results'].map((demographic)=>{
                    let _demographic = {};
                    Object.keys(demographic).map((key)=>{
                        if(Array.isArray(demographic[key])){
                            _demographic[key] = mapSlugToId(demographic[key]);
                        }
                    });
                    _results.push(_demographic)
                })
                obs.next(response["results"]);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    trackMissingFields(field_values:any){
        
        let _missingFields = [];
        let _tempMissingFields = [];

        let _ez_wizard_mode = (field_values.ez_wizard_mode === 'true');
        for(let field in WIZARD_FIELDS){
            let _wF = WIZARD_FIELDS[field];
            let _missing = (!field_values[_wF.storeKey] || field_values[_wF.storeKey] === '')

            if(field === 'LI_LOAN_NUMBER'){
                _missing = (!field_values[_wF.storeKey] || field_values[_wF.storeKey] === '' || field_values[_wF.storeKey].length != 10)
            }

            if(field === 'POC_PHONE_NUMBER'){
                _missing = (!field_values[_wF.storeKey] || field_values[_wF.storeKey] === '' || field_values[_wF.storeKey].length != 12)
            }

            if(field === 'LI_LOAN_AMOUNT'){
                _missing = (!field_values[_wF.storeKey] || field_values[_wF.storeKey] === '' || field_values[_wF.storeKey] > 10000000 || field_values[_wF.storeKey] === "0.00")
            }

            if(field === 'LI_FORGIVENESS_AMOUNT_REQ'){
                _missing = (!field_values[_wF.storeKey] || field_values[_wF.storeKey] === '' || field_values[_wF.storeKey] > 10000000 || field_values[_wF.storeKey] === "0.00")
            }

            

            // If the wizard is in EZ Mode
            // ----------------------------
            if(_ez_wizard_mode && _missing){

                // Is it missing and has no dependents?
                if( _wF.required === true && 
                    (_wF.onlyOn === 'EZ' || !_wF.onlyOn) ){
                        if(_wF.isAtleastOne){
                            _tempMissingFields.push(_wF);
                        }else{
                            _missingFields.push(_wF);
                        }
                       
                }

                // Is it missing with dependent conditions? Does it need to be required?
                if( _wF.required === undefined || _wF.required === null  ){
                    if( Object.keys(WIZARD_FIELDS).indexOf(_wF.requiredIf) > -1 ){
                        let _dependentFieldValue = field_values[
                            WIZARD_FIELDS[_wF.requiredIf].storeKey
                        ];
                        if(_wF.requiredCondition === _dependentFieldValue){
                            _missingFields.push(_wF);
                        }
                    }
                }
            }

            // If the wizard is not in EZ Mode
            // --------------------------------
            if(!_ez_wizard_mode && _missing){

                // Is it missing and has no dependents?
                if( _wF.required === true && 
                    ( _wF.onlyOn !== 'EZ' || !_wF.onlyOn) ){
                        if(_wF.isAtleastOne){
                            _tempMissingFields.push(_wF);
                        }else{
                            _missingFields.push(_wF);
                        }
                }

                // Is it missing with dependent conditions? Does it need to be required?
                if( _wF.required === undefined || _wF.required === null  ){
                    if( Object.keys(WIZARD_FIELDS).indexOf(_wF.requiredIf) > -1 ){
                        let _dependentFieldValue = field_values[
                            WIZARD_FIELDS[_wF.requiredIf].storeKey
                        ];
                        if(_wF.requiredCondition === _dependentFieldValue){
                            _missingFields.push(_wF);
                        }
                    }
                }
            }
        }

        if(_tempMissingFields.length === 5){
            if(_missingFields.indexOf(WIZARD_FIELDS["AC_CRITERIA2"]) == -1){
                _missingFields.push(WIZARD_FIELDS["AC_CRITERIA2_REASONS"]); 
            }
        }

        this.$missingFields.next(_missingFields);

    }

    requestNewAppeal(payload:any):Observable<any>{
        //console.log(payload);
        return new Observable((obs)=>{
            obs.next({
                id:this.appDefaults.ui.NEW_APPEAL_SLUG
            });
            obs.complete();
        })
    }

    saveApplication(payload:any){
        let _payload = this.formatPayload(payload);
        if(!_payload.hasOwnProperty('type')) _payload.type = 'ppp';
        return this.http.post(this.api.endpoints['APPEAL_ROOT'], _payload).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    updateApplication(payload:any, slug:string , user_ref = ""){
        if(slug !== undefined){
            let _payload = this.formatPayload(payload);
            _payload['submitter']= user_ref
           return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+slug, _payload).pipe(
               take(1),
               catchError(err => throwError(err))
           );
        }
    }

    updateReconApplication(payload,slug) {
        return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+slug, payload).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    submitApplicationWithoutCOS(appealId:string, documents:any, signature_for_electronic_filing?: string ){
        if(appealId !== undefined){
            return this.http.post(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+this.api.endpoints['APPEAL_DOCKETS'],
            {documents,signature_for_electronic_filing}).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    updateAppellantGroup(group:string, username:string){
        let _group = group === "appellant" ? "Appellant" : "Appellant Counsel";
        return this.http.put(this.api.endpoints['UPDATE_USER']+'/'+username + this.api.endpoints['GROUP'] +
       '/'+ _group , {}).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    signCertificateOfService(signature:string,slug:string,documents:any,docketFilingRef:string){
        return this.http.put(this.api.endpoints['APPEAL_ROOT']+
        '/'+slug+this.api.endpoints['APPEAL_DOCKETS'] + '/' + docketFilingRef, {
            signature,documents
        }).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    // signCertificateOfService(signature:string,slug:string,items:any){
    //     return this.http.post(this.api.endpoints['APPEAL_ROOT']+'/'+slug+this.api.endpoints['APPEAL_CERTIFICATE_OF_SERVICE'], {
    //         signature,items
    //     }).pipe(
    //         take(1),
    //         catchError(err => throwError(err))
    //     );
    // }

    generateCertificate(id:string):Observable<any>{
        return this.http.post(this.api.endpoints['APPEAL_ROOT']+'/'+id+
        this.api.endpoints['APPEAL_CERTIFICATE_OF_SERVICE'], '').pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    formatPayload(payload:any){
        // Some values need to be adjusted
        let _payload = Object.assign({},payload);
        let _lAmount = _payload[WIZARD_FIELDS['LI_LOAN_AMOUNT'].storeKey];
        let _lForgive = _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT'].storeKey];
        let _lForgiveReq = _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT_REQ'].storeKey];
        let _lForgiveReqLender = _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey];
        let _reviewDate = _payload[WIZARD_FIELDS['AC_LOAN_REVIEW_RECEIVED'].storeKey];
        let _pppLoanDisbursementDate = _payload[WIZARD_FIELDS['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey];
        let _pppLoanForgivessDate = _payload[WIZARD_FIELDS['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey];

        if(_lAmount){
            _payload[WIZARD_FIELDS['LI_LOAN_AMOUNT'].storeKey] = (_lAmount * 100).toFixed(0);
        }

        if(_lForgive){
            _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT'].storeKey] = (_lForgive * 100).toFixed(0);
        }

        if(_lForgiveReq){
            _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] = (_lForgiveReq * 100).toFixed(0);
        }

        if(_lForgiveReqLender){
            _payload[WIZARD_FIELDS['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] = (_lForgiveReqLender * 100).toFixed(0);
        }

        if(_reviewDate){
            _payload[WIZARD_FIELDS['AC_LOAN_REVIEW_RECEIVED'].storeKey] = (new Date(_reviewDate)).toISOString();
        }

        if(_pppLoanDisbursementDate){
            _payload[WIZARD_FIELDS['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey] = (new Date(_pppLoanDisbursementDate)).toISOString();
        }

        if(_pppLoanForgivessDate){
            _payload[WIZARD_FIELDS['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey] = (new Date(_pppLoanForgivessDate)).toISOString();
        }

        return _payload;
    }

    getDemographicVeteran():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_VETERAN']).pipe(
                take(1))
            .subscribe((response)=>{
                let _response = mapValueToId(Object.assign([],response));
                obs.next({name:'veteran_status', options: _response});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDemographicGender():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_GENDER']).pipe(
                take(1))
            .subscribe((response)=>{
                let _response = mapValueToId(Object.assign([],response));
                obs.next({name:'Gender', options: _response});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDemographicRace():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_RACE']).pipe(
                take(1))
            .subscribe((response)=>{
                let _response = mapSlugToId(Object.assign([],response['results']));
                obs.next({name:'Race', options: _response});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDemographicEthnicity():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ETHNICITY']).pipe(
                take(1))
            .subscribe((response)=>{
                let _response = mapValueToId(Object.assign([],response));
                obs.next({name:'Ethnicity', options: _response});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDocumentCategories(types?:string[],offset?:number,limit?:number):Observable<any>{
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['DOCUMENT_CATEGORIES_APPELLANT'],{
                params:_params 
            }).pipe(
                take(1),
            ).subscribe((categories)=>{
                let _categories = [{
                    slug:null,
                    type:null,
                    display_name:"Error: No Categories Loaded"
                }];
                if(categories['results']){
                    if(types){
                        //console.log(types,categories['results'])
                        _categories = categories['results'].filter((category)=>{
                            return types.indexOf(category.type)>-1
                        });    
                    }else{
                        _categories = categories['results']
                    }

                    let _finalCategories = [];
                    _categories.map((category)=>{

                        _finalCategories.push({
                            slug:category.slug,
                            display_name:category.display_name,
                            type:category.type,
                            requiredUploads:['generic'],
                            is_privileged:false
                        });
                        
                    })

                    // console.log(_finalCategories)
                    obs.next(_finalCategories);
                    obs.complete();
                }
            },(err)=>{
                if(err){
                    obs.next(null);
                    obs.complete();
                    console.log(err)
                }
            })
        })
    }

    getLendersList(search:string):Observable<any>{
        let _params = {};
        if(search !== undefined) _params['search'] = search.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_LENDERS,{
                params:_params
            }).pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }
}
