import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { NavigatedFromSummary } from '../../store/appeal-wizard.actions';
import { selectNavigationFlag } from '../../store/appeal-wizard.selectors';
import { WizardStep } from '../wizard-step.component';

@Component({
    selector:'loan-information',
    template:require('./loan-information.component.html'),
})
export class LoanInformation extends WizardStep{

    public validLoanNumber = true;
    public isNavigatedFromSummary = false;
    
    constructor(
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService
    ){
        super(
            "Loan Information",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        let _initialValues = this.activatedRoute.snapshot.data.state.storedValues;

        this.ngrxstore.select(selectNavigationFlag()).subscribe((isNavigated)=>{
            this.isNavigatedFromSummary = isNavigated;
        });

        this.formGroup = this.formBuilder.group({
            [this.fields.LI_LOAN_NUMBER.storeKey]:[_initialValues[
                this.fields.LI_LOAN_NUMBER.storeKey
            ],[Validators.maxLength(10),Validators.minLength(10),Validators.required]],
            [this.fields.LI_LOAN_AMOUNT.storeKey]:[_initialValues[
                this.fields.LI_LOAN_AMOUNT.storeKey
            ],[Validators.max(10000000),Validators.min(1)]],
            [this.fields.LI_FORGIVENESS_AMOUNT.storeKey]:[_initialValues[
                this.fields.LI_FORGIVENESS_AMOUNT.storeKey
            ],[Validators.max(10000000)]],
            [this.fields.LI_FORGIVENESS_AMOUNT_REQ.storeKey]:[_initialValues[
                this.fields.LI_FORGIVENESS_AMOUNT_REQ.storeKey
            ],[Validators.max(10000000),Validators.min(1)]],
            [this.fields.LI_FORGIVENESS_AMOUNT_REQ_LENDER.storeKey]:[_initialValues[
                this.fields.LI_FORGIVENESS_AMOUNT_REQ_LENDER.storeKey
            ],[Validators.max(10000000)]],
            [this.fields.LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE.storeKey]:[_initialValues[
                this.fields.LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE.storeKey
            ],Validators.required],
            [this.fields.LI_PPP_LOAN_DISBURSEMENT_DATE.storeKey]:[_initialValues[
                this.fields.LI_PPP_LOAN_DISBURSEMENT_DATE.storeKey
            ],Validators.required]
        });  

        // This is a method in the WizardStep class which watches value changes on formGroup, simply call it and the ngrx store will start saving values as a user types.
        this.watchChanges();

    }


    ngOnDestroy(){
        this.ngrxstore.dispatch( new NavigatedFromSummary(false))
    }
}