import {Injectable,Inject} from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectPPPAppealWizardValues, selectPPPAppealWizardStatus, selectWizardSteps } from '../store/appeal-wizard.selectors';
import { take, map  } from 'rxjs/operators';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { AuthService, selectSessionState } from '../../../platform/modules/auth';

@Injectable({
    providedIn: 'root'
})
export class PPPAppealWizardStepResolver implements Resolve < any > {

    public route: ActivatedRouteSnapshot = null;

    constructor(
        @Inject(Store) public ngrxstore: Store,
        @Inject(PPPAppealWizardService) public appealWizardService,
        @Inject(AuthService) public authService
    ) {}

    // Provides the stored wizard values before inital step render.
    resolve(route: ActivatedRouteSnapshot) {
        this.route = route;
        return this.getValues(route.paramMap.get('id'));
    }

    getValues(id: string): Observable < any > {
        return new Observable((obs) => {
            zip(
                this.ngrxstore.select(selectPPPAppealWizardValues()),
                this.appealWizardService.$MissingFields,
                this.ngrxstore.select(selectWizardSteps),
                this.authService.getCurrentUser()
            ).pipe(
                map(([
                    $storedValues,
                    $missingFields,
                    $steps,
                    $user
                ])=>({
                    storedValues:$storedValues,
                    missingFields:$missingFields,
                    steps:$steps,
                    user:$user
                })),
                take(1)
            ).subscribe((values) => {
                obs.next(values);
                obs.complete();
            });
        });
    }

}