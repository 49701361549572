import { Routes, RouterModule, RunGuardsAndResolvers } from '@angular/router';

import { APPEAL_WIZARD_PATHS,APPEAL_WIZARD_ROOT_PATH } from './constants/appeal-wizard.constants';
import { ModuleWithProviders } from '@angular/core';
import { ShellComponent } from '../../shell';
import { PPPAppealWizard } from './wizard/wizard.component';
import { AuthGuard } from '../../platform/modules/navigation';
import { PPPAppealWizardStepResolver } from './resolvers/appeal-wizard-step.resolver';
import { PPPAppealWizardDocumentsResolver } from './resolvers/appeal-wizard-documents.resolver';
import { PPPAppealWizardResolver } from './resolvers/appeal-wizard.resolver';
import { StartYourAppeal } from './steps/start-your-appeal/start-your-appeal.component';
import { PointOfContact } from './steps/point-of-contact/point-of-contact.component';
import { LoanInformation } from './steps/loan-information/loan-information.component';
import { AppealContent } from './steps/appeal-content/appeal-content.component';
import { CertificateOfService } from './steps/certificate-of-service/certificate-of-service.component';
import { AppealSummary } from './steps/summary/summary.component';
import { PPPAppealWizardStartYourAppealResolver } from './resolvers/appeal-wizard-start-your-appeal.resolver';
import { AppealSubmittedGuard } from './appeal-submitted.guard';
import { PPPAppealWizardDemographicsResolver } from './resolvers/appeal-wizard-demographics.resolver';
import { PPPAppealWizardSummaryResolver } from './resolvers/appeal-wizard-summary.resolver';
import { BorrowerDetails } from './steps/borrower-details/borrower-details.component';
import { PPPAppealWizardBorrowerDetailsResolver } from './resolvers/appeal-wizard-borrower-details.resolver';

export const APPEAL_WIZARD_ROUTES = [{
    path: APPEAL_WIZARD_ROOT_PATH,
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [{
        path: APPEAL_WIZARD_PATHS.APPEAL_WIZARD + '/:id',
        component: PPPAppealWizard,
        resolve:{ 
            wizard:PPPAppealWizardResolver 
        },
        children: [{
                path: APPEAL_WIZARD_PATHS.APPEAL_START_APPEAL,
                component: StartYourAppeal,
                resolve: {
                    state: PPPAppealWizardStepResolver,
                    step: PPPAppealWizardStartYourAppealResolver,
                    demographics:PPPAppealWizardDemographicsResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            {
                path: APPEAL_WIZARD_PATHS.APPEAL_BORROWER_DETAILS,
                component: BorrowerDetails,
                resolve: {
                    state: PPPAppealWizardStepResolver,
                    step: PPPAppealWizardBorrowerDetailsResolver,
                    demographics:PPPAppealWizardDemographicsResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            {
                path: APPEAL_WIZARD_PATHS.APPEAL_POINT_OF_CONTACT,
                component: PointOfContact,
                resolve: {
                    state: PPPAppealWizardStepResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            {
                path: APPEAL_WIZARD_PATHS.APPEAL_LOAN_INFORMATION,
                component: LoanInformation,
                resolve: {
                    state: PPPAppealWizardStepResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            {
                path: APPEAL_WIZARD_PATHS.APPEAL_CONTENT,
                component: AppealContent,
                resolve: {
                    state: PPPAppealWizardStepResolver,
                    documents: PPPAppealWizardDocumentsResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            {
                path: APPEAL_WIZARD_PATHS.APPEAL_SUMMARY,
                component: AppealSummary,
                resolve: {
                    state: PPPAppealWizardStepResolver,
                    documents: PPPAppealWizardDocumentsResolver,
                    options: PPPAppealWizardSummaryResolver
                },
                // canActivate:[AppealSubmittedGuard]
            },
            // {
            //     path: APPEAL_WIZARD_PATHS.APPEAL_CERTIFICATE_OF_SERVICE,
            //     component: CertificateOfService,
            //     resolve: {
            //         state: PPPAppealWizardStepResolver
            //     }
            // }
        ],
    }]
}]

export const forgivenessWizardRoutes: Routes = APPEAL_WIZARD_ROUTES;

export const PPPAppealWizardRouting: ModuleWithProviders<RouterModule> =  RouterModule.forChild(
    forgivenessWizardRoutes
);