import { Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSessionState } from './store/auth.selectors';
import { AUTH_ACTIONS } from './constants/auth.constants';
import { AUTH_ENDPOINTS } from './constants/auth-api.constants';
import { AuthConfiguration, AuthConfigurationInterface, AUTH_CONFIG } from './auth.interface';

export class AuthenticatedAPIService{

  public currentRoles:string[];
  get CurrentRoles(){
    return this.currentRoles;
  }

  public currentUserName:string;
  get CurrentUserName(){
    return this.currentUserName;
  }

  public currentUserId:string;
  get CurrentUserId(){
    return this.currentUserId;
  }

  public currentStatus:string;
  get CurrentSessionStatus(){
    return this.currentStatus;
  }

  // Where can the user go?
  // -----------------------
  public routePermissions = {};
  get PagePermissions():any{
    return this.routePermissions;
  }
  set PagePermissions(permissions:any){
    this.routePermissions = permissions;
    this.roles = Object.keys(permissions);
  }

  // What API can they access?
  // --------------------------
  public apiPermissions:any = {};
  set ApiPermissions(pemissions){
    this.apiPermissions = pemissions;
  }
  get ApiPermissions(){
    return this.apiPermissions;
  }


  // Where is the API?
  // ------------------
  public domain:string = null;
  set Domain(endpoint:string){
    this.domain = endpoint;
  }

  // What is te API made up of?
  // ---------------------------
  public endpoints:any = {};
  set Endpoints(endpoints){
    this.endpoints = endpoints;
  }
  get Endpoints(){
    return this.endpoints;
  }

  // API Endpoint correlate to a method in this service
  // --------------------------------------------------
  public dictionary:any = {};
  set Dictionary(dictionary:any){
    this.dictionary = dictionary;
  }

  // other
  // ----------------------------
  public token:string = null;
  set Token(token:string){
    this.token = token;
  }
  get Token(){
    return this.token;
  }
  public roles = [];
  get Roles(){
    return this.roles;
  }

  public origins:any = {};
  get Origins(){
    return this.origins;
  }

  public redirects:any = {};
  get Redirects(){
    return this.redirects;
  }

  constructor(
    public config:AuthConfiguration,
    public http:HttpClient,
    public ngrxstore:Store<any>
  ){
    this.ngrxstore.select(selectSessionState()).subscribe((state)=>{

      // We create endpoints based on the base url provided to the auth service
      this.Endpoints = config.endpoints;
      this.PagePermissions = config.permissions;
      this.origins = config.auth_origins;
      this.redirects = config.auth_redirects;

      // Used by Components
      this.currentRoles = state.roles;
      this.currentUserName = state.user;
      this.currentUserId = state.user_id;
      this.currentStatus = state.status;

      // Used by Base API Class only
      // console.log(state)
      this.Token = state.token;

    })
  }

  httpParams(params:any){
    const _params = new HttpParams()
    Object.keys(params).map((key)=>{
      _params.set(key,params[key])
    })
    return _params;
  }

  validateToken(token?:any):Observable<any>{
    return new Observable((observer)=>{
      let _response = {
          "roles":["Guest"],
          "email_address":"Adam",
          "user_id":2,
          "user":"Adam"
        };
      observer.next(_response);
      observer.complete();
    })
		// return this.http.get(API_ENDPOINTS.LOGIN_STATUS, this.authHeader(token)).pipe(
    //   catchError(err => throwError(err))
    // );
	}

  authHeader(key?:string): any {

      // ==================================================
      // DEPRICATED -> use an interceptor
      // --------------------------------------------------
      let header = new Headers();
      header.append("Content-Type", "application/json");
      if(this.token !== null){
        header.append("Authorization", this.token);
      }
      if(key){
        header.append("Key", key);
      }
      return header;
  }

  crossOriginHeader():any{

    // ==================================================
    // DEPRICATED -> use an interceptor
    // --------------------------------------------------
		let header = new HttpHeaders();
		header.append("Content-Type", "application/json");
		let options = {headers: header};
		return options;
	}
}
