import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector:'sliding-panel',
  template:require('./sliding-panel.component.html')
})

export class SlidingPanel{

  @Output() onClose = new EventEmitter<boolean>()

  public isOpen:boolean = false;
  public closeButtonText:string = 'Cancel'
  @Input() set open(bol:boolean){
    //console.log('open: ',bol)
    this.isOpen = bol;
  };

  public $isFluid = new BehaviorSubject<boolean>(false);
  @Input() set stretchFullWidth(bol:boolean){
    this.$isFluid.next(bol);
  };

  @Input() title:string;
  @Input() panelClass:string;
  @Input() panelId:string;

  constructor(public ngrxstore:Store<any>){}

  closePanel($event){
    this.isOpen = false;
    this.onClose.emit();
    $event.preventDefault();
  }

}
