import { APPEAL_WIZARD_PATHS } from '../appeal-wizard.constants';

const STEP = APPEAL_WIZARD_PATHS.APPEAL_LOAN_INFORMATION;
export const APPEAL_LOAN_INFORMATION={
    LI_LOAN_NUMBER:{
        label:'SBA PPP Loan Number',
        storeKey:'loan_number',
        required:true,
        step:STEP
    },
    LI_LOAN_AMOUNT:{
        label:'PPP Loan Amount',
        storeKey:'loan_amount_in_cents',
        required:true,
        step:STEP
    },
    LI_FORGIVENESS_AMOUNT:{
        label:'Forgiveness Amount Received (if any)',
        storeKey:'forgiveness_amount_received_in_cents',
        required:false,
        step:STEP
    },
    LI_FORGIVENESS_AMOUNT_REQ:{
        label:'Forgiveness Amount Requested by Borrower',
        storeKey:'forgiveness_amount_requested_in_cents',
        required:true,
        step:STEP
    },
    LI_FORGIVENESS_AMOUNT_REQ_LENDER:{
        label:'Forgiveness Amount Requested by Lender',
        storeKey:'forgiveness_amount_requested_by_lender_in_cents',
        required:false,
        step:STEP
    },
    LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE:{
        label:'Date of Forgiveness Application',
        storeKey:'forgiveness_application_date',
        required:true,
        step:STEP
    },
    LI_PPP_LOAN_DISBURSEMENT_DATE:{
        label:'PPP Loan Disbursement Date',
        storeKey:'ppp_loan_disbursement_date',
        required:true,
        step:STEP
    }
    
}