import { Action, createFeatureSelector } from '@ngrx/store';
import { APP_DEFAULTS } from '../../constants/app.constants';
import { SHELL_ACTIONS } from '../constants/shell.constants';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

let _currentLocalState:ShellState;
if(localStorage.getItem('shell')){
  _currentLocalState = JSON.parse(localStorage.getItem('shell'))
}else{
  _currentLocalState = {
    action_panel_collapsed_on_shell:true,
    dismissed_help:[],
    list_page_limit:APP_DEFAULTS.LIST_PAGE_LIMIT,
    view_frozen:false
  }
}

export interface ShellState{
  action_panel_collapsed_on_shell:boolean;
  dismissed_help:string[];
  list_page_limit:number;
  view_frozen:boolean;
}


const initialState:ShellState = {
  action_panel_collapsed_on_shell:_currentLocalState['action_panel_collapsed_on_shell'] || true,
  dismissed_help:_currentLocalState['dismissed_help'] || [],
  list_page_limit:_currentLocalState['list_page_limit'] || APP_DEFAULTS.LIST_PAGE_LIMIT,
  view_frozen:false
}


export function reducer(state:ShellState = initialState, action: any):ShellState{

    let _value = null;

    switch (action.type) {

        case SHELL_ACTIONS.TOGGLE_ACTION_PANEL_SHELL:
        //console.log('shell',action)
          _value = Object.assign({},state,{
            action_panel_collapsed_on_shell:action.collapsed
          })
          //console.log(_value)
          return _value;

        case SHELL_ACTIONS.THAW_VIEW:
          _value = Object.assign({},state,{
            view_frozen:false
          })
          return _value;

        case SHELL_ACTIONS.FREEZE_VIEW:
          _value = Object.assign({},state,{
            view_frozen:true
          })
          return _value;

        default:
          return state;
    }
}

export const getShellState = createFeatureSelector<ShellState>('shell');
