import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../../../platform/modules/navigation';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { AppealJumpToStep, SaveAppeal, SignCertificateOfService, AfterSignedCertificateOfService, NavigatedFromSummary } from '../../store/appeal-wizard.actions';
import { WizardStep } from '../wizard-step.component';
import * as _ from 'lodash';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../../app.interface';
import { AuthConfiguration, AUTH_CONFIG, SaveProfileChanges } from '../../../../platform/modules/auth';
import { BASE_URL } from '../../../../constants/rest_api.constants';
import { selectPPPAppealIsSubmitted, selectPPPAppealWizardErrors, selectPPPAppealWizardValues } from '../../store/appeal-wizard.selectors';
import { Actions, ofType} from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { TriggerModal, TriggerToast } from '../../../../platform/modules/notification';
import { convertToPhoneFormat } from '../../../../platform/utilities/helpers';

@Component({
    selector:'appeal-summary',
    template:require('./summary.component.html'),
})
export class AppealSummary extends WizardStep{

    public bucketItems:BucketItemConfiguration[] = [];
    public wizardSteps:any[] = [];
    public missingFields:any[] = [];
    public missingFieldsByStep:any[] = [];
    public wizardValues:any = {};
    public signingCOS:boolean = false;
    public hideGenCertificate:boolean = false;
    public certificateUrl = "";
    public viewCertificate: boolean = false;
    public appealDocuments:any[] = [];
    public lenderName:string = "";
    public lenderOptions:any[] =[];
    public docketFilingRef = "";
    public isAppealSubmitted = false;
    public submitDate = null;

    public roles = [];
    public pocRole = null;
    public isCOSEnabled:boolean = false;
    public isAppealSubmittedCOS:boolean = false;
    public formattedPhone = "";
    public submitInProgress: boolean = false;
    public isDayLightSavingsOn = false;

    constructor(
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService,
        public navigationService:NavigationService,
        public actions$:Actions
    ){
        super(
            "Summary",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        
        this.roles = Object.values(this.authConfig.roles);

        let _initialValues = this.wizardValues = this.activatedRoute.snapshot.data.state.storedValues;

        
        if(this.wizardValues[this.fields.POC_PHONE_NUMBER.storeKey] != null){
            this.formattedPhone = convertToPhoneFormat(this.wizardValues[this.fields.POC_PHONE_NUMBER.storeKey].replace("+1",""));
        }

        this.lenderOptions = this.activatedRoute.snapshot.data.options.lenders.map((option)=>{
            return {
                value:option.id,
                label:option.name
            }
        });

        this.isCOSEnabled = this.activatedRoute.snapshot.data.options.isCOSEnabled;

        this.lenderName = this.matchLenderLabel(this.wizardValues[this.fields.ASYA_LENDER_NAME.storeKey]);

        this.wizardSteps = this.activatedRoute.snapshot.data.state.steps;
        this.missingFields = this.activatedRoute.snapshot.data.state.missingFields;

        this.appealDocuments = this.activatedRoute.snapshot.data.documents.data;
        
        this.findMissingSteps();

        this.title = 'Summary of Appeal for';

        this.pocRole = this.roles.filter((role)=>{
            return role.slug === this.wizardValues[this.fields.POC_ROLE.storeKey]
        })[0];

        this.bucketItems = [
            {
                filename:'Reason For Our Appeal',
                reference_id:'012221'
            }
        ];

        this.formGroup = this.formBuilder.group({
            signature_for_electronic_filing:[null,Validators.required]
        })

        this.watchChanges();
        this.isDayLightSavingsOn = this.isDST(new Date());
    }

    isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }

    ngOnInit(){
        let _isSubmittedSub = this.ngrxstore.select(selectPPPAppealIsSubmitted()).subscribe((isSubmitted)=>{
            this.signingCOS = false;
            this.isAppealSubmitted = isSubmitted;
        });

        let _onWizardError = this.ngrxstore.select(selectPPPAppealWizardErrors()).subscribe((errors)=>{
            this.signingCOS = false;
        });

        // this.zombiesByKey('wizard_submitted',_isSubmittedSub);
        // this.zombiesByKey('wizard_errors', _onWizardError);
    }

    matchLenderLabel = (id: any) => {
        //console.log(id);
        let _result = this.lenderOptions.filter((obj)=>{
            return obj.value === id;
        });
        //there should never be more than one matching name, if so thats a flaw of the backend/data model
        return (_result[0]) ? _result[0].label : null;
    }

    findMissingSteps(){
        let _steps = [];
        let _stepsWithMissingFields = {};
        let _missingFieldsByStep = [];
        let _missingFieldsByStepWithFields = [];

        for( let field of this.missingFields){
            _steps.push(field.step);
        }
        _steps = _.uniq(_steps);

        Object.keys(this.steps).map((key)=>{
            if( _steps.indexOf(this.steps[key].path) > -1 && this.wizardSteps.indexOf(this.steps[key].path)> -1){
                _stepsWithMissingFields[this.steps[key].path] = this.steps[key].name;
            }
        });

        Object.values(this.appealWizardService.WizardFields).map((value)=>{
            if(_stepsWithMissingFields[value['step']]){
                _missingFieldsByStep[value['step']] = {
                    label:_stepsWithMissingFields[value['step']],
                    path:value['step'],
                    fields:[]
                };
            }
        });

        for( let step in _missingFieldsByStep ){
            let _fieldByStep = _missingFieldsByStep[step];
            for( let mField of this.missingFields ){
                _missingFieldsByStepWithFields[step] = Object.assign({},_fieldByStep);
                if( mField.step === _fieldByStep.path ){
                    _missingFieldsByStepWithFields[step].fields.push(mField);
                }
            }
        }

        this.missingFieldsByStep = Object.values(_missingFieldsByStepWithFields);
    }

    goToStep(path:string){
        this.ngrxstore.dispatch( new AppealJumpToStep(path) )
        this.ngrxstore.dispatch( new NavigatedFromSummary(true));
        // adapter method, keeps the router out of the component, same arguments as router.navigate()
        this.navigationService.navigate([path],{relativeTo:this.activatedRoute.parent});
    }

    submitApplicationNoCOS(){
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
            if((wizardValues[this.fields.LI_FORGIVENESS_AMOUNT.storeKey] > 10000000) ||
                    (wizardValues[this.fields.LI_FORGIVENESS_AMOUNT_REQ_LENDER.storeKey] > 10000000) ){
                    this.ngrxstore.dispatch(new TriggerModal({
                        content:{
                            title:"Check Your Loan Information Details",
                            message:"Forgiveness Amount Received and Forgiveness Amount Requested by Lender cannot be more than $10,000,000.00"
                        },
                        type:'message'
                    })) 
                }else{
                    this.ngrxstore.dispatch(new TriggerModal({
                        content:{
                          title:"Are you sure you want to submit the appeal?",
                          message: "Please confirm you have completed all fields accurately before submitting your appeal, as you will not be able to edit the information after submission.",
                          approveLabel: "Confirm",
                          cancelLabel: "Cancel"
                        },
                        onCancel:()=>{},
                        onApprove:()=>{
                            this.submitInProgress = true;
                            let documents = [];
                                this.appealDocuments.map((item) =>{
                                    documents.push(item.reference_id);
                                })
            
                                this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
                                    this.ngrxstore.dispatch( new SaveAppeal({
                                            initialSave:wizardValues['reference_id'] === this.appDefaults.ui.NEW_APPEAL_SLUG,
                                            callback:()=>{
                                                if(this.formGroup.controls['signature_for_electronic_filing'].value != null &&  this.formGroup.controls['signature_for_electronic_filing'].value != ''){
                                                    this.submitDate = new Date().toUTCString();
                                                    this.appealWizardService.submitApplicationWithoutCOS(
                                                        this.activatedRoute.snapshot.parent.paramMap.get('id'),
                                                        documents,
                                                        this.formGroup.controls['signature_for_electronic_filing'].value,
                                                    ).subscribe((response)=>{
                                                        this.isAppealSubmittedCOS = true;
                                                        this.submitInProgress = false;
                                                        this.appealWizardService.getAppealApplication(
                                                            this.activatedRoute.snapshot.paramMap.get('id')
                                                        ).subscribe((response)=>{
                                                            let _profiledata = {
                                                                "phone" : response["phone_number"],
                                                                "reference_id": response["primary_contact_reference_id"]
                                                            } 
                            
                                                            this.ngrxstore.dispatch( new SaveProfileChanges(_profiledata, null, true));
                                                            this.ngrxstore.dispatch(new AfterSignedCertificateOfService());
                                                        })
                                                        
                                                    },(err)=>{
                                                        this.isAppealSubmittedCOS = false;
                                                        this.submitInProgress = false;
                                                    })
                                                }else{
                                                    this.ngrxstore.dispatch(new TriggerToast({
                                                        style:'danger',
                                                        message:"Signature cannot be empty."
                                                    }));
                                                } 
                                            },
                                            user_ref:this.activatedRoute.snapshot.data.wizard.user.reference_id,
                                            submitSaveFlag:true
                                        }
                                    ));
                                });
            
                                     
                            }
                      }))  

                }
        });
              
    }

    submitApplication(){
        let _docRefernceId = [];
        this.appealDocuments.map((item) =>{
            _docRefernceId.push(item.reference_id);
        })

        this.signingCOS = true;
        this.ngrxstore.dispatch( new SignCertificateOfService({
            signature_for_electronic_filing:this.formGroup.controls['signature_for_electronic_filing'].value,
          documents:_docRefernceId},
          this.docketFilingRef
        ));
    }

    generateCertificate(){
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
            this.ngrxstore.dispatch( new SaveAppeal({
                    initialSave:wizardValues['reference_id'] === this.appDefaults.ui.NEW_APPEAL_SLUG,
                    callback:()=>{
                        this.appealWizardService.generateCertificate(
                            this.activatedRoute.snapshot.parent.paramMap.get('id')
                        ).subscribe((response)=>{
                            this.certificateUrl = BASE_URL+response.url;
                            //console.log("this.certificateUrl", this.certificateUrl);
                            this.docketFilingRef = response.reference_id;
                            this.viewCertificate = true;
                        },(err)=>{
                             this.viewCertificate = false;
                        })
                    },
                    user_ref:this.activatedRoute.snapshot.data.wizard.user.reference_id
                }
            ));
        });
    }
}