import { InjectionToken } from '@angular/core';

export const NAVIGATION_CONFIG = new InjectionToken<NavigationConfig>('NAVIGATION_CONFIG');

export interface NavigationConfigInterface {
  paths?:any;
  routes?:any;
  permissions?:any;
  external_link_permissions?:any,
  navigationRoot?:string;
  rolesSelector?:any;
  sideNavRoles?:any;
  secondaryNav?:any;
  wizardRoutes?:any;
  branding?:BrandingConfiguration
}

export class NavigationConfig implements NavigationConfigInterface {
  public paths:any;
  public routes:any;
  public permissions:any;
  public external_link_permissions:any;
  public navigationRoot:string;
  public rolesSelector:string;
  public sideNavRoles:any;
  public secondaryNav?:any;
  wizardRoutes?:any;
  public branding?:BrandingConfiguration;


  constructor(config: NavigationConfigInterface = {}) {
    this.assign(config);
  }

  public assign(config: NavigationConfigInterface = {}) {
    for (const key in config) {
      this[key] = config[key];
    }
  }
}
