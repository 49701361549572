import { Component, ChangeDetectionStrategy, OnInit, EventEmitter, Input, Output, OnChanges, AfterContentInit } from '@angular/core';
import { Observable,BehaviorSubject, Subject, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ChangeCase from 'change-case';
import * as _ from 'lodash';

@Component({
  selector:'query-panel',
  // changeDetection:ChangeDetectionStrategy.OnPush,
	template:require('./query-panel.component.html')
})

export class QueryPanel implements OnInit, OnChanges{

  @Output() onChangeEmitter = new EventEmitter<any>();
  @Output() onRequestEmitter = new EventEmitter<boolean>();
  @Output() onConfiguration = new EventEmitter<any>();
  @Output() onConfigurationError = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<boolean>();
  @Output() onInit = new EventEmitter<boolean>();

  @Input() get configure(){ return this.config; }
  @Input() get configureInitial(){ return this.initialValues };

  set configure(val){
    this.lastBaseKey = (this.previousSets[0]) ? this.previousSets[0]['value'] : null ;
    this.currentBaseKey = (this.inputModels[0]) ? this.inputModels[0]['value'] : null ;
    if(val){
      this.config = val;
      if(this.init){
        this.previousSets = this.inputModels;
        this.formIsLoading = true;
        this.optionSets = {};
        this.inputModels = [];
        this.queryString = this.createQueryString(this.config);
        this.buildBaseInput();
      }
      this.onConfiguration.emit(this.config)
      this.ref.detectChanges();
    }
  }

  set configureInitial(val){
    if(val){
      this.initialValues = val;
      this.ref.detectChanges();
    }
  }

  @Input() service:any;
  @Input() onQueryClick:any;
  @Input() reduxSet:any;
  @Input() reduxNodeName:string = null;
  @Input() UI_REDUX_SET:any;
  @Input() callToAction:any = null;

  @Input() requireAllValues:boolean = true;
  @Input() locked:boolean = false;
  @Input() minNumOfFields:number = 2;

  public config:any = {};
  public initialValues:any[] = [];
  public inputsLoaded:any = [];

  public baseKeyName:string = null;
  public currentBaseKey:string = null;

  public lastBaseKey:string = null;
  public lastEnteredVin:string = null;
  public lastSetCreated:string = null;

  public needsAutoFill:boolean = false;
  public performingAutoComplete:boolean = false;
  public vinIsInvalid:boolean = false;

  public $uiState: Observable<any>;
  public $componentState: Observable<any>;
  public ngUnsubscribe:Subject<any> = new Subject();

  public $queryChangeModalSubscription:any;
  public $finalizeModalSubscription:any;
  public $querysHidden = new BehaviorSubject<boolean>(false);
	public querysHidden = false;
  public init:boolean = false;
  public previousSets:any = [];
  public lastValueAssesed:string = '';
  public lastKeyNameAssessed:string = '';
  public lastOptionsAssesed:any = [];
  public noOptionsAvailable:boolean = false;
  public invalidSelectionMessage:string = '';
  public retentionThreshold:number = -1;

  public inputModelsLoaded = 0;
  public queryValues:any = [];
  public queryValid:boolean = false;
  public queryReady:boolean = false;
  public initialQuerys:any = {};
  public lastVehicleQueryValues:any = {};
  public retainedVehicleQueryValues:any = {};
  public queryString:string='';
  public inputModels:any = [];
  public optionSets:any = {};
	public queryChange:boolean = false;
  public formIsReady:boolean = false;
  public formIsLoading:boolean = false;
  public tracking:boolean = false;
  public refreshFollowingSets:boolean = false;
  public lastValidSet:number = 0;
  public clearingQuery:boolean = false;


	constructor(
    public ngrxstore:Store<any>,
    public ref:ChangeDetectorRef){}

  ngOnInit(){
    this.previousSets = this.inputModels;
    this.init = true;
    this.formIsReady = false;
    this.formIsLoading = true;
    this.inputModels = [];
    this.optionSets = {};
    this.queryString = this.createQueryString(this.config);
    this.onInit.emit(true);


    if(this.reduxNodeName !== null){
      this.$componentState = this.ngrxstore.select(this.reduxSet);
      this.$componentState.pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe((val)=>{
          // this.reduxNodeName+'selector.ready';
      });
    }
    // this.buildBaseInput();
  }

  ngOnChanges(changes){
    if(this.config !== undefined && !this.formIsReady){
      this.initialQuerys = _.cloneDeep(this.config);
    }
  }

  findBaseInputKeyName(config:any){
    let _returnThis;
    for( let key in config ){
      if( config[key]['order'] === 0 ){
        _returnThis = key;
      }
    }
    return _returnThis;
  }

  /// TODO: user key reponse to determine order, force async to report status, once all status in, allow api calls on remaing autocomplete candidates

  buildBaseInput(){
    this.inputsLoaded = [];
    this.noOptionsAvailable = false;
    this.vinIsInvalid = false;
    let _keyName = this.findBaseInputKeyName(this.config);
    this.baseKeyName = _keyName;
    if(_keyName !== null){
      this.synchronousGetInputOptions(_keyName);
    }
  }

  createQueryStringFromInputModels(models:any){
    let _camelCaseRegEx = new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*');
    let queryString = '';
    let newKey = '';

    for ( let model of models){
      if( model['value'] !== null &&
          model['value'] !== undefined &&
          model['value'] !== "All" &&
          model['value'] !== ''){
            newKey = (_camelCaseRegEx.test(model['keyName'])) ?  ChangeCase.snakeCase(model['keyName']) : model['keyName'] ;
            let _param = (typeof model['value'] === 'string') ? (model['value']).trim() : model['value'].toString() ;
            queryString += '&' + (newKey.toLowerCase()).trim()+'='+encodeURIComponent(_param);
      }
    }

    if(queryString !== ''){
      queryString = (queryString.indexOf('?')>-1) ? queryString : queryString.replace('&','?');
    }

    return queryString;
  }

  createQueryString(params:any): string{
    let _camelCaseRegEx = new RegExp('[A-Z]([A-Z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]*');
    let queryString = '';
    let newKey = '';
    for (let key in params) {
        if( params[key]['value'] !== null &&
            params[key]['value'] !== undefined &&
            params[key]['value'] !== "All" && params[key]['value'] !== ''){
              newKey = (_camelCaseRegEx.test(key)) ?  ChangeCase.snakeCase(key) : key ;
              let _param = (typeof params[key]['value'] === 'string') ? (params[key]['value']).trim() : params[key]['value'].toString() ;
              queryString += '&' + (newKey.toLowerCase()).trim()+'='+encodeURIComponent(_param);
            }
    }
    if(queryString !== ''){
      queryString = (queryString.indexOf('?')>-1) ? queryString : queryString.replace('&','?');
    }
    return queryString
  }

  findNextKeyName(lastKeyName:string,lastIndex:number){
    for( let key in this.config ){
      if( this.config[key]['order'] === lastIndex+1 ){
        return key;
      }
    }
    return null;
  }

  // Option Loading Methods
  // --------------------------------------------
  synchronousGetInputOptions(keyName:string){
    let _formattedName = keyName.trim();
    let _name = ChangeCase.headerCase(_formattedName).replace('-',' ');
    let _requiresAll = this.config[keyName]['value'] === 'All';
    let _optionsRequestMethod = 'get'+_formattedName.replace('_','')+'Options';
    let _optionsMethod = (_formattedName === 'VIN') ? this.service.getVINOptions.bind(this.service) : this.service.getOptionsFor.bind(this.service);
    let promise = _optionsMethod(_formattedName,this.queryString)
      .toPromise()
      .then((res)=>{
        let optionsFromAPI = res;
        if(typeof optionsFromAPI !== 'string' && typeof optionsFromAPI !== 'number'){
          optionsFromAPI = (res.json) ? res.json() : res ;
          optionsFromAPI = optionsFromAPI.map((option)=>{
            if(typeof option === 'number'){
              return option.toString();
            }else{
              return option;
            }
          })
        }
        this.buildInputModel(keyName,_formattedName,_name,optionsFromAPI);
      }).catch((res)=>{
        // If an error occurs just stop loading entirely
        if(res.error){
          if(res.error['type'] === 'invalid_vin'){
            this.vinIsInvalid = true;
          }else{
            this.noOptionsAvailable = true;
            this.onConfigurationError.emit(res);
          }

          this.inputsLoaded = [];
          this.checkRequirements().then((isValid)=>{
            this.onChangeEmitter.emit({
              values: this.config,
              config: this.queryString,
              ready: isValid
            });

            this.lastValidSet = 0;
            this.refreshFollowingSets = false;
            this.formIsReady = true;
            this.queryReady = true;
            this.formIsLoading = false;
            this.tracking = false;
          });
        }
      })
  }

  buildInputModel(keyName,formattedName,label,optionsFromAPI){


    let _config = _.cloneDeep(this.config);
    let _placement = _config[keyName]['order'];
    let _lastQueryForThisInput = this.lastVehicleQueryValues[keyName]
    let _retainedQueryForThisInput = this.retainedVehicleQueryValues[keyName] || {value:null} ;

    this.lastKeyNameAssessed = keyName;
    this.lastOptionsAssesed = optionsFromAPI;
    this.lastValueAssesed = _config[keyName]['value'];

    if(_lastQueryForThisInput){
      //console.log(_retainedQueryForThisInput,optionsFromAPI)
      let _lastQueryFoundInNewOptions = optionsFromAPI.indexOf(decodeURIComponent(_lastQueryForThisInput['value'])) > -1;
      let _retainedQueryFoundInNewOptions = false;
      if(_retainedQueryForThisInput['value']){
        _retainedQueryFoundInNewOptions = optionsFromAPI.indexOf(decodeURIComponent(_retainedQueryForThisInput['value'])) > -1;
      }
      //console.log(_lastQueryForThisInput['value'])
      //console.log(_retainedQueryFoundInNewOptions)
      //console.log(this.refreshFollowingSets,_placement,' > ',this.lastValidSet,_lastQueryFoundInNewOptions)
      if(
        (this.refreshFollowingSets && _placement > this.lastValidSet) ||
        !_lastQueryFoundInNewOptions){
          if(!_retainedQueryFoundInNewOptions){
            _config[keyName]['value'] = '';
          }else{
            _config[keyName]['value'] = this.retainedVehicleQueryValues[keyName]['value'];
          }
      }

      if(encodeURIComponent(this.config[keyName]['value']) !== _lastQueryForThisInput['value']){
        this.refreshFollowingSets = true;
        this.lastValidSet = _placement;
      }
    }

    this.inputModels[_placement] = this.createOptionSet({
      name:formattedName,
      label:label,
      key:keyName,
      config:_config,
      options:optionsFromAPI
    });


    this.queryString = this.createQueryStringFromInputModels(this.inputModels);
    this.optionSets[formattedName.replace('_','')] =  (this.inputModels[_placement].options) ? this.inputModels[_placement].options : [];
    this.config[keyName]['value'] = encodeURIComponent(this.inputModels[_placement]['value']) ;
    this.loadNextInputModel(keyName,_placement);

  }

  loadNextInputModel(keyName:string,index:number){



    let _nextIndex = index+1;
    let _nextKeyName = this.findNextKeyName(keyName,index);
    let _nextIsValid = (this.config[_nextKeyName]) ? true : false ;
    this.queryReady = false;


    if(_nextIsValid && this.inputsLoaded.indexOf(_nextKeyName) < 0){
      this.tryLoadingNextInputModel(_nextKeyName);
    }else{
      //console.log('Finsihed')
      this.inputsLoaded = [];
      this.checkRequirements().then((isValid)=>{
        this.onChangeEmitter.emit({
          values: this.config,
          config: this.queryString,
          ready: isValid
        });
        this.lastValidSet = 0;
        this.refreshFollowingSets = false;
        this.formIsReady = true;
        this.queryReady = true;
        this.formIsLoading = false;
        this.tracking = false;
      });
    }

  }

  tryLoadingNextInputModel(keyName:string){
    this.inputsLoaded.push(keyName);
    this.synchronousGetInputOptions(keyName);
  }
  //------------------------------------

  createOptionSet(setup:any){

    let name = setup['name'];
    let label = setup['label'];
    let key = setup['key'];
    let config = setup['config'];
    let optionsFromAPI = setup['options'];
    let _onlyOne = optionsFromAPI.length === 1 || false;
    let _emptyOptions = optionsFromAPI.length<1 || false;
    let _inputName = (label.replace(' ','-')+'-query').toLowerCase();
    let _idName = _.upperFirst(_.camelCase(_inputName));
    let _ariaLabel = ('Select a '+name.replace('_',' ')+' Vehicle Parameter').toLowerCase();
    let _keyName = name.replace('_','');
    let _set = null;

    // Auto set to first, when alone
    let _value = "";
    if( config[key]['value'].toString().trim() !== "" &&
        config[key]['value'] !== null &&
        config[key]['value'] !== undefined ){

      // If it's got a value, keep that value
      _value = config[key]['value'];

    }else{
      // If it has no value
      if(config[key]['type']!=='text' && _onlyOne){
        // if it's select and has only one, select the only option
        _value = optionsFromAPI[0];

        if(!this.lastVehicleQueryValues[key]){
          this.lastVehicleQueryValues[key] = {};
        }
        this.lastVehicleQueryValues[key]['value'] = optionsFromAPI[0];
        if(this.retainedVehicleQueryValues[key]){
          this.retainedVehicleQueryValues[key]['value'] = optionsFromAPI[0];
        }
      }else if (config[key]['type']==='text'){
        // If it's a text field it's likley a vin, ifnot add another conditional
        if(this.lastBaseKey !== 'VIN'){
          // If it's the first time on VIN, we wipe out the last value from the previous baseKey selections
          _value = '';
        }else{
          // If it's been on vin, we keep the vin value so the following fields can auto select
          // UNLESS we are clearing the query
          _value = (this.clearingQuery) ? config[key]['value'] : optionsFromAPI ;
        }


      }
    }

    switch(config[key]['type']){
      case 'select':
        _set = {
          idName:_idName,
          inputName:_inputName,
          ariaLabel:_ariaLabel,
          name:label,
          keyName:_keyName,
          value:_value,
          options:optionsFromAPI,
          type:'select',
          disabled:_emptyOptions
        }
      break;
      case 'text':
        _set = {
          idName:_idName,
          inputName:_inputName,
          ariaLabel:_ariaLabel,
          name:label,
          keyName:_keyName,
          value:_value,
          options:[],
          type:'text'
        }
      break;
      default:
        _set = {
          idName:_idName,
          inputName:_inputName,
          ariaLabel:_ariaLabel,
          name:label,
          keyName:_keyName,
          value:_value,
          options:[],
          type:'text'
        }
      break;

    }

    this.lastEnteredVin = ( key==='VIN' && _value !== '' && this.currentBaseKey === 'VIN') ?
      this.lastEnteredVin : _value ;

    this.lastSetCreated = key;

    return _set;
  }

  onApplyClick($event){
    $event.preventDefault();
    if(this.queryValid){
      this.queryChange = false;
      this.onRequestEmitter.emit(this.config);
    }
  }

  hideQuerys(){
    this.ngrxstore.dispatch({type:'TOGGLE_FILTER_PANE',payload:this.UI_REDUX_SET});
	}

  clearQueryInputs($event){
    $event.preventDefault();
    this.formIsReady = false;
    this.queryValid = false;
    this.queryReady = false;
    this.inputModels = [];
    this.clearingQuery = true;
    this.lastVehicleQueryValues = {};
    this.retainedVehicleQueryValues = {};
    this.retentionThreshold = -1;
    this.onClear.emit(true);
  }

  setComponentReduxNode(type:string,nodeNamePartial:string,value:any){
    if(this.reduxNodeName !== null){
      this.ngrxstore.dispatch({
        type:type,
        payload:{
          nodeName:this.reduxNodeName+'.'+nodeNamePartial,
          value:value
        }
      })
    }
  }

  checkRequirements(){
    return new Promise((resolve)=>{
      let _complete = 0;

      for( let set in this.config ){
        if( this.config[set]['value'] !== '' &&
            this.config[set]['value'] !== null){
              _complete++
        }else{
        }
      }

      this.clearingQuery = false;
      //console.log(_complete)
      if(_complete === _.size(this.config) && _.size(this.config) >= this.minNumOfFields){
        this.setComponentReduxNode('SET_QUERY_READY','selector.ready',true);
        this.queryValid = true;
        resolve(true);
      }else{
        this.setComponentReduxNode('SET_QUERY_READY','selector.ready',false);
        this.queryValid = false;
        resolve(false);
      }
    })
  }

  trackQuery(value:string,key:string,index:number = -1,detectChange = true){
    if(!this.tracking){
      this.tracking = true;

      let _currentValues = _.cloneDeep(this.config);

      let _newValue = Object.assign({},
        _currentValues[key],{
          value:value
        });

      // Cascade
      this.lastVehicleQueryValues = Object.assign({},
        _currentValues,{
          [key]:_newValue
        });

      // Look ahead
      if(index > this.retentionThreshold){
        this.retainedVehicleQueryValues = Object.assign({},
          this.lastVehicleQueryValues
        );
        this.retentionThreshold = index;
      }

      this.queryChange = true;
      this.queryValid = false;
      this.config[key]['value'] = encodeURIComponent(value) ;
      this.queryString = this.createQueryString(this.config);
      this.checkRequirements().then((isValid)=>{
        if(detectChange){
          this.onChangeEmitter.emit({
            values: this.config,
            config: this.queryString,
            state: this.inputModels,
            ready: isValid
          });
        }
      })

    }
  }


}
