import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserPortal } from './user/user-portal.component';
import { AdminPortal } from './admin/admin-portal.component';

import { PORTAL_PATHS } from './constants/portal.constants';
import { TwoFAPortal } from './twofa/twofa-portal.component';
import { RedirectGuard } from '../../platform/modules/auth/redirectGuard/redirect-guard.service';
import { UserPortalResolver } from './resolver/user-portal.resolver';
import { UserIdPortalResolver } from './resolver/user-id-portal.resolver';

const portalRoutes: Routes = [

	// Portal or Landing
	{path:PORTAL_PATHS.USER_PORTAL, 
		component:UserPortal,
		resolve: {
			userPortal: UserPortalResolver
		}, canActivate:[RedirectGuard]
		},
	// {path:PORTAL_PATHS.DASHBOARD_PORTAL, component:AdminPortal,
	// 	canActivate:[RedirectGuard]},


	// Logins
	// {path:PORTAL_PATHS.DASHBOARD_LOGIN, 
	// 	component:AdminPortal,
	// 	canActivate:[RedirectGuard]},
	{path:PORTAL_PATHS.USER_LOGIN, 
		component:UserPortal,
		resolve: {
			userPortal: UserPortalResolver
		}
	},

	{path:PORTAL_PATHS.LOGIN_WITH_INVITE, 
		component:UserPortal,
		resolve: {
			userPortal: UserPortalResolver
		}
		},

	// Password Reset
	// {path:PORTAL_PATHS.DASHBOARD_FORGOT_PASSWORD, 
	// 	component:AdminPortal},
	// {path:PORTAL_PATHS.DASHBOARD_RESET_PASSWORD, 
	// 	component:AdminPortal},
	{path:PORTAL_PATHS.USER_FORGOT_PASSWORD, 
		component:UserPortal,
		resolve: {
			userPortal: UserPortalResolver
		}
	},
	{path:PORTAL_PATHS.USER_RESET_PASSWORD, 
		component:UserPortal,
		resolve: {
			userPortal: UserPortalResolver
		}},
		//invite attorney user
	{path:PORTAL_PATHS.INVITE_USER_WITH_TOKEN, 
		component:UserPortal,
		resolve: {
			inviteDetails: UserPortalResolver
		}
	},
	{path:PORTAL_PATHS.LOGIN_WITH_APPEAL_ID, 
		component: UserPortal,
		resolve: {
			userPortal: UserIdPortalResolver
		}
	},
		
	// 2FA Registration
	{path:PORTAL_PATHS.TWO_FA_REGISTER_PHONE, 
		component:TwoFAPortal
	},
	{path:PORTAL_PATHS.TWO_FA_PROVIDE, 
		component:TwoFAPortal
	},
	{path:PORTAL_PATHS.TWO_FA_METHOD, 
		component:TwoFAPortal},
	{path:PORTAL_PATHS.TWO_FA_SCAN_QR, 
		component:TwoFAPortal},
]

@NgModule({
  imports:[RouterModule.forChild(portalRoutes)],
  exports:[RouterModule]
})

export class PortalRoutingModule {}
