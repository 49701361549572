import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { AUTH_ACTIONS } from './constants/auth.constants';
import { InviteUser, LoginAction, LoginWithMFATokenAction, LogoutAction, RegisterPhoneWithTwoFactor } from './store/auth.actions';
import { AuthenticatedAPIService } from './authenticated-api.service';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { catchError, retry, take, map } from 'rxjs/operators';
import { selectSessionState } from './store/auth.selectors';
import { AuthConfiguration, AuthConfigurationInterface, AUTH_CONFIG } from './auth.interface';
import { EmailValidator } from '@angular/forms';
import { API_ENDPOINTS } from '../../../constants/rest_api.constants';
import * as Sentry from "@sentry/browser";


@Injectable()
export class AuthService extends AuthenticatedAPIService{

  public $tokenIsValid:BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
      @Inject(AUTH_CONFIG) public config:AuthConfiguration,
      http:HttpClient,
      ngrxstore:Store<any>
  ){
    super(config,http,ngrxstore);
  }

  interpretErrorsFromAPI(err):any{
    let col = err.error;
    let title = 'An Error Occured';
    let message = 'An unexpected error occured, check to make sure you submitted information is correct and valid.';

    if(Object.values(col).length>0){

      title = 'Attention';
      message = '';
      
      if(col.phone_number){
            title = 'Invalid Submission'
            message += 'You have supplied an invalid U.S. Phone Number. '
        }

        if(col.email){
          message += col.email[0]
        }

        if(col.non_field_errors){
          message += col.non_field_errors[0]
        }
        
        if(col.password1){
            col.password1.map((i) =>{
              message += i
            })          
        }

        if(col.loginRedirectMsg){
          message += col.loginRedirectMsg
        }

        if(message.length < 1){
            message += 'Please ensure all your information is correct and valid.'
        }
    }

    return {
        title,
        message
    }
}

	signUp(payload:any):Observable<any>{
		return this.http.post(this.Endpoints['REGISTRATION'], payload).pipe(
      catchError(err => throwError(err))
    );
	}

	requestPasswordReset(payload:any):Observable<any>{
		return this.http.post(this.Endpoints['RESET_PASSWORD'], payload).pipe(
      catchError(err => throwError(err))
    );
  }
  
  requestMFAReset(payload:any):Observable<any>{
		return this.http.put(this.Endpoints['USER_AUTH'] + "/" + payload.ref + this.Endpoints['MFA_RESET'], payload).pipe(
      catchError(err => throwError(err))
    );
	}

	resetPassword(payload:any):Observable<any>{
		return this.http.post(this.Endpoints['RESET_PASSWORD_CONFIRM'], payload).pipe(
      catchError(err => throwError(err))
    );
	}

  requestLogout(){
    this.ngrxstore.dispatch(new LogoutAction());
  }

  refreshAccess(refresh_token:string){
    return this.http.post(this.Endpoints['REFRESH_TOKEN'], {
      refresh:refresh_token
    },{observe:'response'}).pipe(
      take(1),
      catchError(err => throwError(err))
    );
  }

  registerUser(payload:any):Observable<any>{

    //console.log(payload)

    return this.http.post(this.Endpoints['REGISTRATION'], payload,{observe:'response'}).pipe(
      take(1),
      catchError(err => throwError(err))
    );

    // return new Observable((obs)=>{
    //   let _response = new HttpResponse({
    //     body:{
    //       access:'12345',
    //       refresh:'12345',
    //       roles:['Admin']
    //     }
    //   })
    //   obs.next(_response);
    //   obs.complete();
    // });
  }

  performLogout(){
    return this.http.post(this.Endpoints['LOGOUT'],{}).pipe(
      take(1),
      catchError(err => throwError(err))
    )}

	performLogin(payload:LoginCredentialsPayload):Observable<any>{
    return this.http.post(this.Endpoints['LOGIN'], payload,{observe:'response'}).pipe(
      take(1),
      catchError(err => throwError(err))
    );
  
    // return new Observable((obs)=>{
    //   let _response = new HttpResponse({
    //     body:{
    //       access:'12345',
    //       refresh:'12345'
    //     }
    //   })
    //   obs.next(_response);
    //   obs.complete();
    // });
  }

  sendTwilioRequest(payload:any){
    return this.http.post(this.Endpoints['TWILIO_SEND'], {
      user_email:payload.email,
      method:payload.auth_type,
    },{observe:'response'}).pipe(
      catchError((err) => {
        Sentry.captureException(Error(`Failed to call ${this.Endpoints['TWILIO_SEND']}`), { extra: { cause: err } });
        return throwError(err)
      })
    );
  }

  setTwiloPhoneNumber(payload:any){
    return this.http.post(this.Endpoints['TWILIO_SET_PHONE'], {
      user_email:payload.email,
      phone_number:payload.phone_number,
      country_code: payload.country_code
    },{observe:'response'}).pipe(
      catchError((err) => {
        Sentry.captureException(Error(`Failed to call ${this.Endpoints['TWILIO_SET_PHONE']}`), { extra: { cause: err } });
        return throwError(err)
      })
    );
  }
  
  performLoginWithToken(payload:any){
    return this.http.post(this.Endpoints['LOGIN'], {
      password:payload.password,
      email:payload.email,
      token:payload.token,
      auth_type:payload.auth_type,
      last_2fa_check_success:new Date().toISOString()
    },{observe:'response'}).pipe(
      catchError(err => throwError(err))
    );
  }

  performTwilioLoginWithToken(payload:any){
    return this.http.post(this.Endpoints['TWILIO_VERIFY'], {
      user_email:payload.email,
      code:payload.token,
      verification_id:payload.verification_id,
    },{observe:'response'}).pipe(
      catchError((err) => {
        Sentry.captureException(Error(`Failed to call ${this.Endpoints['TWILIO_VERIFY']}`), { extra: { cause: err } });
        return throwError(err)
      })
    );
  }

  getCurrentUser(){
    return this.http.get(API_ENDPOINTS['USER_AUTH'])
    .pipe(
      take(1),
      map((value)=>{
        return value['results'][0]
      }),
      catchError(err => throwError(err))
    );
  }

  saveProfileChanges(payload:any){
    //console.log("payload>>>",payload);
    return this.http.put(this.Endpoints['USERS'] + '/' + payload.reference_id, payload ,{observe:'response'}).pipe(
      catchError(err => throwError(err))
    );
  }

  saveAppealProfileChanges(payload:any){
    return this.http.put(this.Endpoints['APPEAL_ROOT'] + '/' + 
    payload.app_reference_id +this.Endpoints['PRIMARY_CONTACT'] + '/'+payload.reference_id ,
     payload ,{observe:'response'}).pipe(
      catchError(err => throwError(err))
    );
  }

  updatePointOfContact(payload:any){
    return this.http.put(this.Endpoints['APPEAL_INVITATION'] + '/' + payload.inviteToken, {username: payload.email} ,{observe:'response'}).pipe(
      catchError(err => throwError(err))
    );
  }

  inviteAppellantPOC(payload:any){
    return this.http.post(this.Endpoints['APPEAL_INVITATION'], payload ,{observe:'response'}).pipe(
      take(1),
      catchError(err => throwError(err))
    );
  }

  getInviteUserDetails(token:string):Observable<any>{
    return new Observable((obs)=>{
        this.http.get(API_ENDPOINTS['APPEAL_INVITATION'] + "/" + token)
        .pipe(
            take(1),
            catchError(err => throwError(err))
        ).subscribe((response)=>{
            obs.next(response);
            obs.complete();
        })
    });
}

getSettings(){
    return new Observable((obs)=>{
        this.http.get(API_ENDPOINTS['SETTINGS'])
            .pipe(
                take(1)
            ).subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
              if(err){
                obs.next(false);
              }
          })
    });
  }

}
