import { API_ENDPOINTS } from './rest_api.constants';
import { FULL_ROUTES } from './routes.constants';

export const USER_ROLES = {
  APPELLANT:{
    slug:'appellant',
    name:'Borrower',
    value:3
  },
  ATTORNEY:{
    slug:'attorney',
    name:'Attorney',
    value:68
  },
  OHA_ADMIN:{
    slug:'oha staff',
    name:'OHA Staff',
    value:67
  },
  OHA:{
    slug:'external oha staff',
    name:'External OHA Staff',
    value:1
  },
  OGC:{
    slug:'ogc',
    name:'OGC',
    value:2
  },
  JUDGE:{
    slug:'judge',
    name:'Judge',
    value:34
  },
  APPELLANT_COUNCEL:{
    slug:'appellant counsel',
    name:"Attorney",
    value:4
  },
  OGC_ADMIN:{
    slug:'ogc admin',
    name:'OGC Admin',
    value:69
  },
}

export const APPEAL_STATUS = {
  DRAFT:{
    name: "Draft"
  },
  APPEAL_FILED:{
    name: "Appeal Filed"
  },
  AWAITING_APPELLANT:{
    name: "Awaiting Appellant"
  },
  AWAITING_SBA:{
    name: "Awaiting SBA"
  },
  AWAITING_ADMIN_RECORD:{
    name: "Awaiting Administrative Record"
  },
  ATTORNEY_REVIEW:{
    name: "Attorney Review"
  },
  JUDGE_REVIEW:{
    name: "Judge Review"
  },
  COMPLETE:{
    name: "Complete"
  },
}

export const USER_ORIGINS = {
  [USER_ROLES.APPELLANT.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.APPELLANT_COUNCEL.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.ATTORNEY.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.JUDGE.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.OGC.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.OGC_ADMIN.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.OHA_ADMIN.slug]:FULL_ROUTES['USER_PORTAL'],
  [USER_ROLES.OHA.slug]:FULL_ROUTES['USER_PORTAL']
}

export const USER_HOMES = {
  [USER_ROLES.APPELLANT.slug]:FULL_ROUTES['YOUR_APPEALS'],
  [USER_ROLES.APPELLANT_COUNCEL.slug]:FULL_ROUTES['YOUR_APPEALS'],
  [USER_ROLES.ATTORNEY.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST'],
  [USER_ROLES.OGC.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST'],
  [USER_ROLES.OGC_ADMIN.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST'],
  [USER_ROLES.JUDGE.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST'],
  [USER_ROLES.OHA_ADMIN.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST'],
  [USER_ROLES.OHA.slug]:FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
}

// Who can see what
export const ROUTE_PERMISSIONS = {
  'None':[
    FULL_ROUTES['DASHBOARD_PORTAL'],
    FULL_ROUTES['USER_PORTAL'],
    FULL_ROUTES['LINK_LOAN_WIZARD']
  ],
  [USER_ROLES.OHA_ADMIN.slug]:[
    FULL_ROUTES['USER_LIST'],
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ],
  [USER_ROLES.OHA.slug]:[
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ],
  [USER_ROLES.OGC.slug]:[
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ],
  [USER_ROLES.OGC_ADMIN.slug]:[
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ],
  [USER_ROLES.APPELLANT.slug]:[
    FULL_ROUTES['APPEAL_WIZARD'],
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['YOUR_APPEALS'],
    FULL_ROUTES['YOUR_APPEAL']
  ],
  [USER_ROLES.APPELLANT_COUNCEL.slug]:[
    FULL_ROUTES['APPEAL_WIZARD'],
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['YOUR_APPEALS'],
    FULL_ROUTES['YOUR_APPEAL']
  ],
  [USER_ROLES.ATTORNEY.slug]:[
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ],
  [USER_ROLES.JUDGE.slug]:[
    FULL_ROUTES['MESSAGE_THREAD'],
    FULL_ROUTES['MESSAGE_INBOX'],
    FULL_ROUTES['ACCOUNT_PROFILE'],
    FULL_ROUTES['APPEALS_TICKET'],
    FULL_ROUTES['RESOURCES_PAGE'],
    FULL_ROUTES['APPEAL_APPLICATIONS_LIST']
  ]
};

export const EXTERNAL_LINK_PERMISSIONS = {
  [USER_ROLES.OHA_ADMIN.slug]:[API_ENDPOINTS.DJANGO_ADMIN],
  // [USER_ROLES.OHA.slug]:[API_ENDPOINTS.DJANGO_ADMIN]
}

// Who can see the back office drawer/side navigation
export const ROLE_HAS_SIDE_NAV = {}

// Un-used... yet
export const API_PERMISSIONS = {
  'None':[]
}
