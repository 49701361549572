import { createSelector } from '@ngrx/store';
import { NavigationState, getNavigationState } from './navigation.reducer';
import { create } from 'domain';

export const selectUrlState = createSelector(
  getNavigationState,
  (nav:NavigationState)=>{
    return {
      previous:nav.previous_url,
      current:nav.current_url,
    }
  }
)

export const selectNavigationDrawerState = () => createSelector(
  getNavigationState,
  (navigation:NavigationState)=>{
    return {
      type:navigation.drawer_type || 'navigation',
      collapsed:navigation.drawer_collapsed,
      roles:navigation.roles
    }
  }
)

export const selectNavigationRoles = createSelector(
  getNavigationState,
  (navigation:NavigationState)=>{
    return navigation.roles;
  }
);

export const getCurrentPage = () => createSelector(
  getNavigationState,
  (nav:NavigationState)=>{
    return{
      page_name:nav.current_page_name,
      page_index:nav.current_page_index,
      section_name:nav.current_section_name,
      section_index:nav.current_section_index
    }
  }
)

export const getPageState = () => createSelector(
  getNavigationState,
  (nav:NavigationState)=>{
    return{
      current_tab:nav.current_page_tab
    }
  }
);

export const getWindowWidth = () => createSelector(
  getNavigationState,
  (state:NavigationState)=>{
    return state.window_width;
  }
)

export const getWindowState = () => createSelector(
  getNavigationState,
  (state:NavigationState)=>{
    let size:string = null;
    let windowState = {
      isMobile:state['is_mobile'],
      isTablet:state['is_tablet'],
      isDesktop:state['is_desktop'],
      isWidescreen:state['is_widescreen'],
      isFullHD:state['is_full_hd'],
      scrollY:state['scroll_y'],
      scrollYMax:state['max_scroll_y']
    }
    Object.keys(windowState).map((key)=>{
      if( key.indexOf('is') > -1 ){
        if(windowState[key]){
          size = key;
        }
      }
    })
    return {
      size:size,
      scrollY:windowState.scrollY,
      scrollYMax:windowState.scrollYMax
    };
  }
)

export const navigationIsFrozen = createSelector(
  getNavigationState,
  (state:NavigationState)=>{
    return state.frozen;
  }
);
