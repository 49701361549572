import {map,take} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectPPPAppealWizardStatus, selectPPPAppealWizardValues } from './store/appeal-wizard.selectors';

@Injectable()
export class AppealSubmittedGuard implements CanActivate {

  constructor(
    public router: Router,
    public ngrxstore:Store<any>) {
  }

  // This should keep logged in users away from portals.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    //console.log(state)
    return this.ngrxstore.select(selectPPPAppealWizardStatus).pipe(take(1),map((storeState)=>{
        //console.log(storeState);
        return true;
    }));
  }

}
