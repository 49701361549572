import { Component, OnDestroy, Input, Output, EventEmitter, LOCALE_ID, Inject, forwardRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export const NG_SELECT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgInputSelect),
    multi: true
};
  

@Component({
    selector:'ng-input-select',
    template:require('./ng-input-select.component.html'),
    providers:[NG_SELECT_ACCESSOR]
})
export class NgInputSelect implements ControlValueAccessor{

    @Input() set size(size:InputSize){
        this.big = (size === 'big');
        this.small = (size === 'small');
    }

    @Input() set name(_name:string){
        this.fieldName = _name;
        this.inputID = _.snakeCase(_name);
   }

    @Input() public placeholder = null;
    @Input() public multiple = false;
    @Input() set options(_options:any[]){
        this.choices = _options;
    }
    @Input() set value(_value){
        if(Array.isArray(_value)){
            this.inputModelValue = [];
            _value.map((selection)=>{
                this.inputModelValue.push(selection.id);
            });
        }else{
            this.inputModelValue = _value;
        }
        //console.log(this.inputModelValue)
    }

    @Input() public showPlaceholder = false;
    
    public type = 'select';
    public fieldName = 'undefined';
    public big:boolean = false;
    public small:boolean = false;
    public inputModelValue:any = null;
    public inputID:string = null;
    public choices:any = [];

    // Emitters
    @Output() onChangeEmitter = new EventEmitter<any>();
    @Output() onReadyEmitter = new EventEmitter<any>();

    constructor(
        @Inject(LOCALE_ID) public _locale: string,
    ){
    }

    changeCallback=(data:any)=>{
        this.onChangeEmitter.emit(data)
    };
    touchCallback=()=>{};

    onMultiChange(){
        this.changeCallback(this.inputModelValue)
    }

    onSingleChange(){
        this.changeCallback(this.inputModelValue)
    }

    onMultiSelect(){
        // console.log(this.inputModelValue)
    }

    onMultiDeSelect(){
        // console.log(this.inputModelValue)
    }

    titleCase(str:string){
        return _.startCase(str);
    }

    writeValue(value: any){

    }
    registerOnChange(fn:any){
        this.changeCallback = fn;
    }
    registerOnTouched(fn:any){
        this.touchCallback = fn;
    }

}
