import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ShellComponent } from '../../shell';
import { ACCOUNT_PATHS, ACCOUNT_ROOT_PATH } from './constants/account.constants';
import { AccountResolver } from './resolvers/account.resolver';
import { AccountProfile } from './profile/profile.component';
import { UserList } from './user-list/user-list.component';
import { InboxMessage } from './messages/message/inbox-message.component';
import { Inbox } from './messages/inbox/inbox.component';
import { InboxResolver } from './resolvers/inbox.resolver';
import { UsersResolver } from './resolvers/users.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { ProfileResolver } from './resolvers/profile.resolver';
import { CreateNewUser } from './create-new/create-new.component';
import { GroupResolver } from './resolvers/group.resolver';

export const ACCOUNT_ROUTES = [
    {
        path:ACCOUNT_ROOT_PATH,
        component:ShellComponent,
        children:[
            {path:ACCOUNT_PATHS.ACCOUNT_PROFILE, component:AccountProfile,
                resolve:{
                    profile:ProfileResolver,
                    GroupResolver,
                }
            },
            {path:ACCOUNT_PATHS.USER_LIST, component:UserList,
                resolve:{
                    users:UsersResolver,
                    GroupResolver,
                }
            },
            {path:ACCOUNT_PATHS.CREATE_USER, component: CreateNewUser,
                resolve:{
                    groups:GroupResolver,
                }
            },
            {path:ACCOUNT_PATHS.USER_LIST+'/:id', component:AccountProfile,
                resolve:{
                    profile:UserResolver,
                    GroupResolver,
                }
            },
            {path:ACCOUNT_PATHS.USER_LIST+'/:id/:type', component:AccountProfile,
                resolve:{
                    profile:UserResolver,
                    GroupResolver,
                }
            },
            {path:ACCOUNT_PATHS.MESSAGE_INBOX, component:Inbox,
                resolve:{
                    profile:InboxResolver,
                }
            },
            {path:ACCOUNT_PATHS.MESSAGE_THREAD+'/:id', component:InboxMessage,
                resolve:{
                    profile:UserResolver,
                }
            }
        ]
    }
]

export const accountRoutes: Routes = ACCOUNT_ROUTES;

export const AccountRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(accountRoutes);