import {Injectable,Inject} from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, map  } from 'rxjs/operators';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { APPEAL_ACTION_TYPES } from '../../../constants/app.constants';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';

@Injectable({
    providedIn: 'root'
})
export class PPPAppealWizardDemographicsResolver implements Resolve < any > {

    public route: ActivatedRouteSnapshot = null;

    constructor(
        @Inject(Store) public ngrxstore: Store,
        @Inject(PPPAppealWizardService) public appealWizardService,
        @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    ) {}

    resolve(route:ActivatedRouteSnapshot){

      let appealId$ = new Observable((obs)=>{
        obs.next({action:APPEAL_ACTION_TYPES.DRAFT});
        obs.complete();
      });
  
      //console.log(route.paramMap.get('id'))
      if( route.paramMap.get('id') !== this.appDefaults.ui.NEW_APPEAL_SLUG ){
        appealId$ = this.appealWizardService.getDemographicData(route.paramMap.get('id'))
      }
        return new Observable((obs)=>{
          zip(
            appealId$,
            this.appealWizardService.getDemographicVeteran(),
            this.appealWizardService.getDemographicGender(),
            this.appealWizardService.getDemographicRace(),
            this.appealWizardService.getDemographicEthnicity(),
          ).pipe(
            map(([$details,$veteran, $gender, $race, $ethnicity])=>(
              {
                details:$details,
                veteran:$veteran,
                gender:$gender,
                race:$race,
                ethnicity:$ethnicity
              }
            )),
            take(1)
          ).subscribe((results)=>{
            obs.next(results);
            obs.complete();
          })
        }) ;
      }

}