import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, HostBinding, EventEmitter, Inject } from '@angular/core';
import { FileItem, HttpClientUploadService } from '@wkoza/ngx-upload';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiConfig, API_CONFIG } from '../../../../../../app.interface';
import { BASE_URL } from '../../../../../../constants/rest_api.constants';

@Component({
    selector:'bucket-item',
    template:require('./bucket-item.component.html')
})
export class BucketItem{

    public filename:string = null
    public category:string = null
    public created:string = null
    public id:string = null
    public url:string = null
    public isMobile:boolean = false;

    public isReady:boolean = false;

    @Output() onRemove:EventEmitter<any> = new EventEmitter();
    
    @Input() set config(config:BucketItemConfiguration){
        this.filename = config.filename || null ;
        this.created = config.created || null;
        this.category = config.category || 'file' ;
        this.id = config.reference_id || null ;
        this.url = config.file || null;
    }

    @Input() set isMobileDevice(bool : boolean){
        this.isMobile = bool;
    }

    constructor( 
        @Inject(API_CONFIG) public api:ApiConfig,
        public http:HttpClient
    ){
        
    }

    ngOnInit(){
        this.isReady = true;
    }

    view(itemUrl){
        // window.open(itemUrl, '_blank');
        this.getDocketAsset(itemUrl).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    getDocketAsset(docUrl:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.BASE_API + docUrl,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }

    remove(){
        this.onRemove.emit(this.id);
    }
}