import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HealthComponent } from './health-page/health.component';
import { HealthRouting } from './health.routes';
import { HealthService } from './health.service';


@NgModule({
    imports:[
      CommonModule,
      HealthRouting,
      NgbModule
    ],
    declarations:[
        HealthComponent
    ],
    providers:[
        HealthService
    ],
    exports:[
        RouterModule
    ]
  })

  export class HealthModule {}
  