import { Component, Input, ComponentFactoryResolver, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FileItem, HttpClientUploadService, MineTypeEnum, UploadEndPoint } from '@wkoza/ngx-upload';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { BucketService } from './bucket.service';

@Component({
    selector:'bucket',
    template:require('./bucket.component.html')
})
export class Bucket implements OnDestroy{

    public default_doc = false;
    public uploadsRequired:any[] = ['generic'];
    public uploadFileProgress = false;

    @Input() set config(config:BucketConfiguration){

        let _datePipe = new DatePipe('en-US');
        //(config.categories)
        this.categories = (config.categories) ? this.createCategoryOptions(config.categories) : this.categories;
        this.loading = true;
        this.title = config.title;

        if(config.items){
            this.bucketItems = config.items.map((item)=>{
                return Object.assign({},item,{
                    category:this.getCategoryLabel(item.category),
                    created:_datePipe.transform(item.created,'MMMM d, y')
                });
            });
        }
       // this.readOnly = config.readonly;
        this.cardClass = config.classes;


        this.uploadContext = (config.context) ? Object.assign({},
            this.uploadContext,
            config.context
        ) : this.uploadContext;
        
        this.labels = Object.assign({},this.labels,config.labels);
       

    }

    @Input() set isMobileDevice(bool:boolean){
        this.isMobile = bool;
    }
    @Input() set MaximumMB(size:number){
        this.maxMegaBytes = size;
    }

    @Input() set isfromAppellant(bool:boolean){
        this.fromAppellant = bool;
    }

    @Input() set multiple(bol:boolean){
        // DEPRICATED in favor of better UX
        // this.uploadMultiple = bol;
        // if(!bol){
        //     this.inputFileOptions = Object.assign({},this.inputFileOptions,{
        //         multiple:false
        //     });
        // }
    }

    @Input() set disabled(truthy:boolean){
        this.isDisabled = truthy;
        this.readOnly = truthy;
    }

    @Input() set uploadWith(upload){
        this.uploadFn = upload;
    }
    public uploadFn:any = ()=>{}

    @Input() uploadProgress: number = 0;
    @Input() hideUploaded:boolean = false;

    @Output() onUpdateItems:EventEmitter<any> = new EventEmitter();
    @Output() onUploadSuccess:EventEmitter<any> = new EventEmitter();
    @Output() onDeleteItem:EventEmitter<any> = new EventEmitter();
    @Output() onError:EventEmitter<any> = new EventEmitter();

    public ngxUnsubscribe:Subject<any> = new Subject<any>();

    public inputFileOptions = {
        multiple:false,
        accept:[
            MineTypeEnum.Image_Gif,
            MineTypeEnum.Image_Jpeg,
            MineTypeEnum.Image_Png,
            MineTypeEnum.Application_Doc,
            MineTypeEnum.Application_VndDocm,
            MineTypeEnum.Application_VndDocx,
            MineTypeEnum.Application_VndXlt,
            MineTypeEnum.Application_VndXla,
            MineTypeEnum.Application_VndXls,
            MineTypeEnum.Application_VndXlsx,
            MineTypeEnum.Text_Csv,
            MineTypeEnum.Application_Pdf
        ]
    }

    public baseFormControls = [
        'name',
        'name_selected',
        'name_filename',
        'category',
        'is_privileged',
        'requiredUploads'
    ];


    public currentUploadBeingSelected:string = null;
    public uploadMultiple:boolean = false;
    public isMobile: boolean = false;
    public isDisabled = false;
    public uploadContext:BucketUploadContext = {};
    public maxMegaBytes:number = 40;
    public fromAppellant = false;
    public title = null;
    public uploadFormTitle = null;
    public categories:BucketSelectOption[] = [];
    public queueTitle = null;
    public type = null;
    public loading:boolean = false;
    public fileForm:FormGroup = new FormGroup({});
    public firstPendingUploadItem:FileItem;
    public readOnly = false;
    public cardClass = null;
    public nextFile:any = null;
    public labels:BucketLabelOptions = {
        success:'Successful Uploads',
        pending:'Pending Uploads',
        upload:'Choose a File',
        drag_and_drop_valid:'You can also drop files here',
        drag_and_drop_invalid:''
    }

    public frozen:boolean = false;

    public itemValues:any = {};

    public bucketItems:BucketItemConfiguration[] = [];

    constructor(
        public componentFactoryResolver:ComponentFactoryResolver,
        public uploader:HttpClientUploadService,
        public formBuilder:FormBuilder){

        this.fileForm = this.formBuilder.group({
            name:[null,[Validators.required,Validators.minLength(1)]],
            name_selected:[false,[Validators.required,Validators.requiredTrue]],
            name_filename:[null,[Validators.required,,Validators.minLength(1)]],
            category:[null,[Validators.required,,Validators.minLength(1)]],
            is_privileged:[false],
            requiredUploads:[['generic']]
        });

        this.fileForm.controls.category.valueChanges.pipe(takeUntil(this.ngxUnsubscribe)).subscribe((categorySlug)=>{
            
            let _matchingCategory = this.categories.filter((category)=>{
                return category.slug === categorySlug;
            })[0];

            if(_matchingCategory){
                this.fileForm.patchValue({
                    is_privileged:_matchingCategory.is_privileged,
                    requiredUploads:_matchingCategory.requiredUploads
                });
                this.uploadsRequired = _matchingCategory.requiredUploads;
                this.uploadMultiple = _matchingCategory.requiredUploads.length > 1;
                if(this.uploadsRequired.length > 1){
                    this.uploadsRequired.map((upload,i)=>{
                        if(i > 0){
                            this.fileForm.addControl(
                                upload,new FormControl(
                                    (this.fileForm.controls['name'].value !== "" &&
                                        this.fileForm.controls['name'].value !== undefined &&
                                        this.fileForm.controls['name'].value !== null ) ? 
                                    this.fileForm.controls['name'].value+' - '+_.startCase(upload) :
                                    _.startCase(upload)
                                ,Validators.required)
                            );
                            this.fileForm.addControl(
                                upload+'_selected',new FormControl(false,[Validators.required,Validators.requiredTrue])
                            );
                            this.fileForm.addControl(
                                upload+'_filename',new FormControl('',Validators.required)
                            );
                        }
                    })
                }else{
                    Object.keys(this.fileForm.controls).map((controlKey)=>{
                        if(!this.baseFormControls.includes(controlKey)){
                            this.fileForm.removeControl(controlKey);
                        }
                    })
                }
            }else{
                this.fileForm.patchValue({
                    is_privileged:false,
                    requiredUploads:['generic']
                });
                this.uploadsRequired = ['generic'];
                this.uploadMultiple = false;
                Object.keys(this.fileForm.controls).map((controlKey)=>{
                    if(!this.baseFormControls.includes(controlKey)){
                        this.fileForm.removeControl(controlKey);
                    }
                })
            }
    
            
        });

        this.fileForm.controls.name.valueChanges.pipe(takeUntil(this.ngxUnsubscribe)).subscribe((value)=>{
            if(this.uploadsRequired.length > 1){
                this.uploadsRequired.map((upload,i)=>{
                    if(i > 0){
                        this.fileForm.patchValue({
                            [upload]:value+' - '+_.startCase(upload)
                        });
                    }
                })
            }
        })

        uploader.onProgress$.pipe(takeUntil(this.ngxUnsubscribe)).subscribe((fileItem)=>{
            this.nextFile = fileItem;
        });

        uploader.onAddToQueue$.pipe(takeUntil(this.ngxUnsubscribe)).subscribe((data:any)=>{
            
            let _fileToRemove = null;

            console.log(this.fileForm.controls[this.currentUploadBeingSelected],this.currentUploadBeingSelected)

            data.file['given_name'] = this.fileForm.controls[this.currentUploadBeingSelected].value;
            data.file['is_parent'] = (this.currentUploadBeingSelected === 'name');
            data.file['privileged'] = false;
            data.file['slug'] = (this.currentUploadBeingSelected === 'name') ? this.fileForm.controls['category'].value : this.currentUploadBeingSelected ;
            
            if(this.uploadMultiple){

                if(
                    this.fileForm.controls[this.currentUploadBeingSelected+'_selected'].value &&
                    this.fileForm.controls[this.currentUploadBeingSelected+'_filename'].value !== '' &&
                    this.fileForm.controls[this.currentUploadBeingSelected+'_filename'].value !== null &&
                    this.fileForm.controls[this.currentUploadBeingSelected+'_filename'].value !== undefined
                ){
                    _fileToRemove = uploader.queue.filter((file)=>{
                        return file.file['slug'] === this.currentUploadBeingSelected;
                    })[0];
                    if(_fileToRemove){
                        this.uploader.removeFromQueue(_fileToRemove);
                    }
                }

                this.fileForm.patchValue({
                    [this.currentUploadBeingSelected+'_selected']:true,
                    [this.currentUploadBeingSelected+'_filename']:data.file.name
                });

                if(data.file){
                    if(data.file.size/1000000 <= this.maxMegaBytes){
                        this.onUpdateItems.emit({
                            fileItem:data,
                            formData:this.fileForm.value
                        });
                    }else{
                        this.onError.emit({
                            type:'size_exceeded',
                            file:data.file
                        });
                        this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length-1]);
                    }
                }
            }else{
                if(this.uploader.queue.length > 1){
                    this.uploader.removeFromQueue(this.uploader.queue[0]);
                }
                if(data.file){
                    if(data.file.size/(1024*1024) <= this.maxMegaBytes){
                        this.onUpdateItems.emit({
                            fileItem:data,
                            formData:this.fileForm.value
                        });
                        this.firstPendingUploadItem = (this.uploader.queue[0]) ? this.uploader.queue[0] : null ;
                        if(this.firstPendingUploadItem){
                            this.startFileUpload();
                        }
                    }else{
                        this.onError.emit({
                            type:'size_exceeded',
                            file:data.file
                        });
                        this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length-1]);
                    }
                }
            }
        });

        uploader.onSuccess$.pipe(takeUntil(this.ngxUnsubscribe)).subscribe((data:any)=>{
            this.onUploadSuccess.emit({
                fileItem:data,
                formData:this.fileForm.value
            });
        });

    }

    onSelectFile(slug:string){
        this.currentUploadBeingSelected = slug;
    }

    startFileUpload(){
        if(this.uploadMultiple){
            this.fileUploadMultiple(this.uploader.queue);
        }else{
            this.fileUpload(this.uploader.queue[0]);
        }
    }

    fileUploadMultiple(queue){
        this.frozen = true;

        this.uploadFn({
            filePacket:queue,
            formGroupData:this.fileForm.value
        }).pipe(take(1)).subscribe((success:boolean,err?:any)=>{
            if(success){
                this.uploader.onSuccess$.next({
                    item:queue, 
                    body:'400', 
                    status:400, 
                    headers:null
                });
                this.uploader.removeAllFromQueue();
                this.firstPendingUploadItem = null;
                Object.keys(this.fileForm.controls).map((key)=>{
                    this.fileForm.patchValue({
                        [key]:null
                    });
                })
            }else{
                this.uploader.onError$.next({
                    item:queue, 
                    body:err.error, 
                    status:err.status, 
                    headers:err.headers
                });
            }
            this.frozen = false;
        },(err)=>{
            this.uploader.onError$.next({
                item:queue, 
                body:err.error, 
                status:err.status, 
                headers:err.headers
            });
            this.frozen = false;
        });
    }

    fileUpload(itemFile){
        this.frozen = true;
        this.uploadFileProgress = true;
        //console.log(itemFile)

        // Execute the bound upload function ( which should return an be an observable ),
        // then handle the subscription event
        this.uploadFn({
            fileItem:itemFile,
            formGroupData:this.fileForm.value
        }).pipe(take(1)).subscribe((success:boolean,err?:any)=>{
            if(success){
                this.uploader.onSuccess$.next({
                    item:itemFile, 
                    body:'400', 
                    status:400, 
                    headers:null
                });
                this.uploader.removeFromQueue(itemFile);
                this.firstPendingUploadItem = null;
                console.log(this.fileForm);
                Object.keys(this.fileForm.controls).map((key)=>{
                    this.fileForm.patchValue({
                        [key]:null
                    });
                })
                this.uploadFileProgress = false;
            }else{
                this.uploadFileProgress = false;
                // this.uploader.onError$.next({
                //     item:itemFile, 
                //     body:err.error, 
                //     status:err.status, 
                //     headers:err.headers
                // });
            }
            this.frozen = false;
           
        },(err)=>{
            this.uploadFileProgress = false;
            this.uploader.onError$.next({
                item:itemFile, 
                body:err.error, 
                status:err.status, 
                headers:err.headers
            });
            this.frozen = false;
        });
    }

    createCategoryOptions(categories:BucketSelectOption[]){
        return categories.map((category)=>{
            if(category.id){
                return category;
            }else{
                return Object.assign({},
                    category,
                {
                    // TODO convert to regex to remove lodash dependency
                    id:'bucketChoice_'+_.camelCase(category.display_name),
                });
            }
        })
    }

    removeItemFromUploads(id:String){
        //console.log(id)
        this.onDeleteItem.emit(id);
    }

    getCategoryLabel(value:any){
        let _firstMatch = this.categories.filter((category)=>{
            return category.slug === value;
        })[0];
        return (_firstMatch) ? _firstMatch.display_name : 'uncategorized';  
    }

    ngOnDestroy(){
        this.ngxUnsubscribe.next('');
        this.ngxUnsubscribe.complete();
    }

    triggerEnterClick(btn:any, event:any){
        event.preventDefault();
        let element: HTMLElement= document.getElementById(btn) as HTMLElement;
        element.click();
    }
}