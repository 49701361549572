import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap} from 'rxjs/operators';
import { NavigationService } from '../../../platform/modules/navigation';
import { RequestNewAppeal, StartNewAppealWizard } from './actions/appeals-applications.actions';
import { APPEALS_APPLICATIONS_ACTIONS } from '../constants/appeals-applications.constants';
import { TriggerModal, TriggerToast } from '../../../platform/modules/notification';

@Injectable()
export class AppealsApplicationsEffects {
  // Listen for the 'LOGIN' action
  constructor(
    public actions$: Actions,
    public navigationService:NavigationService,
    public ngrxstore:Store<any>) {
    }

    triggerErrorAsModal(err:any){
      if(err){
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:"An Error occured",
            message:JSON.stringify(err.error),
          },
          type:'message'
        }))
        throw err;
      }
    }

    triggerErrorAsToast(err:any, message:string = 'Error'){
      this.ngrxstore.dispatch( new TriggerToast({
        message:message+': '+err.statusText,
        style:'danger',
        ttl:6
      }));
    }

    triggerSuccessAsToast(message:any){
      this.ngrxstore.dispatch( new TriggerToast({
        message:message,
        style:'success',
        ttl:4
      }));
    }

    @Effect({dispatch:false})
    onRequestNewAppeal$: Observable<RequestNewAppeal> = this.actions$.pipe(
      ofType<RequestNewAppeal>(APPEALS_APPLICATIONS_ACTIONS.REQUEST_NEW_APPEAL),
      tap((action) =>{
          //console.log(action)
          this.ngrxstore.dispatch( new StartNewAppealWizard(action.payload.values) );
      })
    );

}
