export const NAVIGATION_CONSTANTS = {
  SET_CURRENT_ROUTE_URL:'SET_CURRENT_ROUTE_URL',
  SET_PREVIOUS_ROUTE_URL:'SET_PREVIOUS_ROUTE_URL',
  SET_DRAWER_COLLAPSE:'SET_DRAWER_COLLAPSE',
  SET_DRAWER_TYPE:'SET_DRAWER_TYPE',
  SET_ROLES:'SET_ROLES',
  SET_BROWSER_STATE:'SET_BROWSER_STATE',
  SET_PAGE:'SET_PAGE',
  FREEZE_NAVIGATION:'FREEZE_NAVIGATION',
  THAW_NAVIGATION:'THAW_NAVIGATION',
  SET_CURRENT_PAGE_TAB:'SET_CURRENT_PAGE_TAB'
}
