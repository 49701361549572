import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as changeCase from 'change-case';
import { API_CONFIG, ApiConfig } from '../../app.interface';
import { catchError, observeOn, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MOCK_TICKET, YOUR_APPEALS_TABLE, YOUR_APPEALS_TABLE_ALT } from '../../constants/mock.data';
import { createColumns, findColumnNames, traverseJsonArray, traverseJson } from '../../platform/utilities/helpers';
import { FileItem } from '@wkoza/ngx-upload';
import { AuthConfiguration, AUTH_CONFIG } from '../../platform/modules/auth';
import { titleCase } from 'change-case';

@Injectable()
export class AppealsApplicationsService{
    
    constructor(
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
        @Inject(API_CONFIG) public api:ApiConfig,
        public http:HttpClient
    ){}

    getDraftAppealsList(offset?:number,limit?:number,ordering?:{ judge?:string, ordering?:string }){
        let _params = {
            action__name:'Draft'
        };
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        if(ordering){
            if(ordering.judge){
                _params['judge'] = ordering.judge.toString();
            }
            if(ordering.ordering){
                _params['ordering'] = ordering.ordering.toString();
            }
        } 

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'],{
                params:_params
            }).pipe((take(1))).subscribe((response)=>{
                let _totCount= response['count'];
                let _serverData = response['results'];
                
                let _columnList = {
                    "Appellant":['title'],
                    "Date Created":['created'],
                    "Status":['action','name'],
                    "SBA PPP Loan Number":['loan_number'],
                    "Judge":['judge','name'],
                }
                let _columns = createColumns(_columnList);
                let _rows = traverseJsonArray(_columnList,_serverData);
                
                obs.next({totalCount: _totCount, columns : _columns,
                    rows: _rows})
                obs.complete(); 
            })
        });
    }

    getAppealType(){
        return this.http.get(
            this.api.endpoints['APPEAL_TYPE']
        )
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getOgcUsers(offset?:number,limit?:number){
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();
        _params['ordering'] = 'groups_name';

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.USER,{
                params:_params 
            }).pipe(
                take(1),
                catchError(err => throwError(err))
            ).subscribe((response)=>{
                let _response = [] 
                response['results'].map((user)=>{
                    if(user.groups){
                        let _yes = [];
                        user.groups.map((group)=>{
                            if(group.name === 'OGC' || group.name === 'OGC Admin' ){
                                _yes.push(true);
                            }
                        });
                        if(_yes.length > 0){
                            _response.push(user);
                        }
                    }
                })
                obs.next(_response);
                obs.complete();
            })
        });
    }

    getAppealsList(
        queryString?:string,
        offset?:number,
        limit?:number, 
        ordering?:{ judge_ordering?:string, ordering?:string, action__name_ordering?:string,filing_date_ordering?:string }, 
        filter?:{ assigned?:boolean, search?:string, judge?:boolean, missing_ogc_poc?:boolean, attorney_oha?:boolean, missing_poc?:boolean }, 
        role?:any, 
        appealStat?:any,
        appealType?:any
    ):Observable<any>{

        let queryDict = {
            search:'search',
            judge:'assigned_to_judge',
            assigned:'assigned',
            missing_ogc_poc:'primary_contact_ogc__isnull',
            attorney_oha:'assigned_to_attorney',
            missing_poc:'primary_contact__isnull'
        }

        let _params = {};
        
        // if(queryString){
        //     _params[queryString] = 'true';
        // }
        if(queryString){
            _params[queryString] = '';
        }

        if(offset !== undefined) _params['offset'] = offset.toString();

        if(limit) _params['limit'] = limit.toString();

        if(ordering){
            if(ordering.judge_ordering){
                _params['judge_ordering'] = ordering.judge_ordering.toString();
            }
            if(ordering.action__name_ordering){
                _params['action__name_ordering'] = ordering.action__name_ordering.toString();
            }
            if(ordering.filing_date_ordering){
                _params['filing_date_ordering'] = ordering.filing_date_ordering.toString();
            }
            if(ordering.ordering){
                _params['ordering'] = ordering.ordering.toString();
            }
        } 

        if(filter){
          Object.keys(filter).map((key)=>{
              _params[queryDict[key]] = filter[key].toString().trim();
          })  
        } 
        
        if(appealStat) _params['action__name'] = appealStat.toString();
        if(appealType && appealType != "") _params['type'] = appealType.toString(); //to be upadted as backend

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'],{
                params:_params 
            }).pipe((take(1))).subscribe((response)=>{
                let _totCount= response['count'];
                let _serverData = response['results'];
                let _columnList = {};

                if(role === this.authConfig.roles.OHA_ADMIN.slug){
                    _columnList = {
                        "Appellant":['title'],
                        "Judge":['judge','name'],
                        "Status":['action','name'],
                        "SBA PPP Loan Number":['loan_number'],
                        "Last Modified":['modified'],
                        "Deadline":['close_of_record'], 
                        "Appeal Filed":['filing_date'] ,
                        "Reconsideration":['type'],
                        "Duplicate/Refiled Appeals":['has_duplicate_appeals'],
                       
                    }
                }else if(role === this.authConfig.roles.OGC_ADMIN.slug || role === this.authConfig.roles.OGC.slug){
                    _columnList = {
                        "Appellant":['title'],
                        "Judge":['judge','name'],
                        "OGC POC":['primary_contact_ogc','username'],
                        "Status":['action','name'],
                        "SBA PPP Loan Number":['loan_number'],
                        "Last Modified":['modified'],
                        "Deadline":['close_of_record'],
                        "Appeal Filed":['filing_date'],
                        "Reconsideration":['type'],
                        "Duplicate/Refiled Appeals":['has_duplicate_appeals'],
                    }
                }else{
                    _columnList = {
                        "Appellant":['title'],
                        "Judge":['judge','name'],
                        "Status":['action','name'],
                        "SBA PPP Loan Number":['loan_number'],
                        "Last Modified":['modified'],
                        "Deadline":['close_of_record'],
                        "Appeal Filed":['filing_date'],
                        "Reconsideration":['type'],
                        "Duplicate/Refiled Appeals":['has_duplicate_appeals'],
                    }
                }
               
                let _columns = createColumns(_columnList);
                let _rows = traverseJsonArray(_columnList,_serverData);

                
                // console.log("_colums" , _columns);
                // console.log("_rows" , _rows);
                
                obs.next({totalCount: _totCount, columns : _columns,
                    rows: _rows})
                obs.complete(); 
            })
        });
    }

    getAppealStatuses():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ACTION']).pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response['results']);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getSubmittedAppealsList(queryModel?:any,offset?:number,limit?:number,ordering?:{
        judge?:string, ordering?:string,action__name_ordering?:string,filing_date_ordering?:string
    }, filter?:any, type?:any):Observable<any>{
        let _params = {};
        if(queryModel){
            _params[queryModel] = '';
        }
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();
        
        if(ordering){
            if(ordering.judge){
                _params['judge'] = ordering.judge.toString();
            }
            if(ordering.action__name_ordering){
                _params['action__name_ordering'] = ordering.action__name_ordering.toString();
            }
            if(ordering.filing_date_ordering){
                _params['filing_date_ordering'] = ordering.filing_date_ordering.toString();
            }
            if(ordering.ordering){
                _params['ordering'] = ordering.ordering.toString();
            }
        } 

        if(filter) _params['search'] = filter.toString();
        if(type) _params['type'] = type.toString();
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'],{
                params:_params 
            }).pipe((take(1))).subscribe((response)=>{
                let _totCount= response['count'];
                let _serverData = response['results'];

                let _columnList = {
                    "Appellant":['title'],
                    "Date Filed":['filing_date'],
                    "Status":['action','name'],
                    "SBA PPP Loan Number":['loan_number'],
                    "Judge":['judge','name'],
                    "Reconsideration":['type'],
                }
                let _columns = createColumns(_columnList);
                let _rows = traverseJsonArray(_columnList,_serverData);
                
                obs.next({totalCount: _totCount, columns : _columns,
                    rows: _rows})
                obs.complete(); 
            })
        });
    }

    getAppealDocuments(id:string):Observable<any>{
        
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT + '/' + id + this.api.endpoints.APPEAL_DOCUMENTS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{

                    let _response =  response['results'];

                    Object.keys(_response).map((element) =>{
                        _response[element].documents.map((document) => {
                            document.category = titleCase(document.category);
                        })
                    })
                    
                    let _notesList = _response;
                    let _serverData = [];

                    let _multiplePerNote = [];
                    let _singlePerNote = [];

                    if(_notesList){
                        _notesList.map((note)=>{
                            if(note.documents.length > 1){
                                _multiplePerNote.push(note);
                            }else{
                                _singlePerNote.push(note);
                            }
                        });

                        _multiplePerNote = _multiplePerNote.map((note)=>{
                            return Object.assign({},note,{
                                isMultiple:true,
                                set:note.documents,
                                file_url:note.documents[0].file,
                                document:note.documents[0].filename,
                                category:note.documents[0].category,
                                reference_id:note.documents[0].reference_id,
                                documents:null
                            });
                        });
    
                        _singlePerNote = _singlePerNote.filter((note)=>{
                            return note.documents.length > 0;
                        }).map((note)=>{
                            return Object.assign({},note.documents[0],{
                                file_url:note.documents[0].file,
                                document:note.documents[0].filename,
                                category:note.documents[0].category,
                                reference_id:note.documents[0].reference_id,
                            })
                        });
    
                        _serverData = [..._serverData].concat(_multiplePerNote,_singlePerNote)
                    }

                    // console.log("data",_serverData);

                    let _columnList = {
                        "Document":['filename'],
                        "Date":['created'],
                        "Submitted By":['created_by'],
                        "Document Type":['category'],
                       // "Submitted On":['created'],
                        "Actions":['actions']
                        
                    }
                    let _columns = createColumns(_columnList);
                    let _rows = traverseJsonArray(_columnList,_serverData);

                    if(_rows){
                        _rows = _rows.map((row)=>{
                            // console.log(row);
                            if(Array.isArray(row.data)){
                                return Object.assign({},row,{
                                    isMultiple:true,
                                    file_url:row.data[0].file,
                                    document:row.data[0].filename,
                                    category:row.data[0].category,
                                    reference_id:row.data[0].reference_id,
                                });
                            }else{
                                return Object.assign({},row,{
                                    file_url:row.data.file,
                                    document:row.data.filename,
                                    category:row.data.category,
                                    reference_id:row.data.reference_id,
                                })
                            }
                        });
                    }

                    // console.log(_rows,_columns);

                    obs.next({
                        result :response['results'],
                                columns:_columns,
                                rows:_rows,
                                count: response['count'],
                            });
                    obs.complete();
                })
        });
    }

    getAppealDocket(appealId:string,offset?:number,limit?:number,ordering?: {ordering?:string}){
        let _params = {};

        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();
        if(ordering){
            if(ordering.ordering){
                _params['ordering'] = ordering.ordering.toString();
            }
        } 

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+this.api.endpoints['DOCKET_FILING_DOCUMENT'],{
                params:_params
            }).pipe(
                take(1))
            .subscribe((response)=>{
                let _totCount= response['count'];
                let _serverData = response['results'];
                let _columnList = {
                    "file":['file'],
                    "Document":['filename'],
                    "Document Type":['category'],
                    "Date":['filed_to_docket_datetime'],
                    "Submitted By":['created_by','name'],
                    "Certificate of Service": ['certificate_of_service']
                }

                let _docketRowsData = [];
                let _docketColumns = createColumns(_columnList);
                _serverData.map((element) =>{
                    element.category = titleCase(element.category);
                    _docketRowsData.push(element);
                })
                let _docketRows = traverseJsonArray(_columnList,_docketRowsData);
                obs.next({totalCount: _totCount ,docketColumns : _docketColumns,
                    docketRows: _docketRows});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getAppeals(){
        return this.http.get(
            this.api.endpoints['APPEALS_ROOT']
        )
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getAppeal(appealId:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/'+appealId).pipe(
                take(1))
            .subscribe((response)=>{
                // TODO set up columns
                obs.next(response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    postAppeal(payload:any){
        this.http.post(
            this.api.endpoints['APPEAL_ROOT'],
            payload,{observe:'response'}
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getAppealDetails(appealId:string,detailsId:string){
        return this.http.get(
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DETAILS']
            +'/'+detailsId+'/')
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getDockets(appealId:string){
        return this.http.get(
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DOCKETS'])
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getDocuments(appealId:string,from?:string){
        let _uri = (from) ? 
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DOCUMENTS']
            +'/'+from+'/'
         : 
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DOCUMENTS']
        ;
        return this.http.get(
            _uri
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    postDocuments(appealId:string,payload:any,from?:string){
        let _uri = (from) ? 
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DOCUMENTS']
            +'/'+from+'/'
         : 
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_DOCUMENTS']
        ;
        return this.http.post(
            _uri,
            payload,{observe:'response'}
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getPublicNotes(appealId:string){
        let _params = {};

        _params['offset'] = "0"
        _params['limit'] = "100"
        return this.http.get(
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId +
            this.api.endpoints['APPEAL_PUBLIC_NOTE'],
            {
                params:_params
            })
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getCurrentUser():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['CURRENT_USER']).pipe(take(1)).subscribe((response) =>{
                let _response = response["results"];
                _response[0]['is_current']= true;
                obs.next(_response[0]);
                obs.complete();
            }) 
        });
    }

    postNote(appealId:string,payload:any){
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']
            +'/'+appealId+'/'+
            this.api.endpoints['APPEAL_NOTES'],
            payload,{observe:'response'}
        )
        .pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    uploadAppealDocument(appealId:string,document:FileItem,privileged:boolean = false){

        //console.log(privileged)

        let _formData = new FormData();
        _formData.append('filename',document.formData.get('name'));
        _formData.append('category',document.formData.get('category'));
        _formData.append('file',document.file);
        if(privileged){
            _formData.append('is_privileged','true');
        }
        
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENTS'],
            _formData
        ).pipe(take(1))
    }

    removeDocumentFromAppeal(appealSlug:string,documentSlug:string){
        return this.http.delete(this.api.endpoints.APPEAL_ROOT+'/'+appealSlug+this.api.endpoints.APPEAL_DOCUMENTS+'/'+documentSlug).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    generateCertificate(id:string):Observable<any>{
        return this.http.post(this.api.endpoints['APPEAL_ROOT']+'/'+id+
        this.api.endpoints['APPEAL_CERTIFICATE_OF_SERVICE'], '').pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    signCOSAndAttachDocuments(id:string,payload:any){
        return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+id+
        this.api.endpoints['APPEAL_DOCKETS']+"/"+payload.docketId,{
            documents:payload.documents,
            signature:payload.signature,
        }).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    attachDocumentsWithoutCOS(appealId:string, documents:any){
        return this.http.post(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+this.api.endpoints['APPEAL_DOCKETS'],documents).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getDocumentCategories(types?:string[],offset?:number,limit?:number):Observable<any>{
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['DOCUMENT_CATEGORIES_APPELLANT'],{
                params:_params 
            }).pipe(
                take(1),
            ).subscribe((categories)=>{
                categories['results'].push({slug: "motion-for-extension-of-the-close-of-record", display_name: "Motion for Extension of the Close of Record", type: "active_appeal"});
                let _categories = [{
                    slug:null,
                    type:null,
                    display_name:"Error: No Categories Loaded"
                }];
                if(categories['results']){
                    if(types){
                        //console.log(this.authConfig.roles,types,categories['results'])
                        _categories = categories['results'].filter((category)=>{
                            return types.indexOf(category.type)>-1
                        });    
                    }else{
                        _categories = categories['results']
                    }

                    let _finalCategories = [];
                    _categories.map((category)=>{

                        _finalCategories.push({
                            slug:category.slug,
                            display_name:category.display_name,
                            type:category.type,
                            requiredUploads:['generic'],
                            is_privileged:false
                        });

                    })

                    // console.log(_finalCategories)
                    obs.next(_finalCategories);
                    obs.complete();
                }
            },(err)=>{
                if(err){
                    obs.next(null);
                    obs.complete();
                    console.log(err)
                }
            })
        })
    }

    getCOSSetting(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.SETTINGS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['certificate_of_service_enabled']);
                    obs.complete();
                })
        });
    }

    getDocketAsset(docUrl:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.BASE_API + docUrl,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }

    getUploadedDocketAsset(docUrl:string){
        return new Observable((obs)=>{
            this.http.get(docUrl,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }

    addTicketNotes(appealId:string, content:any){
        let _callBody = {
            'category': "public_note",
            'comment': content.text
        };
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_PUBLIC_NOTE'],
            _callBody
        ).pipe(take(1))
    }

    uploadPetitionDocument(appealId:string,document:any){
        const _formData = new FormData();
        // console.log(">>" , document);
        _formData.append('filename',document['name']);
        _formData.append('category',"petition-for-reconsideration");
        _formData.append('name',document['name']);
        _formData.append('file',document);
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENTS'],
            _formData
        ).pipe(take(1))
    }

    getUploadedDocuments(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT + '/' + id + this.api.endpoints.APPEAL_DOCUMENTS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next( response['results'] );
                    obs.complete();
                })
        });
    }
}
