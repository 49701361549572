import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from '../../shell';
import { HEALTH_PATHS, HEALTH_ROOT_PATH } from './constants/health.constants';
import { HealthComponent } from './health-page/health.component';

export const HEALTH_ROUTES = [
    {
        path:HEALTH_ROOT_PATH,
        component:ShellComponent,
        children:[
            {
                path:HEALTH_PATHS.HEALTH_PAGE, 
                component:HealthComponent,
            }
        ]
    }
]

export const healthRoutes: Routes = HEALTH_ROUTES;

export const HealthRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(healthRoutes);