import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { NavigatedFromSummary, StoreAppealValues } from '../../store/appeal-wizard.actions';
import { WizardStep } from '../wizard-step.component';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { selectNavigationFlag, selectPPPAppealWizardValue, selectPPPAppealWizardValues } from '../../store/appeal-wizard.selectors';
import { MOCK_INDUSTRY, MOCK_STATES } from '../../../../constants/mock.data';
import { InputCard } from '../../../../platform/modules/shared/components/input-card/input-card.component';
import { TriggerModal, TriggerToast } from '../../../../platform/modules/notification';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../../app.interface';
import { init } from '@sentry/browser';
import { mapSlugToId } from '../../../../platform/utilities/helpers';

@Component({
    changeDetection:ChangeDetectionStrategy.OnPush,
    selector:'borrower-details',
    template:require('./borrower-details.component.html'),
})
export class BorrowerDetails extends WizardStep implements AfterViewInit{

    @ViewChild('savedDemographicsContainer',{read:ViewContainerRef}) savedDemographicsContainer:ViewContainerRef;
    @ViewChild('newDemographicsContainer',{read:ViewContainerRef}) newDemographicsContainer:ViewContainerRef;
    
    public industryOptions:any = [];
    public lenderLabelModel:string = null;
    public industryLabelModel:string = null;
    
    public newDemographicsData:any = {};
    public newDemographicComponents:any = null;
    public savedDemographicsData:any = {};
    public savedDemographicComponents:any = null;

    public demographicsDropdowns:any = [];
    public demographicsDropdownsValue:any =[];
    public demographicCollpaseLabel: string = "Saved Principals";
    public showViewDemographicLink = false;
    public isAddressInput:boolean = false;
    public isNavigatedFromSummary:boolean = false;

    public demographicsComponentConfiguration:InputCardConfiguration = {
        name:'demographic',
        title:{
            name:'Principal Name',
        },
        inputs:[]
    }

    //mock data
    stateOptions:any = MOCK_STATES.map((obj)=>{
        obj['value'] = obj['abbreviation']
        obj['label'] = obj['name']
        return obj;
    });



    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        public changeDetector:ChangeDetectorRef,
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService,
        public componentFactoryResolver:ComponentFactoryResolver,
        public router:Router
    ){
        super(
            "Borrower Details",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        let _initialValues = this.activatedRoute.snapshot.data.state.storedValues;

        this.ngrxstore.select(selectNavigationFlag()).subscribe((isNavigated)=>{
            this.isNavigatedFromSummary = isNavigated;
        });

        this.activatedRoute.snapshot.data.demographics.details.map((_demographic)=>{
            this.savedDemographicsData[_demographic.reference_id] = _demographic;
        });

        if(Object.keys(this.savedDemographicsData).length > 0){
            this.show('savedDemographicsToggler');
        }else{
            this.hide('savedDemographicsToggler')
        }

        if(_initialValues[this.fields.ASYA_BORROWER_ADDRESS_LINE1.storeKey] != null){
            this.isAddressInput = true;
        }

        let _industry_code = (_initialValues[this.fields.ASYA_BORROWER_INDUSTRY.storeKey] != null ) ? _initialValues[this.fields.ASYA_BORROWER_INDUSTRY.storeKey].code : null; 

        this.formGroup = this.formBuilder.group({
            
            [this.fields.ASYA_BORROWER_ADDRESS_LINE1.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_ADDRESS_LINE1.storeKey
            ],[Validators.maxLength(80),Validators.required]],
            [this.fields.ASYA_BORROWER_ADDRESS_LINE2.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_ADDRESS_LINE2.storeKey
            ],[Validators.maxLength(80)]],
            [this.fields.ASYA_BORROWER_ADDRESS_CITY.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_ADDRESS_CITY.storeKey
            ],[Validators.maxLength(40),Validators.required]],
            [this.fields.ASYA_BORROWER_ADDRESS_STATE.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_ADDRESS_STATE.storeKey
            ],[Validators.required]],
            [this.fields.ASYA_BORROWER_ADDRESS_ZIP.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_ADDRESS_ZIP.storeKey
            ],[Validators.maxLength(5),Validators.required]],
            [this.fields.ASYA_BORROWER_INDUSTRY.storeKey]:[_initialValues[
                this.fields.ASYA_BORROWER_INDUSTRY.storeKey
            ]],
            [this.fields.ASYA_BORROWER_INDUSTRY_CODE.storeKey]:[(_initialValues[this.fields.ASYA_BORROWER_INDUSTRY.storeKey] != null && _initialValues[this.fields.ASYA_BORROWER_INDUSTRY.storeKey] != undefined ) ? _initialValues[this.fields.ASYA_BORROWER_INDUSTRY.storeKey].code  : null,[Validators.required]],
            [this.fields.ASYA_EMP_AT_LOAN.storeKey]:[_initialValues[
                this.fields.ASYA_EMP_AT_LOAN.storeKey
            ],[Validators.maxLength(3),Validators.pattern("^[0-9]*$")]],
            [this.fields.ASYA_EMP_AT_FORGIVENESS.storeKey]:[_initialValues[
                this.fields.ASYA_EMP_AT_FORGIVENESS.storeKey
            ],[Validators.maxLength(3),Validators.pattern("^[0-9]*$")]]
            
        });
        this.hide('savedDemographics');

        // This is a method in the WizardStep class which watches value changes on formGroup, simply call it and the ngrx store will start saving values as a user types.
        this.watchChanges((changes)=>{
            console.log(changes)
        });

    }

    ngAfterViewInit(){
        this.appendSavedDemographicsData();
        // let _refreshSub = this.appealWizardService.$RefreshTrigger.pipe(take(1)).subscribe((refresh)=>{
        //     if(refresh){
        //         this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe(
        //         (value)=>{
        //             this.changeDetector.detectChanges();
        //         })
        //     }
        // })
    }

    ngOnDestroy(){
        this.ngrxstore.dispatch(new NavigatedFromSummary(false))
    }

    toggleSavedDemographics(show?:boolean){
        let _hidden = (show) ? !show : this.hidden.savedDemographics;
        if(_hidden){
            this.show('savedDemographics');
        }else{
            this.hide('savedDemographics');
        }
    }

    onAddressFinal(content){
        this.isAddressInput = true;
        let _addressObj= content[0];
        this.formGroup.patchValue({
            [this.fields.ASYA_BORROWER_ADDRESS_LINE1.storeKey]: _addressObj.delivery_line_1,
            [this.fields.ASYA_BORROWER_ADDRESS_LINE2.storeKey]: "",
            [this.fields.ASYA_BORROWER_ADDRESS_CITY.storeKey]: _addressObj.components.city_name,
            [this.fields.ASYA_BORROWER_ADDRESS_STATE.storeKey]: _addressObj.components.state_abbreviation,
            [this.fields.ASYA_BORROWER_ADDRESS_ZIP.storeKey]: _addressObj.components.zipcode,

        });
    }

    onUserInputAddressLine1(content){
        this.isAddressInput = true;
       if(typeof content === 'string'){
        this.formGroup.patchValue({
            [this.fields.ASYA_BORROWER_ADDRESS_LINE1.storeKey]: content

        });
       } 
    }

    onEmptyAddress(){
        this.isAddressInput = false;
    }

    appendNewDemographic(){
        this.freeze('addDemographicButton');
        let _numberOfnewDemographicsData = Object.keys(this.newDemographicsData).length;
        if(!this.newDemographicComponents){
            this.newDemographicComponents = {};
        }
        this.newDemographicComponents[_numberOfnewDemographicsData] = this.buildDemographicInstance(
            {reference_id:_numberOfnewDemographicsData},
            this.createDemographicDropdowns({
                veteran_status:null,
                gender:null,
                race:null,
                ethnicity:null
            }),
            'new'
        );
    }

    appendSavedDemographicsData(){
        let _numberOfsavedDemographicsData = Object.keys(this.savedDemographicsData).length;
        if(!this.savedDemographicComponents){
            this.savedDemographicComponents = {};
        }

        Object.keys(this.savedDemographicsData).map((_demographicKey)=>{
            let _demographic = this.savedDemographicsData[_demographicKey];
            this.savedDemographicComponents[_numberOfsavedDemographicsData] = this.buildDemographicInstance(
                _demographic,
                this.createDemographicDropdowns({
                    veteran_status:_demographic.veteran_status,
                    gender:_demographic.gender,
                    race:_demographic.race,
                    ethnicity:_demographic.ethnicity
                }),
                'saved'
            );
        })

        this.demographicCollpaseLabel = 'Saved Principals ('+Object.keys(this.savedDemographicsData).length+')';

    }

    createDemographicDropdowns(initialValue){

        let _vetData =  Object.assign({}, this.activatedRoute.snapshot.data.demographics.veteran,{
            value:initialValue.veteran_status
        }); 
        let _genderData =  Object.assign({}, this.activatedRoute.snapshot.data.demographics.gender,{
            value:initialValue.gender
        });
        let _race =  Object.assign({}, this.activatedRoute.snapshot.data.demographics.race,{
            value:initialValue.race
        });
        let _ethnicity =  Object.assign({}, this.activatedRoute.snapshot.data.demographics.ethnicity,{
            value:initialValue.ethnicity
        });

        return [_vetData,_genderData,_race,_ethnicity];
    }


    createInputConfiguration(titleValue:string,inputs:any[]){
        let _inputs = []; 
        inputs.map((input,i)=>{
            _inputs[i] = {... input};
            if(input.name === "Race"){
                _inputs[i].multiple = true;
            }
        });
        return Object.assign({},
            Object.assign({},this.demographicsComponentConfiguration,
                {
                    //inputs:[... _inputs],
                    title:{
                        name:'Principal Name',
                        value:  titleValue
                    }
                })
        );
    }

    createDemographicComponent(data:any,inputs:any[],containerKey:string,labelOptions?:any){

        let _name = data.principal_name || data.name;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            InputCard
        );
        let _container = this[containerKey+'DemographicsContainer'];
        let _config = this.createInputConfiguration(_name,inputs);

        let _component = _container.createComponent(componentFactory);
        
        _component.instance.id = ''+data.reference_id;
        _component.instance.config = _config;
        _component.instance.classes = 'col-lg-6 col-12';

        if(labelOptions){
            let _labels = {};
            Object.keys(labelOptions).map((key)=>{
                _labels[key] = labelOptions[key]
            });
            _component.instance.config = Object.assign({},_config,{
                labels:_labels
            });
        }

        return _component;
    }

    buildDemographicInstance(data:any,inputs:any[],containerKey:string){


        // console.log('On Demographics Instance Build (container key): ',containerKey);

        let _container = this[containerKey+'DemographicsContainer'];
        let _component = null;
        
        switch(containerKey){
            case 'saved':
                _component = this.createDemographicComponent(data,inputs,containerKey,{save:"Update"})
            break;
            default:
                _component = this.createDemographicComponent(data,inputs,containerKey)
            break;
        }

        // console.log('On Demographics Instance Build (component): ',_component);
        // console.log('On Demographics Instance Build (container): ',_container);

        let _onRemove = _component.instance.onRemove.subscribe((emission)=>{
            
            let _thisComponentContainer:ViewContainerRef = _container;
            let _thisDemographicData = {... data};
            let _thisContainerKey = containerKey;

            // console.log('On Demographics Remove (payload): ',emission);
            // console.log('On Demographics Remove (component): ',_component);
            // console.log('On Demographics Remove (container): ',_container);

            switch(_thisContainerKey){
                case 'saved':
                    this.appealWizardService.removeDemographicDetails(
                        this.activatedRoute.snapshot.parent.paramMap.get('id'),
                        _thisDemographicData.reference_id
                    ).subscribe(()=>{

                        _component.destroy();
                        // console.log(this.savedDemographicsData[_thisDemographicData.reference_id])
                        delete this.savedDemographicsData[_thisDemographicData.reference_id];
                        // console.log(this.savedDemographicsData)
                        
                        this.ngrxstore.dispatch( new TriggerToast(
                            {
                                message:"Removed "+_thisDemographicData.name,
                                style:'warning'
                            }
                        ) )
                        this.demographicCollpaseLabel = 'Saved Principals ('+Object.keys(this.savedDemographicsData).length+')';
                        this.changeDetector.detectChanges();
                    });
                break;
                case "new":
                    _component.destroy();
                
                    this.ngrxstore.dispatch( new TriggerToast(
                        {
                            message:"Removed ",
                            style:'warning'
                        }
                    ))

                    this.thaw('addDemographicButton');
                break;
            }

        });

        let _onSave = _component.instance.onSave.subscribe((emission)=>{

            let _thisComponent = _component;
            let _thisContainerKey = containerKey;
            let _thisDemographicData = {... data};
            let _thisInputConfigurations = [... inputs];

            // console.log('On Demographics Save (payload): ',emission);

            if(emission.principal_name || emission.name ){
                
                if (emission.hasOwnProperty("principal_name")) {
                    emission["name"] = emission["principal_name"];
                }

                switch(_thisContainerKey){
                    case 'new':
                        // POST
                        this.appealWizardService.saveDemographicDetails(
                            this.activatedRoute.snapshot.parent.paramMap.get('id'),
                            emission
                        ).subscribe((response)=>{
                            if(!this.savedDemographicsData[response['reference_id']] && response.hasOwnProperty('reference_id')){
                                
                                
                                this.newDemographicsContainer.clear();
                                delete this.newDemographicsData[_thisDemographicData.reference_id];        
                                
                                this.savedDemographicsData[response['reference_id']] = response;
                                this.savedDemographicComponents[response['reference_id']] = _thisComponent;
                                
                                if(Object.keys(this.savedDemographicsData).length > 0){
                                    this.show('savedDemographicsToggler');
                                }else{
                                    this.hide('savedDemographicsToggler')
                                }
                                
                                let _dataFromResponse = this.savedDemographicsData[response['reference_id']]

                                _dataFromResponse.race = mapSlugToId(_dataFromResponse.race);

                                this.buildDemographicInstance(
                                    _dataFromResponse,
                                    this.createDemographicDropdowns({
                                        veteran_status:_dataFromResponse.veteran_status,
                                        gender:_dataFromResponse.gender,
                                        race:_dataFromResponse.race,
                                        ethnicity:_dataFromResponse.ethnicity
                                    }),
                                    'saved'
                                );

                                this.ngrxstore.dispatch(new TriggerToast({
                                    message:"Successfully Saved Principal",
                                    style:'success'
                                }));

                                this.demographicCollpaseLabel = 'Saved Principals ('+Object.keys(this.savedDemographicsData).length+')';
                                this.thaw('addDemographicButton');

                            }
                        });
                    break;
                    case 'saved':

                        let _race = [];
                        emission.race.map((i) => {
                            if(i.id) {
                                _race.push(i.id);
                            }else{
                                _race.push(i);
                            }
                        })

                        let _emission = Object.assign({},emission,{
                            race:_race
                        })

                        // PUT
                        this.appealWizardService.updateDemographicDetails(
                            this.activatedRoute.snapshot.parent.paramMap.get('id'),
                            _thisDemographicData.reference_id,
                            _emission
                        ).subscribe((response)=>{
                            this.savedDemographicsData[response['reference_id']] = response;
                            this.savedDemographicComponents[response['reference_id']] = _thisComponent;


                            this.ngrxstore.dispatch(new TriggerToast({
                                message:"Successfully Updated Principal",
                                style:'success'
                            }));
                        });
                    break;
                }

            }else{

                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                      title:"Check Your Principal Input",
                      message:"Please enter 'Principal Name' for Principal."
                    },
                    type:'message'
                  }));

    
            }
        });

        this.storeZombies.push(_onRemove,_onSave);

        return _component;
    }


    matchIndustry = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.appealWizardService.getIndustryCode(searchText) 
        )              
    );

    resultFormatIndustryValue(value: any) {            
        return value.description;
    } 
      
    inputFormatIndustryValue(value: any)   {
        if(value.description)
            return value.description
        return value;
    }

    industryLabelChange(event:any){
        this.formGroup.patchValue({
            [this.fields.ASYA_BORROWER_INDUSTRY.storeKey]:event.item.description,
            [this.fields.ASYA_BORROWER_INDUSTRY_CODE.storeKey]:event.item.code
        });
        this.changeDetector.detectChanges();
    }

    industryChange(event:any){            
        if((event.target.value.length === 1 || event.target.value.length === 0 || event.keyCode == 8 || event.keyCode == 46) && event.keyCode != 13){
            this.formGroup.patchValue({
                [this.fields.ASYA_BORROWER_INDUSTRY_CODE.storeKey]:null
            });
        }
        
    }

    consumeDemographic(demographic:any){
        this.ngrxstore.select(selectPPPAppealWizardValue(this.fields.ASYA_DEMOGRAPHICS.storeKey)).pipe(take(1)).subscribe((currentDemographics)=>{
            this.ngrxstore.dispatch( new StoreAppealValues({
               [this.fields.ASYA_DEMOGRAPHICS.storeKey]:Object.assign({},currentDemographics,{
                [demographic.reference_id]:demographic
               })
            }));
        });
    }

    refreshDemographics(){
        this.appealWizardService.getDemographicData(
            this.activatedRoute.snapshot.parent.paramMap.get('id')
        ).subscribe((demographicData)=>{
            this.savedDemographicsData = demographicData;
            this.toggleSavedDemographics(true);
        })
    }

}