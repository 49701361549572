import { Component, ElementRef, forwardRef, Inject, Input, LOCALE_ID, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { transform } from 'lodash';
  
@Component({
    template:`
        <select class="form-control"  
            #selectInput
            [ngClass]="classes" 
            type="select" 
            name='{{name}}'
            [attr.title]="title"
            [attr.aria-label]='title' 
            [attr.disabled]="disabled?true:null"
            (change)="onChange($event)"
            (keyup)="onKeyUp($event)" 
            (blur)="touchCallback()">

            <option *ngFor='let option of optionsCollection' value='{{option.value}}'>{{option.label}}</option>

        </select>`,
    selector:"input-select",
})
export class InputSelect implements ControlValueAccessor{

    @ViewChild('selectInput',{static:true}) input:ElementRef;

    @Input() classes:string = '';
    @Input() set label(value){
        this.name = 'input_'+value;
        this.title = _.startCase(value);
        this.id = _.camelCase(value);
    }
    @Input() set options(values){
        this.optionsCollection = values;
    }
    public optionsCollection = [{}];

    public disabled:boolean = false;
    public placeholder:string = null;
    public title:string = '';
    public name:string = '';
    public id:string = '';

    constructor(
        @Inject(LOCALE_ID) public _locale: string,
        public renderer:Renderer2
    ){}
    
    changeCallback=(data:any)=>{};
    touchCallback=()=>{};

    onChange(event){
        this.changeCallback(event.target.value);
    }

    onKeyUp(event){
        this.renderer.setProperty(this.input.nativeElement,'value',event.target.value);
    }

    writeValue(value: any){
        this.renderer.setProperty(this.input.nativeElement,'value',value);
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled=isDisabled;
    }
}