import {Observable} from 'rxjs';
import {distinctUntilChanged,map,debounceTime} from 'rxjs/operators';
import {snakeCase} from 'change-case';

export function createColumns(dict):any{
    let _columns:any[] = Object.keys(dict)
        .map((columnName) => { return {
            name:columnName,
            prop:snakeCase(columnName)
        } 
    })

    return _columns;
}

export function findColumnNames(data):any{
    let _columns = [] ;
    if (data.length > 0){ 
        var columnsIn = data[0]; 
        for(var key in columnsIn){
          _columns.push(key);
        } 
    }

    return _columns.map((columnName) => { return {
        name:columnName.replace(/_/g,' ').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()),
        prop:columnName
    }    
    });
}

export function traverseJsonArray(dict,col):any{
    let _values = [];
    Object.keys(dict).map((columnName,index)=>{
        col.map((_row,_index)=>{
            let _rowRef = _row;
            if(_row.isMultiple){
                _rowRef = _row.set[0];
            }

            if(!_values[_index]){
                _values[_index] = {};
            }
            _values[_index][columnName.toLowerCase().replace(' ','_')] = null;
            _values[_index]['data'] = (_row.isMultiple) ? _row.set : _row;
            let _nestedKeysCount = (dict[columnName]) ? dict[columnName].length : 0;
            if(_nestedKeysCount > 1){
                if(_rowRef[dict[columnName][0]]){
                    switch (_nestedKeysCount){
                        case 2:
                            _values[_index][columnName.toLowerCase().replace(' ','_')] = _rowRef[
                                dict[columnName][0]
                            ][
                                dict[columnName][1]
                            ]; 
                        break;
                        case 3:
                            if(_rowRef[dict[columnName][0]][dict[columnName][1]] === undefined){
                                "";
                            }else{
                                _values[_index][columnName.toLowerCase().replace(' ','_')] = _rowRef[
                                    dict[columnName][0]
                                ][
                                    dict[columnName][1]
                                ][
                                    dict[columnName][2]
                                ]; 
                            }
                        break;
                        case 4:
                            if(dict[columnName][3] === 'append'){
                                _values[_index][columnName.toLowerCase().replace(' ','_')] = (_rowRef[
                                    dict[columnName][0]
                                ]) ?  _rowRef[
                                    dict[columnName][0]
                                ][
                                    dict[columnName][1]
                                ] +" " +  _rowRef[
                                    dict[columnName][0]
                                ][
                                    dict[columnName][2]
                                ]: "";
                            }else{                          
                                if(_rowRef[dict[columnName][0]][dict[columnName][1]] === undefined){
                                    "";
                                }else{
                                    _values[_index][columnName.toLowerCase().replace(' ','_')] = (_rowRef[
                                        dict[columnName][0]
                                    ][
                                        dict[columnName][1]
                                    ][
                                        dict[columnName][2]
                                    ]) ? _rowRef[
                                        dict[columnName][0]
                                    ][
                                        dict[columnName][1]
                                    ][
                                        dict[columnName][2]
                                    ][
                                        dict[columnName][3]
                                    ] : '';
                                }
                            }
                             
	                         break;
                    }
                }else{
                    _values[_index][columnName.toLowerCase().replace(' ','_')] = null;
                }
            }else{
                if(dict[columnName]){
                    let _columnName = columnName.toLowerCase().replace(/ /g,'_');
                    _columnName = _columnName.toLowerCase().replace(/\//g,'_');
                    _values[_index][_columnName] = _rowRef[dict[columnName][0]];
                }else{
                    _values[_index][columnName.toLowerCase().replace(' ','_')] = null;
                }
            }
        })
    });
    return _values;
}

export function traverseJson(dict,json):any{
    let _values = {};
    Object.keys(dict).map((columnName,index)=>{
        if(!_values){
            _values = {};
        }
        _values[columnName.toLowerCase().replace(' ','_')] = null;
        _values['data'] = json;
        let _nestedKeysCount =  (dict[columnName] && json[columnName.toLowerCase().replace(' ','_')]) ? dict[columnName].length : 0;
        if(_nestedKeysCount > 1){
            switch (_nestedKeysCount){
                case 2:
                    _values[columnName.toLowerCase().replace(' ','_')] = json[
                        dict[columnName][0]
                    ][
                        dict[columnName][1]
                    ]; 
                break;
                case 3:
                    _values[columnName.toLowerCase().replace(' ','_')] = json[
                        dict[columnName][0]
                    ][
                        dict[columnName][1]
                    ][
                        dict[columnName][2]
                    ]; 
                break;
            }
        }else{
            if(dict[columnName]){
                _values[columnName.toLowerCase().replace(' ','_')] = dict[columnName][0];
            }else{
                _values[columnName.toLowerCase().replace(' ','_')] = null;
            }
        }
    });
    return _values;
}


export class assigneeMatcher{

    details:any = {};

    constructor(details){
        if(details){
            this.details = {... details} 
        }
    }

    set Details(newDetails){
        this.details = {... newDetails};
        // console.log(this.details);
    }

    matchFieldLabel = (id: any, details:any) => {
        // console.log('matchFieldLabel:' ,details,id)
        let _result = details.filter((obj)=>{
            return obj.value === id;
        });
        //there should never be more than one matching name, if so thats a flaw of the backend/data model
        return (_result[0]) ? _result[0].label : null;
    }

    matchFieldValue = (label: string, details:any) => {
        // console.log('matchFieldValue: ',details,label)
        let _result = details.filter((obj)=>{
            return obj.label === label;
        });
        //there should never be more than one matching name, if so thats a flaw of the backend/data model 
        return (_result[0]) ? _result[0].value : null;
    }   
    matchFieldObject = (label: string, details:any) => {
        // console.log('matchFieldObject: ',label,details)
        let _result = details.filter((obj)=>{
            // console.log("obj>>>>>>",obj);
            return obj.label === label;
        });
        //there should never be more than one matching name, if so thats a flaw of the backend/data model
        return (_result[0]) ? _result[0] : null;
    }   

    matchFieldObjectByRef = (id: string, details:any) => {
        // console.log('matchFieldObjectByRef id: ',id)
        // console.log('matchFieldObjectByRef details: ',details)
        let _result = details.filter((obj)=>{
            // console.log("obj>>>>>>",obj);
            return obj.reference_id === id;
        });
        // console.log("_result>>>>",_result);
        //there should never be more than one matching name, if so thats a flaw of the backend/data model
        return (_result[0]) ? _result[0] : null;
    }   

    matchField(field:string) : (text: Observable<string>) => Observable<any[]> {
        // console.log(this.details)
        let _details = [];
        switch(field){
            case 'judge':
                _details = this.details['judge'] || [];
            break;
            case 'attorney':
                _details = this.details['attorney'] || [];
            break;
            case 'ogc':
                _details = this.details['ogc'] || [];
            break;
            case 'lender':
                _details = this.details['lender'] || [];
            break;
            case 'appellant':
                _details = this.details['appellant'] || [];
            break;
            case 'oha':
                _details = this.details['oha'] || [];
            break;
        }
        return (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) => {
                if(term.length < 2){
                    return [];
                }else{
                    let _matches = _details.filter((obj)=>{
                        return obj.label.toLowerCase().indexOf(term.toLowerCase()) > -1;
                    }).map((obj)=>{
                        return obj.label;
                    }).slice(0,10);
                    return _matches;
                }
            })
	)}
}

export function valueIsDefined(val){
    return( val !== '' && val !== undefined && val !== null);
}

export function removeNulls(dict:any){
    let _nullessModel = {}
    Object.keys(dict).map((key)=>{
        if(dict[key] !== null){
            _nullessModel[key] = dict[key];
        };
    });
    return _nullessModel;
}

export function mapSlugToId(dict){
    let _dict = dict;
    for (var i = 0; i < _dict.length; i++) {
        _dict[i].id = _dict[i].slug;
        _dict[i].name = _dict[i].display_name;
        delete _dict[i].slug;
        delete _dict[i].display_name;
    }
    return _dict;
}

export function mapValueToId(dict){
    let _dict = dict;
    for (var i = 0; i < _dict.length; i++) {
        _dict[i].id = _dict[i].value;
        _dict[i].name = _dict[i].display_name;
        delete _dict[i].value;
        delete _dict[i].display_name;
    }
    return _dict;
}

export function convertToPhoneFormat(value):string{
    let _val = value.indexOf('+1') > -1 ? value.split('+1')[1] : value;
    var ph = _val.replace(/\D/g,'').substring(0,30);
    var len = ph.length;
    if(len==0){
        ph=ph;
    }else if(len<3){
        ph='('+ph;
    }else if(len==3){
        ph = '('+ph + ') ';
    }else if(len<6){
        ph='('+ph.substring(0,3)+') '+ph.substring(3,6);
    }else if(len==6){
        ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+ '';
    }else if(len<10){
        ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+ph.substring(10);
    }else if(len==10){
        ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+'';
    }else{
        if(this.ext){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+' ext. '+ph.substring(10);
        }else{
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10);
        }
    }
    return ph;
}

//Wed, 07 Jul 2021 00:00:00 GMT
export function getDateFromUTC(dateString) {
    const date = dateString.split(" ");
    const convertedDate = date[2] + " " + date[1]+ ", " +date[3];
    return convertedDate;
}