import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }  from '@angular/forms';
import { AuthModule } from '../../platform/modules/auth';
import { PortalRoutingModule } from './portal.routes';
import { UserPortal } from './user/user-portal.component';
import { AdminPortal } from './admin/admin-portal.component';
import { TwoFAPortal } from './twofa/twofa-portal.component';
import { UserPortalResolver } from './resolver/user-portal.resolver';
import { UserIdPortalResolver } from './resolver/user-id-portal.resolver';
import { RouterModule } from '@angular/router';

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
		ReactiveFormsModule,
		AuthModule,
		PortalRoutingModule
    ],
    declarations:[
		UserPortal,
    AdminPortal,
    TwoFAPortal
    ],
    providers:[
      UserPortalResolver,
      UserIdPortalResolver,
    ],
    exports:[
      RouterModule
    ]
})
export class PortalModule{}
