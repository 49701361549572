import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, Component, Inject, ViewChild, ViewChildren, Query, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG} from '../../../app.interface';
import { FreezeNavigation, getPageState, getWindowState, NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView, SetCurrentPageTab, ThawNavigation } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { FilePetition, TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { AssignUsersToTicket, AssignUserToTicket, SaveAppealTicket, UnAssignUserToTicket } from '../../tickets/store/ticket.actions';
import { TicketsService } from '../../tickets/tickets.service';
import { AppealsApplicationsService } from '../appeals-applications.service';
import { APPEALS_APPLICATIONS_PAGE_NAMES, APPEAL_LIST_COLUMNS_SORTING } from '../constants/appeals-applications.constants';
import { AuthConfiguration, AUTH_CONFIG, getSessionState, getPageLimit, ListPageLimit  } from '../../../platform/modules/auth';
import { CurrentFilterValues } from '../store/actions/appeals-applications.actions';
import { selectAppealApplicationsFilters } from '../store/appeals-applications.selector';
import { selectFilePetitionDetails } from '../../../platform/modules/notification/store/notification.selector';
import { CreateReconsiderationAppeal } from '../../appeal-wizard/store/appeal-wizard.actions';
import { HttpErrorResponse } from '@angular/common/http';


interface AppealRows{
	data:any;
	total:number;
}

interface AppealColumns{
	raw:any[];
	display:any[];
}


@Component({
	selector: 'appeal-applications-list',
	template: require('./appeal-applications-list.component.html')
})
export class AppealApplicationsList extends KillZombies(PageView) implements AfterViewInit {
	
	@ViewChild('appealTable') table: any;
	@ViewChild('unassignedTable') unassignedTable: any;
	@ViewChild('assignedTable') assignedTable: any;

	@ViewChildren(DatatableComponent) dataTables: QueryList<DatatableComponent>;

	rows:AppealRows = {
		data:[],
		total:0
	};

	columns:AppealColumns = {
		raw:[],
		display:[]
	};

	assignedRows:AppealRows = {
		data:[],
		total:0
	};

	unassignedRows:AppealRows = {
		data:[],
		total:0
	};

	emptyColumn:any = {
		name: "Actions",
		prop: "emptyColumn",
		sortable: false
	};

	statusList:any = [];
	ncValue:string= "";
	
	viewRefDict = {
		assigned:null,
		unassigned:null,
		all:null
	}

	public activeTable:'assigned'|'unassigned'|'all' = 'all';
	public activeFilter:'assigned'|'unassigned'|'all' = 'all';
	public currentOrderedBy:any = {ordering:'filing_date'};
	public previousClickedRow: any = null;
	public invalidAssigneeChoices:any = {};
	public filterValues:{
		assigned?:any,
		unassigned?:any,
		all?:any
	} = {}

	public appealsPerPage: number = 30 ; //this.appDefaults.ui.LIST_PAGE_LIMIT;
	
	public appealsData: any = null;
	public assignedAppealsData: any = null;
	public unassignedAppealsData: any = null;
	
	public appealsOffset:any = 0;
	public assignedAppealsOffset:number = 0;
	public unassignedAppealsOffset:number = 0;
	
	public columnsForDisplay = [];
	public defaultSorts = [];
	public currentUserRoles = [];
		
	public appealTypes: any = this.appDefaults.ui.APPEAL_TYPE_OPTIONS_FILTER;
	public rowsPerPageOptions= this.appDefaults.ui.ROWS_PER_PAGE_OPTIONS || [{label:'10',value:"10"}];
	
	public searchForm:FormGroup = new FormGroup({});
	public assigneeForm:FormGroup = new FormGroup({});

	public columnMode = ColumnMode;
	
	public judgeAppealsAssigned:any= null;
	public judgeMaxAppeals:any= null;
	public updatedJudge:any = {};
	
	public attorneyAppealsAssigned:any= null;
	public validAttorney = true;
	public attorneyMaxAppeals:any= null;
	public updatedAttorney:any= {};
	
	public canAssign:boolean = false;
	public ohaExtenalCanAssign:boolean = false;
	public showSingleTable:boolean = false;
	public showTabbedTables:boolean = false;
	public showSingleAssignTable:boolean = false;
	public viewRefDictInitialized:boolean = false;

	public filterStoreValues= {
		term:'',
		type:'',
		appealStatus: ''
	};

	public expandedRows:{reference_id:string,index:number}[] = [];
	public expandedRowIndex:number = null;

	public currentUser:any={};

	public columnVisibility = {
        isFullHD:[
			"Appellant",
			"Judge",
			"OGC POC",
            "Status",
            "SBA PPP Loan Number",
            "Last Modified",
			"Deadline",
			"Appeal Filed",
			"Duplicate/Refiled Appeals",
			"Reconsideration",
			"Actions"

        ],
        isWidescreen:[
            "Appellant",
			"Judge",
			"OGC POC",
            "Status",
            "SBA PPP Loan Number",
            "Last Modified",
			"Deadline",
			"Appeal Filed",
			"Duplicate/Refiled Appeals",
			"Reconsideration",
			"Actions"
        ],
        isDesktop:[
            "Appellant",
			"Judge",
			"OGC POC",
            "Status",
            "SBA PPP Loan Number",
            "Last Modified",
			"Deadline",
			"Appeal Filed",
			"Duplicate/Refiled Appeals",
			"Reconsideration",
			"Actions"
        ],
        isTablet:[
			"Appellant",
            "Judge",
			"Status",
            "SBA PPP Loan Number",
			"Actions"
        ],
        isMobile:[
			"Appellant",
            "Judge",
			"Status"
        ]
    }

	public columnVisibilityUnassigned = {
        isMobile:[
			"Appellant",
            "SBA PPP Loan Number",
			"Status"
        ]
    }

	public windowState : string = 'isWidescreen';
	public petitionToolTip: string = 'File Petition for Reconsideration';
	public isDayLightSavingsOn = false;

	constructor(
		@Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
		@Inject(NAVIGATION_CONFIG) public configuration: NavigationConfig,
		@Inject(TicketsService) public ticketService:TicketsService,
		public changeDetector:ChangeDetectorRef,
		public navigationService: NavigationService,
		public activatedRoute: ActivatedRoute,
		public appealApplicationService: AppealsApplicationsService,
		public formBuilder:FormBuilder,
		public ngrxstore:Store<any>,
		public router: Router,
		@Inject(AUTH_CONFIG) public authConfig:AuthConfiguration
	){

		super(configuration, navigationService);
		this.appealsData = this.activatedRoute.snapshot.data.appealsList.appealData;
		this.unassignedAppealsData = this.activatedRoute.snapshot.data.appealsList.unassignedAppealData;
		this.assignedAppealsData = this.activatedRoute.snapshot.data.appealsList.assignedAppealData;
		this.currentUser = this.activatedRoute.snapshot.data.appealsList.currentUser;
		this.statusList= this.activatedRoute.snapshot.data.appealsList.appealStatus;
		this.statusList.map((status) => {
			if(status.name !== 'Complete'){
				this.ncValue+= status.name+ ","
			}
		});
		if(this.authConfig.roles.OGC_ADMIN.slug || this.authConfig.roles.OGC.slug){
			this.statusList = this.statusList.filter(({ name }) => name !== 'In Progress'); 

		}

		let _windowState = this.ngrxstore.select(getWindowState()).subscribe((state)=>{
			this.isMobile = ( state.size === 'isMobile' );
			this.isTablet = ( state.size === 'isTablet' );
			this.isDesktop = ( state.size === 'isDesktop' );
			this.isWidescreen = ( state.size === 'isWidescreen' );
			this.isFullHD = ( state.size === 'isFullHD' );
			this.windowState = state.size;
		  });

		this.Name = APPEALS_APPLICATIONS_PAGE_NAMES.APPEAL_APPLICATIONS_LIST;

		this.ngrxstore.select(selectAppealApplicationsFilters()).subscribe((response)=>{
			this.filterStoreValues = response;
		});

		this.ngrxstore.select(getPageLimit).pipe(take(1)).subscribe((response)=>{
			this.appealsPerPage = response;
		});
		
		this.searchForm = this.formBuilder.group({
			type:[this.filterStoreValues.hasOwnProperty('type') ? this.filterStoreValues.type : ''],
			term:[this.filterStoreValues.hasOwnProperty('term') ? this.filterStoreValues.term : ''],
	 		appealStatus:[this.filterStoreValues.hasOwnProperty('appealStatus') ? this.filterStoreValues.appealStatus : '']
		});

		let _searchFilterFormSub = this.searchForm.valueChanges.subscribe((change)=>{
			
		});

		this.assigneeForm = this.formBuilder.group({
			judgeLabel:[null],
			attorneyLabel:[null],
			judge_id:[null],
			attorney_id:[null],
			primary_contact_ogc:[null],
			primary_contact_ogc_id:[null]
		});

		let _session = this.activatedRoute.snapshot.data.appealsList.session;

		this.currentUserRoles = _session.roles;

		this.canAssign = (
			this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug)>-1 ||
			this.currentUserRoles.indexOf(this.authConfig.roles.OHA_ADMIN.slug)>-1 ||
			this.currentUserRoles.indexOf(this.authConfig.roles.OHA.slug)>-1
		);

		if(this.currentUserRoles.indexOf(this.authConfig.roles.OGC.slug)>-1 || 
		(this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug)>-1)){
			this.petitionToolTip = "File Petition for Reconsideration"
		}else{
			this.petitionToolTip = "Submit a  Reconsidered Decision";
		}

		this.ohaExtenalCanAssign = (
			this.currentUserRoles.indexOf(this.authConfig.roles.OHA.slug)>-1
		)
		
		// if OHA/OGC
        if( _session.roles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1 || 
			_session.roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1){
            this.showTabbedTables = true;
			this.showSingleTable = false;
			this.showSingleAssignTable = false;
		}else if( _session.roles.indexOf(this.authConfig.roles.OHA.slug) > -1){
			this.showTabbedTables = false;
			this.showSingleTable = false;
			this.showSingleAssignTable = true;			
		}else{
			this.showTabbedTables = false;
			this.showSingleTable = true;
			this.showSingleAssignTable = false;
		}

		this.ngrxstore.select(getPageState()).pipe(take(1)).subscribe((initalPageState)=>{

			if(this.appealsData[0] === 'empty'){
				this.activeTable = 'unassigned';
			}
			
			this.activeTable = (
				initalPageState.current_tab === 'all' ||
				initalPageState.current_tab === 'assigned' ||
				initalPageState.current_tab === 'unassigned'
				) ? initalPageState.current_tab : this.activeTable;
				
			this.activeFilter = 'unassigned';
			this.handleColumns(this.activeTable);
			this.handleRows();
		})


		this.storeZombieByKey('search', _searchFilterFormSub);
		this.isDayLightSavingsOn = this.isDST(new Date());

	}

	isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }

	noopComparator(){
		// this is a workaround for a bug in ngx-datatable where externalSorting flag working unexpected
		return 0;
	}

	refreshViewRefDictionary(){
		this.viewRefDict = {
			assigned:this.assignedTable,
			unassigned:this.unassignedTable,
			all:this.table
		}

		if(this.viewRefDict[this.activeTable]){
			// this.ngrxstore.dispatch( new FreezeView() );
			this.dataTables.changes.pipe(take(1)).subscribe((view)=>{
				if(this.expandedRows[0]){
					this.viewRefDict[this.activeTable].rowDetail.collapseAllRows();
					
					let _rowsOnThisTable = this.viewRefDict[this.activeTable].rows.filter((rowData)=>{
						return this.expandedRows[0].reference_id === rowData.data.reference_id
					});

					_rowsOnThisTable.map((row)=>{
						this.expandThisRow(this.expandedRows[0].reference_id);
					})

					this.changeDetector.detectChanges();
				}
				// this.ngrxstore.dispatch( new ThawView() );
			});
		}
			
	}

	ngAfterViewInit(){
		this.refreshViewRefDictionary();
		this.viewRefDictInitialized = true;
	}

	showList(listName:'assigned'|'unassigned'|'all'){
		this.ngrxstore.dispatch( new CurrentFilterValues({
			term:'',
			appealStatus: '',
	 		type:[]
		}));
		this.activeTable = listName;
		this.activeFilter = listName;
		this.handleColumns(listName);
		this.reloadFilter();
		this.reloadTableData();
		this.ngrxstore.dispatch( new SetCurrentPageTab(this.activeTable) );
	}

	reloadFilter(){
		this.ngrxstore.select(selectAppealApplicationsFilters()).subscribe((response)=>{
			this.filterValues[this.activeTable] = response;
		});
		if(this.activeFilter && this.filterValues[this.activeTable]){
			this.searchForm.patchValue({
				type:[this.filterValues[this.activeFilter].type],
				term:[this.filterValues[this.activeFilter].term],
				appealStatus:[this.filterValues[this.activeFilter].appealStatus]
			});
		}
	}

	formatLoanAmount(_payload){
		_payload.map((i) => {
			i.data = Object.assign({},i.data, {
				loan_amount_in_cents : i.data.loan_amount_in_cents/100
			})
		})
		
		return _payload;
	}
	
	handleRows(){
		this.rows.total = this.appealsData.totalCount;
		this.rows.data = this.appealsData.rows ?  this.formatLoanAmount(this.appealsData.rows) : this.appealsData.rows;
		if(this.appealsData.rows){
			this.rows.data.map((item) => {
				item.has_user_filed_reconsideration = this.validateReconFile(item.data);
				item.isPFRCreated = this.validateIsPFR(item.data);
				item.isReconsidered = this.validateReconsidered(item.data);
				item.isPFRonRecon = this.validatePFRonRecon(item.data);
				item.isValidForPFRonRecon = this.isValidForPFRonRecon(item.data);
				item.has_user_filed_pfr_appeal = item.data.has_user_filed_pfr_appeal['primary_contact_ogc'];
				item.has_user_filed_pfr_on_reconsidered_appeal = item.data.has_user_filed_pfr_on_reconsidered_appeal['primary_contact_ogc'];
			})
		}
		

		this.assignedRows.total = this.assignedAppealsData.totalCount;
		this.assignedRows.data = this.assignedAppealsData.rows ? this.formatLoanAmount(this.assignedAppealsData.rows): this.assignedAppealsData.rows;
		if(this.assignedAppealsData.rows){
			this.assignedRows.data.map((item) => {
				item.has_user_filed_reconsideration = this.validateReconFile(item.data);
				item.isPFRCreated = this.validateIsPFR(item.data);
				item.isReconsidered = this.validateReconsidered(item.data);
				item.isPFRonRecon = this.validatePFRonRecon(item.data);
				item.isValidForPFRonRecon = this.isValidForPFRonRecon(item.data);
				item.has_user_filed_pfr_appeal = item.data.has_user_filed_pfr_appeal['primary_contact_ogc'];
				item.has_user_filed_pfr_on_reconsidered_appeal = item.data.has_user_filed_pfr_on_reconsidered_appeal['primary_contact_ogc'];
			})
		}
		
		this.unassignedRows.total = this.unassignedAppealsData.totalCount;
		this.unassignedRows.data = this.unassignedAppealsData.rows ? this.formatLoanAmount(this.unassignedAppealsData.rows) : this.unassignedAppealsData.rows;
		if(this.unassignedAppealsData.rows){
			this.unassignedRows.data.map((item) => {
				item.has_user_filed_reconsideration = this.validateReconFile(item.data);
				item.isPFRCreated = this.validateIsPFR(item.data);
				item.isReconsidered = this.validateReconsidered(item.data);
				item.isPFRonRecon = this.validatePFRonRecon(item.data);
				item.isValidForPFRonRecon = this.isValidForPFRonRecon(item.data);
				item.has_user_filed_pfr_appeal = item.data.has_user_filed_pfr_appeal['primary_contact_ogc'];
				item.has_user_filed_pfr_on_reconsidered_appeal = item.data.has_user_filed_pfr_on_reconsidered_appeal['primary_contact_ogc'];
			})
		}
		
		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.rows = Object.assign({},this.rows);
		this.assignedRows = Object.assign({},this.assignedRows);
		this.unassignedRows = Object.assign({},this.unassignedRows);
		if(this.viewRefDictInitialized){
			this.refreshViewRefDictionary();
		}	 
	}

	handleColumns(table:string = null){

		this.columns.display = [];

		switch(table){
			case 'assigned':
				this.columns.raw = this.assignedAppealsData.columns
			break;
			case 'unassigned':
				this.columns.raw = this.unassignedAppealsData.columns
			break;
			case 'all':
				this.columns.raw = this.appealsData.columns
			break;
			default:
				this.columns.raw = this.appealsData.columns
			break;
		}

		if(this.canAssign){
			if(!(this.columns.raw.some((i)=> i.prop === 'emptyColumn'))){
				this.columns.raw.push(this.emptyColumn);
			}
		}

		this.columns.raw.map((value)=>{
			if(value.prop !== 'id'){
				if(value.prop === 'appellant_name'){
					value.flexGrow = 2;
				}else{
					value.flexGrow = 1;
				}
				this.columns.display.push(value);
			}
		});



		if(table === 'unassigned' && this.windowState === 'isMobile'){
			this.columns.display = this.columns.display.filter((value)=>{
				return this.columnVisibilityUnassigned[this.windowState].indexOf(value.name) > -1;
			});
		}else{
			this.columns.display = this.columns.display.filter((value)=>{
				return this.columnVisibility[this.windowState].indexOf(value.name) > -1;
			});
		}
		

		this.defaultSorts = [{prop: this.columns.raw[0].prop, dir: 'asc'}];

		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.columns = Object.assign({},this.columns);

	}

	updateAppealsPerPage($event){
		this.appealsPerPage = $event.target.value;
		this.ngrxstore.dispatch(new ListPageLimit(this.appealsPerPage));
		let payload = this.createPayloadForTable(this.activeTable);
		this.updateRows({
			offset:this[payload.offset]
		},'appealsPerPage',this.activeTable);
	}

	updateRows(pageInfo:{offset:number},type:string,table:string = null) {
		this.refresh('appealsTable');
		this.ngrxstore.dispatch( new FreezeNavigation() );
		let _offsetValue = 0;

		let payload = this.createPayloadForTable(table);

		let __dataCollection = payload.collection;
		let __offset = payload.offset;
		let _filters = payload.filters;

		let _appealStat= this.searchForm.value.appealStatus || "";
		let _appealType = this.searchForm.value.type || "";

		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.appealsPerPage;
		}

		this.appealApplicationService.getAppealsList('active'
			, _offsetValue, this.appealsPerPage,this.currentOrderedBy, _filters, this.currentUserRoles[0], _appealStat,_appealType).pipe(take(1)).subscribe((response)=>{
				if(
					(response.rows.length < 1 && this[__offset] > 0)
				){
					this[__offset] = 0;
					this.appealApplicationService.getAppealsList('active'
						, 0, this.appealsPerPage,this.currentOrderedBy, _filters, this.currentUserRoles[0], _appealStat,_appealType).pipe(take(1)).subscribe((newResponse)=>{
						this[__dataCollection] = newResponse;
						this.handleRows();
						this.ngrxstore.dispatch( new ThawNavigation() );
						this.completeRefresh('appealsTable');		
					});
				}else{
					this[__dataCollection] = response;
					//this[__offset] = pageInfo.offset;
					this[__offset] = 0;
					this.handleRows();
					this.ngrxstore.dispatch( new ThawNavigation() );
					this.completeRefresh('appealsTable');
				}
		})
		
		
	}

	queryAppeals(offset?:number){
		let _appealStat= this.searchForm.value.appealStatus || "";
		let _appealType = this.searchForm.value.type || "";

		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');

		if(this.activeTable){
			this.filterValues[this.activeTable] = this.searchForm.value;
		}

		this.ngrxstore.dispatch( new CurrentFilterValues(this.filterValues[this.activeTable]));

		let payload = this.createPayloadForTable(this.activeTable);

		
		let __dataCollection = payload.collection;
		let __offset = payload.offset;
		let _filters = payload.filters;

		// console.log(payload);
		// console.log(this[__offset]);

		this.appealApplicationService.getAppealsList('active'
			, this[__offset], this.appealsPerPage,this.currentOrderedBy,_filters, this.currentUserRoles[0],_appealStat,_appealType).pipe(take(1)).subscribe((response)=>{
				// console.log(response.rows.length,this.appealsOffset)

				// If we are on a page that no longer exhists based on filters, return to first page.
				if(
					(response.rows.length < 1 && this[__offset] > 0)
				){
					this.appealsOffset = 0;
					this.appealApplicationService.getAppealsList('active',0,this.appealsPerPage,this.currentOrderedBy,_filters,this.currentUserRoles[0],_appealStat,_appealType).pipe(take(1)).subscribe((newResponse)=>{
						this[__dataCollection] = newResponse;
						this[__offset] = 0;
						// this.handleColumns(this.activeTable)
						this.handleRows()
						this.completeRefresh('appealsTable');
						this.ngrxstore.dispatch( new ThawNavigation() );
					})

				// Else stay on same page
				}else{
					this[__dataCollection] = response;
					// this.handleColumns(this.activeTable)
					this.handleRows()
					this.completeRefresh('appealsTable');
					this.ngrxstore.dispatch( new ThawNavigation() );
				}
				// console.log("done")
		})
	}

	goToTicket(id:string){
		this.navigationService.navigate([
			this.Routes['APPEALS_TICKET'],
			id
		]);
	}

	resetFilter(){
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');
		this.searchForm.patchValue({
			type:[''],
			term:[''],
			appealStatus:['']
		});

		this.appealApplicationService.getAppealsList('active'
			, 0, this.appealsPerPage,this.currentOrderedBy, {
				search:""
			}, this.currentUserRoles[0], "").pipe(take(1)).subscribe((response)=>{
				this.appealsData = response;
				// this.handleColumns(this.activeTable)
				this.handleRows()
				this.completeRefresh('appealsTable');
				this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}

	onSort($event) {
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');
		let _orderBy = {};
		let _appealsCount = null;

		let payload = this.createPayloadForTable(this.activeTable);

		let __dataCollection = payload.collection;
		let __offset = payload.offset;
		let _filters = payload.filters;

		_appealsCount = this.appealsPerPage;
		let _columnDict = {};

		if(
			this.currentUserRoles[0] === this.authConfig.roles.OHA_ADMIN.slug
		){
			_columnDict = APPEAL_LIST_COLUMNS_SORTING.oha
		}else if(
			this.currentUserRoles[0] === this.authConfig.roles.OGC_ADMIN.slug || 
			this.currentUserRoles[0] === this.authConfig.roles.OGC.slug
		){
			_columnDict = APPEAL_LIST_COLUMNS_SORTING.ogc
		}else{
			_columnDict = APPEAL_LIST_COLUMNS_SORTING.oha
		}
		

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === $event.sorts[0].prop
		});

		console.log($event,_serverColumn,_columnDict);

		if($event.sorts.length > 0){
			switch($event.sorts[0].dir){
				case 'asc': _orderBy = {ordering: _columnDict[_serverColumn[0]]};
				break;
				case 'desc': _orderBy = {ordering:"-" + _columnDict[_serverColumn[0]]};
				break;
				default:
				break;					
			}
		}
		let _appealStat= this.searchForm.value.appealStatus || "";
		let _appealType = this.searchForm.value.type || ""; 
		
		if(_appealsCount){
			this.appealApplicationService.getAppealsList('active'
			,this[__offset], _appealsCount,_orderBy,_filters,this.currentUserRoles[0] ,_appealStat, _appealType).pipe(take(1)).subscribe((response)=>{
					this.currentOrderedBy = _orderBy;
					this[__dataCollection] = response;
					// this.handleColumns(this.activeTable);
					this.handleRows();
					this.completeRefresh('appealsTable');
					this.ngrxstore.dispatch( new ThawNavigation() );
			})
		}
	}

	expandThisRow(reference_id){
		let _index = null;
		let _thisRow = null;
		this.viewRefDict[this.activeTable].rows.map((thisrow,i)=>{
			if(thisrow.data.reference_id === reference_id){
				_thisRow = thisrow;
				_index = i;
			};
		});
		this.expandedRows = [{
			index:_index,
			reference_id:_thisRow.data.reference_id
		}];
		this.viewRefDict[this.activeTable].rowDetail.collapseAllRows();
		this.viewRefDict[this.activeTable].rowDetail.toggleExpandRow(_thisRow);
	}

	collapseAllRows(){
		this.expandedRowIndex = null;
		this.expandedRows = [];
		this.viewRefDict[this.activeTable].rowDetail.collapseAllRows();
	}

	showAssignRowDetailForOHA(index,expanded?:boolean){ 
		let row = this.viewRefDict[this.activeTable].rows[index];
		if(!expanded){
			this.invalidAssigneeChoices = Object.assign({},{});
			if(!this.ohaExtenalCanAssign){
				if(row.data.judge){
					let _tempJudge = {
						reference_id:row.data.judge['reference_id'],
						appealCount: row.data.judge["appeals_assigned"],
						maxAppeals: row.data.judge["appeals_assignment_max"],
						value: row.data.judge["username"],
						label:row.data.judge["name"] + " ( Assigned Appeals: " + row.data.judge["appeals_assigned"] + " Remaining Appeals: " + (row.data.judge["appeals_assignment_max"] - row.data.judge["appeals_assigned"])  + ")"}
					this.assigneeForm.patchValue({
						judgeLabel: _tempJudge.label,
						judge_id:row.data.judge.reference_id,
					})

					this.judgeAppealsAssigned= "Assigned Appeals: "+_tempJudge['appealCount'];
					this.judgeMaxAppeals=" Max Appeals: "+ _tempJudge['maxAppeals'];

				}else{
					this.assigneeForm.patchValue({
						judgeLabel:null,
						judge_id:null,
					})
					this.judgeAppealsAssigned= null;
					this.judgeMaxAppeals=null;
				}
			}
			if(row.data.attorney){
				let _tempAttroney = {
					reference_id:row.data.attorney['reference_id'],
					appealCount: row.data.attorney["appeals_assigned"],
					maxAppeals: row.data.attorney["appeals_assignment_max"],
					value: row.data.attorney["username"],
					label:row.data.attorney["name"] + " ( Assigned Appeals: " + row.data.attorney["appeals_assigned"] + " Remaining Appeals: " + (row.data.attorney["appeals_assignment_max"] - row.data.attorney["appeals_assigned"])  + ")"}
				this.assigneeForm.patchValue({
					attorneyLabel:_tempAttroney.label,
					attorney_id:row.data.attorney.reference_id
				})

				this.attorneyAppealsAssigned= "Assigned Appeals: "+ _tempAttroney["appealCount"];
				this.attorneyMaxAppeals=" Max Appeals: "+ _tempAttroney["maxAppeals"];
			}else{
				this.assigneeForm.patchValue({
					attorneyLabel:null,
					attorney_id:null
				})

				this.attorneyAppealsAssigned= null;
				this.attorneyMaxAppeals=null;
			}
			
			this.expandThisRow(row.data.reference_id);
		}else{
			this.collapseAllRows()
		}
	}

	onToggleOfRow($event){
		console.log($event);
	}

	showAssignRowDetailForOGC(index,expanded?:boolean){ 
		let row = this.viewRefDict[this.activeTable].rows[index];
		if(!expanded){
			this.invalidAssigneeChoices = Object.assign({},{});
			if(row.data.primary_contact_ogc){
				let _ogcDef = {
					reference_id:row.data.primary_contact_ogc['reference_id'],
					appealCount: row.data.primary_contact_ogc["appeals_assigned"],
					maxAppeals: row.data.primary_contact_ogc["appeals_assignment_max"],
					value: row.data.primary_contact_ogc["username"],
					label:row.data.primary_contact_ogc["name"] + " ( Assigned Appeals: " + row.data.primary_contact_ogc["appeals_assigned"] + " Remaining Appeals: " + (row.data.primary_contact_ogc["appeals_assignment_max"] - row.data.primary_contact_ogc["appeals_assigned"])  + ")"}
				let _ogcId = row.data.primary_contact_ogc.reference_id;
				
				if(_ogcDef && _ogcId){
					this.assigneeForm.patchValue({
						primary_contact_ogc: _ogcDef.label,
						primary_contact_ogc_id: _ogcId,
					});
				}else{
					this.assigneeForm.patchValue({
						primary_contact_ogc: null,
						primary_contact_ogc_id: null,
					});
				}
			}else{
				this.assigneeForm.patchValue({
					primary_contact_ogc:null,
					primary_contact_ogc_id:null,
				});
			}
			
			this.expandThisRow(row.data.reference_id);
		}else{
			this.collapseAllRows();
		}

	}

	collapseAssignRowDetail(){
		this.table.rowDetail.collapseAllRows();
	}
	
	assignUser(row,formControlNames:string[]){
		let _assigned = 0;
		formControlNames.map((formControlName)=>{
			if(this.assigneeForm.controls[formControlName].value){
				this.refresh('appealsTable');
				this.ngrxstore.dispatch( new AssignUserToTicket({
					type:formControlName,
					user:this.assigneeForm.controls[formControlName].value,
					appealId:row.data.reference_id,
					callback:()=>{
						_assigned += 1;
						if(_assigned === formControlNames.length){
							this.reloadTableData();
						}

						let successMsg = "";
						if(formControlName === 'judge_id'){
							successMsg = "Judge Assigned Succesfully."
						}else{
							successMsg = "Attorney Assigned Succesfully."
						}
						this.ngrxstore.dispatch( new TriggerToast({
							message:successMsg,
							style:'success',
							ttl:4
						  }));

						  this.collapseAllRows();
					}
				}));
			}
		})
	}

	unAssignUser(row,formControlNames:string[]){
		let _assigned = 0;
		formControlNames.map((formControlName)=>{
			if(this.assigneeForm.controls[formControlName].value){
				this.refresh('appealsTable');
				this.ngrxstore.dispatch( new UnAssignUserToTicket({
					type:formControlName,
					user:this.assigneeForm.controls[formControlName].value,
					appealId:row.data.reference_id,
					callback:()=>{
						_assigned += 1;
						if(_assigned === formControlNames.length){
							this.reloadTableData();
						}
					}
				}));
			}
		});
	}

	assignOGCPointOfContact(row,formControlName:string){
		// this.previousClickedRow= row;
		
		let _payload = Object.assign({},{
				primary_contact_ogc:this.assigneeForm.controls[formControlName].value
		});

		let request_type = 'patch';
		if(this.assigneeForm.controls[formControlName].value){
			this.refresh('appealsTable');
			this.ngrxstore.dispatch( new SaveAppealTicket(
				_payload,
				row.data.reference_id,
				(response)=>{
					if(response['primary_contact_ogc']){
						this.ngrxstore.dispatch( new TriggerToast({
							message:"Succesfully Assigned OGC user "+response['primary_contact_ogc'].username,
							style:'success',
							ttl:4
						}));
						this.reloadTableData();
						this.collapseAllRows();
					}else{
						this.ngrxstore.dispatch( new TriggerModal({
							content:{
								title:"An Error Occured",
								message:"Something unexpected happened, please reach out the the adminstrator"
							},
							onCancel:()=>{
								this.reloadTableData();
							}
						}) )
					}
				},
				request_type),
				
			);
		}
	}

	createPayloadForTable(table:string){

		let __dataCollection = 'appealsData';
		let __offset = 'appealsOffset';
		let _filters = {};
		
		switch(table){
			case 'assigned':
				__dataCollection = 'assignedAppealsData';
				__offset = 'assignedAppealsOffset';

				if(this.currentUserRoles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1){
					_filters = {
						assigned:true
					}
				}

				if(
					this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1 ||
					this.currentUserRoles.indexOf(this.authConfig.roles.OGC.slug) > -1
				){
					_filters = {
						missing_ogc_poc:false
					}
				}
				
			break;
			case 'unassigned':
				__dataCollection = 'unassignedAppealsData';
				__offset = 'unassignedAppealsOffset';
				
				if(this.currentUserRoles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1){
					_filters = {
						assigned:false
					}
				}

				if(
					this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1 ||
					this.currentUserRoles.indexOf(this.authConfig.roles.OGC.slug) > -1
				){
					_filters = {
						missing_ogc_poc:true
					}
				}
				
			break;
			case 'all':
				if(this.currentUserRoles.indexOf(this.authConfig.roles.OHA.slug) > -1){
					_filters = {
						assigned:true
					}
				}else{
					__dataCollection = 'appealsData';
					__offset = 'appealsOffset';
				}
			
			break;
			default:
				if(this.currentUserRoles.indexOf(this.authConfig.roles.OHA.slug) > -1){
					_filters = {
						assigned:true
					}
				}else{
					__dataCollection = 'appealsData';
					__offset = 'appealsOffset';
				}
			break;
		}

		let _searchValue = "";
		if( this.activeTable && this.filterValues[this.activeTable] ){
			_searchValue = this.filterValues[this.activeTable].term;
		}

		_filters = Object.assign({},{
			search:_searchValue
		},_filters);

		return {
			collection:__dataCollection,
			offset:__offset,
			filters:_filters,
			table:table
		}
	}

	
	  reloadTableData(){
		
		this.refresh('appealsTable');

		let payload = this.createPayloadForTable(this.activeTable);

		let __dataCollection = payload.collection;
		let __offset = payload.offset;
		let _filters = payload.filters;
		
		let _orderBy = {ordering:'filing_date'};
		let _appealsCount = null;
		let _appealsOffset = null;
		_appealsCount = this.appealsPerPage;
		_appealsOffset = this[__offset];
		let _appealStat= this.searchForm.value.appealStatus || "";
		let _appealType= this.searchForm.value.type || "";

		this.appealApplicationService.getAppealsList('active'
		,_appealsOffset, _appealsCount,_orderBy,_filters,this.currentUserRoles[0] , _appealStat, _appealType).pipe(take(1)).subscribe((response)=>{
			this[__dataCollection] = response;
			// this.handleColumns(this.activeTable)
			this.handleRows()
			if(!this.authConfig.roles.OGC.slug && this.authConfig.roles.OGC_ADMIN.slug){
				//this.updateJudgesandAttorney();
			}
			this.completeRefresh('appealsTable');		
		})
	  }

	matchJudge = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketService.getAssigneeList(searchText,"Judge") 
        )              
    );

	matchAttorney = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketService.getAssigneeList(searchText,"Attorney") 
        )              
    );

	matchOGC= (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketService.getAssigneeList(searchText,"OGC") 
        )              
    );

	resultFormatAssignedValue(value: any) {            
        return value.first_name + " " + value.last_name + " ( Assigned Appeals: " + value["appeals_assigned"] + " Remaining Appeals: " + (value["appeals_assignment_max"] - value["appeals_assigned"])  + ")";
    }

	resultFormatAssignedValueOGC(value: any) {            
        return value.first_name + " " + value.last_name ;
    }

	inputFormatAssignedValue(value: any)   {
        if(typeof value === 'string'){
            if(value)
                return value;
        }else if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.first_name + " " + value.last_name + " ( Assigned Appeals: " + value["appeals_assigned"] + " Remaining Appeals: " + (value["appeals_assignment_max"] - value["appeals_assigned"])  + ")";
            if(_matchedItem)
                return _matchedItem;
        }
    }

	inputFormatAssignedValueOGC(value: any) {
        if(typeof value === 'string'){
            if(value)
                return value;
        }else if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.first_name + " " + value.last_name ;
            if(_matchedItem)
                return _matchedItem;
        }
    }

	assigneeLabelChange(event:any, group:string){
        let _matchedItem = null;

        switch(group){
            case 'Judge':
                _matchedItem = event.item.first_name + " " + event.item.last_name + " ( Assigned Appeals: " + event.item["appeals_assigned"] + " Remaining Appeals: " + (event.item["appeals_assignment_max"] - event.item["appeals_assigned"])  + ")";
                this.assigneeForm.patchValue({
					judgeLabel:_matchedItem,
					judge_id:event.item.reference_id
				})
				this.judgeAppealsAssigned= "Assigned Appeals: "+ event.item["appeals_assigned"];
				this.judgeMaxAppeals=" Max Appeals: "+ event.item["appeals_assignment_max"];
                break;
			case 'Attorney':
				_matchedItem = event.item.first_name + " " + event.item.last_name + " ( Assigned Appeals: " + event.item["appeals_assigned"] + " Remaining Appeals: " + (event.item["appeals_assignment_max"] - event.item["appeals_assigned"])  + ")";
				this.assigneeForm.patchValue({
					attorneyLabel:_matchedItem,
					attorney_id:event.item.reference_id
				})
				this.attorneyAppealsAssigned= "Assigned Appeals: "+ event.item["appeals_assigned"];
				this.attorneyMaxAppeals=" Max Appeals: "+ event.item["appeals_assignment_max"];
				break;
			case 'OGC':
				_matchedItem = event.item.first_name + " " + event.item.last_name ;
				this.assigneeForm.patchValue({
					primary_contact_ogc:_matchedItem,
					primary_contact_ogc_id:event.item.reference_id
				});
				break;
        }
        
        
    }

	openPetitionModel(appealDetails,type){
		if((this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug)>-1) ||
		(this.currentUserRoles.indexOf(this.authConfig.roles.OGC.slug)>-1)){
			this.ngrxstore.dispatch(new TriggerModal({
				type:'petition-input',
				content: {
					title: "File Petition for Reconsideration",
					message: "OHA cannot modify the deadline to file a petition for reconsideration. 13 CFR § 134.1202(c)(3)(i)(A).",
					checkboxItems:[
						{
							label:"Upload a document (pleading) that explains a clear error of fact or law in the initial decision"
						}
					],
					is_ogc: true,
				},
				onCancel:()=>{
					this.ngrxstore.dispatch(new FilePetition({file:null , signature:''}))
				},
				onApprove:()=>{
					this.ngrxstore.select(selectFilePetitionDetails()).pipe(take(1)).subscribe((response)=>{
						let _uploaded_file = response['uploaded_file'];
						let _digital_signature = response['signature'];
						this.refresh('appealsTable');
						this.ngrxstore.dispatch( new CreateReconsiderationAppeal({
							type:type,
							parent_ticket:appealDetails.reference_id,
							signature_for_electronic_filing:_digital_signature,
							lender:appealDetails.lender.id,
							title: appealDetails.title,
							action: {
								"slug": "Draft",
								"display_name": "Draft"
							},
							submitter:this.currentUser.reference_id,
							callback:(response)=>{
								if(_uploaded_file != null) {
									this.uploadPetitionDocument(response,_uploaded_file);
								}else{
									this.reloadTableData();
									this.goToReconTicket(response.reference_id); 
									this.completeRefresh('appealsTable');	
								}
							}
						})
						)
					});
				}
			}));
		}else {
			let title = '';
			if((this.currentUserRoles.indexOf(this.authConfig.roles.OGC_ADMIN.slug)>-1) ||
				(this.currentUserRoles.indexOf(this.authConfig.roles.OGC.slug)>-1)){
					if(type === 'pfr-on-reconsidered'){
						title = 'Are you sure you want to file a Petition for Reconsideration on Reconsidered Decision?'
					}else {
						title = 'Are you sure you want to file a Petition for Reconsideration?'
					}
				}else {
					title = 'Are you sure you want to submit a Reconsidered Decision?';
				}
			this.ngrxstore.dispatch(new TriggerModal({
				content:{
				  title:title,
				  approveLabel: "Confirm",
				  cancelLabel: "Cancel"
				},
				onCancel:()=>{},
				onApprove:()=>{
					this.refresh('appealsTable');
					this.ngrxstore.dispatch( new CreateReconsiderationAppeal({
						type:type,
						parent_ticket:appealDetails.reference_id,
						lender:appealDetails.lender.id,
						title: appealDetails.title,
						action: {
                            "slug": "Draft",
                            "display_name": "Draft"
                        },
						submitter:this.currentUser.reference_id,
						callback:(response)=>{
							if(!response.hasOwnProperty('error')) {
								this.reloadTableData();
								this.goToReconTicket(response.reference_id);
								this.completeRefresh('appealsTable');	
							}
						}
					}))
				}
			  }))
		}
	}

	goToReconTicket(id:string){
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`${this.Routes['APPEALS_TICKET']}/${id}`])
		);
		window.open(url, '_blank', '');
	}

	validateReconFile(appealData){
        let _session = this.activatedRoute.snapshot.data.appealsList.session;
        let has_user_filed_reconsideration = false;
        if(( _session.roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1) ){
            has_user_filed_reconsideration= false;
        }else{
            let _roleDict = {
                "judge":"oha_team",
                "ogc":"primary_contact_ogc",
                "attorney":'oha_team',
            }
            let _rolefilter = Object.keys(appealData.has_user_filed_reconsideration_appeal).filter(
                item =>  item == _roleDict[_session.roles[0]] 
            )[0];
            has_user_filed_reconsideration = appealData.has_user_filed_reconsideration_appeal[_rolefilter];
        }
		return has_user_filed_reconsideration;
        
    }

	validateIsPFR(appealData){
		if(appealData.child_tickets.length > 0){
			let isPFR = appealData.child_tickets.filter((item) => item.type === 'pfr');
	 		return isPFR.length > 0;
		}
		return false;
	}

	validateReconsidered(appealData){
		if(appealData.child_tickets.length > 0){
			let isReconsidered = appealData.child_tickets.filter((item) => item.type === 'reconsideration');
			return isReconsidered.length > 0;
		}
		return false;
	}

	validatePFRonRecon(appealData){
		if(appealData.child_tickets.length > 0){
			let isPFRonRecon = appealData.child_tickets.filter((item) => item.type === 'pfr-on-reconsidered');
			return isPFRonRecon.length > 0;
		}
		return false;
	}

	isValidForPFRonRecon(appealData){
		if(appealData.submitter != null){
			let isValid =  [this.authConfig.roles.OHA_ADMIN.slug,
				this.authConfig.roles.OHA.slug,
				this.authConfig.roles.JUDGE.slug,
				this.authConfig.roles.ATTORNEY.slug].includes(appealData.submitter.groups[0].name.toLowerCase());
			return isValid;
		}
		return false;
	}


	uploadPetitionDocument(reconDetail,fileItem){
        this.appealApplicationService.uploadPetitionDocument(
            reconDetail.reference_id,fileItem
        ).pipe(take(1)).subscribe((res1)=>{
			this.appealApplicationService.getUploadedDocuments(
				reconDetail.reference_id
			).subscribe((res2) => {
				let _document = [];
				_document.push(res2[0]["documents"][0].reference_id) //to be updated if backend updates response
				// _document.push(res1["documents"][0].reference_id)
				this.appealApplicationService.attachDocumentsWithoutCOS(
					reconDetail.reference_id,
					{"documents": _document},
				).pipe(take(1)).subscribe((response)=>{
					this.reloadTableData();
					this.goToReconTicket(reconDetail.reference_id); 
					this.completeRefresh('appealsTable');	
				},((err) =>{
					if(err){
						this.ngrxstore.dispatch(new TriggerToast({
							message:err.error['detail'],
							style:'danger'
						}));
					}
					this.completeRefresh('appealsTable');	
				}))

			})
        },(err)=>{
            if(err instanceof HttpErrorResponse){
                 this.ngrxstore.dispatch(new TriggerModal({
                     content:{
                         title:"Unable to Upload",
                         message:err.error.file[0]
                     }
                 }));
            }
			this.completeRefresh('appealsTable');	
        });
    }
  
}