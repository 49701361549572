// Google and Third Party ----------------------------------------------------------------------------------
// =========================================================================================================

// Gesture dependencies
import 'hammerjs';
// Angular Components
import {APP_BASE_HREF} from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
// Third-Party Components
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import {A11yModule} from '@angular/cdk/a11y';


// RightBrain Angular Platform -----------------------------------------------------------------------------
// =========================================================================================================

// Platform Modules
import { AuthModule, AUTH_CONFIG, LogoutAction } from './platform/modules/auth';
import { NotificationModule } from './platform/modules/notification';
import { NavigationModule, NAVIGATION_CONFIG } from './platform/modules/navigation';

// Platform Store
import { getRoles } from './platform/modules/auth';

// App Constants
// -------------------------
import { ROUTE_PATHS, FULL_ROUTES, ROUTES_WITH_WIZARD_BAR } from './constants/routes.constants';
import { ROUTE_PERMISSIONS, ROLE_HAS_SIDE_NAV, EXTERNAL_LINK_PERMISSIONS, USER_ROLES, USER_HOMES, USER_ORIGINS, APPEAL_STATUS } from './constants/permissions.constants';
import { API_ENDPOINTS, BASE_URL } from './constants/rest_api.constants';

// App Store
// -------------------------
import { reducers, metaReducers } from './store/localstorage.configuration';
import { AuthEffects } from './store/effects/auth.effects';
import { ShellEffects } from './store/effects/shell.effects';

// App Components
// -------------------------
import { AppComponent } from './app.component';

// App Sections
import { AppealsApplicationsModule } from './features/appeals-applications/appeals-applications.module';

// App Services
// -------------------------
import { AppService } from './app.service';
import { SentryErrorHandler } from './error-sentry.service'

// App Navigation
// -------------------------
import { routing, appRoutingProviders } from './app.routes';

import { SharedModule } from './platform/modules/shared/shared.module';

import { ShellModule } from './shell/shell.module';
import { PPPAppealWizardModule } from './features/appeal-wizard/appeal-wizard.module';
import { AccountModule } from './features/account/account.module';

import { API_CONFIG, APP_DEFAULT_CONFIG } from './app.interface';
import { TicketsModule } from './features/tickets/tickets.module';
import { ResourcesModule } from './features/resources/resources.module';
import { YourAppealModule } from './features/your-appeal/your-appeal.module';
import { PortalModule } from './features/portal/portal.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AppealWizardEffects } from './features/appeal-wizard/store/appeal-wizard.effects';
import { AppealsApplicationsEffects } from './features/appeals-applications/store/appeals-applications.effects';
import { TicketEffects } from './features/tickets/store/ticket.effects';
import { APP_DEFAULTS } from './constants/app.constants.ts';
import { APPEAL_ACTION_TYPES, APPELLANT_DOCUMENT_CATEGORIES, DOCUMENT_CATEGORIES } from './constants/app.constants';
import { QRCodeModule } from 'angularx-qrcode';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { HealthModule } from './features/health/health.module';


@NgModule({
	imports: [
		// Global Modules
		CommonModule,
		HttpClientModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		NgSelectModule,
		PerfectScrollbarModule,
		InfiniteScrollModule,
		QRCodeModule,
		
		// Platform Modules
		AuthModule.forRoot(),
		NotificationModule.forRoot(),
		NavigationModule.forRoot(),
		SharedModule.forRoot(),
		ShellModule.forRoot(),
		MomentModule,
    	NgIdleKeepaliveModule.forRoot(),
		
		// // Section Modules
		PortalModule,
		ResourcesModule,
		AppealsApplicationsModule,
		PPPAppealWizardModule,
		AccountModule,
		TicketsModule,
		HealthModule,

		// NGRX Modules
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([
			
			// App Level Effects
			AuthEffects, 
			ShellEffects,

			// Feature Level Effects
			AppealWizardEffects,
			AppealsApplicationsEffects,
			TicketEffects

		]),

		// ACCESIBILITY
		A11yModule,
				
		// Routing
		routing
	],
	declarations: [
		AppComponent,
	],
	providers: [
		BnNgIdleService,
		DatePipe,
		appRoutingProviders,
		AppService,
		{
			provide: APP_BASE_HREF, 
			useValue: '/'
		},
		{
			provide: API_CONFIG,
			useValue:{
				url:BASE_URL,
				endpoints:API_ENDPOINTS
			},
			
		},
		{
		 	provide: APP_DEFAULT_CONFIG,
			 useValue:{
				ui:APP_DEFAULTS,
				appealActions:APPEAL_ACTION_TYPES,
				documentCategories:DOCUMENT_CATEGORIES,
				appellantDocCategory:APPELLANT_DOCUMENT_CATEGORIES
			},
		 },
		// {
		// 	provide: ErrorHandler,
		//  	useClass: SentryErrorHandler,
		// },
		{
			provide: AUTH_CONFIG,
			useValue: {
				branding:{
					logo_svg:'../../../../assets/svg/sba_logo.svg',
					logo_full_150:'../../../../assets/img/SBA-Logo-Horizontal_150.png',
					logo_full_fullsize:'../../../../assets/img/SBA-Logo-Horizontal_300.png',
					organization_name:'Small Business Administration'
				},
				api_url: BASE_URL,
				endpoints:API_ENDPOINTS,
				permissions: ROUTE_PERMISSIONS,
				roles:USER_ROLES,
				status:APPEAL_STATUS,
				auth_origins:USER_ORIGINS,
				auth_redirects:USER_HOMES
			}
		},
		{
			provide: NAVIGATION_CONFIG,
			useValue: {
				branding:{
					logo_svg:'../../../../assets/svg/sba_logo.svg',
					logo_full_150:'../../../../assets/img/SBA-Logo-Horizontal_150.png',
					logo_full_fullsize:'../../../../assets/img/SBA-Logo-Horizontal_300.png',
					logo_square_150:'../../../../assets/img/SBA-Logo-Stacked-32x32.png',
					organization_name:'Small Business Administration'
				},
				paths: ROUTE_PATHS,
				routes: FULL_ROUTES,
				permissions: ROUTE_PERMISSIONS,
				external_link_permissions:EXTERNAL_LINK_PERMISSIONS,
				navigationRoot: FULL_ROUTES['SHELL'],
				sideNavRoles:ROLE_HAS_SIDE_NAV,
				wizardRoutes:ROUTES_WITH_WIZARD_BAR,
				rolesSelector: getRoles
			}
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: {
				suppressScrollX: true
			}
		},
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	],
	bootstrap: [AppComponent] //<-- RUN ON PAGE LOAD
})


export class AppModule { }
