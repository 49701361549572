import { Routes, RouterModule } from '@angular/router';

import { TICKETS_PATHS,TICKETS_ROOT_PATH } from './constants/tickets.constants';
import { ModuleWithProviders } from '@angular/core';
import { ShellComponent } from '../../shell';
import { AppealTicket } from './appeal-ticket/appeal-ticket.component';
import { AuthGuard } from '../../platform/modules/navigation';
import { TicketResolver } from './resolvers/ticket.resolver';
import { ShellResolver } from '../../shell/components/shell/shell.resolver';

export const TICKETS_ROUTES = [
    {
        path:TICKETS_ROOT_PATH,
        component:ShellComponent,
        children:[
            {
                path:TICKETS_PATHS.APPEALS_TICKET+'/:id',
                component:AppealTicket, 
                canActivate:[AuthGuard],
                resolve:{ticket:TicketResolver}
            }
        ]
    }
]

export const ticketsRoutes: Routes = TICKETS_ROUTES;

export const TicketsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(ticketsRoutes);