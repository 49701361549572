export const MOCK_EXPORTS = [
    {
        slug:'cae818ec-8c8b-41d0-a27d-202a38d1ecbc',
        organization:null,
        created_by:null,
        created_at:null,
        updated_at:null,
        processed_at:null,
        is_processed:true
    },
    {
        slug:'cae818ec-8c8b-41d0-a27d-202a38d1ecbc',
        organization:null,
        created_by:null,
        created_at:null,
        updated_at:null,
        processed_at:null,
        is_processed:true
    },
    {
        slug:'cae818ec-8c8b-41d0-a27d-202a38d1ecbc',
        organization:null,
        created_by:null,
        created_at:null,
        updated_at:null,
        processed_at:null,
        is_processed:true
    },
    {
        slug:'cae818ec-8c8b-41d0-a27d-202a38d1ecbc',
        organization:null,
        created_by:null,
        created_at:null,
        updated_at:null,
        processed_at:null,
        is_processed:true
    }
]

export const MOCK_TICKET={
    appeal_type:"Appeal of SBA Loan Review Decision und 13 C.F.R. 134.1201(b)",
    loan_number:'12345678901',
    lender_name:'Bank of America',
    loan_amount:1000000,
    forgiveness_amount:20000,
    poc_name:'Adam Goodnight',
    poc_address:'1334 Mulberry Lane, NY, 12345',
    poc_email:'agoodnight@mpi.com',
    poc_phone_number:'123-456-7890',
    poc_role:'Borrower',
    business_name:'Microcomputers Processors Inc',
    judge:'Judge Reynolds Grey',
    attorney:'Marks Legal',
    appeal_date:'1/2/12',
    sba_decision_date:'1/2/34',
    docket_filings:{
        rows: [
            {
                date: '20/10/2020',
                id: '1',
                text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. `
            },
            {
                date: '20/10/2020',
                id: '2',
                text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. `
            }
        ],
        columns: [
            {
                name: 'Date Filed',
                prop: 'date'
            },
            {
                name: '#',
                prop: 'id'
            },
            {
                name: 'Docket Text',
                prop: 'text'
            }
        ]
    }
}

export const MOCK_OGC={
    results: [
        {
            name: 'test1',
            cc: 'test1@test1.com',
            phone: '(123) 456-789'
        },
        {
            name: 'test2',
            cc: 'test2@test2.com',
            phone: '(456) 123-789'
        }
    ],
}

export const MOCK_ETRAN_LOAN = {
    'etran_notional_amount':10000,
    'bank_notional_amount':11000,
    'sba_number':12345678901,
    'loan_number':123456789,
    'entity_name':"Toad",
    'application_id':123,
    'ein':123456789,
    'funding_date':Date.now(),
    'address1':"1 Redridge Mountains",
    'address2':"2 Trisfal Glades",
    'dba_name':"null",
    'phone_number':"716-807-1270",
    'docusign_name':"Adam",
    'docusign_email':"adam@gmail.com",
    'docusign_title':"President",
    'banker':"Fred",
    'assign_to_user':"Douglas"
}

export const MOCK_MESSAGES=[
    {
        sender:"Doug",
        subject:"ever since the 1500s, when an unknown printer took",
        sent_at:'11/04/2020',
        id:0
    },
    {
        sender:"Patrick",
        subject:"ever since the 1500s, when an unknown printer took",
        sent_at:'11/04/2020',
        id:1
    },
    {
        sender:"Mabel",
        subject:"ever since the 1500s, when an unknown printer took",
        sent_at:'11/04/2020',
        id:2
    }
]

export const MOCK_MESSAGE = 
    {
        thread:1,
        sender:"Doug",
        sent_at:'11/11/2020',
        unread:true,
        content:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada varius dui, at iaculis lacus volutpat a. Mauris eleifend, quam varius varius fringilla, tellus ligula egestas massa, iaculis vehicula arcu neque id lacus. Fusce tempus vulputate urna ac dignissim. Maecenas sed varius justo. Phasellus in dapibus purus, at luctus metus. Donec leo metus.`
    }


export const MOCK_USER_THREAD=
    {
        thread:1,
        user:"Douglas",
        unread:true,
        deleted:false
    }


export const MOCK_MESSAGE_THREAD=
    {
        subject:"Mario 64",
        users:["Douglas","Tanya"],
        organization:"Red Mountain",
        ticket:1,
        message_type:'text',
        is_complete:false,
        completed_at:null,
        sba_complete:false,
        sba_completed_at:null,
        is_deleted:false
    }

export const MOCK_MESSAGE_TYPE={
    name:null,
    allow_document_uploads:true,
    sequence:null,
    is_active:true
}


export const MOCK_TICKET_NOTES = [
    {
        id:'0',
        entries:[
            {
                sent_at:Date.now().toString(),
                read_at:Date.now().toString(),
                replied_at:Date.now().toString(),
                sender:{
                    name:'Dave',
                    email:'dave@sba.gov'
                },
                lastMessage:true,
                subject:'RE: Question About Loan',
                body:`
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada varius dui, at iaculis lacus volutpat a. Mauris eleifend, quam varius varius fringilla, tellus ligula egestas massa, iaculis vehicula arcu neque id lacus. Fusce tempus vulputate urna ac dignissim. Maecenas sed varius justo. Phasellus in dapibus purus, at luctus metus. Donec leo metus.
                    
                    pellentesque id tempus efficitur, sollicitudin blandit justo. Aenean id sem augue. Phasellus mollis luctus congue. Sed non orci quis eros varius bibendum. Suspendisse potenti. Sed id libero lacinia, auctor felis non, rhoncus mi. Nam malesuada lorem sapien, a semper quam pellentesque ut. Pellentesque suscipit varius sapien a ullamcorper. Aliquam placerat lorem nec mauris pharetra, in interdum quam maximus. Etiam varius vel nulla ut lobortis.
                `
            },
            {
                sent_at:Date.now().toString(),
                read_at:Date.now().toString(),
                replied_at:Date.now().toString(),
                sender:{
                    name:'Dave',
                    email:'dave@sba.gov'
                },
                lastMessage:false,
                subject:'Question About Loan',
                body:`
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada varius dui, at iaculis lacus volutpat a. Mauris eleifend, quam varius varius fringilla, tellus ligula egestas massa, iaculis vehicula arcu neque id lacus. Fusce tempus vulputate urna ac dignissim. Maecenas sed varius justo. Phasellus in dapibus purus, at luctus metus. Donec leo metus.
                `
            }
        ]
    }
]

export const MOCK_FORGIVENESS_REQUESTS = {
    "recordsTotal": 2294448,
    "recordsFiltered": 2294448,
    "draw": 1,
    "data": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((value)=>{
        return {
            "slug": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "borrower_name": "Jason",
            "etran_loan": {
                "slug": "df481fbb-c41f-46f3-b96f-94374d747a8d",
                "bank_notional_amount": 900000.00,
                "sba_number": "9999114733",
                "loan_number": "7777777",
                "entity_name": "Abc Inc",
                "ein": "997114733",
                "funding_date": "2020-07-06",
                "forgive_eidl_amount": 100.00,
                "forgive_eidl_application_number": 123456789,
                "forgive_payroll": 1000.00,
                "forgive_rent": 1000.00,
                "forgive_utilities": 1000.00,
                "forgive_mortgage": 1000.00,
                "address1": "5050 Ritter Road – Suite B",
                "address2": "Mechanicsburg, PA",
                "dba_name": "Abc Inc",
                "organization": {
                    "name": "Abc Bank",
                    "etran_location_id": "string",
                    "slug": "e2f282d3-33e4-408e-9f2d-07a837c894e5"
                },
                "phone_number": "6102342123",
                "forgive_fte_at_loan_application": 10,
                "documents": [
                    {
                        "slug": "5ae02dd4-a7f7-4c40-90fc-20cba32e8de0",
                        "name": "Payroll.pdf",
                        "created_at": "2020-07-06T17:50:43.369Z",
                        "updated_at": "2020-07-06T17:50:43.369Z",
                        "document": "https://lenders-co-operative/../Payroll.pdf",
                        "url": "https://lenders-co-operative/../Payroll.pdf",
                        "etran_loan": "df481fbb-c41f-46f3-b96f-94374d747a8d",
                        "document_type": {
                            "id": 1,
                            "name": "Payroll.pdf",
                            "description": "Payroll"
                        }
                    }
                ],
                "status": "Pending Validation",
                "demographics": [
                    {
                        "name": "Jason",
                        "position": "self-employed individual",
                        "veteran_status": "1",
                        "gender": "M",
                        "ethnicity": "H",
                        "races": [
                            {
                                "race": "1"
                            }
                        ]
                    }
                ],
                "forgive_line_6_3508_or_line_5_3508ez": 3999.00,
                "forgive_modified_total": 3999.00,
                "forgive_payroll_cost_60_percent_requirement": 1666.66,
                "forgive_amount": 1666.66,
                "forgive_fte_at_forgiveness_application": 10,
                "forgive_schedule_a_line_1": 1.00,
                "forgive_schedule_a_line_2": 1.00,
                "forgive_schedule_a_line_3_checkbox": true,
                "forgive_schedule_a_line_3": 1.00,
                "forgive_schedule_a_line_4": 1.00,
                "forgive_schedule_a_line_5": 1.00,
                "forgive_schedule_a_line_6": 1.00,
                "forgive_schedule_a_line_7": 1.00,
                "forgive_schedule_a_line_8": 1.00,
                "forgive_schedule_a_line_9": 1.00,
                "forgive_schedule_a_line_10": 6.00,
                "forgive_schedule_a_line_10_checkbox": true,
                "forgive_schedule_a_line_11": 10.00,
                "forgive_schedule_a_line_12": 10.00,
                "forgive_schedule_a_line_13": 1.00,
                "forgive_covered_period_from": "2020-07-06",
                "forgive_covered_period_to": "2020-09-06",
                "forgive_alternate_covered_period_from": "2020-07-06",
                "forgive_alternate_covered_period_to": "2020-09-06",
                "forgive_2_million": false,
                "forgive_payroll_schedule": "Weekly",
                "primary_email": "user@example.com",
                "primary_name": "Jason",
                "ez_form": true,
                "no_reduction_in_employees": true,
                "no_reduction_in_employees_and_covid_impact": true,
                "forgive_lender_confirmation": true,
                "forgive_lender_decision": 1,
                "sba_decision": "Fully Approved",
                "approval_date": "2020-08-20",
                "final_forgive_amount": 1000.00,
                "calculated_interest": 10.00,
                "final_forgive_payment": 910.00,
                "final_forgive_payment_date": "2020-08-25",
                "final_forgive_payment_batch": "PPY08091",
                "final_forgive_amount_with_interest": 910.00,
                "payment_status": "Payment Sent"
            },
            "created": "07/02/2020"
        }
    })
    
}

export const MOCK_INDUSTRY = [
    {
        "name": "Retail",
        "abbreviation": "RT"
    },
    {
        "name": "Manufacturing",
        "abbreviation": "MFG"
    },
    {
        "name": "FOOD",
        "abbreviation": "FD"
    }
]

export const MOCK_APPEAL_ROLES = [
    {
        "id": "APL",
        "name": "Borrower"
    },
    {
        "id": "ATR",
        "name": "Borrower's Attorney"
    },
]

export const MOCK_STATES = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    // {
    //     "name": "Federated States Of Micronesia",
    //     "abbreviation": "FM"
    // },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    // {
    //     "name": "Marshall Islands",
    //     "abbreviation": "MH"
    // },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    // {
    //     "name": "Palau",
    //     "abbreviation": "PW"
    // },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

export const MOCK_LOANS = [{ 
    created_by_name:'David',
    created_at:Date.now().toString(),
    created_by_email:'david@dave.com',
    id:0,
    notational_amount:'12000',
    exchange:{
        id:1,
        sale_type:'Snake Oil',
        product_type:"RESI",
        servicing_type:"Servicing Retained",
        servicing_fee:"100.00",
        asking:"11000",
        listed_at:Date.now(),
        listed_by_name:'David',
        listed_by_email:'david@dave.com'
    },
    organization:{
        name:"Mushroom Kingdom Treasury",
        city:"Acorn Plains",
        state:"Acorn Providence",
        url:"nintendo.com",
        date_established:Date.now().toString(),
        type:"Bank",
        federal_reserve_id:"12230",
        fdic_cert_number:"22345",
        rating_agency:'FDIC',
        swift_bic:"None",
        management:{
            name:"Merideth",
            title:"USP",
            phone:"455-900-1222",
            email:"mer@de.com"
        }
    },
    doc:{
        name:"Invoice",
        description:"A Stupid Invoice",
        id:'12',
        slug:'1eed34454aadasd'
    }
},
{ 
    created_by_name:'David',
    created_at:Date.now().toString(),
    created_by_email:'david@dave.com',
    id:0,
    notational_amount:'12000',
    exchange:{
        id:1,
        sale_type:'Snake Oil',
        product_type:"RESI",
        servicing_type:"Servicing Retained",
        servicing_fee:"100.00",
        asking:"11000",
        listed_at:Date.now(),
        listed_by_name:'David',
        listed_by_email:'david@dave.com'
    },
    organization:{
        name:"Mushroom Kingdom Treasury",
        city:"Acorn Plains",
        state:"Acorn Providence",
        url:"nintendo.com",
        date_established:Date.now().toString(),
        type:"Bank",
        federal_reserve_id:"12230",
        fdic_cert_number:"22345",
        rating_agency:'FDIC',
        swift_bic:"None",
        management:{
            name:"Merideth",
            title:"USP",
            phone:"455-900-1222",
            email:"mer@de.com"
        }
    },
    doc:{
        name:"Invoice",
        description:"A Stupid Invoice",
        id:'12',
        slug:'1eed34454aadasd'
    }
},
{ 
    created_by_name:'David',
    created_at:Date.now().toString(),
    created_by_email:'david@dave.com',
    id:0,
    notational_amount:'12000',
    exchange:{
        id:1,
        sale_type:'Snake Oil',
        product_type:"RESI",
        servicing_type:"Servicing Retained",
        servicing_fee:"100.00",
        asking:"11000",
        listed_at:Date.now(),
        listed_by_name:'David',
        listed_by_email:'david@dave.com'
    },
    organization:{
        name:"Mushroom Kingdom Treasury",
        city:"Acorn Plains",
        state:"Acorn Providence",
        url:"nintendo.com",
        date_established:Date.now().toString(),
        type:"Bank",
        federal_reserve_id:"12230",
        fdic_cert_number:"22345",
        rating_agency:'FDIC',
        swift_bic:"None",
        management:{
            name:"Merideth",
            title:"USP",
            phone:"455-900-1222",
            email:"mer@de.com"
        }
    },
    doc:{
        name:"Invoice",
        description:"A Stupid Invoice",
        id:'12',
        slug:'1eed34454aadasd'
    }
}];


export const MOCK_RESOUCES_PAGE_REQUEST =  {  "userGuide" : "This is some text for user guide",
    "videos" : "This is some text for videos", "faqs": "This is some text for faqs",
    "additionalResources" : "This is some text for additionalResources"
 };

 export const MOCK_RESOURCES_PAGE_VIDEOS = [
        { label:"Getting Started", url:"https://player.vimeo.com/video/446438499"},
        { label:"Admin Functions", url:"https://player.vimeo.com/video/443992793"},
        { label:"Decision Submission", url:"https://player.vimeo.com/video/443992765"},
        { label:"Monitoring Forgiveness", url:"https://player.vimeo.com/video/443992724"},
        { label:"User Management", url:"https://player.vimeo.com/video/443992687"},
        { label:"Create Api User", url:"https://player.vimeo.com/video/445628400"}
    ];

 export const MOCK_RESOURCES_PAGE_FAQ = [
    { topic:"General", 
        data: [{ques: "Which internet browser is supported by the Platform?", ans : "Does the platform automatically log out users based on inactivity?"},
        {ques: "Does the platform automatically log out users based on inactivity?", ans : "Yes, the Platform will log out after 90 minutes of inactivity"},
        {ques: "Where can I find out more information on using APIs to integrate with the Platform?", ans : "A complete guide for Platform API use can be found at the following address: <a href=\"https://ussbaforgiveness.github.io/\">https://ussbaforgiveness.github.io</a>"}]
    },
    { topic:"Platform", 
        data: [{ques: "How do I create a Platform account?", ans : "Authorizing Officials (AOs) currently registered in CAFS will receive a welcome email with instructions approximately one week prior to platform launch. If you are not registered as the AO for your organization, please contact your AO for assistance in creating your account. AOs will have Admin access for their institution."},
        {ques: "I am not the Authorizing Official for my institution. How do I create a general account?", ans : " Contact the registered Authorizing Official for your institution for assistance in creating your account."},
        {ques: "How do I log in to the Platform?", ans : "For enhanced security, all Platform users will encounter two-factor authentication upon login. You may choose between scanning a QR code or receiving a text message to generate a unique token. If you do not already have an authenticator app on your phone, we recommend using the text authentication method."},
        {ques: "Are institutions limited in the number of users they can register in the Platform?", ans : " Admin users may collectively create up to 10 active users for each institution. We recommend that no more than two users be Admins."},
        {ques: "What if I did not receive a Welcome email?", ans : " If you are your institution's Authorizing Official (AO) and have not received the Welcome email by end of day August 3, please email the Forgiveness Platform inbox at <a href=\"mailto:help@ussbaforgiveness.com\">help@ussbaforgiveness.com</a> for assistance. Please be sure to check your spam inbox for any missed emails."},
        {ques: "How do I remove a user from my institution?", ans : "Admin users can deactivate other users within their Institution rather than removing users completely. To edit an existing user, select the \"Users\" icon to view your institution's user list. Select “Edit” for the user you'd like to deactivate. Here, you can change the user’s contact information, user permissions, or deactivate their account."},
        {ques: "Where can the General Platform be accessed?", ans : "A The Forgiveness Platform can be accessed via the link on the CAFS homepage or directly at <a href=\"https://forgiveness.sba.gov\">forgiveness.sba.gov</a>"},
        {ques: "The Authorizing Official listed for my institution no longer works here. How can we update the Authorizing Official in CAFS?", ans : "Send an email to <a href=\"mailto:CLS@SBA.gov\">CLS@SBA.gov</a> with the subject “Authorizing Official Approval Request.” You will receive an email once the request has been granted."}]
    },
    { topic:"Institution", 
    data: [{ques: "Do I have to input a distribution list rather than an individual's email on the Institution Settings page?", ans : "A distribution list is recommended so that all active users at your Institution are notified of any requests from SBA or changes to institution-wide settings."},
    {ques: "Where do I enter my institution's payment information?", ans : " AOs can enter the institution's payment information in the Forgiveness Platform under the institution settings in the side-bar options. AO's are required to enter routing number, account number, account type, and taxpayer identification number."}]
    },
    { topic:"Forgiveness", 
    data: [{ques: "Should I use the 3508 or the 3508EZ option to submit a forgiveness decision?", ans : "Lenders should use whichever form they received from the borrower to submit a forgiveness decision. Instructions on determining if your Borrower is eligible to use the 3508EZ form can be found here:<a href= \"https://home.treasury.gov/system/files/136/PPP-LoanForgiveness-Application-Form-EZ-Instructions.pdf\">https://home.treasury.gov/system/files/136/PPP-LoanForgiveness-Application-Form-EZ-Instructions.pdf</a>. <br/>The Platform\'s default form is 3508EZ. Lenders may switch to the standard 3508 form by selecting “Switch to 3508” in the upper right corner of the forgiveness decision form."},
    {ques: "When can I submit a forgiveness decision for a PPP loan?", ans : "Once the Platform is live, Lenders can request Forgiveness on a PPP loan or a pool of PPP loans as soon as 8 weeks after full disbursement."},
    {ques: "When should Lenders notify Borrowers of full or partial loan forgiveness?", ans : "Lenders must make a decision on the borrower's forgiveness request before submitting the forgiveness decision to SBA, but should not notify the borrowers of forgiveness until payment is confirmed by the SBA. Within 10 days of receiving payment on the forgiveness purchase request, lenders must notify the borrower that the loan is forgiven in the correct amount and report the remaining amount, if any, on all 1502 monthly reports until the loan is paid in full."},
    {ques: "Can I submit a forgiveness decision before a loan has been fully disbursed?", ans : "Forgiveness requests may only be submitted for loans that have been properly marked as fully disbursed in ETRAN. Before creating a forgiveness decision, Lenders must verify that the loan is indeed eligible for forgiveness in the Platform. Click on “Lookup Disbursed PPP Loans” in the left-hand menu and search for the particular loan in the search bar by loan number. Loans that are listed in this dashboard are eligible for forgiveness decisions."},
    {ques: "Do I need to submit a forgiveness decision even if it is denied?", ans : "Yes, all forgiveness decisions must be submitted to the Platform. Denial decisions will still be processed by the Platform and routinely reviewed by SBA."},
    {ques: "Where can I get more information about Forgiveness", ans : " For more information on the Forgiveness process, see Procedures for Lender Submission of PPP Loan Forgiveness Decisions (Procedural Notice 5000-20038) - <a href=\"https://www.sba.gov/document/procedural-notice-5000-20038-procedures-lendersubmission-paycheck-protection-program-loan-forgiveness-decisions-sba-sba\">https://www.sba.gov/document/procedural-notice-5000-20038-procedures-lendersubmission-paycheck-protection-program-loan-forgiveness-decisions-sba-sba</a> Add<br/>itional CARES Act and PPP resources, including application forms, Lender guidance, and PPP program rules, can be found on SBA’s PPP website or the U.S. Treasury PPP website."}]
    },
    { topic:"Decision", 
    data: [{ques: "Should I use the 3508 or the 3508EZ option to submit a forgiveness decision?", ans : " Lenders should use whichever form they received from the borrower to submit a forgiveness decision.  Instructions on determining if your Borrower is eligible to use the 3508EZ form can be found here: <a href=\"https://home.treasury.gov/system/files/136/PPP-LoanForgiveness-Application-Form-EZ-Instructions.pdf\">https://home.treasury.gov/system/files/136/PPP-LoanForgiveness-Application-Form-EZ-Instructions.pdf</a>.<br/> The Platform's default form is 3508EZ. Lenders may switch to the standard 3508 form by selecting “Switch to 3508” in the upper right corner of the forgiveness decision form."},
    {ques: "Can I save an in-progress forgiveness decision as a draft?", ans : " Yes, partial and complete forgiveness decisions can be saved prior to submitting to SBA. Use the \"Save As Draft\" button at the bottom of the forgiveness decision form to save your decision. Draft decisions can be viewed on the Drafts page. Please note that forgiveness decisions will not be reviewed by the SBA until they are submitted in the Platform."},
    {ques: "How do I account for an EIDL Advance Amount in submitting a forgiveness decision?", ans : "The Platform will automatically deduct any applicable EIDL Advance amount. Lenders should not reduce the forgiveness amount to account for an EIDL Advance."},
    {ques: "Can I withdraw a forgiveness decision?", ans : "You can withdraw a forgiveness decision after it has been submitted and before it has been reviewed by SBA (\"Pending Validation\" status). From the All Forgiveness Requests page, open the forgiveness decision click \"Withdraw Request\" at the top of the page. Please note that you will be unable to withdraw a forgiveness decision once the decision has progressed to \"Accepted\" status and the SBA has initiated its review."},
    {ques: "Why do I get an error that my uploaded documents are not saved when submitting a forgiveness decision?", ans : "If the Platform detects an error in your forgiveness decision, you will not be able to submit and an error message with a display. Once you correct the error(s), you must re-upload any supporting documents prior to submitting the forgiveness decision again."},
    {ques: "Will I receive automated notification emails about a change in status for my submitted forgiveness decisions?", ans : "No, Lenders will only receive email notifications when additional documentation is requested by the SBA via the Platform inbox. Lenders are encouraged to regularly monitor the Platform for status updates, SBA review requests, and other Platform updates."},
    {ques: "When should Lenders notify Borrowers of full or partial loan forgiveness?", ans : "Lenders must make a decision on the borrower forgiveness request before submitting the forgiveness decision to SBA, but should not notify the borrowers of forgiveness until payment is confirmed by the SBA. Within 10 days of receiving payment on the forgiveness purchase request, lenders must notify the borrower that the loan is forgiven in the correct amount and report the remaining amount, if any, on all 1502 monthly reports until the loan is paid in full."},
    {ques: "Do I need to submit a forgiveness decision even if it is denied?", ans : "Yes, all forgiveness decisions must be submitted to the Platform. Denial decisions will still be processed by the Platform and reviewed by SBA."},
    {ques: "Is there a maximum number of documents that can be uploaded with each application? ", ans : "No, Lenders may submit an unlimited number of documents with each application. Please note that lenders must submit, at a minimum, the original 3508/3508EZ from the Borrower with their forgiveness decision."},
    {ques: "What file formats are supported for document uploads?", ans : "The Platform supports pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, and png files"},
    {ques: "When can I submit a forgiveness decision for a PPP loan?", ans : "Once the Platform is live, Lenders can request&nbsp;Forgiveness&nbsp;on a PPP as soon as&nbsp;8 weeks&nbsp;after full disbursement.  Please see the official guidance for this information."},
    {ques: "Can I upload additional supporting documentation to a forgiveness decision once it has been submitted?", ans : "Submitted decisions that are in \"Pending Validation\" status and have yet to be reviewed by the SBA may be withdrawn, and the Lender may resubmit the decision with the desired documentation. Forgiveness decisions cannot be edited once they are in \"Accepted\" status and under review by the SBA. The SBA Review team will alert Lenders via the Platform Inbox if additional documentation is needed."},
    {ques: "What documentation am I required to provide as part of my forgiveness decision?", ans : "At a minimum, Lenders must attach the Borrower's completed 3508/3508EZ to the forgiveness decision. Additional documentation beyond this form is optional. Should SBA require additional supporting documentation, the Lender will receive that request through the Platform's messaging feature along with email notification."}]
    },
    { topic:"Documentation", 
    data: [{ques: "Is there a maximum number of documents that can be uploaded with each application?", ans : "No, Lenders may submit an unlimited number of documents with each application. Please note that lenders must submit, at a minimum, the original 3508/3508EZ from the Borrower with their forgiveness decision."},
    {ques: "What file formats are supported for document uploads?", ans : "The Platform supports pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, and png files."},
    {ques: "Can I upload additional supporting documentation to a forgiveness decision once it has been submitted?", ans : "Submitted decisions that are in \"Pending Validation\" status and have yet to be reviewed by the SBA may be withdrawn, and the Lender may resubmit the decision with the desired documentation. Forgiveness decisions cannot be edited once they are in \"Accepted\" status and under review by the SBA. The SBA Review team will alert Lenders via the Platform Inbox if additional documentation is needed."},
    {ques: "What documentation am I required to provide as part of my forgiveness decision?", ans : "For applications submitted using SBA Form 3508 or a lender equivalent, Lenders must submit a PPP Loan Forgiveness Calculation Form (including the signed certification document by the borrower), PPP Schedule A, and PPP Borrower Demographic Information Form (if submitted to the lender).<br/>For applications submitted using SBA Form 3508EZ, Lenders must submit the SBA Form 3508EZ or lender’s equivalent form, (including the signed certification document by the borrower) and PPP Borrower Demographic Information Form (if submitted to the lender).<br/> Additional documentation beyond this form is optional. Should SBA require additional supporting documentation, the Lender will receive that request through the Platform's messaging feature along with email notification."}]
    },
    { topic:"MonitoringForgiveness", 
    data: [{ques: "How will the SBA remit payment to lenders for forgiven loans?", ans : "The SBA will remit payment using the bank account information entered by your institution's Authorizing Official in the Institution Settings page of the Platform. Be sure to validate this information prior to submitting forgiveness decisions."},
    {ques: "Will I receive automated notification emails about a change in status for my submitted forgiveness decisions?", ans : "No, Lenders will only receive email notifications when additional documentation is requested by the SBA via the Platform inbox. Lenders are encouraged to regularly monitor the Platform for status updates, SBA review requests, and other Platform updates."},
    {ques: "When can I expect my forgiveness payment from SBA?", ans : " SBA will have 90 days from receipt of a complete package to review the Lender's forgiveness decision, render a decision, and make payment."}]
    },
    { topic:"AdditionalResources", 
    data: [{ques: "PPP Lender Hotline", ans : "833-572-0502"},
    {ques: "BA PPP Website", ans : "Additional information and resources on the Paycheck Protection Program."},
    {ques: "U.S. Treasury CARES Act Website", ans : "Additional Procedural Notices, Final Rules, and other information related to the CARES Act."},
    {ques: "Procedures for Lender Submission of Paycheck Protection Program Loan Forgiveness Decisions", ans : "Guidance informing Paycheck Protection Program (PPP) Lenders of the processes for submitting decisions on PPP borrower loan forgiveness applications to SBA, requesting payment of the forgiveness amount determined by the Lender, SBA loan forgiveness reviews, and payment of the loan forgiveness amount determined by SBA."},
    {ques: "Interim Final Rule on Loan Forgiveness", ans : "Guidance to help PPP borrowers prepare and submit loan forgiveness applications as provided for in the CARES Act and lenders who will be making the loan forgiveness decisions, and inform borrowers and lenders of SBA’s process for reviewing PPP loan applications and loan forgiveness."},
    {ques: "Interim Final Rule on Revisions to Loan Forgiveness Interim Final Rule and SBA Loan Review Procedures Interim Final Rule", ans : "Guidance revising interim final rules by changing key provisions to conform to the Flexibility Act."},
    {ques: "Interim Final Rule on Certain Eligible Payroll Costs", ans : "Supplement to previous interim final rules providing additional guidance on certain eligible payroll costs."},
    {ques: "Platform Inbox", ans : "<a href=\"“mailto:help@ussbaforgiveness.com”\">help@ussbaforgiveness.com</a><br/>Email for technical questions about using the Platform."},
    {ques: "ISBA Forgiveness Inbox", ans : "<a href=\"“mailto:PPPForgivenessRequests@SBA.gov”\">PPPForgivenessRequests@SBA.gov</a><br/>Email for questions about PPP Forgiveness policies and procedures."},
    {ques: "Standard 3508 Application", ans : " Extended Treasury PPP Loan Forgiveness Application form. Form 3508 can be found on pages 3 and 4."},
    {ques: "3508EZ Application", ans : "Instructions for using the 3508EZ application form."}]
    }
];


export const YOUR_APPEALS_TABLE = {
    "success": true,
    "appealsList": [{
            appellant_name: "Microcomputers Processors Inc",
            status:'New',
            attorney: "Markus Legal",
            judge: "Judge Reynolds",
            loan_number: "0000123456",
            appeal_date: "13/10/2020",
            sba_decision_date: "13/11/2020",
            id: '1'
        },
        {
            appellant_name: "Intergalactic Express",
            status:'Under Review',
            attorney: "Markus Legal",
            judge: "Judge Reynolds",
            loan_number: "000123365",
            appeal_date: "13/10/2020",
            sba_decision_date: "13/11/2020",
            id: '1'

        },
        {
            appellant_name: "Baby Strollers",
            status:'New',
            attorney: "Markus Legal",
            judge: "Judge Reynolds",
            loan_number: "23124144",
            appeal_date: "13/10/2020",
            sba_decision_date: "13/11/2020",
            id: '1'

        },
        {
            appellant_name: "Krazy Joes BBQ House Inc",
            status:'New',
            attorney: "Markus Legal",
            judge: "Judge Reynolds",
            loan_number: "124124343",
            appeal_date: "13/10/2020",
            sba_decision_date: "13/11/2020",
            id: '1'

        },
        {
            appellant_name: "Krazy Joes Pizza House Inc",
            status:'Complete',
            attorney: "Markus Legal",
            judge: "Judge Reynolds",
            loan_number: "342352525",
            appeal_date: "13/10/2020",
            sba_decision_date: "13/11/2020",
            id: '1'

        }
    ]
}

export const YOUR_APPEALS_TABLE_ALT = {
    "success": true,
    "appealsList": [{
            appellant_name: "Microcomputers Processors Inc",
            status:'New',
            lender_name: "Markus Legal",
            loan_number: "342352525",
            modified_date: "13/10/2020",
            id: '1'
        },
        {
            appellant_name: "Intergalactic Express",
            status:'Under Review',
            lender_name: "Markus Legal",
            loan_number: "342352525",
            modified_date: "13/10/2020",
            id: '1'
        },
        {
            appellant_name: "Baby Strollers",
            status:'New',
            lender_name: "Markus Legal",
            loan_number: "342352525",
            modified_date: "13/10/2020",
            id: '1'

        },
        {
            appellant_name: "Krazy Joes BBQ House Inc",
            status:'New',
            lender_name: "Markus Legal",
            loan_number: "342352525",
            modified_date: "13/10/2020",
            id: '1'

        },
        {
            appellant_name: "Krazy Joes Pizza House Inc",
            status:'Complete',
            lender_name: "Markus Legal",
            loan_number: "342352525",
            modified_date: "13/10/2020",
            id: '1'

        }
    ]
}


export const MOCK_USERS = [
    {
        id:'100234',
        name:'AshleyNCloud',
        full_name:'Ashley N. Cloud',
        email:'',
        organization:'Small Business Administration',
        roles:['Reviewer']
    },
    {
        id:'00232',
        name:'DeloricePriceFord',
        full_name:'Delorice Price Ford',
        email:'',
        organization:'Small Business Administration',
        roles:['Reviewer']
    },
    {
        id:'1000222',
        name:'JimmyJHe',
        full_name:'Jimmy J. He',
        email:'',
        organization:'Small Business Administration',
        roles:['Reviewer']
    }
]
    


