import { Component, OnDestroy, Input, ChangeDetectorRef, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector:'input-common',
    template:`
        <ng-container *ngIf='loading'>
            ...
        </ng-container>
        <ng-container *ngIf='!loading'>
            <label class='label is-small'>{{iConfiguration.name}}</label>
            <input class="input is-primary form-control" 
                [ngClass]="{'form-control-sm':small, 'form-control-lg':big}"
                attr.title="{{iConfiguration.name}}"
                attr.aria-label='{{iConfiguration.name}}' 
                name="{{titleCase(iConfiguration.name)}}" 
                type="text" 
                maxLength ="{{maxLength}}"
                placeholder="{{iConfiguration.placeholder}}"
                (keyup)='onChange()'
                [(ngModel)]='inputModelValue'>
        </ng-container>
    `
})
export class InputCommon{

    public iConfiguration:InputConfiguration = {
        name:null,
    };

    @Input() set config(config:InputConfiguration){
        this.loading = true;
        this.iConfiguration = {
            name:config.name || null,
            placeholder: config.placeholder || null,
            type: config.type || 'text',
            multiple:config.multiple || false
        }
        this.inputModelValue = config.value;
        this.renderer.setProperty(this.ele.nativeElement,'id', _.snakeCase(config.name));
        this.loading = false;
        this.maxLength = config.maxLength;
    }

    @Input() set size(size:any){
        this.big = (size === 'big');
        this.small = (size === 'small');
    }

    public big:boolean = false;
    public small:boolean = false;

    public inputModelValue:any = null;
    public inputID:string = null;
    public loading:boolean = true;
    public maxLength:number = 100;

    // Emitters
    @Output() onChangeEmitter = new EventEmitter<any>();
    @Output() onReadyEmitter = new EventEmitter<any>();

    constructor(
        public changeDetectorRef:ChangeDetectorRef,
        public renderer:Renderer2,
        public ele:ElementRef
    ){
    }

    titleCase(str:string){
        return _.startCase(str);
    }

    onChange(){
        this.onChangeEmitter.emit(this.inputModelValue);
    }

}
