import {Injectable,Inject} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { Observable, zip } from 'rxjs';
import { AuthConfiguration, AUTH_CONFIG, getPageLimit, getSessionState } from '../../../platform/modules/auth';
import { AppealsApplicationsService } from '../appeals-applications.service';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { TicketsService } from '../../tickets/tickets.service';
import { selectAppealApplicationsFilters } from '../store/appeals-applications.selector';

@Injectable({
  providedIn:'root'
})
export class LinkedAppealsResolver implements Resolve<any> {

  constructor(
    @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    @Inject(AppealsApplicationsService) public appealsService:AppealsApplicationsService,
    @Inject(TicketsService) public ticketService:TicketsService,
    @Inject(Store) public ngrxstore:Store,
    @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration
  ){}

  // Provides the loans before inital page render.
  resolve(route:ActivatedRouteSnapshot){

    let _currentFilterStore = '';
    let _currentStatusStore = '';
    let _currentTypeStore = '';
    this.ngrxstore.select(selectAppealApplicationsFilters()).subscribe((response)=>{
      _currentFilterStore = response.hasOwnProperty('term') ? response.term : '';
      _currentStatusStore = response.hasOwnProperty('appealStatus') ? response.appealStatus : null;
      _currentTypeStore =  response.hasOwnProperty('type') ? response.type : null;
    });

    let _role = ""; 
    this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
      _role = session.roles[0];
    });

    let list_page_limit = 30;
     this.ngrxstore.select(getPageLimit).pipe(take(1)).subscribe((response)=>{
       list_page_limit = response;
     });

    let appealList$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    let assignedAppealList$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    let unassignedAppealList$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    switch(_role){

      case this.authConfig.roles.OHA_ADMIN.slug:
        // appealList$ = new Observable((obs)=>{
        //   this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
        //     this.appealsService.getAppealsList('active'
        //     ,0,this.appDefaults.ui.LIST_PAGE_LIMIT,{ordering:"+title"},"",this.authConfig.roles.OHA_ADMIN.slug).pipe(take(1)).subscribe((response)=>{
        //       obs.next(response);
        //       obs.complete();
        //     })
        //   })
        // });
        appealList$ = new Observable((obs)=>{
          obs.next(['empty']);
          obs.complete();
        })
        unassignedAppealList$ = new Observable((obs)=>{
          this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
            this.appealsService.getAppealsList('active'
            ,0,list_page_limit,{ordering:'filing_date'},{
              search:_currentFilterStore,
              assigned:false
            },this.authConfig.roles.OHA_ADMIN.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
              obs.next(response);
              obs.complete();
            })
          })
        });
        assignedAppealList$ = new Observable((obs)=>{
          this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
            this.appealsService.getAppealsList('active'
            ,0,list_page_limit,{ordering:'filing_date'},{
              search:_currentFilterStore,
              assigned:true
            },this.authConfig.roles.OHA_ADMIN.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
              obs.next(response);
              obs.complete();
            })
          })
        });
      break;

      case this.authConfig.roles.OGC_ADMIN.slug:
        // appealList$ = new Observable((obs)=>{
        //   this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
        //     this.appealsService.getAppealsList('active'
        //     ,0,this.appDefaults.ui.LIST_PAGE_LIMIT,{ordering:"+title"},"",this.authConfig.roles.OGC_ADMIN.slug).pipe(take(1)).subscribe((response)=>{
        //       obs.next(response);
        //       obs.complete();
        //     })
        //   })
        // });
        appealList$ = new Observable((obs)=>{
          obs.next(['empty']);
          obs.complete();
        })
        unassignedAppealList$ = new Observable((obs)=>{
          this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
            this.appealsService.getAppealsList('active'
            ,0,list_page_limit,{ordering:'filing_date'},{
              search:_currentFilterStore,
              missing_ogc_poc:true
            },this.authConfig.roles.OGC_ADMIN.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
              obs.next(response);
              obs.complete();
            })
          })
        });
        assignedAppealList$ = new Observable((obs)=>{
          this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
            this.appealsService.getAppealsList('active'
            ,0,list_page_limit,{ordering:'filing_date'},{
              search:_currentFilterStore,
              missing_ogc_poc:false
            },this.authConfig.roles.OGC_ADMIN.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
              obs.next(response);
              obs.complete();
            })
          })
        });
      break;

      default:
        if(_role ===  this.authConfig.roles.OHA.slug) {

          appealList$ = new Observable((obs)=>{
            this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
              this.appealsService.getAppealsList('active'
              ,0,list_page_limit,{ordering:'filing_date'},{
                search:_currentFilterStore,
                assigned:true
              },this.authConfig.roles.OHA.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
                obs.next(response);
                obs.complete();
              })
            })
          });
          unassignedAppealList$ = new Observable((obs)=>{
            obs.next(['empty']);
            obs.complete();
          });
          assignedAppealList$ = new Observable((obs)=>{
            obs.next(['empty']);
            obs.complete();
          });


        }else{
          appealList$ = new Observable((obs)=>{
            this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
              this.appealsService.getAppealsList('active'
              ,0,list_page_limit,{ordering:'filing_date'},{
                search:_currentFilterStore
              },this.authConfig.roles.OHA.slug,_currentStatusStore,_currentTypeStore).pipe(take(1)).subscribe((response)=>{
                obs.next(response);
                obs.complete();
              })
            })
          });
          unassignedAppealList$ = new Observable((obs)=>{
            obs.next(['empty']);
            obs.complete();
          });
          assignedAppealList$ = new Observable((obs)=>{
            obs.next(['empty']);
            obs.complete();
          });

        }
      break;
    }

    return zip(
      appealList$,
      assignedAppealList$,
      unassignedAppealList$,
      this.appealsService.getAppealStatuses(),
      this.ngrxstore.select(getSessionState),
      this.appealsService.getCurrentUser()
      ).pipe(
      map(([
        $appealData,
        $assignedAppealData,
        $unassignedAppealData,
        $appealStatus,
        $session,
        $currentUser
      ])=>({
        appealData:$appealData,
        assignedAppealData:$assignedAppealData,
        unassignedAppealData:$unassignedAppealData,
        appealStatus:$appealStatus,
        session:$session,
        currentUser:$currentUser
      })),
      take(1)
    );
  }

}
