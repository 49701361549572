import { PORTAL_PATHS } from '../features/portal/constants/portal.constants';
import { APPEALS_APPLICATIONS_PATHS, APPEALS_APPLICATIONS_ROOT_PATH } from '../features/appeals-applications/constants/appeals-applications.constants';
import { APPEAL_WIZARD_PATHS, APPEAL_WIZARD_ROOT_PATH } from '../features/appeal-wizard/constants/appeal-wizard.constants';
import { ACCOUNT_PATHS, ACCOUNT_ROOT_PATH } from '../features/account/constants/account.constants';
import { TICKETS_PATHS, TICKETS_ROOT_PATH } from '../features/tickets/constants/tickets.constants';
import { RESOURCES_PATHS, RESOURCES_ROOT_PATH } from '../features/resources/constants/resources.constants';
import { PortalRoutingModule } from '../features/portal/portal.routes';
import { HEALTH_PATHS, HEALTH_ROOT_PATH } from '../features/health/constants/health.constants';


// Global that represents a route without it's suffix or 'space'
// ---------------------------------------------------
export const ROUTE_PATHS = Object.assign({},
  APPEALS_APPLICATIONS_PATHS,
  PORTAL_PATHS,
  APPEAL_WIZARD_PATHS,
  ACCOUNT_PATHS,
  TICKETS_PATHS,
  RESOURCES_PATHS,
  HEALTH_PATHS
);

// Global that represents a route including it's suffix or 'space'
// ---------------------------------------------------
export const FULL_ROUTES = Object.assign(
  addLeadingSlashToPaths(PORTAL_PATHS),
  nestPaths(addLeadingSlashToPaths(APPEALS_APPLICATIONS_PATHS),APPEALS_APPLICATIONS_ROOT_PATH),
  nestPaths(addLeadingSlashToPaths(APPEAL_WIZARD_PATHS),APPEAL_WIZARD_ROOT_PATH),
  nestPaths(addLeadingSlashToPaths(ACCOUNT_PATHS),ACCOUNT_ROOT_PATH),
  nestPaths(addLeadingSlashToPaths(TICKETS_PATHS),TICKETS_ROOT_PATH),
  nestPaths(addLeadingSlashToPaths(RESOURCES_PATHS),RESOURCES_ROOT_PATH),
  nestPaths(addLeadingSlashToPaths(HEALTH_PATHS),HEALTH_ROOT_PATH),
);

// Global to control backdrop visibility
// ---------------------------------------------------
export const ROUTE_PATHS_WITH_BACKGROUND =  Object.assign({
  USER_PORTAL:PORTAL_PATHS.USER_PORTAL,
  LOGIN:PORTAL_PATHS.LOGIN,
  RESET_PASSWORD:PORTAL_PATHS.RESET_PASSWORD,
  FORGOT_PASSWORD:PORTAL_PATHS.FORGOT_PASSWORD,
  INVITE_USER_WITH_TOKEN:PORTAL_PATHS.INVITE_USER_WITH_TOKEN,
  LOGIN_WITH_APPEAL_ID:PORTAL_PATHS.LOGIN_WITH_APPEAL_ID,
  LOGIN_WITH_INVITE:PORTAL_PATHS.LOGIN_WITH_INVITE,
  TWO_FA_PROVIDE:PORTAL_PATHS.TWO_FA_PROVIDE,
  TWO_FA_REGISTER_PHONE:PORTAL_PATHS.TWO_FA_REGISTER_PHONE,
  TWO_FA_METHOD:PORTAL_PATHS.TWO_FA_METHOD,
  TWO_FA_SCAN_QR:PORTAL_PATHS.TWO_FA_SCAN_QR,
  DASHBOARD_PORTAL:PORTAL_PATHS.DASHBOARD_PORTAL,
  DASHBOARD_LOGIN:PORTAL_PATHS.DASHBOARD_PORTAL+'/'+PORTAL_PATHS.LOGIN,
  DASHBOARD_RESET_PASSWORD:PORTAL_PATHS.DASHBOARD_PORTAL+'/'+PORTAL_PATHS.RESET_PASSWORD,
  DASHBOARD_FORGOT_PASSWORD:PORTAL_PATHS.DASHBOARD_PORTAL+'/'+PORTAL_PATHS.FORGOT_PASSWORD,
});

export const ROUTES_WITH_WIZARD_BAR = Object.assign({
  APPEALS_WIZARD:APPEAL_WIZARD_PATHS.APPEAL_WIZARD
});

// Helper Functions
// ---------------------------------------------------
function addLeadingSlashToPaths(paths){
  let _paths = {};
  Object.keys(paths).map((key)=>{
    _paths[key] = '/'+paths[key];
  });
  return _paths;
}

function nestPaths(paths,nestPath){
  let _paths = {};
  Object.keys(paths).map((key)=>{
    _paths[key] = '/'+nestPath+paths[key];
  });
  return _paths;
}


