export const NOTIFICATION_ACTION_CONSTANTS ={
  TRIGGER_MODAL:'TRIGGER_MODAL',
  REMOVE_MODAL:'REMOVE_MODAL',
  TRIGGER_TOAST:'TRIGGER_TOAST',
  REMOVE_TOAST:'REMOVE_TOAST',
  APPROVE_MODAL_ACTION:'APPROVE_MODAL_ACTION',
  REJECT_MODAL_ACTION:'REJECT_MODAL_ACTION',
  CANCEL_MODAL_ACTION:'CANCEL_MODAL_ACTION',
  DISMISS_TOASTS:'DISMISS_TOASTS',
  FILE_PETITION:'FILE_PETITION'
}
