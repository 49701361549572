import { Directive, Input, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector:'[statusBadge]'
})
export class StatusBadge implements AfterViewInit{

    public classPrefix:string = 'bg-';
    public classSuffix:string = 'white';
    public badgeSizeClass:string = '';

    @Input() set statuses(dict:any){
        this.statusDictionary = dict;
    }
    public statusDictionary:any = {
        new:{
            display:'New',
            class:'purple'
        }
    }

    @Input() set size(sizeType:'sm'|'lg'){
        switch(sizeType){
            case 'sm':
                this.badgeSizeClass = 'status-badge-sm';
            break;
            case 'lg':
                this.badgeSizeClass = 'status-badge-lg';
            break;
            default:
                this.badgeSizeClass = '';
            break;
        }
        this.renderClass();
    }

    @Input() set style(styleType:'solid'|'outline'|'dashed'){
        switch(styleType){
            case 'solid':
                this.classPrefix = 'bg-';
            break;
            case 'outline':
                this.classPrefix = 'solid-border-';
            break;
            case 'dashed':
                this.classPrefix = 'dashed-border-';
            break;
            default:
                this.classPrefix = 'bg-';
            break;
        }
        this.renderClass();
    }

    @Input() set statusBadge(
        statusString:string
    ){
        switch(statusString){
            case 'New':
                this.classSuffix = 'purple'
            break;
            case 'Action Requested':
                this.classSuffix = 'orange'
            break;
            default:
                this.classSuffix = 'cyan'
            break;
        }
        this.renderClass();
    }

    constructor(
        public el:ElementRef,
        public renderer:Renderer2
    ){}

    ngAfterViewInit(){
        this.renderClass();
    }

    renderClass(){
        let _class = 'status-badge '+this.classPrefix+this.classSuffix+' '+this.badgeSizeClass;
        //console.log(_class)
        this.renderer.setAttribute(this.el.nativeElement,'class',_class);
    }

}
