import { InjectionToken } from '@angular/core';

export const AUTH_CONFIG = new InjectionToken<AuthConfiguration>('AUTH_CONFIG');

export interface AuthConfigurationInterface {
  api_url?: string;
  permissions?:any;
  auth_origins?:any;
  auth_redirects?:any;
  endpoints:any;
  roles?:any;
}

export class AuthConfiguration implements AuthConfigurationInterface {
  public api_url?: string;
  public permissions?: any;
  public auth_origins?:any;
  public auth_redirects?:any;
  public endpoints:any;
  public roles:any;
  public status:any;

  constructor(config: AuthConfigurationInterface = {
    endpoints:{}
  }) {
    this.assign(config);
  }

  public assign(config: AuthConfigurationInterface = {
    endpoints:{}
  }) {
    for (const key in config) {
      this[key] = config[key];
    }
  }
}
