import { Action, createFeatureSelector } from '@ngrx/store';
import { AUTH_ACTIONS } from '../constants/auth.constants';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

let _currentLocalAuthState:AuthState;
if(localStorage.getItem('auth')){
  _currentLocalAuthState = JSON.parse(localStorage.getItem('auth'))
}else{
  _currentLocalAuthState = {
    status:'signedOut',
    roles:['None'],
    user:'',
    user_id:null,
    connected:false,
    token:null,
    refresh_token:null,
    origin:null,
    application_id:null,
    list_page_limit:30
  }
}

export interface AuthState{
  status:'signedIn'|'signingIn'|'signingOut'|'signedOut',
  roles:Array<string>,
  user:string,
  user_id:string,
  connected:boolean,
  token:string,
  refresh_token:string,
  origin:string,
  application_id:string,
  list_page_limit:number
}

export const initialState:AuthState = {
  status:(_currentLocalAuthState['status']) ? _currentLocalAuthState['status'] : 'signedOut',
  roles:(_currentLocalAuthState['roles']) ? _currentLocalAuthState['roles'] : ['None'],
  user:(_currentLocalAuthState['user']) ? _currentLocalAuthState['user'] : '',
  user_id:(_currentLocalAuthState['user_id']) ? _currentLocalAuthState['user_id'] : null,
  connected:(_currentLocalAuthState['connected']) ? _currentLocalAuthState['connected'] : false,
  token:(_currentLocalAuthState['token']) ? _currentLocalAuthState['token'] : null,
  refresh_token:(_currentLocalAuthState['refresh_token']) ? _currentLocalAuthState['refresh_token'] : null,
  origin:(_currentLocalAuthState['origin']) ? _currentLocalAuthState['origin'] : null,
  application_id:(_currentLocalAuthState['application_id']) ? _currentLocalAuthState['application_id'] : null,
  list_page_limit:30
}


export function AuthReducer(state:AuthState = initialState, action: ActionWithPayload<any>):AuthState{

    let _value = null;

    switch (action.type) {

        case AUTH_ACTIONS.LOGIN:
          _value = Object.assign({},state,{
            user:'',
            user_id:'',
            connected:false,
            roles:['None'],
            token:null,
            refresh_token:null,
            origin:null
          });
          return _value;

        case AUTH_ACTIONS.COMPLETE_LOGIN:
          _value = Object.assign({},state,{
            user:action.payload.user,
            connected:true,
            user_id:action.payload.user_id,
            roles:action.payload.roles,
            status:'signedIn',
            token:action.payload.token,
            refresh_token:action.payload.refresh_token,
            origin:action.payload.origin
          });
          return _value;

        case AUTH_ACTIONS.COMPLETE_AMBIENT_LOGIN:
          _value = Object.assign({},state,{
            user:action.payload.user,
            connected:true,
            user_id:action.payload.user_id,
            roles:action.payload.roles,
            status:'signedIn',
            token:action.payload.token,
            refresh_token:action.payload.refresh_token,
            origin:action.payload.origin
          });
          return _value;

        case AUTH_ACTIONS.LOGIN_WITH_APPLICATION:
          _value = Object.assign({},state,{
            token:action.payload.application_token,
            application_id:action.payload.application_id,
            connected:true
          });
          return _value;

        case AUTH_ACTIONS.LOGOUT:
          _value = Object.assign({},state,{
            
          });
          return _value;

        case AUTH_ACTIONS.SET_ORIGIN:
          _value = Object.assign({},state,{
            origin:action.payload
          })
          return _value;

        case AUTH_ACTIONS.REFRESH_TOKEN:
          _value = Object.assign({},state,{
            token:action.payload.token,
            refresh_token:action.payload.refresh_token
          });
          return _value;

        case AUTH_ACTIONS.COMPLETE_LOGOUT:
          _value = Object.assign({},state,{
            user:'',
            user_id:'',
            connected:false,
            roles:['None'],
            status:'signedOut',
            token:null,
            refresh_token:null,
            origin:null
          });
          return _value;

        case AUTH_ACTIONS.LIST_PAGE_LIMIT:
            _value = Object.assign({},state,{
              list_page_limit:action.payload
            });
            return _value;
    
        default:
          return state;
    }
}

export const getAuthState = createFeatureSelector<AuthState>('auth');
