import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../../platform/modules/shared';

import { ShellModule } from '../../shell';
import { AccountModule } from '../account/account.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PPPAppealWizardRouting } from './appeal-wizard.routes';
import { PPPAppealWizard } from './wizard/wizard.component';
import { PPPAppealWizardService } from './appeal-wizard.service';
import { WizardStep } from './steps/wizard-step.component';
import { PPPAppealWizardStepResolver } from './resolvers/appeal-wizard-step.resolver';
import { NgbTooltipModule, NgbButtonsModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PPPAppealWizardDocumentsResolver } from './resolvers/appeal-wizard-documents.resolver';
import { PPPAppealWizardDemographicsResolver } from './resolvers/appeal-wizard-demographics.resolver';
import { PPPAppealWizardResolver } from './resolvers/appeal-wizard.resolver';
import { InputCard } from '../../platform/modules/shared/components/input-card/input-card.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { StartYourAppeal } from './steps/start-your-appeal/start-your-appeal.component';
import { BorrowerDetails } from './steps/borrower-details/borrower-details.component';
import { PointOfContact } from './steps/point-of-contact/point-of-contact.component';
import { LoanInformation } from './steps/loan-information/loan-information.component';
import { AppealContent } from './steps/appeal-content/appeal-content.component';
import { CertificateOfService } from './steps/certificate-of-service/certificate-of-service.component';
import { AppealSummary } from './steps/summary/summary.component';
import { EffectsModule } from '@ngrx/effects';
import { AppealWizardEffects } from './store/appeal-wizard.effects';
import { PPPAppealWizardStartYourAppealResolver } from './resolvers/appeal-wizard-start-your-appeal.resolver';
import { PPPAppealWizardBorrowerDetailsResolver } from './resolvers/appeal-wizard-borrower-details.resolver';
import { AppealSubmittedGuard } from './appeal-submitted.guard';
import { PPPAppealWizardSummaryResolver } from './resolvers/appeal-wizard-summary.resolver';
import { BUCKET_CONFIG } from '../../platform/modules/shared/modules/bucket/bucket.interface';
import { API_ENDPOINTS } from '../../constants/rest_api.constants';


@NgModule({
  imports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    InfiniteScrollModule,
    PPPAppealWizardRouting,
    ShellModule,
    AccountModule,
    NgxDatatableModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTypeaheadModule,
    SharedModule,
    NgxExtendedPdfViewerModule
  ],
  declarations:[
    PPPAppealWizard,
    WizardStep,
    StartYourAppeal,
    PointOfContact,
    LoanInformation,
    AppealContent,
    CertificateOfService,
    AppealSummary,
    BorrowerDetails
  ],
  providers:[
    PPPAppealWizardService,
    PPPAppealWizardResolver,
    PPPAppealWizardStepResolver,
    PPPAppealWizardDocumentsResolver,
    PPPAppealWizardDemographicsResolver,
    PPPAppealWizardStartYourAppealResolver,
    PPPAppealWizardSummaryResolver,
    AppealSubmittedGuard,
    PPPAppealWizardBorrowerDetailsResolver
  ],
  entryComponents:[
    InputCard
  ],
  exports:[
    RouterModule
  ]
})

export class PPPAppealWizardModule {};
