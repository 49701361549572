import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthConfiguration, AuthService, AUTH_CONFIG } from '../../../platform/modules/auth';
import { Store } from '@ngrx/store';
import { NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView } from '../../../platform/modules/navigation';
import { FormBuilder } from '@angular/forms';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';


@Component({
  selector: 'twofa-portal',
  template:require('./twofa-portal.component.html')
})
export class TwoFAPortal extends KillZombies(PageView){

  public title:string = '';
  public body:string = '';
  public validEmail:boolean = false;

  public authOrigin = null;
  public authRedirect = null;
  public qrCode = null;
  public auth_type = null;

	constructor(
    public formBuilder:FormBuilder,
    public authService:AuthService,
    public activatedRoute:ActivatedRoute,
    @Inject(NAVIGATION_CONFIG) protected navigationConfig:NavigationConfig,
    public navigationService:NavigationService
  ){

    super(navigationConfig,navigationService);

    let _route = this.activatedRoute.snapshot.routeConfig.path;
    
    this.authOrigin = (this.LastNavigationState) ? this.LastNavigationState.origin: '';
    this.authRedirect = (this.LastNavigationState) ? this.LastNavigationState.redirect : '';
    this.authOrigin = (this.authOrigin && this.authOrigin !== '') ? this.authOrigin.substring(1) : '';
    this.qrCode = (this.LastNavigationState) ? this.LastNavigationState.qrCode : '';
    this.auth_type = (this.LastNavigationState) ? this.LastNavigationState.auth_type : '';

    //console.log(_route,this.Routes,this.Routes['TWO_FA_PROVIDE'])

    switch('/'+_route){
      case this.Routes['TWO_FA_PROVIDE']:
        if(this.auth_type === 'sms'){
          this.title = 'Check Your Phone';
          this.body = `Please provide the token we sent to your phone in order to login`;
        }else{
          this.title = 'Check Your Phone';
          this.body = `Please provide the token generated by the Authenticator App`;
        }
      break;
      case this.Routes['TWO_FA_REGISTER_PHONE']:
        this.title = 'Register Your Phone';
        this.body = `In order to validate it is you please provide us your phone number so we can send you access tokens for subsequent logins.`;
      break;
      case this.Routes['TWO_FA_METHOD']:
        this.title = 'Select your preferred choice of authentication';
        this.body = ``;
      break;
      case this.Routes['TWO_FA_SCAN_QR']:
        this.title = 'Register Your Phone';
        this.body = `Scan the below QR Code`;
      break;
      default:
        this.title = 'Welcome Back';
        this.body = ``;
      break;
    }
  }
}
