import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { APPEAL_ACTION_TYPES, DOCUMENT_CATEGORIES } from '../../../constants/app.constants';
import { AuthConfiguration, AUTH_CONFIG, getSessionState } from '../../../platform/modules/auth';
import { AppealsApplicationsService } from '../appeals-applications.service';

@Injectable({
  providedIn: 'root'
})
export class YourAppealResolver implements Resolve<any> {

  constructor(
    @Inject(AppealsApplicationsService) public appealsService: AppealsApplicationsService,
    @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    @Inject(Store) public ngrxstore: Store,
    @Inject(AUTH_CONFIG) protected authConfig: AuthConfiguration,
  ) { }

  // Provides the loans before inital page render.
  resolve(route: ActivatedRouteSnapshot) {

    let documentCategories = new Observable((obs) => { obs.next([]); obs.complete(); })

    let _role;
    this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session) => {
      _role = session.roles[0];
    });

    if (_role === this.authConfig.roles.APPELLANT_COUNCEL.slug ||
      _role === this.authConfig.roles.APPELLANT.slug) {

        documentCategories = new Observable(
          (obs) => {
            this.appealsService.getDocumentCategories(['active_appeal','wizard'],0,100).pipe(take(1)).subscribe(
              (response) => {

                let _response = response.filter((item) => {
                  return (
                    item.slug === DOCUMENT_CATEGORIES.APPEAL.slug ||
                    item.slug === DOCUMENT_CATEGORIES.PAYROLL.slug ||
                    item.slug === DOCUMENT_CATEGORIES.TAXES.slug ||
                    item.slug === APPEAL_ACTION_TYPES.OBJECTION_TO_ADMINISTRATIVE_RECORD.slug ||
                    item.slug === APPEAL_ACTION_TYPES.SUPPLEMENTAL_APPEAL.slug ||
                    item.slug === APPEAL_ACTION_TYPES.MOTION_FOR_EXTENSION_OF_THE_CLOSE_OF_RECORD.slug ||
                    item.slug === DOCUMENT_CATEGORIES.OTHERS.slug
                  )
                } )
                _response.push((response.filter((item) => {
                  return (item.slug === DOCUMENT_CATEGORIES.OTHERS.slug);
                }))[0])
                obs.next(_response);
                obs.complete();
              })
            
          }
      )
    }

    return zip(
      this.appealsService.getAppeal(route.paramMap.get('id')),
      this.appealsService.getAppealDocuments(route.paramMap.get('id')),
      this.appealsService.getAppealDocket(route.paramMap.get('id'), 0 , this.appDefaults.ui.LIST_PAGE_LIMIT, {ordering:'-created'}),
      this.appealsService.getCOSSetting(),
      documentCategories,
      this.appealsService.getPublicNotes(route.paramMap.get('id')),
      this.appealsService.getCurrentUser()
    ).pipe(map(([
      $details,
      $documents,
      $appealDocket,
      $cosSettings,
      $documentCategories,
      $notes,
      $currentUser
    ]) => {
      return {
        details: $details,
        documents: $documents,
        appealDocket: $appealDocket,
        cosSettings: $cosSettings,
        documentCategories: $documentCategories,
        notes:$notes,
        currentUser: $currentUser
      }
    }), take(1));
  }

}