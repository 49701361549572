import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from '../../shell';
import { RESOURCES_PATHS, RESOURCES_ROOT_PATH } from './constants/resources.constants';
import { ResourcesPageResolver } from './resolvers/resources-page.resolver';
import { ResourcesPage } from './resources-page/resources-page.component';

export const RESOURCES_ROUTES = [
    {
        path:RESOURCES_ROOT_PATH,
        component:ShellComponent,
        children:[
            {
                path:RESOURCES_PATHS.RESOURCES_PAGE, 
                component:ResourcesPage,
                resolve: {"pageData" : ResourcesPageResolver}
            }
        ]
    }
]

export const resourcesRoutes: Routes = RESOURCES_ROUTES;

export const ResourcesRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(resourcesRoutes);