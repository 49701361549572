import { createSelector } from '@ngrx/store';
import { getPPPAppealWizardState, PPPAppealWizardState } from './appeal-wizard.reducer';
import { stat } from 'fs';

export const selectPPPAppealWizardStatus = createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return{
            currentStep:state.fw_current_step,
            previousStep:state.fw_previous_step,
            nextStep:state.fw_next_step,
            previousStepName:state.fw_previous_step_name,
            nextStepName:state.fw_next_step_name,
            current_loan_id:state.fw_current_loan_id,
            complete:state.fw_complete
        }
    }
);

export const selectForgivenessDocuments = ( id:string ) => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.fw_values['documents'] || [];
    }
)

export const selectPPPAppealWizardUnsavedValues = () => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.fw_unsaved_values || {};
    }
)

export const selectPPPAppealWizardErrors = () => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.reported_error || null;
    }
);

export const selectPPPAppealWizardValues = () => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.fw_values || {};
    }
);

export const selectPPPAppealWizardValue = (key:string) => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.fw_values[key];
    }
);

export const selectWizardSteps = createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.fw_steps;
    }
);

export const selectPPPAppealIsSubmitted = () => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.submittedAppeal ;
    }
);

export const selectNavigationFlag = () => createSelector(
    getPPPAppealWizardState,
    (state:PPPAppealWizardState)=>{
        return state.is_navigated_from_summary ;
    }
);