import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, resolveForwardRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FilePetition, TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig, getWindowState, WindowRef, getWindowWidth, FreezeNavigation, ThawNavigation } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { TICKETS_PAGE_NAMES } from '../constants/tickets.constants';
import { TicketsService } from '../tickets.service';
import { map, pairwise, take } from 'rxjs/operators';
import { interval, Observable, pipe, zip } from 'rxjs';
import { PageViewAnchors } from '../../../platform/modules/navigation/components/page-view-with-anchors.component';
import { AssignUsersToTicket, AssignUserToTicket, SaveAppealTicket } from '../store/ticket.actions';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { AuthConfiguration, AUTH_CONFIG, SaveAppealProfileChanges } from '../../../platform/modules/auth';
import { FormBuilder, FormControl, FormGroup, NgControlStatus, Validators } from '@angular/forms';
import { assigneeMatcher, createColumns , getDateFromUTC, traverseJsonArray} from '../../../platform/utilities/helpers';
import { DatePipe } from '@angular/common';
import { ReturnStatement } from '@angular/compiler';
import { WIZARD_FIELDS } from '../../appeal-wizard/constants/wizard_steps.constants';
import { BASE_URL } from '../../../constants/rest_api.constants';
import { titleCase,snakeCase,pascalCase,headerCase } from 'change-case';
import { saveAs } from 'file-saver';
import { Subject } from "rxjs";

import * as _ from 'lodash';
import { FileItem } from '@wkoza/ngx-upload';
import { APPEAL_ACTION_TYPES, DOCUMENT_CATEGORIES } from '../../../constants/app.constants';
import { Certificate } from 'crypto';
import { HttpErrorResponse } from '@angular/common/http';
import { selectFilePetitionDetails } from '../../../platform/modules/notification/store/notification.selector';
import { CreateReconsiderationAppeal } from '../../appeal-wizard/store/appeal-wizard.actions';

@Component({
    selector:'appeal-ticket',
    template:require('./appeal-ticket.component.html'),
})
export class AppealTicket extends PageViewAnchors implements AfterViewInit{

    @ViewChild('additionalUploadsTable') public additionalUploadsTable:any;

    public threadLabels = { message:'Note' };
    public threadLabelsQuestion = { message:'Questions' };
    public ticketDetailsFromServer:TicketSummaryDetails = null;
    public currentTicketDetails:TicketSummaryDetails = null;
    public summaryFormGroupSnapshot:any = {};
    public updatedFormGroupSnapted:any={};
    public showDocuments:boolean = false;
    public readOnly:boolean = true;
    public isAttorney:boolean = true;
    public appealStatus = null;
    public ppp_loan_forgiveness_date_display: string = null;
    public maximumMb= 100;

    public cosEnabled = false;
    public ogcEmail = "";
    public signCOSForm:FormGroup = new FormGroup({});

    public createDocFreeText: any;
    // public showFreeText:boolean = false;
    
    resetFormSubject: Subject<boolean> = new Subject<boolean>();

    public ticketDocuments = {rows:[],columns:[]};
    public ticketDraftDocuments = {rows:[],columns:[]};
    public ticketDocumentsColumnsForDisplay = [];
    public ticketSummaryInputOptions:Partial<TicketSummaryOptionsConfiguration> = {};

    public generatedDocumentSrc:string = null;

    public documentGenerationFormGroup:FormGroup = new FormGroup({});
    public generatableDocuments:any[] = [];
    public documentGenerationFields:any[] = [];
    public documentToGenerate:string = '';

    public ticketNotes:any = {};
    public ticketNotesList = [];
    public publicNotesList = [];
    public appealDocket:any = {};
    public appealDocketRows: any = [];
    public appealDocketColumns: any = [];
    public duplicateAppealRows: any = [];
    public duplicateAppealColumns: any = [];
    public appealId:string = "";
    public updatedAppealData:TicketSummaryEmission = null;
    public ticketAudit:any = {};
    public ticketAuditList = [];
    public auditColumns = [];
    public auditLength = 0;
    public auditColumnsForDisplay = [];
    public showEntireThread:boolean = false;
    public disAssigneeJudge: any = {};
    public disAssigneeAttorney: any = {};
    public selectedCount = 0;
    public enableAssignDocket = false;
    public showAssignDocket = true;
    public certificates:{
        url:string,
        privileged:boolean,
        fileNames:string[],
        signatureFormControl:string
        visible:boolean
    }[] = [];
    public showCertificates:boolean = false;
    public canSaveChanges:boolean = true;
    public visibleCert:string = null;

    public hasRecentlyUploadedDocument:boolean = false;

    public assignedDocket = [];
    
    public fields:any = WIZARD_FIELDS;
    
    public columnMode = ColumnMode;
    public SelectionType = SelectionType;
    public selectedDoc:{
        files:any[],
        reference_id:string,
        privileged:boolean
    }[] = [];
    public uploadedDoc :any =[];
    public auditCollpaseLabel: string = "View Audit Log";

    public docsPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
    public rowsPerPageOptions= this.appDefaults.ui.DOCS_PER_PAGE_OPTIONS || [{label:'10',value:"10"}];
    public showrowsperpage: boolean= true;

    public dupAppealsPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
    public dupApeealsrowsPerPageOptions= this.appDefaults.ui.ROWS_PER_PAGE_OPTIONS || [{label:'10',value:"10"}];

    public docUploadSpinner: boolean= false;

    // public visibleCertificates:any = {}

    public showAuditLog:boolean = false;
    public showInternalNotes:boolean = false;
    public showCreateDocuments:boolean = false;
    public viewInitialized:boolean = false;
    public isOGC:boolean = false;
    public baseControlsForCOS:string[] = [];
    public docSubscription;
    public docFetchInProgress:boolean = false;
    public documentAR  = "";
    public showViewAR:boolean = false;
    public adminRecordRequested:boolean = false;
    public currentOrderedBy:any = {ordering:'-created'};
	public docketPageOffset:any = 0;
    public totalDocketCount = 0;

    public isViewAREnabled = true;

    public currentUser:any={};
    public isDupReadOnly = false;
    //public allowParentJudge = false;

    public unreadMessageCount = 5;

    public uploadFn = this.uploadDocuments.bind(this);

    public documentsBucketConfiguration:BucketConfiguration = {
        title:"",
        classes:'no-border-left no-border-right no-border-bottom',
        labels:{
            drag_and_drop_valid:'You can now drop files here.',
            drag_and_drop_invalid:'You must Name your file and select a Type before dropping files here.'
        }
    }

    public statusCardConfiguration:StatusCardStatus[] = [
        { name:'New', active:false },
        { name:'Started', active:true },
        { name:'Pending Approval', active:false },
        { name:'Approved', active:false }
    ]

    public anchors:any = [
        {label:'About',id:'#about',scrollOffset:220},
        {label:'Assignees',id:'#assigneeDetails',scrollOffset:200},
        {label:'Point of Contact',id:'#pointOfContact',scrollOffset:220},
        {label:'Docket',id:'#docket',scrollOffset:200},
        {label:'Administrative Record', id: '#adminRec',scrollOffset:200},
        {label:'File Documents',id:'#additionalDocuments',scrollOffset:220}
    ];

    public originalAppealRows:any = [];
    public originalAppealColumns: any = [];
    public openNewTicket:boolean = false;

    public reconsiderationAppealRows:any = [];
    public reconsiderationAppealColumns: any = [];
    public isNotEligibleForPetition:boolean = false;
    public isDisableForPetitionOnRecon:boolean = false;
    public isNotEligibleForReconsideration: boolean = false;
    public isDayLightSavingsOn = false;
   
    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        public formBuilder:FormBuilder,
        public activatedRoute:ActivatedRoute,
        public el:ElementRef,
        public windowRef:WindowRef,
        public ngrxstore:Store<any>,
        public navigationService:NavigationService,
        public ticketsService:TicketsService,
        public datePipe:DatePipe,
        public changeDetectorRef:ChangeDetectorRef,
        public router:Router,
    ){
        // Provide PageView's dependencies
        super(
            configuration,
            el,
            windowRef,
            ngrxstore,
            navigationService
        );

        
        // Name is Inherited from PageView. It should be assigned a constant in the module's constants file
        this.Name = TICKETS_PAGE_NAMES.APPEALS_TICKET;

        this.ticketDetailsFromServer = this.activatedRoute.snapshot.data.ticket.details;
        this.currentUser = this.activatedRoute.snapshot.data.ticket.currentUser;
        this.updateCurrentTicketDetails();
        this.ticketDocuments = this.activatedRoute.snapshot.data.ticket.documents;
        this.appealDocket = this.activatedRoute.snapshot.data.ticket.appealDocket;
        this.totalDocketCount = this.appealDocket.totalCount;
        this.ticketNotes= this.activatedRoute.snapshot.data.ticket.notes;
        this.ticketNotesList = this.ticketNotes['results'];
        this.publicNotesList = this.activatedRoute.snapshot.data.ticket.publicNotes['results'];
        this.ticketAudit = this.activatedRoute.snapshot.data.ticket.audit;
        this.generatableDocuments = this.activatedRoute.snapshot.data.ticket.documentGenerationOptions;
        this.adminRecordRequested = this.ticketDetailsFromServer["administrative_record_requested"];
        
        // Check for OHA role to enable editing and saving the ticket.
        let _session = this.activatedRoute.snapshot.data.ticket.session;

        this.currentTicketDetails.typeToDisplay = this.getAppealTypeLabel(this.currentTicketDetails.type, this.currentTicketDetails.submitter);

        if(( _session.roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.ATTORNEY.slug) > -1) ){
            this.readOnly = false;
        }

        if(( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1) ){
            if(Object.keys(this.currentTicketDetails.assignees).length > 0){
                let assigneeArr =  Object.values(this.currentTicketDetails.assignees).filter((item => 
                    item.groups[0].name === this.authConfig.roles.JUDGE.name
                ));
                 if(assigneeArr.length > 0){
                    if(assigneeArr[0].reference_id != this.currentUser.reference_id){
                        this.isDupReadOnly = true;
                    }
                 }else{
                    this.isDupReadOnly = true;
                 }   
            }else{
                this.isDupReadOnly = true;
            }
        }
        if(( _session.roles.indexOf(this.authConfig.roles.ATTORNEY.slug) > -1) ){
            if(Object.keys(this.currentTicketDetails.assignees).length > 0){
                let assigneeArr =  Object.values(this.currentTicketDetails.assignees).filter((item => 
                    item.groups[0].name === this.authConfig.roles.ATTORNEY.name
                ));
                 if(assigneeArr.length > 0){
                    if(assigneeArr[0].reference_id != this.currentUser.reference_id){
                        this.isDupReadOnly = true;
                    }
                 }else{
                    this.isDupReadOnly = true;
                 }  
            }else{
                this.isDupReadOnly = true;
            }
        }

        if(( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1) ){
            if(this.currentTicketDetails.primary_contact_ogc != null){
                if(this.currentTicketDetails.primary_contact_ogc.reference_id != this.currentUser.reference_id){
                    this.isDupReadOnly = true;
                 }  
            }else{
                this.isDupReadOnly = true;
            }
        }

        if(( _session.roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.OHA.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1)){
            this.isAttorney = false;
        }

        if(( _session.roles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1) ||
            ( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1)){
            this.isOGC = true;
            this.maximumMb = 300
            if(this.adminRecordRequested){
                this.getARDocument();
                this.docFetchInProgress = true;
                this.docSubscription = interval(5000).subscribe((x =>{
                    this.getARDocument();
                }));
            }
        }else{
            this.isOGC = false;
        }


        if(( _session.roles.indexOf(authConfig.roles.OGC.slug ) < 0 &&
            _session.roles.indexOf(authConfig.roles.OGC_ADMIN.slug ) < 0 ) && !this.isDupReadOnly){
            this.anchors.push(
                {label:'Create Documents', id:'#createDocuments', scrollOffset:200} 
            );
            this.showCreateDocuments = true;
        }


        if( (_session.roles.indexOf(authConfig.roles.OHA_ADMIN.slug) > -1) ||
        (_session.roles.indexOf(authConfig.roles.JUDGE.slug) > -1) ||
        (_session.roles.indexOf(authConfig.roles.ATTORNEY.slug) > -1) ||
        (_session.roles.indexOf(authConfig.roles.OHA.slug) > -1)){
            this.anchors.push({label:'OHA Internal Notes',id:'#internalNotes',scrollOffset:220});
            this.anchors.push({label:'Appellant/SBA OGC Questions',id:'#appellantNotes',scrollOffset:220});
            this.showInternalNotes = true;
        }else{
            this.anchors.push({label:'Appellant/SBA OGC Questions',id:'#appellantNotes',scrollOffset:220});
        }

        if(this.currentTicketDetails.has_duplicate_appeals){
            this.anchors.push({label:'Duplicate/Refiled Appeals',id:'#duplicate',scrollOffset:220});
        }

        if(this.currentTicketDetails.type == appDefaults.ui.RECONSIDERATION_SLUG || this.currentTicketDetails.type == appDefaults.ui.PFR_SLUG
            || this.currentTicketDetails.type == appDefaults.ui.PFR_ON_RECONSIDERED_SLUG){
            this.anchors.push( {label:'Original Appeal',id:'#original',scrollOffset:220});
            this.getOriginalAppeal();
        }
        if((this.currentTicketDetails.type == appDefaults.ui.ORIGINAL_APPEAL_SLUG || 
            this.currentTicketDetails.type == appDefaults.ui.RECONSIDERATION_SLUG ) &&
             this.currentTicketDetails.child_tickets.length > 0){
            this.anchors.push( {label:'Reconsideration Appeal',id:'#reconsideration',scrollOffset:220});
            this.getReconsiderationAppeal();
        }

        if( _session.roles.indexOf(authConfig.roles.OHA_ADMIN.slug) > -1 ||
            _session.roles.indexOf(authConfig.roles.APPELLANT_COUNCEL.slug) > -1){
                this.anchors.push({label:'Audit Log',id:'#auditLog',scrollOffset:200});
                this.showAuditLog = true;
        }

        // if(( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1) && 
        // (this.currentTicketDetails.type ==  this.appDefaults.ui.RECONSIDERATION_SLUG ||
        //     this.currentTicketDetails.type ==  this.appDefaults.ui.PFR_SLUG)){
        //     if(this.currentTicketDetails['parent_ticket'].judge !== null){
        //         this.allowParentJudge = this.currentTicketDetails['parent_ticket'].judge.reference_id == this.currentUser.reference_id;
        //     }
        // }

        this.validatePetitionFile();
        this.validateReconFile();
        this.validatePetitionFileonRecon();

        this.cosEnabled  = this.activatedRoute.snapshot.data.ticket.settings["certificate_of_service_enabled"];
        this.ogcEmail  = this.activatedRoute.snapshot.data.ticket.settings["ogc_email"];
        
        if(this.showAuditLog){
            this.handleAuditData(4);
        }

        this.signCOSForm = this.formBuilder.group({
            docketId:[null,Validators.required],
            signature:[null,Validators.required],
            documents:[null,Validators.required]
        });

        this.documentGenerationFormGroup = this.formBuilder.group({
            type:[null,[Validators.required]]
        });

        this.documentsBucketConfiguration = Object.assign({},this.documentsBucketConfiguration,{
            categories:this.activatedRoute.snapshot.data.ticket.documentCategories
        });
        
        let windowWidthSub$ = this.ngrxstore.select(getWindowWidth()).pipe(pairwise()).subscribe((widths)=>{
            if(widths[0] !== widths[1]){
                this.refreshAnchors();
            }
        });
        
        this.refreshDocketTable();
        this.refreshDocumentsTable();
        this.updateAppealStatus();

        this.getDuplicateTable();
        
        this.navigationService.scrollToTopPostion({top:'230px'});
        
        this.storeZombies.push(
            // _getNotes$,
            windowWidthSub$
        );
            
        let documentGenerationFormChanges = this.watchDocumentGenerationFormChanges();
        this.storeZombieByKey('documentGenerationFormChanges',documentGenerationFormChanges);
    
        this.isDayLightSavingsOn = this.isDST(new Date());
    }

    isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }

    getAppealTypeLabel(type,submitter){
        let _type_to_display = "";
        switch (type.toLowerCase()){
            case 'reconsideration':
                _type_to_display = 'RECONSIDERED DECISION'; 
                break;
            case 'pfr':
                _type_to_display = 'PETITION FOR RECONSIDERATION';
                    break;
            case 'pfr-on-reconsidered':
                _type_to_display = 'PFR of the RECONSIDERED DECISION';
                break;
            case 'ppp':
                _type_to_display = 'PPP Appeal';
                break;
            default:
                _type_to_display = 'PPP Appeal';
        }
        return _type_to_display;
    }

    ngAfterViewInit(){
        this.viewInitialized = true;
    }

    watchDocumentGenerationFormChanges(){
        // return a subscription to the form group changes
        return this.documentGenerationFormGroup.controls.type.valueChanges.subscribe((typeControlValue)=>{
            this.accessDocumentFieldRequirements(typeControlValue);
        })
    }

    // resetThreadForm(){
    //     this.resetFormSubject.next(true);
    //  }

    requestToGenerateDocument($event:any){
        let _payload = {}
        let _fileName = this.documentGenerationFormGroup.controls.type.value+'_for_'+headerCase(this.currentTicketDetails.title)+'__'+headerCase(this.datePipe.transform(new Date()));
        //console.log(_fileName);
        Object.keys(this.documentGenerationFormGroup.controls).map((key)=>{
            let _type = null;
            this.documentGenerationFields.map((field)=>{
                if(field.formControlName === key){
                    _type = field.type;
                }
            });
            _payload[key] = {
                value:this.documentGenerationFormGroup.controls[key].value !== null ? this.documentGenerationFormGroup.controls[key].value : '',
                type:_type
            }
        })
        
        this.ticketsService.generateDocument(
            this.activatedRoute.snapshot.paramMap.get('id'),
            _payload
        ).subscribe((response)=>{
            saveAs(response,_fileName);
        })
    }
    
    accessDocumentFieldRequirements(rootFieldValue){

        // this.showFreeText = true;
        // this.resetThreadForm();
        let _documentsAcceptingFields = [];
        
        // Assess if the field has variables/accepts fields
        this.generatableDocuments.map((docType)=>{
            if(docType.variables.length > 0){
                _documentsAcceptingFields[docType.value] = docType.variables;
            }
            if(docType.value === rootFieldValue){
                this.documentToGenerate = docType.display_name;
            }
        });
        let _transformFormGroup = Object.keys(_documentsAcceptingFields).indexOf(rootFieldValue) > -1;
        

        if(_transformFormGroup){
            
            // Stop watching changes on the form group
            this.killTheseZombies(['documentGenerationFormChanges']);

            // Hide ( stop rendering ) the form markup to avoid runtime errors while manipulating the form group
            this.refresh('documentGenerationForm');

            this.documentGenerationFields = new Array();
            Object.keys(this.documentGenerationFormGroup.controls).map((controlName)=>{
                if(controlName !== 'type'){
                    this.documentGenerationFormGroup.removeControl(controlName);
                }
            });

            // Create an array of fields that can be rendered in the template
            let _document = _documentsAcceptingFields[rootFieldValue];
            _document.map((field)=>{
                  // Define the new field
                var descriptionArr = field.description.split('. ');
                this.documentGenerationFields.push({
                    formControlName:field.name,
                    description:descriptionArr,
                    name:field.display_name,
                    type:field.type,
                    required: field.is_required
                })

                // Add a control to the form group
                this.documentGenerationFormGroup.addControl(
                    field.name,
                    field.is_required ? new FormControl(null, Validators.required) : new FormControl(null)
                );

                if(rootFieldValue === 'decision-v2'){
                    var _ppp_loan_forgiveness_dateUTC = new Date(this.currentTicketDetails.forgiveness_application_date).toUTCString();
                    this.ppp_loan_forgiveness_date_display  = getDateFromUTC(_ppp_loan_forgiveness_dateUTC);
                    this.documentGenerationFormGroup.patchValue({
                        ppp_loan_forgiveness_date:  this.currentTicketDetails.forgiveness_application_date
                    });
                }
            });

            //console.log(this.documentGenerationFormGroup.controls)

            // Create a new subscription the th form group's changes
            let _newWatcher = this.watchDocumentGenerationFormChanges();

            // Report it to the zombie manager
            this.storeZombieByKey('documentGenerationFormChanges',_newWatcher);

        }else{

            // Stop watching changes on the form group
            this.killTheseZombies(['documentGenerationFormChanges']);

            // Hide ( stop rendering ) the form markup to avoid runtime errors while manipulating the form group
            this.refresh('documentGenerationForm');

            // Remove all controls except the 'type' control
            this.documentGenerationFields = new Array();
            Object.keys(this.documentGenerationFormGroup.controls).map((controlName)=>{
                if(controlName !== 'type'){
                    this.documentGenerationFormGroup.removeControl(controlName);
                }
            });

            // Create a new subscription the th form group's changes
            let _newWatcher = this.watchDocumentGenerationFormChanges();
            
            // Report it to the zombie manager
            this.storeZombieByKey('documentGenerationFormChanges',_newWatcher);
        }

        // Show the form markup again, Angular will render our fields now.
        this.completeRefresh('documentGenerationForm');
    }

    updateAppealStatus(action?:any){
        let _showExternalStatus = this.navigationService.CurrentRoles.indexOf(this.authConfig.roles.OGC.slug)>-1
        if(action){
            this.appealStatus = ((_showExternalStatus) ? action.name : action.name);
            this.currentTicketDetails.action.name= this.appealStatus;
        }else{
            this.appealStatus = ((_showExternalStatus) ? this.ticketDetailsFromServer.action['name'] : this.ticketDetailsFromServer.action['name']);
        }
        this.currentTicketDetails.typeToDisplay = this.getAppealTypeLabel(this.currentTicketDetails.type, this.currentTicketDetails.submitter);
    }

    getDuplicateTable(){
        let _dupData = this.currentTicketDetails['duplicate_appeals'];
        let _columnList = {
            "Appellant":['title'],
            "Status":['action','name'],
            "SBA PPP Loan Number":['loan_number'], 
            "Appeal Filed":['filing_date']
        }

        this.duplicateAppealColumns = createColumns(_columnList);
        this.duplicateAppealRows = traverseJsonArray(_columnList,_dupData);
        this.duplicateAppealRows.total = _dupData.length;
    }

    goToTicket(id:string){
        // this.ticketsService.getAppealTicket(id).pipe(take(1)).subscribe((response)=>{
        //     if(response['status_code'] == 404){
        //         this.ngrxstore.dispatch(new TriggerModal({
        //             content:{
        //               title:"Access Denied",
        //               message:"Access is denied. Appeal is not assigned to you.",
        //               approveLabel: "Ok",
        //             }
        //           }))
        //     }else{
        //         const url = this.router.serializeUrl(
        //             this.router.createUrlTree([`${this.Routes['APPEALS_TICKET']}/${id}`])
        //         );
        //         window.open(url, '_blank', '');
        //     }
        // });
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`${this.Routes['APPEALS_TICKET']}/${id}`])
        );
        window.open(url, '_blank', '');
        this.openNewTicket = false;
	}

    refreshDocketTable(){
        this.refresh('docket');
        let _datePipe = new DatePipe('en-US');
        this.appealDocketColumns = new Array();
        this.appealDocketRows = this.appealDocket.docketRows;
        //console.log('this.appealDocket',this.appealDocket)
        this.appealDocketRows.map((i) =>{
            //i.date =  _datePipe.transform(i.date,'MMMM d, y');
            i.file = BASE_URL + i.file;
        });

        // if(this.appealDocketRows.length <= this.appDefaults.ui.LIST_PAGE_LIMIT){
        //     this.showrowsperpage=false;
        // }else{
        //     this.showrowsperpage=true;
        // }

        this.appealDocket.docketColumns.map((i) => {
            if(!this.cosEnabled){
                if(i.prop !== 'file' && i.prop !== 'certificate_of_service'){
                    this.appealDocketColumns.push(i);
                }
            }else{
                if(i.prop !== 'file'){
                    this.appealDocketColumns.push(i);
                }
            }
        });

        // this.appealDocketRows = [...this.appealDocketRows];
        //this.appealDocketColumns = [...this.appealDocketColumns];

        this.completeRefresh('docket');
    }

    refreshAuditLog(limit = 1){
        this.ticketsService.getAuditTrail(this.activatedRoute.snapshot.paramMap.get('id'),limit).subscribe((response)=>{
            //console.log(response)
            this.ticketAudit = response;
            this.handleAuditData(limit);
        })
    }

    refreshDocumentsTable(){
        this.refresh('documents');
        let _datePipe = new DatePipe('en-US');
        this.ticketDocumentsColumnsForDisplay = new Array();

        
        this.ticketDocuments['columns'].map((value)=>{
            if(value.prop !== 'file'){
                this.ticketDocumentsColumnsForDisplay.push(value);
			}
        });

        
        
        this.ticketDraftDocuments = Object.assign({},{
            columns:this.ticketDocuments.columns,
            rows:this.ticketDocuments.rows.filter((document)=>{
                return (document.isMultiple) ? document.data[0].is_in_docket_filing === false : document.data.is_in_docket_filing === false ;
            })
        });
        
        this.ticketDocuments = Object.assign({},this.ticketDocuments);

        this.completeRefresh('documents');
    }

    toggleUploadsDetailRow(expanded:boolean = false,index){
        let _row = this.additionalUploadsTable.rows[index];
        if(!expanded){
            this.additionalUploadsTable.rowDetail.collapseAllRows();
            this.additionalUploadsTable.rowDetail.toggleExpandRow(_row);
        }else{
            this.additionalUploadsTable.rowDetail.collapseAllRows();
        }
    }
    
    handleAuditData(limit){
        let _datePipe = new DatePipe('en-US');
        this.ticketAuditList = [];
        this.ticketAudit['results'].map(auditLog => {
            let _log = {};
            let _changeArray = [];

            _log['timestamp'] = _datePipe.transform(auditLog.timestamp,'MMMM d, y');
            _log['user'] = (auditLog.actor != null) ? auditLog.actor.username : 'System';
            
                Object.keys(auditLog.changes_dict).map((key,index) =>{
                    let fieldname = '';
                Object.keys(this.fields).map((item) =>{
                        if(this.fields[item].storeKey === key){
                            fieldname = this.fields[item].label;
                        }
                    })

                    if(fieldname != ''){
                        _changeArray[index] = "Changed " + fieldname + " from " + auditLog.changes_dict[key][0] + " to " + auditLog.changes_dict[key][1];
                    }else {
                        _changeArray[index] = "Changed " + key + " from " + auditLog.changes_dict[key][0] + " to " + auditLog.changes_dict[key][1];
                    }
                })
           _log['changeLog'] = _changeArray;
           this.ticketAuditList.push(_log);
        });

        this.auditLength = this.ticketAudit.count;

        let _columnList = {
            "Changes":['changeLog'],
            "User":['user'],
            "Date":['timestamp']
            
        }
        this.auditColumnsForDisplay = createColumns(_columnList);

        this.ticketAuditList = traverseJsonArray(_columnList,this.ticketAuditList);

        // instead of relying on server-side pagination, 
        // get the count of total audit entries and then pull all of them if the
        // count doesn't agree with the number of audit entries pulled
        if(limit !== this.auditLength) { this.refreshAuditLog(this.auditLength) }

    }

     onBucketUpdate($event){
         this.docUploadSpinner= true;
        // console.log('onBucketUpdate: ',$event.file);
    }

    onBucketError($event){
        if($event.type === 'size_exceeded'){
            this.ngrxstore.dispatch(new TriggerToast({
                style:'danger',
                message:"That File is too Large!"
            }));
        }
        this.docUploadSpinner=false;
    }

    onBucketDeleteItem(slug:string){
        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Are you sure you want to Delete the Document?",
              approveLabel: "Delete",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                this.ticketsService.removeDocumentFromAppeal(
                    this.activatedRoute.snapshot.paramMap.get('id'),
                    slug
                ).pipe(take(1)).subscribe((response)=>{
                    this.uploadedDoc = this.uploadedDoc.filter((doc) =>{
                        return doc.reference_id != slug
                    });
                    this.documentsBucketConfiguration = Object.assign({},this.documentsBucketConfiguration,{
                        items:this.uploadedDoc
                    });
                    this.ngrxstore.dispatch( new TriggerToast(
                        {
                            message:"Removed ",
                            style:'warning'
                        }
                    ) )
                    this.refreshDocuments();
                    this.refreshDocket();
                })
            }
          }))
    }

    refreshDocuments(){
       this.refresh('documents');
        this.ticketsService.getTicketDocuments(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((documents)=>{
            this.ticketDocuments = documents;
            this.refreshDocumentsTable();
        })  
    }

    refreshDocket(){
       this.refresh('docket');
        this.ticketsService.getAppealDocket(
            this.activatedRoute.snapshot.paramMap.get('id'),this.docketPageOffset,this.docsPerPage,{ordering:'-created'}
        ).pipe(take(1)).subscribe((docketItems)=>{
            this.appealDocket = docketItems;
            this.totalDocketCount = this.appealDocket.totalCount;
            this.refreshDocketTable();
        })  
    }

    onBucketUploadSuccess(){
       this.documentsBucketConfiguration = Object.assign({},this.documentsBucketConfiguration,{
            items:this.uploadedDoc
        });
        this.hasRecentlyUploadedDocument = true;
        this.refreshDocuments();
        this.selectedDoc = [];
        this.certificates = [];
        this.showCertificates = false;
        this.signCOSForm.reset();
        this.docUploadSpinner=false;
        // this.refreshDocket();
    }

    updateCurrentTicketDetails(){
        this.currentTicketDetails = Object.assign({},this.ticketDetailsFromServer);
        let _typeLong = this.appDefaults.ui.APPEAL_TYPE_OPTIONS.filter((action)=>{
            // console.log(action,this.ticketDetailsFromServer.type)
            return action.value === this.currentTicketDetails.type;
        })
        _typeLong = (_typeLong.length > 0) ? _typeLong[0].long : null;
        
        if(!this.ticketDetailsFromServer.action){
            this.currentTicketDetails = Object.assign({},this.currentTicketDetails,{
                type_long : _typeLong,
                action: this.appDefaults.appealActions.DRAFT
            });
        }

        if(!this.ticketDetailsFromServer.lender.name){
            //console.log("this.ticketDetailsFromServer>>>>>>>>>",this.ticketDetailsFromServer);
            this.currentTicketDetails = Object.assign({},this.currentTicketDetails,{
                lender: {
                    id:this.ticketDetailsFromServer.lender,
                    name:null
                }
            });
        }

        this.currentTicketDetails = Object.assign({},this.currentTicketDetails,{
            type_long : _typeLong,
            action_slug: this.currentTicketDetails.action.slug,
        })

        this.updateAppealStatus();

    }

    toggleDocuments(){
        this.showDocuments = !this.showDocuments;
    }

    backToTickets(){
        this.navigationService.navigate([this.Routes['APPEAL_APPLICATIONS_LIST']]);
    }

    toggleEntireThread(){
        this.showEntireThread = !this.showEntireThread; 
        this.auditCollpaseLabel = this.showEntireThread ? 'Hide Audit Log' : 'View Audit Log';
    }

     uploadDocuments(packet:{filePacket?:FileItem[],fileItem?:FileItem,formGroupData:any}):Observable<any>{
        return new Observable((obs)=>{

            if(packet.filePacket){
                let _parentFile = packet.filePacket.filter((document)=>{
                    return document.file['is_parent'];
                })[0];

                let _childrenFiles = packet.filePacket.filter((document)=>{
                    return !document.file['is_parent'];
                });

                let _documentsUploaded = 0;
                this.ticketsService.uploadAppealDocument(
                    this.activatedRoute.snapshot.paramMap.get('id'),
                    _parentFile,
                    {
                        is_privileged:packet.formGroupData.is_privileged
                    }
                ).pipe(take(1)).subscribe((parent_response:{reference_id?:string})=>{
                    _documentsUploaded += 1;
                    this.uploadedDoc.push(parent_response);

                    _childrenFiles.map((document)=>{
                        this.ticketsService.uploadAppealDocument(
                            this.activatedRoute.snapshot.paramMap.get('id'),
                            document,
                            {
                                reference_id:parent_response.reference_id,
                                is_privileged:document.file['privileged']
                            }
                        ).pipe(take(1)).subscribe((response)=>{
                            _documentsUploaded += 1;
                            this.uploadedDoc.push(response);

                            if(_documentsUploaded === packet.filePacket.length){
                                let _message= '';
                                if(this.cosEnabled){
                                    _message='Your document has been uploaded, however it has not yet been filed. To file your documents, click the ‘File Selected Documents to serve upon all parties’ button." After this you are required to sign the Certificate of Service.';
                                }else{
                                    _message='Your document has been uploaded, however it has not yet been filed. To file your documents, click the ‘File Selected Documents to serve upon all parties’ button."';
                                }
                                this.ngrxstore.dispatch(new TriggerModal({
                                    content:{
                                      title:"Next Steps",
                                      message: _message,
                                    },
                                    type:'message'
                                }))
                                obs.next(true);
                                obs.complete();
                            }

                        },(err)=>{
                            if(err instanceof HttpErrorResponse){
                                 this.ngrxstore.dispatch(new TriggerModal({
                                     content:{
                                         title:"Unable to Upload",
                                         message:err.error.file[0]
                                     }
                                 }));
                                 this.docUploadSpinner=false;
                                 obs.next(false);
                                 obs.complete();

                            }
                        });
                    })
                });
            }

            if(packet.fileItem){
                this.ticketsService.uploadAppealDocument(
                    this.activatedRoute.snapshot.paramMap.get('id'),
                    packet.fileItem,
                    {
                        is_privileged:packet.formGroupData.is_privileged
                    }
                ).pipe(take(1)).subscribe((response)=>{
                    this.uploadedDoc.push(response);
                    obs.next(true);
                    obs.complete();
                },(err)=>{
                    if(err instanceof HttpErrorResponse){
                         this.ngrxstore.dispatch(new TriggerModal({
                             content:{
                                 title:"Unable to Upload",
                                 message:err.error.file[0]
                             }
                         }));
                         this.docUploadSpinner=false;
                         obs.next(false);
                         obs.complete();
                    }
                })
            }
        })
     }

    onAssignUser($event:any){
        this.ngrxstore.dispatch( new AssignUserToTicket({
            type:$event.type,
            user:$event.user,
            appealId:this.currentTicketDetails.reference_id,
            callback:(appealData)=>{
                this.ticketDetailsFromServer = {... appealData};
                this.ngrxstore.dispatch(new TriggerToast({
                    message:"Changes Saved.",
                    style:'success'
                }));
                this.updateCurrentTicketDetails();
                this.completeRefresh('about');
            }
        }))
    }
        
    onSubmitThreadContent(content){
        if(content.text == ""){
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Please provide a note to submit",
                style:'danger'
            }));
            return false;
        }
        this.refresh('notes');
        this.ticketsService.addTicketNotes(
            this.currentTicketDetails.reference_id,
            content
        ).pipe(take(1)).subscribe((res) => {
            this.ticketsService.getNotes(
                this.currentTicketDetails.reference_id
            ).pipe(take(1)).subscribe((response) => {
                this.ticketNotes = response;
                this.ticketNotesList = response.results;
               this.completeRefresh('notes');
            })
            this.ngrxstore.dispatch(new TriggerToast({
                message: "Successfully added Notes",
                style: 'success'
            }));
        }, (err) => {
            if (err) {
                this.ngrxstore.dispatch(new TriggerModal({
                    content: {
                        title: "Unable to add Notes",
                        message: "Unable to add Notes",
                    }
                }));
               this.completeRefresh('notes');
            }
        })
    }
    onSubmitAppellantThreadContent(content){
        if(content.text == ""){
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Please provide a question to submit.",
                style:'danger'
            }));
            return false;
        }
        this.refresh('appellantNotes');
        this.ticketsService.addPublicTicketNotes(
            this.currentTicketDetails.reference_id,
            content
        ).pipe(take(1)).subscribe((res) => {
            this.ticketsService.getPublicNotes(
                this.currentTicketDetails.reference_id
            ).pipe(take(1)).subscribe((response) => {
                this.publicNotesList = response["results"];
               this.completeRefresh('appellantNotes');
            })
            this.ngrxstore.dispatch(new TriggerToast({
                message: "Successfully added Question",
                style: 'success'
            }));
        }, (err) => {
            if (err) {
                this.ngrxstore.dispatch(new TriggerModal({
                    content: {
                        title: "Unable to add Question",
                        message: "Unable to add Question",
                    }
                }));
               this.completeRefresh('appellantNotes');
            }
        })
    }

    saveAppealFromSummary(payload){
        this.summaryFormGroupSnapshot = {... payload.allDetails} || this.summaryFormGroupSnapshot;
        this.updatedFormGroupSnapted = {...payload.snapshot}
        this.saveAppeal(payload.callback,payload.request_type);
    }

    saveAppellantFromSummary(payload){
        this.ngrxstore.dispatch( new SaveAppealProfileChanges(payload._data,
            (profileDataFromServer,isProfileUpdated)=>{
                
                if(isProfileUpdated){
                    this.summaryFormGroupSnapshot = Object.assign({},this.summaryFormGroupSnapshot,{
                        address_city : payload._appealSnapshot.address_city,
                        address_line_1: payload._appealSnapshot.address_line_1,
                        address_line_2: payload._appealSnapshot.address_line_2,
                        address_state: payload._appealSnapshot.address_state,
                        address_zip: payload._appealSnapshot.address_zip
                    });


                    this.ngrxstore.dispatch(
                        new SaveAppealTicket(
                            this.summaryFormGroupSnapshot, 
                            this.activatedRoute.snapshot.paramMap.get('id'),
                            (appealDataFromServer)=>{
                                console.log("appealDataFromServer>>", appealDataFromServer);
                                //appealDataFrom server does not return the lender as intended, so we will use our local state to reset
                                this.ticketDetailsFromServer = Object.assign({},
                                    appealDataFromServer
                                );
                                this.updateCurrentTicketDetails();
                                this.refreshAuditLog();
                            }
                        )
                    );
                    this.updateCurrentTicketDetails();
                    this.refreshAuditLog();
                }               
            }));

    }

    requestSaveAppeal(callback?:any){
        this.ngrxstore.dispatch(new TriggerModal({
            content: {
                title: "Are you sure you want to save the changes?",
                approveLabel: "Save",
                cancelLabel: "Cancel"
            },
            onCancel: () => {},
            onApprove: () => {
                this.saveAppeal(callback);
            }
        }))
    }

    saveAppeal(callback?:any,request_type = ''){
        this.ngrxstore.dispatch(
            new SaveAppealTicket(
                //this.summaryFormGroupSnapshot, 
                this.updatedFormGroupSnapted,
                this.activatedRoute.snapshot.paramMap.get('id'),
                (appealDataFromServer)=>{

                    //appealDataFrom server does not return the lender as intended, so we will use our local state to reset
                    this.ticketDetailsFromServer = Object.assign({},
                        appealDataFromServer
                    );
                    this.ngrxstore.dispatch(new TriggerToast({
                        message:"Changes Saved.",
                        style:'success'
                    }));
                    this.updateCurrentTicketDetails();
                    this.refreshAuditLog();
                    
                    let _changedAssignees = this.changedAssignees();
                    if(_changedAssignees.length > 0){
                        this.refresh('about');
                        this.ngrxstore.dispatch( new AssignUsersToTicket({
                            users:_changedAssignees,
                            appealId:appealDataFromServer.reference_id,
                            callback:(appealData)=>{
                                this.ticketDetailsFromServer.assignees = appealData.assignees;
                                this.completeRefresh('about');
                                this.updateCurrentTicketDetails();
                            }
                        }))
                    }

                    if(callback){
                        this.refresh('about');
                        callback(appealDataFromServer);
                    }

                },
                request_type
            )
        );
    }

    changedAssignees():any{
        let _currentAssignees: any = this.ticketDetailsFromServer.assignees;
        let _changedAssignees = [];

        let assignedJudges = [];
        let assignedAttorneys = [];
        _currentAssignees.map((assignee)=>{
            assignee.groups.map((group)=>{
                if(group.name === 'Judge'){
                    assignedJudges.push(assignee);
                }
                if(group.name === 'Attorney'){
                    assignedAttorneys.push(assignee);
                }
            });
        });

        // console.log('Judges: ',assignedJudges[0],' => ',this.summaryFormGroupSnapshot.judge_id.value)
        if(assignedJudges[0]){
            if(this.summaryFormGroupSnapshot.judge_id.value !== assignedJudges[0].reference_id){
                _changedAssignees.push(this.summaryFormGroupSnapshot.judge_id.value)
            }
        }
        
        // console.log('Attroneys: ',assignedAttorneys[0],' => ',this.summaryFormGroupSnapshot.attorney_id.value)
        if(assignedAttorneys[0]){
            if(this.summaryFormGroupSnapshot.attorney_id.value !== assignedAttorneys[0].reference_id){
                _changedAssignees.push(this.summaryFormGroupSnapshot.attorney_id.value)
            }
        }

        return _changedAssignees;
    }

    invitationSent(isInviteSent){
        if(isInviteSent){
            this.refreshAuditLog();
        }
    }

    onSummaryValueChange(ticketSummaryFormControls:any) {  
        let _invalidFields = Object.keys(ticketSummaryFormControls).filter((controlKey)=>{
            return !ticketSummaryFormControls[controlKey].valid
        });

        this.canSaveChanges = _invalidFields.length < 1;
    
        this.currentTicketDetails.title = ticketSummaryFormControls.title.value;
        this.currentTicketDetails.action = Object.assign({},this.currentTicketDetails.action,{
            slug:ticketSummaryFormControls.action.value
        });

        this.summaryFormGroupSnapshot = Object.assign({},ticketSummaryFormControls,{
            lender:{
                value:ticketSummaryFormControls['lender_id'].value,
                valid:ticketSummaryFormControls['lender_id'].value !== null
            }
        });
        
        Object.keys(this.summaryFormGroupSnapshot).map((control:any)=>{
            if(!this.summaryFormGroupSnapshot[control].hasOwnProperty('value')){
                this.summaryFormGroupSnapshot[control] = {
                    value:this.summaryFormGroupSnapshot[control],
                    valid:true
                }
            }
        });

    }

    selectDocumentForFiling( selected, row ) {
        console.log(row)
        let _addSelected = selected.target.checked;
        let _selectedUID = row.reference_id;
        let _noMatches = this.selectedDoc.filter((doc)=>{
            return doc.reference_id === _selectedUID;
        }).length < 1;

        console.log(_noMatches);
        
        let _files = Array.isArray(row.data) ? row.data : [row.data];
        
        if(_addSelected){
            if(_noMatches){
                this.selectedDoc.push({
                    files:_files,
                    privileged:_files.filter((file)=>{return file.is_privileged}).length > 0,
                    reference_id:_selectedUID
                });
            }
        }else{
            if(!_noMatches){
                this.selectedDoc = this.selectedDoc.filter((doc)=>{
                    return doc.reference_id !== _selectedUID;
                });
            }
        }
        
        this.enableAssignDocket = Object.keys(this.selectedDoc).length > 0;
        console.log(this.selectedDoc);

        let _docs = []; 
        this.selectedDoc.map((selectedDoc)=>{
            _docs.push(selectedDoc.files);
        });
        _docs = [].concat.apply([],_docs);

        console.log(_docs);
        this.signCOSForm.patchValue({
            documents:_docs.map((doc)=>{
                return doc.reference_id
            })
        });
    }

    toggleCertificate(certificate:any){
        this.visibleCert = certificate.url;
        // this.visibleCertificates[certificate.url] = !this.visibleCertificates[certificate.url];
        // this.visibleCertificates = Object.assign({},this.visibleCertificates);
    }

    createSignatureControls(controls:string[]){
        
        Object.keys(this.signCOSForm.controls).map((controlName)=>{
            if(this.baseControlsForCOS.indexOf(controlName)>-1){
                this.signCOSForm.removeControl(controlName);
            }
        });
     
        controls.map((name)=>{
            this.signCOSForm.addControl(
                name,new FormControl('',Validators.required)
            );
        });
    }

    generateCertificate(){

        let _allDocuments = []; 
        this.selectedDoc.map((packet)=>{
            _allDocuments.push(packet.files);
        });
        let _documents = [].concat.apply([],_allDocuments);
        let _privateItems = _documents.filter((document)=>{
            return document.is_privileged;
        }); 
        let _otherItems = _documents.filter((document)=>{
            return !document.is_privileged;
        }); 

        this.freeze('attachToDocketButton');
        this.ticketsService.generateCertificate(
            this.activatedRoute.snapshot.paramMap.get('id'),
            _privateItems.length > 0
        ).pipe(take(1)).subscribe((response)=>{

            this.certificates = [];
            this.showCertificates = ( response['url'] !== null );
            
            let _urlDict = {};
            if(this.showCertificates){
                _urlDict['otherSignature'] = {
                    url:BASE_URL+response['url'],
                    privileged:false,
                    documentNames:_otherItems.map((item)=>{
                        return item.filename
                    })
                }  
            }

            if(response['privileged_url'] !== null){
                _urlDict['privateSignature'] = {
                    url:BASE_URL+response['privileged_url'],
                    privileged:true,
                    documentNames:_privateItems.map((item)=>{
                        return item.filename
                    })
                }
            }

            Object.keys(_urlDict).map((controlName)=>{
                this.certificates.push({
                    url:_urlDict[controlName].url,
                    privileged:_urlDict[controlName].privileged,
                    fileNames:_urlDict[controlName].documentNames,
                    signatureFormControl:controlName,
                    visible:false
                });
                this.certificates = [... this.certificates];
            });

            this.signCOSForm.patchValue({
                docketId:response.reference_id
            });

            this.thaw('attachToDocketButton');
        },(err)=>{
            this.certificates = [];
        })
    }

    confirmFile(){
        let _message='';
        if(this.isOGC){
            _message= "Please confirm you want to file with OHA the uploaded documents.";
        }else{
            _message= "Please confirm the accuracy and completion of the document(s) you are filing with OHA before final submission.";
        }
        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Next Steps",
              message: _message,
              approveLabel: "File Selected Documents",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{
                
            },
            onApprove:()=>{
                if(this.cosEnabled){
                    this.generateCertificate();
                }
                else {
                    this.signWithoutCertificateOfService();
                }
            },}));
    }


    signWithoutCertificateOfService(){
        
        this.freeze('signCOSButton');
        let _allDocuments= [];
        this.selectedDoc.map((packet)=>{
            _allDocuments.push(packet.reference_id);
        });
        this.ticketsService.attachDocumentsWithoutCOS(
            this.activatedRoute.snapshot.paramMap.get('id'),
            {"documents": _allDocuments},
        ).pipe(take(1)).subscribe((response)=>{
            this.refreshDocuments();
            this.refreshDocket();
            this.refreshAppeal();
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Successfully Attached Document.",
                style:'success'
            }));
            this.thaw('signCOSButton');
            this.selectedDoc = [];
        },((err) =>{
            if(err){
                this.ngrxstore.dispatch(new TriggerToast({
                    message:err.error['detail'],
                    style:'danger'
                }));
                this.thaw('signCOSButton');
            }
        }))
    }

    refreshAppeal(){
        this.ticketsService.getAppeal(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((res)=>{
            // this.currentTicketDetails.is_decision_filed = res['is_decision_filed'];
            this.currentTicketDetails.is_contest_eligible = res["is_contest_eligible"];
            this.currentTicketDetails.has_user_filed_pfr_appeal = res["has_user_filed_pfr_appeal"];
            this.currentTicketDetails.has_user_filed_pfr_on_reconsidered_appeal = res["has_user_filed_pfr_on_reconsidered_appeal"];
            this.currentTicketDetails.has_user_filed_reconsideration_appeal = res["has_user_filed_reconsideration_appeal"];
            this.currentTicketDetails.is_contest_eligibility_deadline_lapsed = res["is_contest_eligibility_deadline_lapsed"];
            
            this.validatePetitionFile();
            this.validateReconFile();
            this.validatePetitionFileonRecon();
        })
    }

    signCertificateOfService(){
        this.freeze('signCOSButton');
        this.ticketsService.signCOSAndAttachDocuments(
            this.activatedRoute.snapshot.paramMap.get('id'),
            {
                docketId:this.signCOSForm.controls['docketId'].value,
                documents:this.signCOSForm.controls['documents'].value,
                signature:this.signCOSForm.controls['signature'].value
            }
        ).pipe(take(1)).subscribe((response)=>{
            this.refreshDocuments();
            this.refreshDocket();
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Successfully Filed Document with OHA and served upon Appellant",
                style:'success'
            }));
            this.thaw('signCOSButton');
            this.certificates = [];
            this.selectedDoc = [];
            this.showCertificates = false;
            this.signCOSForm.patchValue({
                signature:null
            }); 
        },((err) =>{
            if(err){
                this.ngrxstore.dispatch(new TriggerToast({
                    message:err.error['detail'],
                    style:'danger'
                }));
                this.thaw('signCOSButton');
            }
        }))
    }

    onCreateDocEdit(content){
        this.createDocFreeText = content;
    }

    updateDocumentsPerPage($event){
        this.docsPerPage=$event.target.value;
        this.completeRefresh('documents');
        this.updateRows({
			offset:this.docketPageOffset
		});
    }

    getViewDocument(url){
        this.ticketsService.getDocketAsset(url).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    getViewUploadedDocument(ref_id){
        this.ticketsService.getUploadedAsset(
            this.activatedRoute.snapshot.paramMap.get('id'),
            ref_id
        ).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    fetchARDoc(){
        this.isViewAREnabled = false;
        this.ticketsService.initiateARDocument(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((response)=>{
            if(response["status_code"] === 400){
                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                      title:"Unable to Fetch Administrative Record",
                      message: response["ticket"]
                    }
                  }))
                  this.isViewAREnabled = true;

            }else{
                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                      title:"Administrative Record",
                      message: 'Administrative Record generation is in progress.It will take about 15-30 minutes to generate.Please check back after some time.',
                    }
                  }))
                  this.isViewAREnabled = true;
            }
            
            if((response["status_code"] === 400) || (response["status_code"] === 404)){
                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                      title:"Unable to Fetch Administrative Record",
                      message: response["ticket"]
                    }
                  }))
                  this.docFetchInProgress = false;
                  this.isViewAREnabled = true;
            }else if(response["record"] === null){
                this.getARDocument();
                this.docFetchInProgress = true;
                this.docSubscription = interval(5000).subscribe((x =>{
                    this.getARDocument();
                }));
                this.isViewAREnabled = false;
            }else{
                this.documentAR = response['record_url'];
                this.showViewAR = true;
                this.isViewAREnabled = true;
            }
        },((err) =>{
            if(err){
                this.ngrxstore.dispatch(new TriggerToast({
                    message:err.error['detail'],
                    style:'danger'
                }));
            }
        }))

    }

    getARDocument(){
        this.ticketsService.fetchARDocument(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((response)=>{
            console.log("response  > ", response);

            if(response["record"] !== null && response['record_url'] !== null){
                this.docSubscription.unsubscribe();
                this.documentAR = response['record_url'];
                this.showViewAR = true;
                this.docFetchInProgress = false;
            }
            
        },((err) =>{
            
        }))
    }

    viewARDoc(){
        this.ticketsService.viewARAsset( this.activatedRoute.snapshot.paramMap.get('id')).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    ngOnDestroy(){
        if(this.docSubscription){
            this.docSubscription.unsubscribe();
            this.docFetchInProgress = false;
        }
        
    }
    
    onSort(event) {
		this.ngrxstore.dispatch( new FreezeNavigation() );

		let _orderBy = {};
		

		let _columnDict = {
			"document":"filename",
			"document_type":"category",
			"date":'created',
			"submitted_by":'created_by__name'
		}

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === event.sorts[0].prop
		})

		if(event.sorts.length > 0){
			switch(event.sorts[0].dir){
				case 'asc':
					_orderBy =  {ordering: _columnDict[_serverColumn[0]]};
				break;
				case 'desc':
					_orderBy =  {ordering:"-" +  _columnDict[_serverColumn[0]]};
				break;
				default:
				break;					
			}
		}

		
        this.ticketsService.getAppealDocket( this.activatedRoute.snapshot.paramMap.get('id'),this.docketPageOffset,
        this.docsPerPage,_orderBy ).pipe(take(1)).subscribe((response)=>{
                //console.log(response);
                this.appealDocket = response;
                this.currentOrderedBy = _orderBy;
                this.refreshDocketTable()
                this.ngrxstore.dispatch( new ThawNavigation() );
        })
	}

    updateRows(pageInfo) {
		this.ngrxstore.dispatch( new FreezeNavigation() );

		let _offsetValue = 0;

		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.docsPerPage;
		}
        if(this.totalDocketCount  < _offsetValue){
            _offsetValue = 0;
        }
       
        this.ticketsService.getAppealDocket( this.activatedRoute.snapshot.paramMap.get('id'),_offsetValue,
        this.docsPerPage,this.currentOrderedBy ).pipe(take(1)).subscribe((response)=>{
                //console.log(response);
                this.appealDocket = response;
                this.docketPageOffset = _offsetValue != 0 ? pageInfo.offset : 0 ;
                this.refreshDocketTable()
                this.ngrxstore.dispatch( new ThawNavigation() );
        })
	}

    openPetitionModel(type){
        this.ngrxstore.dispatch(new TriggerModal({
            type:'petition-input',
            content: {
                title: "File Petition for Reconsideration",
                message: "OHA cannot modify the deadline to file a petition for reconsideration. 13 CFR § 134.1202(c)(3)(i)(A).",
                checkboxItems:[
                    {
                        label:"Upload a document (pleading) that explains a clear error of fact or law in the initial decision"
                    }
                ],
                is_ogc: true,
            },
            onCancel:()=>{
                this.ngrxstore.dispatch(new FilePetition({file:null , signature:''}))
            },
            onApprove:()=>{
                this.ngrxstore.select(selectFilePetitionDetails()).pipe(take(1)).subscribe((response)=>{
                    let _uploaded_file = response['uploaded_file'];
                    let _signature = response['signature'];
                    this.openNewTicket = true;
                    this.ngrxstore.dispatch( new CreateReconsiderationAppeal({
                        type:type,
                        parent_ticket:this.activatedRoute.snapshot.paramMap.get('id'),
                        signature_for_electronic_filing:_signature,
                        lender:this.currentTicketDetails.lender.id,
                        title: this.currentTicketDetails.title,
                        action: {
                            "slug": "Draft",
                            "display_name": "Draft"
                        },
                        submitter:this.currentUser.reference_id,
                        callback:(response)=>{
                            if(response.hasOwnProperty('error')){
                                this.openNewTicket = false;
                            } else {
                                if(_uploaded_file != null) {
                                    this.uploadPetitionDocument(response,_uploaded_file);
                                }else{
                                    this.ticketsService.getAppeal(
                                        this.activatedRoute.snapshot.paramMap.get('id')
                                    ).pipe(take(1)).subscribe((res)=>{
                                        this.currentTicketDetails.has_user_filed_pfr_appeal = res["has_user_filed_pfr_appeal"];
                                        this.currentTicketDetails.has_user_filed_reconsideration_appeal = res["has_user_filed_reconsideration_appeal"];
                                        this.currentTicketDetails.has_user_filed_pfr_on_reconsidered_appeal = res["has_user_filed_pfr_on_reconsidered_appeal"];
                                        this.currentTicketDetails.is_contest_eligibility_deadline_lapsed = res["is_contest_eligibility_deadline_lapsed"];
                                        
                                        this.validatePetitionFile();
                                        this.validateReconFile();
                                        this.validatePetitionFileonRecon();
                                        this.goToTicket(response.reference_id);
                                    })  
                                }
                            }
                        }
                    })
                    )
                });
            }
        }));
    }

    uploadPetitionDocument(reconDetail,fileItem){
        this.ticketsService.uploadPetitionDocument(
            reconDetail.reference_id,fileItem
        ).pipe(take(1)).subscribe((res1)=>{
            this.ticketsService.getUploadedDocuments(
				reconDetail.reference_id
			).subscribe((res2) => {
                let _document = [];
				_document.push(res2[0]["documents"][0].reference_id) //to be updated if backend updates response
				// _document.push(res1["documents"][0].reference_id)
                this.ticketsService.attachDocumentsWithoutCOS(
                    reconDetail.reference_id,
                    {"documents": _document},
                ).pipe(take(1)).subscribe((response)=>{
                    this.ticketsService.getAppeal(
                        this.activatedRoute.snapshot.paramMap.get('id')
                    ).pipe(take(1)).subscribe((res)=>{
                        this.currentTicketDetails.has_user_filed_pfr_appeal = res["has_user_filed_pfr_appeal"];
                        this.currentTicketDetails.has_user_filed_reconsideration_appeal = res["has_user_filed_reconsideration_appeal"];
                        this.currentTicketDetails.has_user_filed_pfr_on_reconsidered_appeal = res["has_user_filed_pfr_on_reconsidered_appeal"];
                        this.currentTicketDetails.is_contest_eligibility_deadline_lapsed = res["is_contest_eligibility_deadline_lapsed"];
                        this.validatePetitionFile();
                        this.validateReconFile();
                        this.validatePetitionFileonRecon();
                        this.goToTicket(reconDetail.reference_id);
                    }) 
                },((err) =>{
                    if(err){
                        this.ngrxstore.dispatch(new TriggerToast({
                            message:err.error['detail'],
                            style:'danger'
                        }));
                    }
                    this.openNewTicket = false;
                }))
            })
        },(err)=>{
            if(err instanceof HttpErrorResponse){
                 this.ngrxstore.dispatch(new TriggerModal({
                     content:{
                         title:"Unable to Upload",
                         message:err.error.file[0]
                     }
                 }));
            }
            this.openNewTicket = false;
        });
    }

    getOriginalAppeal(){
        let _originalAppealData = [];
        if(_originalAppealData.length == 0){
            _originalAppealData.push(this.currentTicketDetails['parent_ticket']) ;
        }
        let _columnList = {
            "Appellant":['title'],
            "Status":['action','name'],
            "SBA PPP Loan Number":['loan_number'], 
            "Appeal Filed":['filing_date']
        }
    
        this.originalAppealColumns = createColumns(_columnList);
        this.originalAppealRows = traverseJsonArray(_columnList,_originalAppealData);
        this.originalAppealRows.total = _originalAppealData.length;
    }

    getReconsiderationAppeal(){
        let _reconAppealData = this.currentTicketDetails['child_tickets'];
        let _columnList = {
            "Appellant":['title'],
            "Status":['action','name'],
            "SBA PPP Loan Number":['loan_number'], 
            "Appeal Filed":['filing_date'],
            "Reconsideration":['type'],
            "Filed By":["submitter",'name']
        }
    
        this.reconsiderationAppealColumns = createColumns(_columnList);
        this.reconsiderationAppealRows = traverseJsonArray(_columnList,_reconAppealData);
        this.reconsiderationAppealRows.total = _reconAppealData.length;
    }

    createReconAppeal(type, is_ogc){
        let title = "";
        if(is_ogc){
            if(type === 'pfr-on-reconsidered'){
                title = 'Are you sure you want to file a Petition for Reconsideration on Reconsidered Decision?'
            }else {
                title = 'Are you sure you want to file a Petition for Reconsideration?'
            }
        }else {
            title = 'Are you sure you want to submit a Reconsidered Decision?'
        }

        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:title,
              approveLabel: "Confirm",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                this.openNewTicket = true;
                this.ngrxstore.dispatch( new CreateReconsiderationAppeal({
                    type:type,
                    parent_ticket:this.activatedRoute.snapshot.paramMap.get('id'),
                    lender:this.currentTicketDetails.lender.id,
                    title: this.currentTicketDetails.title,
                    action: {
                        "slug": "Draft",
                        "display_name": "Draft"
                    },
                    submitter:this.currentUser.reference_id,
                    callback:(response)=>{
                        if(response.hasOwnProperty('error')){
                            this.openNewTicket = false;
                        } else {
                            this.ticketsService.getAppeal(
                                this.activatedRoute.snapshot.paramMap.get('id')
                            ).pipe(take(1)).subscribe((res)=>{
                                this.currentTicketDetails.has_user_filed_pfr_appeal = res["has_user_filed_pfr_appeal"];
                                this.currentTicketDetails.has_user_filed_pfr_on_reconsidered_appeal = res["has_user_filed_pfr_on_reconsidered_appeal"];
                                this.currentTicketDetails.has_user_filed_reconsideration_appeal = res["has_user_filed_reconsideration_appeal"];
                                this.currentTicketDetails.is_contest_eligibility_deadline_lapsed = res["is_contest_eligibility_deadline_lapsed"];
                                this.validatePetitionFile();
                                this.validateReconFile();
                                this.validatePetitionFileonRecon();
                                this.goToTicket(response.reference_id);
                            })  
                        }
                    }
                }))
            }
          }))
       
    }

    validatePetitionFile(){
        let _session = this.activatedRoute.snapshot.data.ticket.session;
        let has_user_filed_pfr = false;
        if(( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1) ){
            has_user_filed_pfr = this.currentTicketDetails.has_user_filed_pfr_appeal["primary_contact_ogc"];
        }
        this.isNotEligibleForPetition = has_user_filed_pfr;
    }

    validateReconFile(){
        let _session = this.activatedRoute.snapshot.data.ticket.session;
        let has_user_filed_reconsideration= false;
       
        if(( _session.roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1) ){
            has_user_filed_reconsideration = false;
        }else{
            let _roleDict = {
                "judge":"oha_team",
                "ogc":"primary_contact_ogc",
                "attorney":'oha_team',
            }
            let _rolefilter = Object.keys(this.currentTicketDetails.has_user_filed_reconsideration_appeal).filter(
                item =>  item == _roleDict[_session.roles[0]] 
            )[0];
            has_user_filed_reconsideration = this.currentTicketDetails.has_user_filed_reconsideration_appeal[_rolefilter];
           
        }
        this.isNotEligibleForReconsideration = has_user_filed_reconsideration;
    }

    validatePetitionFileonRecon(){
        let _session = this.activatedRoute.snapshot.data.ticket.session;
        let has_user_filed_pfr_on_reconsidered_appeal = false;
        if(( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1) ){
            has_user_filed_pfr_on_reconsidered_appeal = this.currentTicketDetails.has_user_filed_pfr_on_reconsidered_appeal["primary_contact_ogc"];
        }
        this.isDisableForPetitionOnRecon = has_user_filed_pfr_on_reconsidered_appeal;
    }
}