import { ActionReducer, MetaReducer } from '@ngrx/store';
import { ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

// App States
import * as shellReducer from '../shell/store/shell.reducer';

// States Imported from Modules
import { AuthReducer, AuthState } from '../platform/modules/auth';
import { NotificationReducer, NotificationState } from '../platform/modules/notification';
import { NavigationReducer, NavigationState } from '../platform/modules/navigation';
import { PPPAppealWizardState, PPPAppealWizardReducer } from '../features/appeal-wizard/store/appeal-wizard.reducer';
import { AppealApplicationReducer, AppealApplicationState } from '../features/appeals-applications/store/reducers/appeal-applications.reducer';


export interface State {
  shell: shellReducer.ShellState,
  auth: AuthState,
  notifications: NotificationState,
  navigation: NavigationState,
  appeal_wizard:PPPAppealWizardState,
  appeal_applications:AppealApplicationState
}

export const reducers: ActionReducerMap<State> = {
  shell: shellReducer.reducer,
  auth: AuthReducer,
  notifications: NotificationReducer,
  navigation: NavigationReducer,
  appeal_wizard:PPPAppealWizardReducer,
  appeal_applications:AppealApplicationReducer
}


// Local Storage Configuration
// We can be more selective about what we retain in local storage.
function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'shell',
      'auth',
      'notifications',
      'navigation',
      'appeal_wizard',
      'appeal_applications'
    ]
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
