import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { ModalComponent } from './modals/modal.component';
import { ToastComponent } from './toasts/toast.component';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification.service';
import { A11yModule } from '@angular/cdk/a11y';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './store/notification.effects';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    A11yModule,
    SharedModule.forRoot(),
    EffectsModule.forFeature([
      NotificationEffects
    ])
  ],
  providers:[
    NotificationService
  ],
  declarations: [
    ToastComponent,
    ModalComponent
  ],
  entryComponents: [ModalComponent],
  exports:[
    ToastComponent,
    ModalComponent
  ]
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [ NotificationService ]
    };
  }
}
