import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule} from '@ngrx/store';
import { QRCodeModule } from 'angularx-qrcode';

import { AuthService } from './auth.service';

import { AccessGatewayComponent } from './access_gateway/access-gateway.component';
import { AuthRoutingModule } from './auth-routing.module';

import { AuthConfiguration, AUTH_CONFIG } from './auth.interface';

import { TokenInterceptor } from './token.interceptor.ts';
import { SharedModule } from '../shared';
import { RedirectGuard } from './redirectGuard/redirect-guard.service';
import { AuthEffects } from '../../../store/effects/auth.effects';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    QRCodeModule,
    SharedModule
  ],
  providers:[
    {
      provide:AUTH_CONFIG,
      useValue:{
        api_url:'http://www.pokeapi.com',
        permissions:{}
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    },
    AuthService,
    RedirectGuard
  ],
  declarations: [
    AccessGatewayComponent
  ],
  exports:[
    CommonModule,
    AccessGatewayComponent
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [ AuthService ]
    };
  }
}
