import { Action } from '@ngrx/store';
import { FileItem } from '@wkoza/ngx-upload';
import { NOTIFICATION_ACTION_CONSTANTS } from '../constants/notification.constants';

export class TriggerModal implements Action {
  readonly type = NOTIFICATION_ACTION_CONSTANTS.TRIGGER_MODAL;
  constructor(public configuration:ModalConfiguration){}
}

export class ApproveModalAction implements Action {
  readonly type = NOTIFICATION_ACTION_CONSTANTS.APPROVE_MODAL_ACTION;
  constructor(){}
}

export class RejectModalAction implements Action {
  readonly type = NOTIFICATION_ACTION_CONSTANTS.REJECT_MODAL_ACTION;
  constructor(){}
}

export class CancelModalAction implements Action {
  readonly type = NOTIFICATION_ACTION_CONSTANTS.CANCEL_MODAL_ACTION;
  constructor(){}
}

export class TriggerToast implements Action {
  readonly type = NOTIFICATION_ACTION_CONSTANTS.TRIGGER_TOAST;
  constructor(public payload:ToastConfiguration){}
}

export class DismissToasts implements Action{
  readonly type = NOTIFICATION_ACTION_CONSTANTS.DISMISS_TOASTS;
  constructor(){}
}

export class FilePetition implements Action{
  readonly type = NOTIFICATION_ACTION_CONSTANTS.FILE_PETITION;
  constructor(public payload:{
    file?:FileItem,
    text?:string,
    signature:string
  }){}
}
