import { Inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../../app.interface';

@Injectable({
    providedIn:'root'
})
 export class HealthService{
     constructor(
         public http:HttpClient,
         @Inject(API_CONFIG) public api:ApiConfig,){

     }
}
