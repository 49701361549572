import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { Component, Inject, ChangeDetectorRef, MissingTranslationStrategy, OnInit, ElementRef, DoCheck } from '@angular/core';
import { PageView, NavigationService, NAVIGATION_CONFIG, NavigationConfig, getWindowState } from '../../../platform/modules/navigation';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { Store } from '@ngrx/store';
import { AppealSetup, WipeAppealStoreValues, AppealJumpToStep, SaveAppeal, StoreAppealValues, RecordUnsavedValueKeys } from '../store/appeal-wizard.actions';
import { selectPPPAppealWizardStatus, selectPPPAppealWizardValues, selectPPPAppealWizardValue, selectPPPAppealIsSubmitted, selectPPPAppealWizardUnsavedValues } from '../store/appeal-wizard.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { take, distinctUntilChanged, skipWhile } from 'rxjs/operators';
import { timeStamp } from 'console';
import { stat } from 'fs';
import * as _ from 'lodash';
import { PageScrollService } from 'ngx-page-scroll-core';
import { getPPPAppealWizardState } from '../store/appeal-wizard.reducer';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { DatePipe } from '@angular/common';
import { SaveProfileChanges } from '../../../platform/modules/auth';

@Component({
    selector:'appeal-wizard',
    template:require('./wizard.component.html'),
})
export class PPPAppealWizard extends KillZombies(PageView){

    public appealType = "File a PPP Appeal";
    public readOnly = false; 
    public wizardBarActionLabel = '';
    public $onClose:BehaviorSubject<any> = new BehaviorSubject([]);
    public appealCanBeUpdated:boolean = false;
    public appealShouldBeSaved:boolean = false;
    public stepsInOrder:any[] = [];
    public currentStepIndex:number = 0;
    public referenceId = null;
    public appealSubmitted:boolean = false;
    public appealDataFromServer:any = {};
    
    public currentStep:string = null;
    public previousStep:string = null;
    public nextStepName:string = null;
    public previousStepName:string = null;
    public nextStep:string = null;
    public missingFields:any[];
    public excludedStorageValues:any = [];
    public nestedStorageValues:any = {};
    
    public wizardFields = null;
    public wizardSteps = null;
    public wizardValues:any = {};
    public wizardTabs:any[] = [];
    public postWizardTab:any = null;
    public appealUploadedDocs = [];
    public unsavedValues:any = [];

    public steps:any = [];
    public stepNames:any = [];
    public isAppealSubmitted:boolean = false;

    public disableButtonWhileSave:boolean = false;

    public $values:Subscription = null;
    public $status:Subscription = null;

    public validForm : boolean = false;

    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        public navigationService:NavigationService,
        public activatedRoute:ActivatedRoute,
        public ngrxstore:Store,
        public changeDetectorRef:ChangeDetectorRef,
        public pageScrollService:PageScrollService,
        public ele:ElementRef,
        public router:Router,
        public appealsWizardService:PPPAppealWizardService){

        super(configuration,navigationService);

        this.wizardBarActionLabel = 'Save Application';
        this.wizardFields = appealsWizardService.WizardFields;
        this.wizardSteps = appealsWizardService.WizardSteps;
        this.referenceId = this.activatedRoute.snapshot.paramMap.get('id');
        this.appealSubmitted = ( this.activatedRoute.snapshot.data.wizard.action !== 6 );
        this.appealDataFromServer = this.activatedRoute.snapshot.data.wizard.data;
        this.appealUploadedDocs = this.activatedRoute.snapshot.data.wizard.appealDocs;
        this.unsavedValues = [];

        this.excludedStorageValues = [
            "action",
            "principals",
            "assignees",
            "docket_filings",
            "notes",
            "modified"
        ];

        this.nestedStorageValues = {
            [this.wizardFields['ASYA_BORROWER_INDUSTRY'].storeKey]:'description'
        }

        this.appealDataFromServer ={...this.appealDataFromServer,
            [this.wizardFields['LI_LOAN_AMOUNT'].storeKey] : this.appealDataFromServer[this.wizardFields['LI_LOAN_AMOUNT'].storeKey]/100,
            [this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey] : this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey]/100,
            [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] : this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey]/100,
            [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] : this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey]/100
        }

        if(this.appealUploadedDocs.length > 0){
            let _appealDocuments = [];
           // let _taxDocuments = [];
            let _reviewDocuments = [];
   
           this.appealUploadedDocs.map((item)=>{
               if (item.category === this.appDefaults.documentCategories.APPEAL.slug) {
                   _appealDocuments.push(item);
               }
            //    if(item.category === this.appDefaults.documentCategories.TAXES.slug) {
            //        _taxDocuments.push(item);
            //    }
               if(item.category === this.appDefaults.documentCategories.REVIEW.slug){
                   _reviewDocuments.push(item);
               }
           })
   
           this.ngrxstore.dispatch( new StoreAppealValues({
               [this.wizardFields.APPEAL_DOCUMENT_SUBMITTED_1.storeKey]:_appealDocuments.length > 0,
            //    [this.wizardFields.APPEAL_DOCUMENT_SUBMITTED_3.storeKey]:_taxDocuments.length > 0,
               [this.wizardFields.APPEAL_DOCUMENT_SUBMITTED_2.storeKey]:_reviewDocuments.length > 0
           }));
        }

        // If the resolver returns data with a reference_id, store the values in fw_values
        //console.log(this.appealDataFromServer, this.activatedRoute.snapshot.paramMap.get('id'))
        if(this.appealDataFromServer.reference_id){
            this.checkWizardValuesAgainstSource().pipe(take(1)).subscribe((updated)=>{
                this.ngrxstore.dispatch( new RecordUnsavedValueKeys(updated.changed) );
                this.ngrxstore.dispatch( new StoreAppealValues(Object.assign({},{
                    [this.wizardFields['POC_USER_NAME'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.username,
                    [this.wizardFields['POC_NAME'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.name,
                    [this.wizardFields['POC_EMAIL'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.email,
                    [this.wizardFields['POC_ROLE'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.role },
                    updated.values
                )));
                this.subscribeToValues()
            })
        }else{
            //else, wipe out fw_values
            this.ngrxstore.dispatch( new StoreAppealValues({
                [this.wizardFields['POC_USER_NAME'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.username,
                [this.wizardFields['POC_NAME'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.name,
                [this.wizardFields['POC_EMAIL'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.email,
                [this.wizardFields['POC_ROLE'].storeKey]:this.activatedRoute.snapshot.data.wizard.user.role,
                action:this.appDefaults.appealActions.DRAFT
            }));
            this.subscribeToValues()
        }

        
        this.setWizard();
    }
    
    subscribeToValues(){
        let _unsavedChangesSub = this.ngrxstore.select( selectPPPAppealWizardUnsavedValues() ).subscribe((unsavedValues)=>{
            this.unsavedValues = unsavedValues;
        })
        //  This will execute once we StoreAppealValues in the proceeding conditinal
        let _valuesSub = this.ngrxstore.select(selectPPPAppealWizardValues()).subscribe((values)=>{
            this.wizardValues = values;
            this.checkWizardStatus(values);
            this.appealsWizardService.trackMissingFields(values);
            this.completeRefresh('wizardSlide');
            this.checkWizardValuesAgainstSource().pipe(take(1)).subscribe((update)=>{
                this.ngrxstore.dispatch( new RecordUnsavedValueKeys(
                    update.changed
                ) );
            });
        });
        // Get the next and previous Paths as a subscription
        let _wizardStatusSub = this.ngrxstore.select(selectPPPAppealWizardStatus).subscribe((status)=>{
            // We assign the next and previous steps to the appropriate buttons
            this.nextStep = status.nextStep;
            this.previousStep = status.previousStep;
            this.nextStepName = status.nextStepName;
            this.previousStepName = status.previousStepName;
            this.currentStep = status.currentStep;
        });
        
        let _formValidSub = this.appealsWizardService.$formValid.subscribe((message) => {
            this.validForm = message;
        })

        let _isSubmittedSub = this.ngrxstore.select(selectPPPAppealIsSubmitted()).subscribe((values)=>{
            this.isAppealSubmitted = values;
        });

        this.storeZombieByKey('formValidSub',_formValidSub);
        this.storeZombieByKey('isSubmittedSub',_isSubmittedSub);
        this.storeZombieByKey('valuesSub',_valuesSub);
        this.storeZombieByKey('wizardStatusSub ',_wizardStatusSub );
        this.storeZombieByKey('unsavedValuesSub ',_unsavedChangesSub );
    }

    checkWizardValuesAgainstSource():Observable<any>{
        let _currentValues = {};
        let _datePipe = new DatePipe('en-US');
        return new Observable((obs)=>{
            this.ngrxstore.select( selectPPPAppealWizardValues() ).pipe(take(1)).subscribe((currentValues)=>{
              
                let _changedValues = Object.keys(this.appealDataFromServer).filter((key)=>{
                    if(this.excludedStorageValues.indexOf(key) < 0 && this.appealDataFromServer[key] != null &&
                        currentValues[key] && 
                        !this.nestedStorageValues.hasOwnProperty(key)){
                           
                            currentValues = Object.assign({},currentValues,{
                                [this.wizardFields['LI_LOAN_AMOUNT'].storeKey] : currentValues[this.wizardFields['LI_LOAN_AMOUNT'].storeKey]  ? parseFloat(currentValues[this.wizardFields['LI_LOAN_AMOUNT'].storeKey]).toFixed(2): null,
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey] : currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey] ? parseFloat(currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey]).toFixed(2) : null,
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] : currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] ? parseFloat(currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey]).toFixed(2) : null,
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] : currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] ? parseFloat(currentValues[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey]).toFixed(2) : null,
                                [this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey] : currentValues[this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey] ?  _datePipe.transform(currentValues[this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey],'MMMM d, y') : null,
                                [this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey] : currentValues[this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey] ?  _datePipe.transform(currentValues[this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey],'MMMM d, y') : null,
                                [this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey] : currentValues[this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey] ?  _datePipe.transform(currentValues[this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey],'MMMM d, y') : null
                            });
                            
                            this.appealDataFromServer ={...this.appealDataFromServer,
                                [this.wizardFields['LI_LOAN_AMOUNT'].storeKey] : parseFloat(this.appealDataFromServer[this.wizardFields['LI_LOAN_AMOUNT'].storeKey]).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey] : parseFloat(this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey]).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] : parseFloat(this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey]).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] : parseFloat(this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey]).toFixed(2),
                                [this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey]:  _datePipe.transform(this.appealDataFromServer[this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey],'MMMM d, y'),
                                [this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey]:  _datePipe.transform(this.appealDataFromServer[this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey],'MMMM d, y'),
                                [this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey]: _datePipe.transform(this.appealDataFromServer[this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey],'MMMM d, y'),
                            }

                            if(key !== 'filing_date'){
                                return this.appealDataFromServer[key].toString() !== currentValues[key].toString();
                            }
                    }
                    if(this.nestedStorageValues.hasOwnProperty(key) && currentValues[key] && this.appealDataFromServer[key] != null){
                        if(typeof(currentValues[key]) === 'object' ){
                            return this.appealDataFromServer[key][this.nestedStorageValues[key]].toString() !== currentValues[key].description.toString();
                        }
                    }
                });
                if(_changedValues.length > 0){
                    _currentValues = Object.assign(this.appealDataFromServer,
                        _changedValues.map((key)=>{
                            return currentValues[key];
                        })
                    );
                }else{
                    _currentValues = Object.assign({},this.appealDataFromServer);
                }
                obs.next({
                    values:_currentValues,
                    changed:_changedValues
                });
                obs.complete();
            });
        })
    }

    checkWizardStatus(values:any = {}){
        let _primaryContact = values[this.wizardFields['POC_USER_NAME'].storeKey];
        let _title = values[this.wizardFields['ASYA_TITLE'].storeKey];
        let _lender = values[this.wizardFields['ASYA_LENDER_NAME'].storeKey];
        let _referenceId = values[this.wizardFields['REFERENCE_ID'].storeKey];

        // Ensure these values are not null
        if(_primaryContact && _title && _lender){ 
            // Ensure these values are not blank strings
            if( _primaryContact.length > 0 && _title.length > 0 ){ 
                
                // Check whether the appeal has been saved or not
                if(_referenceId === this.appDefaults.ui.NEW_APPEAL_SLUG){

                    // if the appeal is new, require initial save before subsequent saves
                    this.appealCanBeUpdated = false;
                    this.appealShouldBeSaved = true;
                    this.wizardBarActionLabel = 'Begin Application';
                }else{
                    this.appealCanBeUpdated = true;
                    this.appealShouldBeSaved = false;
                    this.wizardBarActionLabel = 'Save Changes';
                }
            }else{
                // If the values do not exhist but are not defined do not allow saving
                this.appealCanBeUpdated = false;
                this.appealShouldBeSaved = false;
                this.wizardBarActionLabel = 'Begin Application';
            }
        }else if((null == _lender || _lender === "" || _lender == undefined ) && this.wizardValues.reference_id != "new-appeal"){
                    this.appealCanBeUpdated = true;
                    this.appealShouldBeSaved = false;
                    this.wizardBarActionLabel = 'Save Changes';
        }
        else{
            // If the values do not exhist do not allow saving
            // if(this.referenceId === this.appDefaults.ui.NEW_APPEAL_SLUG){
            this.appealCanBeUpdated = false;
            this.appealShouldBeSaved = false;
            this.wizardBarActionLabel = 'Begin Application';
            // }
        }
    }
    
    setWizard(){

        // We manage and track the state of the wizard using Redux 
        // ... because wizard steps are routes that are tracked globally by angular
        // ... thus a global state management makes sense here.

        // check to see if we are refreshing on a step or if we are entering the wizard from elsewhere
        this.currentStep = (this.activatedRoute.snapshot.firstChild) ? this.activatedRoute.snapshot.firstChild.routeConfig.path : this.Paths['APPEAL_GET_STARTED']; 

        // define our steps as route Paths

        this.wizardTabs = [
            {name:this.wizardSteps['APPEAL_START_APPEAL'].name,path:this.Paths['APPEAL_START_APPEAL']},
            {name:this.wizardSteps['APPEAL_BORROWER_DETAILS'].name,path:this.Paths['APPEAL_BORROWER_DETAILS']},
            {name:this.wizardSteps['APPEAL_POINT_OF_CONTACT'].name,path:this.Paths['APPEAL_POINT_OF_CONTACT']},
            {name:this.wizardSteps['APPEAL_LOAN_INFORMATION'].name,path:this.Paths['APPEAL_LOAN_INFORMATION']},
            {name:this.wizardSteps['APPEAL_CONTENT'].name,path:this.Paths['APPEAL_CONTENT']},
            {name:this.wizardSteps['APPEAL_SUMMARY'].name,path:this.Paths['APPEAL_SUMMARY']}
        ];
        
        // console.log(this.wizardTabs,this.stepNames)
        this.stepNames = [];
        this.steps = [];

        this.wizardTabs.map((value)=>{
            // console.log(value,this.stepNames,this.steps)
            this.stepNames.push(value.name);
            // console.log(value.disabled, !value.disabled, value.name)
            if(!value.disabled){
                this.steps.push(value.path);
            }
        })
        
        // Setup the wizard state
        this.ngrxstore.dispatch( new AppealSetup({
            stepNames:this.stepNames,
            steps:this.steps,
            startOn:this.currentStep,
            reference_id:this.referenceId
        }));

    }
    
    goToStep(path:string){
        this.ngrxstore.dispatch( new AppealJumpToStep(path) )
        // adapter method, keeps the router out of the component, same arguments as router.navigate()
        this.navigationService.navigate([path],{relativeTo:this.activatedRoute});

        // The page may scroll if it is shorter, this allows for the page to scroll, then be scrolled to top.
        let timeout = setTimeout(()=>{
            this.pageScrollService.scroll({
                document: document,
                scrollTarget: 'body',
                duration:200,
            });
        },100);
    }

    goToNext(){
        this.goToStep(this.nextStep);
    }

    goToPrevious(){
        this.goToStep(this.previousStep);
    }

    leaveWizard(){
        this.ngrxstore.dispatch( new WipeAppealStoreValues() );
        this.navigationService.navigate([this.Routes['YOUR_APPEALS']]);
    }

    getMessagesForLoan(){
        this.navigationService.navigate([this.Routes['MESSAGE_INBOX']],{
            queryParams:{
                id:this.referenceId
            }
        });
    }

    saveApplication(){
        this.disableButtonWhileSave=true;
        this.refresh('wizardSlide');
        let _datePipe = new DatePipe('en-US');
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
            this.ngrxstore.dispatch( new SaveAppeal({
                    initialSave:wizardValues['reference_id'] === this.appDefaults.ui.NEW_APPEAL_SLUG,
                    callback:()=>{
                        this.appealsWizardService.getAppealApplication(
                            this.activatedRoute.snapshot.paramMap.get('id')
                        ).subscribe((response)=>{
                            this.appealDataFromServer = response;
                            this.appealDataFromServer ={...this.appealDataFromServer,
                                [this.wizardFields['LI_LOAN_AMOUNT'].storeKey] : (this.appealDataFromServer[this.wizardFields['LI_LOAN_AMOUNT'].storeKey]/100).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey] : (this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT'].storeKey]/100).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey] : (this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ'].storeKey]/100).toFixed(2),
                                [this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey] : (this.appealDataFromServer[this.wizardFields['LI_FORGIVENESS_AMOUNT_REQ_LENDER'].storeKey]/100).toFixed(2),
                                [this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey]:  _datePipe.transform(this.appealDataFromServer[this.wizardFields['LI_PPP_LOAN_FORGIVENESS_APPLICATION_DATE'].storeKey],'MMMM d, y'),
                                [this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey]:  _datePipe.transform(this.appealDataFromServer[this.wizardFields['LI_PPP_LOAN_DISBURSEMENT_DATE'].storeKey],'MMMM d, y'),
                                [this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey]: _datePipe.transform(this.appealDataFromServer[this.wizardFields['AC_LOAN_REVIEW_RECEIVED'].storeKey],'MMMM d, y'),
                            }

                            let _profiledata = {
                                "phone" : this.appealDataFromServer.phone_number,
                                "reference_id": this.appealDataFromServer.primary_contact_reference_id,
                            } 

                            this.ngrxstore.dispatch( new SaveProfileChanges(_profiledata, null, true));
                            this.checkWizardValuesAgainstSource().pipe(take(1)).subscribe((update)=>{
                                this.ngrxstore.dispatch( new RecordUnsavedValueKeys(
                                    update.changed
                                ) );
                                this.disableButtonWhileSave=false;
                                this.completeRefresh('wizardSlide');
                            });
                        })
                    },
                    user_ref:this.activatedRoute.snapshot.data.wizard.user.reference_id
                }
            ));
            this.disableButtonWhileSave=false;
            this.completeRefresh('wizardSlide');
        });
    }
}