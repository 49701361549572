import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { filter, pairwise, take } from 'rxjs/operators';
import { FreezeNavigation, getWindowState, NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView, ThawNavigation } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { ContinueAppealApplication, CreatePFRAppeal, CreateReconsiderationAppeal, FileReconPFRAppeal } from '../../appeal-wizard/store/appeal-wizard.actions';
import { AppealsApplicationsService } from '../appeals-applications.service';
import { APPEALS_APPLICATIONS_PAGE_NAMES, APPEAL_LIST_COLUMNS_SORTING } from '../constants/appeals-applications.constants';
import { RequestNewAppeal } from '../store/actions/appeals-applications.actions';
import { AuthConfiguration, AUTH_CONFIG } from '../../../platform/modules/auth';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { FilePetition, TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { selectFilePetitionDetails } from '../../../platform/modules/notification/store/notification.selector';
import { HttpErrorResponse } from '@angular/common/http';


interface AppealRows{
	drafts:AppealRowsData,
	submitted:AppealRowsData
}

interface AppealColumns{
	drafts:any
	submitted:any
}

interface AppealRowsData{
	data:any;
	total:number;
}

interface AppealColumnsData{
	raw:any[];
	display:any[];
}


@Component({
	selector: 'your-appeals-page',
	template: require('./your-appeal-page.component.html')
})
export class YourAppealsList extends KillZombies(PageView) {
	
	rowsDrafts = [];
	rowsSubmitted = [];

	currentSearchTerm:string = '';
	currentSearchType:string = '';
	
	rows:AppealRows = {
		drafts:{
			data:[],
			total:0
		},
		submitted:{
			data:[],
			total:0
		}
	};

	columns:AppealColumns = {
		drafts:{
			raw:[],
			display:[],
		},
		submitted:{
			raw:[],
			display:[],
		}
	};

	public columnVisibility = {
        isFullHD:[
			"Appellant",
			"Date Filed",
            "Status",
            "SBA PPP Loan Number",
            "Judge",
			"Reconsideration"
        ],
        isWidescreen:[
            "Appellant",
			"Date Filed",
            "Status",
            "SBA PPP Loan Number",
            "Judge",
			"Reconsideration"
        ],
        isDesktop:[
            "Appellant",
			"Date Filed",
            "Status",
            "SBA PPP Loan Number",
            "Judge",
			"Reconsideration"
        ],
        isTablet:[
			"Appellant",
            "Status",
            "SBA PPP Loan Number",
            "Judge"
        ],
        isMobile:[
			"Appellant",
            "Judge"
        ]
    }

	public columnVisibilityDraft = {
        isFullHD:[
			"Appellant",
			"Date Created",
            "Status",
            "SBA PPP Loan Number",
            "Judge"
        ],
        isWidescreen:[
            "Appellant",
			"Date Created",
            "Status",
            "SBA PPP Loan Number",
            "Judge"
        ],
        isDesktop:[
            "Appellant",
			"Date Created",
            "Status",
            "SBA PPP Loan Number",
            "Judge"
        ],
        isTablet:[
			"Appellant",
            "Status",
            "SBA PPP Loan Number",
            "Judge"
        ],
        isMobile:[
			"Appellant",
            "SBA PPP Loan Number"
        ]
    }
	
	public isMobile:boolean = false;
	public isTablet:boolean = false;
	public isDesktop:boolean = false;
	public isWidescreen:boolean = false;
	public isFullHD:boolean = false;
	defaultSorts:any = null;

	appealsDrafts: any = null;
	appealsSubmitted: any = null;
	public appealTypes:any = null;
	public createAppealTypes:any = null;

	//appealTypes: any = this.appDefaults.ui.APPEAL_TYPE_OPTIONS || [{label:'undefined',value:"1"}];
	rowsPerPageOptions= this.appDefaults.ui.ROWS_PER_PAGE_OPTIONS || [{label:'10',value:"10"}];

	public appealsPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
	public incompleteAppealsPerPage: any = this.appDefaults.ui.LIST_PAGE_LIMIT;
	public appealsOffset:any = 0;
	public incompleteAppealsOffset:any = 0;
	public columnMode = ColumnMode;

	public loadingSubmittedAppeals:boolean = false;
	public loadingDraftAppeals:boolean = false;
	
	public requestNewAppealForm:FormGroup = new FormGroup({});
	public searchForm:FormGroup = new FormGroup({});
    public requestNewAppealFormValues = {};
	
	public requestingNewAppeal:boolean = false;

	public currentUser:any={};

	public currentOrderedBy:any = {ordering:'+title'};
	public windowState: string = 'isWidescreen';
	public isDayLightSavingsOn = false;

	constructor(
		@Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
		@Inject(NAVIGATION_CONFIG) protected configuration: NavigationConfig,
		@Inject(AppealsApplicationsService) public appealsService:AppealsApplicationsService,
		@Inject(AUTH_CONFIG) public authConfig:AuthConfiguration,
		navigationService: NavigationService,
		public ngrxstore:Store<any>,
		public activatedRoute: ActivatedRoute,
		public appealApplicationService: AppealsApplicationsService,
		public formBuilder:FormBuilder,
		public router:Router
	){

		super(configuration, navigationService);

		this.Name = APPEALS_APPLICATIONS_PAGE_NAMES.YOUR_APPEALS;

		this.appealsDrafts = this.activatedRoute.snapshot.data.appealsList.drafts;
		this.appealsSubmitted = this.activatedRoute.snapshot.data.appealsList.submitted;
		this.currentUser = this.activatedRoute.snapshot.data.appealsList.currentUser;
		this.appealTypes = this.appDefaults.ui.APPEAL_TYPE_OPTIONS_FILTER;

		this.createAppealTypes = this.appDefaults.ui.APPEAL_TYPE_OPTIONS;

		this.requestNewAppealForm = this.formBuilder.group({
			appeal_type:['',[
				Validators.required
			]]
		});

		let _windowState = this.ngrxstore.select(getWindowState()).subscribe((state)=>{
			this.isMobile = ( state.size === 'isMobile' );
			this.isTablet = ( state.size === 'isTablet' );
			this.isDesktop = ( state.size === 'isDesktop' );
			this.isWidescreen = ( state.size === 'isWidescreen' );
			this.isFullHD = ( state.size === 'isFullHD' );
			this.windowState = state.size;
		  });

		  this.storeZombieByKey(
			'wizards_window_state',_windowState
		  );

		this.searchForm = this.formBuilder.group({
			type:[''],
			term:['']
		});

		let _requestNewAppealFromSub =  this.requestNewAppealForm.valueChanges.subscribe((values)=>{
			this.requestNewAppealFormValues = values;
		});

		this.storeZombies.push(_requestNewAppealFromSub);

		this.handleColumns();
		this.handleRows();

		this.isDayLightSavingsOn = this.isDST(new Date());
	}

	isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }


	noopComparator(){
		// this is a workaround for a bug in ngx-datatable where externalSorting flag working unexpected
		return 0;
	}

	handleRows(){

		this.rows.drafts.total = this.appealsDrafts.totalCount;
		this.rows.submitted.total = this.appealsSubmitted.totalCount;

		this.rows.drafts.data = this.appealsDrafts.rows;
		this.rows.submitted.data = this.appealsSubmitted.rows;
		if(this.appealsSubmitted.rows){
			this.rows.submitted.data.map((item) => {
				item.has_user_filed_reconsideration_appeal = item.data.has_user_filed_reconsideration_appeal['primary_contact'];
				item.isPFRCreated = this.validateIsPFR(item.data);
				item.isReconsidered = this.validateReconsidered(item.data);
				item.isPFRonRecon = this.validatePFRonRecon(item.data);
				item.isValidForPFRonRecon = this.isValidForPFRonRecon(item.data);
				item.has_user_filed_pfr_appeal = item.data.has_user_filed_pfr_appeal['primary_contact'];
				item.has_user_filed_pfr_on_reconsidered_appeal = item.data.has_user_filed_pfr_on_reconsidered_appeal['primary_contact'];
			})
		}

		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.rows = Object.assign({},this.rows)
	}

	validateIsPFR(appealData){
		if(appealData.child_tickets.length > 0){
			let isPFR = appealData.child_tickets.filter((item) => item.type === 'pfr');
			return isPFR.length > 0;
		}
		return false;
	}
	
	validateReconsidered(appealData){
		if(appealData.child_tickets.length > 0){
			let isReconsidered = appealData.child_tickets.filter((item) => item.type === 'reconsideration')
			return isReconsidered.length > 0;
		}
		return false;
	}

	validatePFRonRecon(appealData){
		if(appealData.child_tickets.length > 0){
			let isPFRonRecon = appealData.child_tickets.filter((item) => item.type === 'pfr-on-reconsidered');
			return isPFRonRecon.length > 0;
		}
		return false;
	}

	isValidForPFRonRecon(appealData) {
		if(appealData.submitter != null){
			let isValid =  [this.authConfig.roles.OHA_ADMIN.slug,
				this.authConfig.roles.OHA.slug,
				this.authConfig.roles.JUDGE.slug,
				this.authConfig.roles.ATTORNEY.slug].includes(appealData.submitter.groups[0].name.toLowerCase());
				console.log("isvalid" , isValid);
			return isValid;
		}
		return false;
	}

	handleColumns(){

		this.columns.drafts.display = [];
		this.columns.submitted.display = [];
		this.columns.drafts.raw = this.appealsDrafts.columns;
		this.columns.submitted.raw = this.appealsSubmitted.columns;

		this.columns.drafts.raw.map((value)=>{
			if(value.prop !== 'id'){
				if(value.prop === 'appellant_name'){
					value.flexGrow = 2;
				}else{
					value.flexGrow = 1;
				}
				this.columns.drafts.display.push(value);
			}
		});
		
		this.columns.submitted.raw.map((value)=>{
			if(value.prop !== 'id'){
				if(value.prop === 'appellant_name'){
					value.flexGrow = 2;
				}else{
					value.flexGrow = 1;
				}
				this.columns.submitted.display.push(value);
			}
		});

		//console.log("this.columns.submitted.display" , this.columns.submitted.display);

		this.defaultSorts = [{prop: this.columns.drafts.raw[0].prop, dir: 'asc'}];


		// This triggers angular's renderer. Since it does not watch for deep object changes.
		this.columns = Object.assign({},this.columns);

		this.columns.submitted.display = this.columns.submitted.display.filter((value)=>{
			return this.columnVisibility[this.windowState].indexOf(value.name) > -1;
		});

		this.columns.drafts.display = this.columns.drafts.display.filter((value)=>{
			return this.columnVisibilityDraft[this.windowState].indexOf(value.name) > -1;
		});

		// Get Initial State
        // this.ngrxstore.select(getWindowState()).pipe(take(1)).subscribe((state)=>{
		// 	this.columns.submitted.display = this.columns.submitted.display.filter((value)=>{
        //         return this.columnVisibility[state.size].indexOf(value.name) > -1;
        //     });
        // })
        // // Get Subsequent States
        // this.ngrxstore.select(getWindowState()).pipe(pairwise(),filter((state)=>state[0].size !== state[1].size)).subscribe((state)=>{
        //     this.columns.submitted.display = this.columns.submitted.display.filter((value)=>{
        //         return this.columnVisibility[state[1].size].indexOf(value.name) > -1;
        //     });
		// })
		
		// this.ngrxstore.select(getWindowState()).pipe(take(1)).subscribe((state)=>{
		// 	this.columns.drafts.display = this.columns.drafts.display.filter((value)=>{
        //         return this.columnVisibility[state.size].indexOf(value.name) > -1;
        //     });
        // })
        // // Get Subsequent States
        // this.ngrxstore.select(getWindowState()).pipe(pairwise(),filter((state)=>state[0].size !== state[1].size)).subscribe((state)=>{
        //     this.columns.drafts.display = this.columns.drafts.display.filter((value)=>{
        //         return this.columnVisibility[state[1].size].indexOf(value.name) > -1;
        //     });
        // })

	}

	createNewAppeal() {
		this.ngrxstore.dispatch( new RequestNewAppeal({
			values:this.requestNewAppealFormValues
		}) );
	}

	goToAppeal(id:string){
		this.navigationService.navigate([
			this.Routes['YOUR_APPEAL'],
			id
		]);
	}

	goToWizard(id:string){
		this.ngrxstore.dispatch( new ContinueAppealApplication(id) );
	}

	queryAppeals(){
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');
		this.currentSearchTerm = this.searchForm.value.term;
		this.currentSearchType = this.searchForm.value.type;
		this.appealsService.getSubmittedAppealsList('active'
			, (this.appealsOffset*this.appealsPerPage), this.appealsPerPage,this.currentOrderedBy,this.currentSearchTerm, 
			this.currentSearchType).pipe(take(1)).subscribe((response)=>{

				if(
					(response.rows.length < 1 && this.appealsOffset > 0)
				){
					this.appealsOffset = 0;
					this.appealsService.getSubmittedAppealsList('active'
					, this.appealsOffset, this.appealsPerPage,this.currentOrderedBy,this.currentSearchTerm, this.currentSearchType).pipe(take(1)).subscribe((response)=>{
						this.appealsSubmitted = response;
						this.handleRows()
						this.completeRefresh('appealsTable');
						this.ngrxstore.dispatch( new ThawNavigation() );
					});
				}else{
					this.appealsSubmitted = response;
					// this.handleColumns()
					this.handleRows()
					this.completeRefresh('appealsTable');
					this.ngrxstore.dispatch( new ThawNavigation() );
				}

		})
	}

	resetFilter(){
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');
		this.searchForm = this.formBuilder.group({
			type:[''],
			term:['']
		});
		this.appealsService.getSubmittedAppealsList('active'
			, 0, this.appealsPerPage,this.currentOrderedBy).pipe(take(1)).subscribe((response)=>{
				this.appealsSubmitted = response;
				// this.handleColumns()
				this.handleRows()
				this.completeRefresh('appealsTable');
				this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}

	updateSubmittedAppealsPerPage($event){
		this.appealsPerPage = $event.target.value;
		this.updateRows({
			offset:this.appealsOffset
		},'appealsPerPage');
	}

	updateDraftAppealsPerPage($event){
		this.incompleteAppealsPerPage = $event.target.value;
		this.updateDraftsRows({
			offset:this.incompleteAppealsOffset
		},'incompleteAppealsPerPage');
	}

	updateRows(pageInfo,type) {
		console.log(this.appealsPerPage)
		console.log(this.appealsOffset*this.appealsPerPage)
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('appealsTable');
		let _offsetValue = 0;

		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.appealsPerPage;
		}
		this.appealsService.getSubmittedAppealsList('active'
			, _offsetValue, this.appealsPerPage,this.currentOrderedBy,this.currentSearchTerm,this.currentSearchType).pipe(take(1)).subscribe((response)=>{
				if(response['rows'].length < 1 && this.appealsOffset > 0){
					this.appealsOffset = 0;
					this.appealsService.getSubmittedAppealsList('active'
						, 0, this.appealsPerPage,this.currentOrderedBy,this.currentSearchTerm,this.currentSearchType).pipe(take(1)).subscribe((newResponse)=>{
							this.appealsSubmitted = newResponse;
							// this.handleColumns()
							this.handleRows()
							this.completeRefresh('appealsTable');
							this.ngrxstore.dispatch( new ThawNavigation() );		
						});
				}else{
					this.appealsSubmitted = response;
					//this.appealsOffset = pageInfo.offset;
					this.appealsOffset = 0;
					// this.handleColumns()
					this.handleRows()
					this.completeRefresh('appealsTable');
					this.ngrxstore.dispatch( new ThawNavigation() );
				}
		})
		
	}


	updateDraftsRows(pageInfo,type) {
		console.log(this.incompleteAppealsOffset)
		console.log(this.incompleteAppealsOffset*this.incompleteAppealsPerPage)
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('draftAppealsTable');
		let _offsetValue = 0;
		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.incompleteAppealsPerPage;
		}
		this.appealsService.getDraftAppealsList(_offsetValue, this.incompleteAppealsPerPage,this.currentOrderedBy).pipe(take(1)).subscribe((response)=>{
			if(response['rows'].length < 1 && this.incompleteAppealsOffset > 0){
				this.incompleteAppealsOffset = 0;
				this.appealsService.getDraftAppealsList(0, this.incompleteAppealsPerPage,this.currentOrderedBy).pipe(take(1)).subscribe((newResponse)=>{
					this.appealsDrafts = newResponse;
					// this.handleColumns()
					this.handleRows()
					this.loadingDraftAppeals = false;
					this.completeRefresh('draftAppealsTable');
					this.ngrxstore.dispatch( new ThawNavigation() );
				});
			}else{
				this.appealsDrafts = response;
				//this.incompleteAppealsOffset = pageInfo.offset;
				this.incompleteAppealsOffset = 0;
				// this.handleColumns()
				this.handleRows()
				this.loadingDraftAppeals = false;
				this.completeRefresh('draftAppealsTable');
				this.ngrxstore.dispatch( new ThawNavigation() );
			}
		})		
	}

	onSort(event) {
		this.refresh('appealsTable');
		this.ngrxstore.dispatch( new FreezeNavigation() );
		let _orderBy = {};

		let _columnDict = APPEAL_LIST_COLUMNS_SORTING.appellant_submitted;

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === event.sorts[0].prop
		})

		if(event.sorts.length > 0){
			if(_serverColumn[0] === 'judge'){
				switch(event.sorts[0].dir){
					case 'asc': _orderBy = {ordering:"judge__name"};
						break;
					case 'desc': _orderBy = {ordering:"-judge__name"};
						break;
					default:
					break;					
				}
			}else if(_serverColumn[0] === 'status'){
				switch(event.sorts[0].dir){
					case 'asc': _orderBy =  {ordering:"action__display_name"};
					break;
					case 'desc': _orderBy = {ordering:"-action__display_name"};
					break;
					default:
					break;					
				}
			}else if(_serverColumn[0] === 'date_filed'){
				switch(event.sorts[0].dir){
					case 'asc': _orderBy = {ordering:"filing_date"};
					break;
					case 'desc': _orderBy = {ordering:"-filing_date"};
					break;
					default:
					break;					
				}
			}else{
				switch(event.sorts[0].dir){
					case 'asc': _orderBy = {ordering: _columnDict[_serverColumn[0]]};
					break;
					case 'desc': _orderBy = {ordering:"-" + _columnDict[_serverColumn[0]]};
					break;
					default:
					break;					
				}
			}
		}

		this.currentOrderedBy = _orderBy;

		this.appealApplicationService.getSubmittedAppealsList('active'
			,(this.appealsOffset*this.appealsPerPage), this.appealsPerPage,_orderBy,this.currentSearchTerm, this.currentSearchType ).pipe(take(1)).subscribe((response)=>{
				this.appealsSubmitted = response;
				console.log(">>> " , this.appealsSubmitted);
				// this.handleColumns()
				console.log(this.appealsOffset)
				this.handleRows()
				this.completeRefresh('appealsTable');
				this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}

	onSortDrafts(event) {
		//console.log("hello");
		this.ngrxstore.dispatch( new FreezeNavigation() );
		this.refresh('draftAppealsTable');
		let _orderBy = {};

		let _columnDict = APPEAL_LIST_COLUMNS_SORTING.appellant_draft;

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === event.sorts[0].prop
		})

		if(event.sorts.length > 0){
			if(_serverColumn[0] === 'judge'){
				switch(event.sorts[0].dir){
					case 'asc': _orderBy = {judge:"+"};
					break;
					case 'desc': _orderBy = {judge:"-"};
					break;
					default:
					break;					
				}
			}else{
				switch(event.sorts[0].dir){
					case 'asc': _orderBy = {ordering:"+" + _columnDict[_serverColumn[0]]};
					break;
					case 'desc': _orderBy = {ordering:"-" + _columnDict[_serverColumn[0]]};
					break;
					default:
					break;					
				}
			}
		}
		
		this.currentOrderedBy = _orderBy;
		this.appealApplicationService.getDraftAppealsList(this.incompleteAppealsOffset, this.incompleteAppealsPerPage,_orderBy ).pipe(take(1)).subscribe((response)=>{
				this.appealsDrafts = response;
				// this.handleColumns()
				this.handleRows()
				this.completeRefresh('draftAppealsTable');
				this.ngrxstore.dispatch( new ThawNavigation() );
		})
	}
	startNewAppeal(){
		if(this.requestingNewAppeal){
			this.requestingNewAppeal= false;
		}else{
			this.requestingNewAppeal= true;
		}
	}

	openPetitionModel(appealDetails,type){
		this.ngrxstore.dispatch(new TriggerModal({
			type:'petition-input',
			content: {
				title: "File Petition for Reconsideration",
				message: "OHA cannot modify the deadline to file a petition for reconsideration. 13 CFR § 134.1202(c)(3)(i)(A).",
				checkboxItems:[
					{
						label:"Upload a document (pleading) that explains a clear error of fact or law in the initial decision"
					}
				],
				is_ogc: false,
			},
			onCancel:()=>{
				this.ngrxstore.dispatch(new FilePetition({file:null , signature:''}))
			},
			onApprove:()=>{
				this.ngrxstore.select(selectFilePetitionDetails()).pipe(take(1)).subscribe((response)=>{
					let _uploaded_file = response['uploaded_file'];
					let _digital_signature = response['signature'];
					this.refresh('appealsTable');
					this.ngrxstore.dispatch( new CreatePFRAppeal({
						type:type,
						parent_ticket:appealDetails.reference_id,
						signature_for_electronic_filing:_digital_signature,
						lender:appealDetails.lender.id,
						title: appealDetails.title,
						action: {
							"slug": "Draft",
							"display_name": "Draft"
						},
						submitter:this.currentUser.reference_id,
						callback:(response)=>{
							if(!response.hasOwnProperty('error')) {
								if(_uploaded_file != null) {
									this.uploadPetitionDocument(response,_uploaded_file);
								}else{
									this.queryAppeals()
									this.goToReconTicket(response.reference_id); 
									this.completeRefresh('appealsTable');	
								}
							}
						}
					})
					)
				});
			}
		}));
	
	}

	uploadPetitionDocument(reconDetail,fileItem){
        this.appealApplicationService.uploadPetitionDocument(
            reconDetail.reference_id,fileItem
        ).pipe(take(1)).subscribe((res1)=>{
			this.appealApplicationService.getUploadedDocuments(
				reconDetail.reference_id
			).subscribe((res2) => {
				let _document = [];
				_document.push(res2[0]["documents"][0].reference_id) //to be updated if backend updates response
				// _document.push(res1["documents"][0].reference_id) 
				this.appealApplicationService.attachDocumentsWithoutCOS(
					reconDetail.reference_id,
					{"documents": _document},
				).pipe(take(1)).subscribe((response)=>{
					this.ngrxstore.dispatch( new FileReconPFRAppeal(
						reconDetail.reference_id,
						{
							lender:reconDetail.lender.id,
							title: reconDetail.title,
							initial_attachment_reference_id_for_filing: res2[0]["documents"][0].reference_id,
							callback:(response)=>{
								this.queryAppeals()
								this.goToReconTicket(reconDetail.reference_id); 
								this.completeRefresh('appealsTable');	
							}
						})
					)
				},((err) =>{
					if(err){
						this.ngrxstore.dispatch(new TriggerToast({
							message:err.error['detail'],
							style:'danger'
						}));
					}
					this.completeRefresh('appealsTable');	
				}))
			})
            
        },(err)=>{
            if(err instanceof HttpErrorResponse){
                 this.ngrxstore.dispatch(new TriggerModal({
                     content:{
                         title:"Unable to Upload",
                         message:err.error.file[0]
                     }
                 }));
            }
			this.completeRefresh('appealsTable');	
        });
    }


	goToReconTicket(id:string){
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`${this.Routes['YOUR_APPEAL']}/${id}`])
		);
		window.open(url, '_blank', '');
	}

}