import { createSelector } from '@ngrx/store';
import { AuthState, getAuthState } from './auth.reducer';

export const getSessionState = createSelector(
  getAuthState,
  (state:AuthState)=>{
    return {
      roles:state.roles,
      status:state.status,
      user:state.user,
      user_id:state.user_id,
      token:state.token,
      refresh_token:state.refresh_token,
      origin:state.origin
    }
  }
)

export const getRoles = createSelector(
  getAuthState,
  (state:AuthState)=>{
    return state.roles;
  }
)

export const getPageLimit = createSelector(
  getAuthState,
  (state:AuthState)=>{
    return state.list_page_limit;
  }
)

export const selectSessionState = () => createSelector(
  getSessionState,
  (session)=> session
)
