import { ChangeDetectorRef, Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MOCK_STATES, MOCK_APPEAL_ROLES } from '../../../../constants/mock.data';
import { AuthConfiguration, AUTH_CONFIG } from '../../../../platform/modules/auth';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { NavigatedFromSummary } from '../../store/appeal-wizard.actions';
import { selectNavigationFlag } from '../../store/appeal-wizard.selectors';
import { WizardStep } from '../wizard-step.component';

@Component({
    changeDetection:ChangeDetectionStrategy.OnPush,
    selector:'point-of-contact',
    template:require('./point-of-contact.component.html'),
})
export class PointOfContact extends WizardStep{

    public roleOptions = [];
    public isAddressInput:boolean = false;
    public isNavigatedFromSummary:boolean = false;

    stateOptions:any = MOCK_STATES.map((obj)=>{
        obj['value'] = obj['abbreviation']
        obj['label'] = obj['name']
        return obj;
    });

    constructor(
        public changeDetector:ChangeDetectorRef,
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService,
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
    ){
        super(
            "Point Of Contact",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        let _initialValues = this.activatedRoute.snapshot.data.state.storedValues;

        if(_initialValues[this.fields.POC_ADDRESS_LINE_1.storeKey] != null){
            this.isAddressInput = true;
        }

        this.ngrxstore.select(selectNavigationFlag()).subscribe((isNavigated)=>{
            this.isNavigatedFromSummary = isNavigated;
        });

        this.roleOptions = Object.keys(this.authConfig.roles).map((key)=>{
            return this.authConfig.roles[key];
          }).filter((option)=>{
            return (
              option.slug === this.authConfig.roles.APPELLANT.slug || 
              option.slug === this.authConfig.roles.APPELLANT_COUNCEL.slug
            );
          });

        this.formGroup = this.formBuilder.group({
            [this.fields.APPEAL_ROLE.storeKey]:_initialValues[
                this.fields.APPEAL_ROLE.storeKey
            ],
            [this.fields.POC_USER_NAME.storeKey]:_initialValues[
                this.fields.POC_USER_NAME.storeKey
            ],
            [this.fields.POC_NAME.storeKey]:_initialValues[
                this.fields.POC_NAME.storeKey
            ],
            [this.fields.POC_ADDRESS_LINE_1.storeKey]:[_initialValues[
                this.fields.POC_ADDRESS_LINE_1.storeKey
            ],[Validators.maxLength(80),Validators.required]],
            [this.fields.POC_ADDRESS_LINE_2.storeKey]:[_initialValues[
                this.fields.POC_ADDRESS_LINE_2.storeKey
            ],Validators.maxLength(80)],
            [this.fields.POC_ADDRESS_CITY.storeKey]:[_initialValues[
                this.fields.POC_ADDRESS_CITY.storeKey
            ],[Validators.maxLength(40),Validators.required]],
            [this.fields.POC_ADDRESS_STATE.storeKey]:[_initialValues[
                this.fields.POC_ADDRESS_STATE.storeKey
            ],Validators.required],
            [this.fields.POC_ADDRESS_ZIP.storeKey]:[_initialValues[
                this.fields.POC_ADDRESS_ZIP.storeKey
            ],[Validators.maxLength(5),Validators.required]],
            [this.fields.POC_EMAIL.storeKey]:_initialValues[
                this.fields.POC_EMAIL.storeKey
            ],
            [this.fields.POC_PHONE_NUMBER.storeKey]:[_initialValues[
                this.fields.POC_PHONE_NUMBER.storeKey
            ],[Validators.maxLength(12),Validators.minLength(12),Validators.required]],
            [this.fields.POC_ROLE.storeKey]:_initialValues[
                this.fields.POC_ROLE.storeKey
            ],
        });

        // This is a method in the WizardStep class which watches value changes on formGroup, simply call it and the ngrx store will start saving values as a user types.
        this.watchChanges();

    }

    ngOnInit(){
    
    }

    ngOnDestroy(){
        this.ngrxstore.dispatch(new NavigatedFromSummary(false))
    }

    onAddressFinal(content){
        this.isAddressInput = true;
        let _addressObj= content[0];
        this.formGroup.patchValue({
            [this.fields.POC_ADDRESS_LINE_1.storeKey]: _addressObj.delivery_line_1,
            [this.fields.POC_ADDRESS_LINE_2.storeKey]: "",
            [this.fields.POC_ADDRESS_CITY.storeKey]: _addressObj.components.city_name,
            [this.fields.POC_ADDRESS_STATE.storeKey]: _addressObj.components.state_abbreviation,
            [this.fields.POC_ADDRESS_ZIP.storeKey]: _addressObj.components.zipcode,

        });
    }

    onUserInputAddressLine1(content){
        this.isAddressInput = true;
       if(typeof content === 'string'){
        this.formGroup.patchValue({
            [this.fields.POC_ADDRESS_LINE_1.storeKey]: content

        });
       } 
    }

    onEmptyAddress(){
        this.isAddressInput = false;
    }
}