import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, combineLatest, timer, config } from 'rxjs';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn:'root'
})
export class NotificationService{

  public $toastMessages:BehaviorSubject<any> = new BehaviorSubject([]);
  get $ToastMessages(){
    return this.$toastMessages;
  }

  public $requestModal:BehaviorSubject<any> = new BehaviorSubject(null);
  get $OnRequestModal(){
    return this.$requestModal;
  }

  public $dismissModal:BehaviorSubject<any> = new BehaviorSubject(null);
  get $OnDismissModal(){
    return this.$dismissModal;
  }

  public modalApprovedMethod:any;
  get ApproveModal(){return this.approveModal}

  public modalRejectedMethod:any;
  get RejectModal(){return this.rejectModal}

  public modalCanceledMethod:any;
  get CancelModal(){return this.cancelModal}

  public currentModalContent:any = {
    title:"Hello",
    message:"No message has been set"
  }
  get ModalContent(){return this.currentModalContent}
  get CreateToast(){return this.createToast}
  get CreateModal(){return this.createModal}
  get DismissToasts(){return this.dismissToasts}

  public lastIndex:number = 0;
  public messageTimers:any[] = [];

  constructor(
    public liveAnnouncer:LiveAnnouncer
  ){}

  createToast(configuration:any){

    let _configuration = Object.assign({},configuration);

    _configuration.ttl = configuration.ttl || 1;
    _configuration.delay = configuration.delay || 0;

    // place all observable timers into an array
    this.messageTimers.push(

      // Delay by configuration
      timer(_configuration.delay*1000)
      .pipe((take(1)))
      .subscribe((res)=>{
        this.$toastMessages.next([_configuration].concat(this.$toastMessages.getValue()));
        this.liveAnnouncer.announce('Attention, '+_configuration.title+' '+_configuration.message)

      // Lve for as long as configuration
        timer(_configuration.ttl*1000).pipe(take(1)).subscribe((res)=>{
          let _messages = this.$toastMessages.getValue();
          _messages.pop();
          this.$toastMessages.next(_messages);
        })
      })

    );
  }

  dismissToasts(){
    this.messageTimers.map((timer)=>{
      // kill all the observables still running
      timer.complete();
    })
    this.$toastMessages.next([])
  }

  createModal(configuration:ModalConfiguration){

    this.modalApprovedMethod = configuration.onApprove || null;
    this.modalRejectedMethod = configuration.onReject || null;
    this.modalCanceledMethod = configuration.onCancel || null;

    let methodsDefined = {
      approve:this.modalApprovedMethod !== null,
      cancel:this.modalCanceledMethod !==null,
      reject:this.modalRejectedMethod !==null
    }

    this.currentModalContent = configuration.content;
    this.$requestModal.next([configuration.type,methodsDefined,configuration.inputValue || null]);
    this.liveAnnouncer.announce('Modal Active, Action Needed, '+configuration.content.title+' '+configuration.content.message)
  }

  approveModal(){
    if(this.modalApprovedMethod) this.modalApprovedMethod();
    this.$dismissModal.next(true);
  }

  rejectModal(){
    if(this.modalRejectedMethod) this.modalRejectedMethod();
    this.$dismissModal.next(true);
  }

  cancelModal(){
    if(this.modalCanceledMethod) this.modalCanceledMethod();
    this.$dismissModal.next(true);
  }
}
