import {Inject, Injectable} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { take,map } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { APPEAL_ACTION_TYPES, APPELLANT_DOCUMENT_CATEGORIES, DOCUMENT_CATEGORIES } from '../../../constants/app.constants';
import { AuthConfiguration, AUTH_CONFIG, getSessionState } from '../../../platform/modules/auth';
import { TicketsService } from '../tickets.service';

@Injectable()
export class TicketResolver implements Resolve<any> {

  constructor(
    @Inject(TicketsService) public ticketService:TicketsService,
    @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    @Inject(Store) public ngrxstore:Store,
    @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
  ){}

  resolve(route:ActivatedRouteSnapshot){

    let _role = ""; 

    let appealAudit$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    let appealInternalNotes$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    let documentCategories = new Observable((obs) => { 
      obs.next([]); 
      obs.complete(); 
    });

    let lenders$ = new Observable((obs) => { 
      obs.next([]); 
      obs.complete(); 
    });

    this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
      _role = session.roles[0];
    });

    if ((_role === this.authConfig.roles.OHA_ADMIN.slug) ||
    (_role === this.authConfig.roles.JUDGE.slug) ||
    (_role === this.authConfig.roles.ATTORNEY.slug) ||
    (_role === this.authConfig.roles.OHA.slug)) {
      appealInternalNotes$ = this.ticketService.getNotes(route.paramMap.get('id'));
    }

    if (_role === this.authConfig.roles.OHA_ADMIN.slug ||
      _role === this.authConfig.roles.APPELLANT_COUNCEL.slug ||
      _role === this.authConfig.roles.JUDGE.slug ||
      _role === this.authConfig.roles.ATTORNEY.slug) {
      appealAudit$ = this.ticketService.getAuditTrail(route.paramMap.get('id'))
    }

    documentCategories = new Observable(
      (obs) => {
        this.ticketService.getDocumentCategories(['active_appeal'],0,100).pipe(take(1)).subscribe(
          (response) => { 
            if(_role === this.authConfig.roles.APPELLANT_COUNCEL.slug || _role === this.authConfig.roles.APPELLANT.slug){
              obs.next(response.filter((item) => {
                return item.slug === DOCUMENT_CATEGORIES.OTHERS.slug
              } ));
            } else if (_role !== this.authConfig.roles.OGC.slug && _role !== this.authConfig.roles.OGC_ADMIN.slug) {
              let _response = response.filter((item) => {
                return (
                  item.slug === APPEAL_ACTION_TYPES.NOTICE_AND_ORDER.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_TO_SHOW_CAUSE.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_OF_DISMISSAL.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_GRANTING_MOTION.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_FOR_RESPONSE.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_EXTENDING_CLOSE_OF_RECORD.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_STAYING_APPEAL.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_LIFTING_STAY.slug ||
                  item.slug === APPEAL_ACTION_TYPES.ORDER_DENYING_MOTION.slug ||
                  item.slug === APPEAL_ACTION_TYPES.DECISION.slug 
                );
              })

              _response.push((response.filter((item) => {
                return (item.slug === DOCUMENT_CATEGORIES.OTHERS.slug);
              }))[0])
              obs.next(_response);
            } else {
              let _response = response.filter((item) => {
                  return (
                    item.slug === APPEAL_ACTION_TYPES.ADMINISTRATIVE_RECORD.slug ||
                    item.slug === APPEAL_ACTION_TYPES.APPEAL_RESPONSE.slug ||
                    item.slug === APPEAL_ACTION_TYPES.MOTION_TO_DISMISS.slug ||
                    item.slug === APPEAL_ACTION_TYPES.RESPONSE_TO_OBJECTION_TO_ADMINISTRATIVE_RECORD.slug ||
                    item.slug === APPEAL_ACTION_TYPES.SUPPLEMENTAL_OF_ADMINISTRATIVE_RECORD.slug || 
                    item.slug === APPEAL_ACTION_TYPES.MOTION_FOR_EXTENSION_OF_THE_CLOSE_OF_RECORD.slug
                  );
              });
              _response.push((response.filter((item) => {
                return (item.slug === DOCUMENT_CATEGORIES.OTHERS.slug);
              }))[0])
              obs.next(_response);
            }
            obs.complete();
        })
      }
    )

    // if (_role === this.authConfig.roles.OGC.slug ||
    //   _role === this.authConfig.roles.OGC_ADMIN.slug) {
    //   adminRecord = this.ticketService.fetchARDocument(route.paramMap.get('id'))
    // }

    return zip(
      this.ticketService.getTicketDocuments(route.paramMap.get('id')),
      this.ticketService.getAppeal(route.paramMap.get('id')),
      this.ticketService.getAppealDocket(route.paramMap.get('id'), 0 , this.appDefaults.ui.LIST_PAGE_LIMIT, {ordering:'-created'}),
      appealInternalNotes$,
      this.ngrxstore.select(getSessionState),
      lenders$,
      appealAudit$,
      this.ticketService.getAppealActions(),
      documentCategories,
      this.ticketService.getDispositionOptions(),
      this.ticketService.getDismissedOptions(),
      this.ticketService.getGeneratableDocumentsList(),
      this.ticketService.getSettings(),
      this.ticketService.getPublicNotes(route.paramMap.get('id')),
      this.ticketService.getCurrentUser()
      // adminRecord
    ).pipe(map(([
      $documents,
      $details,
      $appealDocket,
      $notes,
      $session,
      $lenders,
      $audit,
      $statusOptions,
      $documentCategories,
      $dispositionOptions,
      $dismissedOptions,
      $documentGenerationOptions,
      settings$,
      publicNotes$,
      $currentUser
      // $adminRecord
    ])=>{
      return{
        documents:$documents,
        details:$details,
        appealDocket:$appealDocket,
        notes:$notes,
        session:$session,
        lenders:$lenders,
        audit:$audit,
        statusOptions: $statusOptions,
        documentCategories:$documentCategories,
        dispositionOptions: $dispositionOptions,
        dismissedOptions: $dismissedOptions,
        documentGenerationOptions:$documentGenerationOptions,
        settings:settings$,
        publicNotes:publicNotes$,
        currentUser: $currentUser
      }
    }),take(1));
  }

}
