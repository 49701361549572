import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { MOCK_STATES } from '../../../constants/mock.data';
import { AuthConfiguration, AUTH_CONFIG, AUTH_PATHS, InviteUser } from '../../../platform/modules/auth';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { TICKETS_PATHS } from '../constants/tickets.constants';
import { RequestAppealStatusChange, SaveAppealTicket } from '../store/ticket.actions';
import * as _ from 'lodash';
import { convertToPhoneFormat, getDateFromUTC } from '../../../platform/utilities/helpers';
import { TriggerModal } from '../../../platform/modules/notification';
import { FreezeNavigation } from '../../../platform/modules/navigation';
import { USER_ROLES } from '../../../constants/permissions.constants';
import { APPEAL_REASONS } from '../constants/tickets.constants'
import { ValidEmailValidator } from '../../../platform/utilities/validators/valid-email.validator';
import { TicketsService } from '../tickets.service';

@Component({
    selector:'ticket-summary',
    template:require('./ticket-summary.component.html')
})
export class TicketSummary extends KillZombies(){

    public refreshing:any = {};

    public columnMode = ColumnMode;
    public pocRole:any = null;
    public lenderDetails= [];
    public lenderLabelModel:string = null;

    public ogcDetails= [];
    public ogcLabelModel:string = null;
    public validOgc = true;
    public showOgcInput = false;
    public ogcEditButtonDisabled= false;

    public appellantDetails= [];
    public appellantLabelModel:string = null;
    public validAppellant = true;
    
    public judgeLabelModel:string = null;
    public validJudge = true;
    public showJudgeInput = false;
    public judgeEditButtonDisabled= false;
    public attorneyLabelModel:string = null;
    public validAttorney = true;
    public showAttorneyInput = false;
    public attorneyEditButtonDisabled= false;

    public showLoanInput = false;
    public showAppealInput = false;
    public showAppellantInput = false;
    public loanEditButtonDisabled=false;
    public appealEditButtonDisabled=false;

    public appellantStateModel:string = null;

    public stateOptions = [];
    public roleOptions = [];
    public validLender = true;

    public formGroupAppeal = new FormGroup({});
    
    public appellantformGroupAppeal = new FormGroup({});
    public inviteUser:boolean = false;
    public updatedAppealData:AppellantContactDetails = null;
    
    public appealDetails:TicketSummaryDetails = null;
    public docketFilingColumns:any = [];
    public docketFilingRows:any = [];

    public judgeAssignee:any ={};
    public attorneyAssignee:any ={};
    public judgeOutput:any ={};
    public attorneyOutput:any ={};

    public appealReasons: any =[];

    public isPocRoleUpdated:boolean = false;
    public isPocEmailUpdated: boolean = false;
    public isInviteUserEnabled :boolean = false;

    public readOnly:boolean = false;
    public ohaReadOnly:boolean = false;
    public allowStatusChanges:boolean = false;

    public statusChoices = [];
    public dispositionChoices = [];
    public dismissedChoices = [];
    public showDisposition= false;
    public showDismissed= false;
    public initialStatusValue= "";
 
    public isJudge:boolean = false;

    public disableStatusButton= false;

    public showforJudgeAndAttorney:Boolean = false;
    public isOGC:Boolean = false;
    public ogcEmail="";
    public currentStatus = "";

    public dupReadOnly: boolean = false;
    public isDayLightSavingsOn= false;
    // public isAllowParentJudge:boolean = false;

    @Input() set details(details:TicketSummaryDetails){
        this.refreshing = Object.assign({},{});
        this.appealDetails = Object.assign({},details);
        this.pocRole = Object.values(this.authConfig.roles).filter((role)=>{
            if(this.appealDetails.primary_contact.groups[0]){
                return this.appealDetails.primary_contact.groups[0].name === role['name']
            }else{
                return false;
            }
        })[0];
        if(!this.pocRole){
            this.pocRole = this.authConfig.roles.APPELLANT;
        }

        
        var serverReasons = [];
        Object.keys(this.appealDetails).map((key) =>{
            if (
                (key === APPEAL_REASONS.PPP_INELIGIBLE.slug ||
                key === APPEAL_REASONS.AMOUNT_INELIGIBLE.slug ||
                key === APPEAL_REASONS.UNAUTHORIZED_USE.slug ||
                key === APPEAL_REASONS.PARTIAL_APPROVAL.slug ||
                key === APPEAL_REASONS.FULL_DENIAL.slug ) && this.appealDetails[key]
            ){
                serverReasons.push(key);
            }
        });
        
        var serverReasons_Display = [];
        serverReasons.forEach(element => {
            Object.keys(APPEAL_REASONS).map((key) => {
                if(APPEAL_REASONS[key].slug === element){
                    serverReasons_Display.push(APPEAL_REASONS[key].name);
                }
            })
        });
        this.appealReasons =  Object.assign([],serverReasons_Display);       

        if(Object.keys(this.appealDetails.assignees).length > 0){
            Object.values(this.appealDetails.assignees).filter((item => {
                if(item.groups[0].name === this.authConfig.roles.JUDGE.name){
                    this.judgeAssignee = Object.assign({},item);
                    this.judgeOutput= Object.assign({},item);;
                }
                if(item.groups[0].name === this.authConfig.roles.ATTORNEY.name){
                    this.attorneyAssignee =  Object.assign({},item);
                    this.attorneyOutput= Object.assign({},item);
                }
            }));
        }

        this.buildForm();
    }
    @Input() set readonly(bol:boolean){
        this.readOnly = (bol)?true:false;
    }

    @Input() set ohareadonly(bol:boolean){
        this.ohaReadOnly = (bol)?true:false;
    }

    @Input() set isDupReadOnly(bol:boolean){
        this.dupReadOnly = (bol)?true:false;
    }

    // @Input() set allowParentJudge(bol:boolean){
    //     this.isAllowParentJudge = (bol)?true:false;
    // }

    @Input() set refreshTriggers(externalTriggers){
        // console.log(externalTriggers);
        this.refreshing = Object.assign({},externalTriggers,this.refreshing);
    }

    @Input() set ogcPrimaryEmail(val:string){
        this.ogcEmail = (val)?val:"";
    }

    @Output() onSummaryChange:EventEmitter<any> = new EventEmitter();
    @Output() onAssignUser:EventEmitter<any> = new EventEmitter();
    @Output() onStatusChange:EventEmitter<any> = new EventEmitter();
    @Output() onRequestSave:EventEmitter<any> = new EventEmitter();
    @Output() onRequestAppellantSave:EventEmitter<any> = new EventEmitter();
    @Output() onInviteSent:EventEmitter<any> = new EventEmitter();

    @Input() set currentUserRoles(roles){
        //console.log(roles,this.authConfig.roles)
        if( roles.indexOf(this.authConfig.roles.OHA_ADMIN.slug) > -1 ||
            roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1 ||
            roles.indexOf(this.authConfig.roles.APPELLANT_COUNCEL.slug) > -1 ||
            roles.indexOf(this.authConfig.roles.ATTORNEY.slug) > -1){
                this.allowStatusChanges = true;
        } else{
            this.allowStatusChanges = false;
        }
    }

    constructor(
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration, 
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public ngrxstore:Store,
        public ticketsService:TicketsService
    ){

        super();
        this.roleOptions = Object.keys(this.authConfig.roles).map((key)=>{
            return this.authConfig.roles[key];
        }).filter((option)=>{
            return (
                option.slug === this.authConfig.roles.APPELLANT.slug || 
                option.slug === this.authConfig.roles.APPELLANT_COUNCEL.slug
            );
        });
        let _session= this.activatedRoute.snapshot.data.ticket.session;
        if( _session.roles.indexOf(this.authConfig.roles.OGC.slug) > -1  || _session.roles.indexOf(this.authConfig.roles.OGC_ADMIN.slug) > -1 ){
            this.isOGC = true;
        }else{
            this.isOGC = false;
        }

        if( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1){
            this.isJudge = true;
        }else{
            this.isJudge = false;
        }

        if( _session.roles.indexOf(this.authConfig.roles.JUDGE.slug) > -1  || _session.roles.indexOf(this.authConfig.roles.ATTORNEY.slug) > -1 ){
            this.showforJudgeAndAttorney = true;
        }else{
            this.showforJudgeAndAttorney = false;
        }

        this.statusChoices = this.activatedRoute.snapshot.data.ticket.statusOptions.filter((option)=>{
            return option.name !== 'Draft'
        });
        this.dispositionChoices = this.activatedRoute.snapshot.data.ticket.dispositionOptions;
        this.dismissedChoices= this.activatedRoute.snapshot.data.ticket.dismissedOptions;
        this.stateOptions = MOCK_STATES.map((obj)=>{
            obj['value'] = obj['abbreviation']
            obj['label'] = obj['name']
            return obj;
        });

        this.pocRole = this.authConfig.roles.APPELANT;

        this.isDayLightSavingsOn = this.isDST(new Date());
    }

    isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }

    onAddressFinal(content){
        let _addressObj= content[0];
        this.appellantformGroupAppeal.patchValue({
            'address_line_1': _addressObj.delivery_line_1,
            'address_line_2': _addressObj.last_line,
            'address_city': _addressObj.components.city_name,
            'address_state': _addressObj.components.state_abbreviation,
            'address_zip': _addressObj.components.zipcode
         });
    }

    buildForm(){
        this.killTheseZombies(['formWatchSub']);

        let _ogcUserName =!this.readOnly?((this.appealDetails.primary_contact_ogc !== null) ? 
        this.appealDetails.primary_contact_ogc : ""): ((this.appealDetails.primary_contact_ogc !== null) ? this.appealDetails.primary_contact_ogc.username : "");

        let _lenderId =  this.appealDetails.lender.id;
        let _lenderName =  this.appealDetails.lender.name;

        let _judgeName = !this.readOnly ?  this.inputFormatAssignedValue(this.judgeAssignee): 
        (Object.keys(this.judgeAssignee).length != 0 ? this.judgeAssignee.name : "");

        let _attorneyName = !this.readOnly ?this.inputFormatAssignedValue(this.attorneyAssignee): 
        (Object.keys(this.attorneyAssignee).length != 0 ? this.attorneyAssignee.name : "");

        let _appellantFirstName = this.appealDetails.primary_contact.first_name;
        let _appellantLastName = this.appealDetails.primary_contact.last_name;
        let _appellantFullName = _appellantFirstName + " " + _appellantLastName;

        let _pocRole = !this.readOnly ? this.appealDetails.primary_contact.groups[0].name.toLowerCase() : 
        (this.appealDetails.primary_contact.groups[0].name.toLowerCase() === "appellant counsel" ? USER_ROLES.APPELLANT_COUNCEL.name : USER_ROLES.APPELLANT.name);
        
        let _formatPhone = null;
        if(this.appealDetails.primary_contact.phone && this.appealDetails.primary_contact.phone != null){
            _formatPhone = convertToPhoneFormat(this.appealDetails.primary_contact.phone.replace("+1",""));
        }
        
        let _ogcFormatPhone = null;
        if(this.appealDetails.primary_contact_ogc !=null  && this.appealDetails.primary_contact_ogc.phone != null){
            _ogcFormatPhone = convertToPhoneFormat(this.appealDetails.primary_contact_ogc.phone.replace("+1",""));
        }

        var _ppp_loan_disbursement_dateUTC = new Date(this.appealDetails.ppp_loan_disbursement_date).toUTCString();
        var _ppp_loan_disbursement_dateFormatted  = getDateFromUTC(_ppp_loan_disbursement_dateUTC);

        var _ppp_deadline_dateUTC = new Date(this.appealDetails.close_of_record).toUTCString();
        var _ppp_deadline_dateFormatted  = getDateFromUTC(_ppp_deadline_dateUTC);

        var _ppp_forgiveness_application_date_dateUTC = new Date(this.appealDetails.forgiveness_application_date).toUTCString();
        var _ppp_forgiveness_application_date_dateFormatted  = getDateFromUTC(_ppp_forgiveness_application_date_dateUTC);

        var _final_loan_review_decision_received_dateUTC = new Date(this.appealDetails.final_loan_review_decision_received_date).toUTCString();
        var _final_loan_review_decision_received_dateFormatted  = getDateFromUTC(_final_loan_review_decision_received_dateUTC);
      
        var _appeal_dateUTC = new Date(this.appealDetails.filing_date).toUTCString();
        var _appeal_dateFormatted  = getDateFromUTC(_appeal_dateUTC);
        this.currentStatus = this.appealDetails.action.name;
        
        let _formGroup = {
            action:[this.appealDetails.action.name],
            appeal_date:[this.appealDetails.filing_date,Validators.required],
            appeal_dateFormatted: _appeal_dateFormatted,
            final_loan_review_decision_received_date:[this.appealDetails.final_loan_review_decision_received_date,Validators.required],
            final_loan_review_decision_received_dateFormatted:_final_loan_review_decision_received_dateFormatted,
            judge:[_judgeName],
            judge_id:[this.judgeAssignee.reference_id],
            attorney:[_attorneyName],
            attorney_id:[this.attorneyAssignee.reference_id],
            lender:[_lenderName,Validators.required],
            lender_id:[_lenderId,Validators.required],
            loan_number:[this.appealDetails.loan_number,[Validators.minLength(10), Validators.maxLength(10)]],
            loan_amount_in_cents:[this.appealDetails.loan_amount_in_cents/100,[Validators.min(1),Validators.max(10000000)]],
            forgiveness_amount_received_in_cents:[this.appealDetails.forgiveness_amount_received_in_cents/100,[Validators.max(10000000)]],
            forgiveness_amount_requested_by_lender_in_cents: [this.appealDetails.forgiveness_amount_requested_by_lender_in_cents/100,[Validators.max(10000000)]],
            forgiveness_amount_requested_in_cents: [this.appealDetails.forgiveness_amount_requested_in_cents/100,[Validators.max(10000000),Validators.min(1)]],
            ppp_loan_disbursement_date: [this.appealDetails.ppp_loan_disbursement_date],
            ppp_loan_disbursement_dateFormatted: _ppp_loan_disbursement_dateFormatted,
            forgiveness_application_date: [this.appealDetails.forgiveness_application_date],
            forgiveness_application_dateFormatted: _ppp_forgiveness_application_date_dateFormatted,
            primary_contact:[this.appealDetails.primary_contact.username,[Validators.required,Validators.maxLength(80)]],
            primary_contact_value:[_appellantFullName],
            primary_contact_first_name:[_appellantFirstName],
            primary_contact_last_name:[_appellantLastName],
            address_line_1:[this.appealDetails.address_line_1,[Validators.required,Validators.maxLength(80)]],
            address_line_2:[this.appealDetails.address_line_2,[Validators.required,Validators.maxLength(80)]],
            address_city:[this.appealDetails.address_city,[Validators.required,Validators.maxLength(40)]],
            address_state:[this.appealDetails.address_state,Validators.required],
            address_zip:[this.appealDetails.address_zip,[Validators.required,Validators.maxLength(5)]],
            //phone_number:[this.appealDetails.primary_contact.phone],
            formatPhone: _formatPhone,
            submitter_email:[this.appealDetails.primary_contact.email,Validators.required],
            point_of_contact_role:[_pocRole,Validators.required],
            title:[this.appealDetails.title,[Validators.required,Validators.maxLength(80)]],
            close_of_record: [this.appealDetails.close_of_record],
            close_of_record_dateFormatted: _ppp_deadline_dateFormatted,
            ogc: [_ogcUserName,Validators.maxLength(80)],
            primary_contact_ogc:[(this.appealDetails.primary_contact_ogc != null) ? this.appealDetails.primary_contact_ogc.username : ""],
            ogc_name:[(this.appealDetails.primary_contact_ogc != null) ? this.appealDetails.primary_contact_ogc.name : ""],
            ogc_cc:[(this.appealDetails.primary_contact_ogc != null) ? this.appealDetails.primary_contact_ogc.email : ""],
            ogc_email:['olitservice@sba.gov'],
            ogc_phone_number: [(this.appealDetails.primary_contact_ogc != null) ? this.appealDetails.primary_contact_ogc.phone : ""],
            ogc_format_phone : _ogcFormatPhone,
            disposition: [(this.appealDetails.disposition != null) ? this.appealDetails.disposition : ""], 
            disposition_dismissed_reason: [(this.appealDetails.disposition_dismissed_reason != null) ? this.appealDetails.disposition_dismissed_reason : ""], 
            appealReasons:[this.appealReasons],
            is_filed_for_ppp_ineligible:[this.appealDetails.is_filed_for_ppp_ineligible],
            is_filed_for_ineligible_ppp_amount:[this.appealDetails.is_filed_for_ineligible_ppp_amount],
            is_filed_for_unauthorized_use:[this.appealDetails.is_filed_for_unauthorized_use],
            is_filed_for_lender_partial_approval:[this.appealDetails.is_filed_for_lender_partial_approval],
            is_filed_for_lender_issued_full_denial:[this.appealDetails.is_filed_for_lender_issued_full_denial],
            
            username:[this.appealDetails.primary_contact.username, [Validators.required,Validators.maxLength(80)]],
            name:[this.appealDetails.primary_contact.name, [Validators.required,Validators.maxLength(80)]],
            first_name:[this.appealDetails.primary_contact.first_name, [Validators.required,Validators.maxLength(80)]],
            last_name:[this.appealDetails.primary_contact.last_name, [Validators.required,Validators.maxLength(80)]]
        }

        this.formGroupAppeal = new FormGroup({});
        this.formGroupAppeal = this.formBuilder.group(_formGroup);


        this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal)); 

        let _formWatchSub = this.formGroupAppeal.valueChanges.subscribe(()=>{
            this.trackStatusSelections();
            this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal)); 
        });      

        this.trackStatusSelections();


        let _appellantformGroup = {
            title: this.appealDetails.title,
            lender:this.appealDetails.lender.id,
            username:[this.appealDetails.primary_contact.username, [Validators.required,Validators.maxLength(80)]],
            name:[this.appealDetails.primary_contact.name, [Validators.required,Validators.maxLength(80)]],
            first_name:[this.appealDetails.primary_contact.first_name, [Validators.required,Validators.maxLength(80)]],
            last_name:[this.appealDetails.primary_contact.last_name, [Validators.required,Validators.maxLength(80)]],
            address_line_1:[this.appealDetails.address_line_1,[Validators.required,Validators.maxLength(80)]],
            address_line_2:[this.appealDetails.address_line_2,[Validators.maxLength(80)]],
            address_city:[this.appealDetails.address_city,[Validators.required,Validators.maxLength(40)]],
            address_state:[this.appealDetails.address_state,Validators.required],
            address_zip:[this.appealDetails.address_zip,[Validators.required,Validators.maxLength(5)]],
            phone:[this.appealDetails.primary_contact.phone,[Validators.required]],
            formatPhone:[_formatPhone],
            submitter_email:[this.appealDetails.primary_contact.email,[Validators.required,Validators.email,ValidEmailValidator(),Validators.maxLength(80)]],
            point_of_contact_role:[this.appealDetails.primary_contact.groups[0].name.toLowerCase(),Validators.required],
        } 


        this.appellantformGroupAppeal = new FormGroup({});
        this.appellantformGroupAppeal = this.formBuilder.group(_appellantformGroup);

        this.appellantformGroupAppeal.valueChanges.subscribe((changes)=>{
            this.inviteUser = this.userMayNeedInvitiation();
            this.updatedAppealData ={...this.appellantformGroupAppeal.value,
                title: this.appealDetails.title,
                lender:this.appealDetails.lender.id
            }
        }); 

        this.storeZombieByKey('formWatchSub',_formWatchSub);
    }

    createFormGroupControlsPayload(formGroup:FormGroup){
        let _formGroupControls:any = {};
        let _formGroupCopy = Object.assign({},formGroup);
        Object.keys(_formGroupCopy.controls).map((key)=>{
            _formGroupControls[key] = {
                valid:_formGroupCopy.controls[key].valid,
                value:_formGroupCopy.controls[key].value
            }
        });
        return _formGroupControls;
    }

    changeStatus(){

        let _selectedStatus = this.formGroupAppeal.controls.action.value;

        const updatedFormValues = {};
            this.formGroupAppeal['_forEachChild']((control, name) => {
            if (control.dirty) {
                updatedFormValues[name] = control.value;
            }
        });
        let statusLabel = this.statusChoices.filter((i) => {
            if( i.name === _selectedStatus){
                return i.name;
            }
        });

        if(_selectedStatus !== this.currentStatus){
            if(_selectedStatus === "Complete"){
                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                        title:"Are you sure?",
                        message:'You are about to change this Appeal to Complete, make sure you have no unintended changes made to the appeal, changing the status to Complete and providing your disposition will save all changes made to the Appeal',
                        approveLabel: "Update Appeal",
                        cancelLabel: "Cancel"
                    },
                    onCancel:()=>{
                    
                    },
                    onApprove:()=>{
    
    
                        // We have to send the lender id as lender to backend;
                        let _appealSnapshot = this.createFormGroupControlsPayload(this.formGroupAppeal)
                        _appealSnapshot.lender = _appealSnapshot.lender_id;
                        _appealSnapshot.new_filing_date = _appealSnapshot.appeal_date;
                        this.onRequestSave.emit(
                            {
                                snapshot:updatedFormValues,
                                callback:()=>{
                                    this.refresh('about');
                                    // console.log('starting status change', this.refreshing)
                                    this.ngrxstore.dispatch( new RequestAppealStatusChange({
                                        reference_id:this.appealDetails.reference_id,
                                        slug:this.formGroupAppeal.controls.action.value,
                                        statusLabel:statusLabel[0].name,
                                        callback:(appealData)=>{
                                            this.appealDetails = Object.assign({},this.appealDetails,{
                                                action:appealData.action
                                            });
                                            this.buildForm();
                                            this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal))
                                            this.onStatusChange.emit(appealData.action);
                                            this.completeRefresh('about');
                                            this.showAppealInput=false;
                                            this.currentStatus = _selectedStatus;
                                        }
                                    }));
                                },
                                request_type:'patch',
                                allDetails: _appealSnapshot
                            }
                        );
                        
                    }
                }));
            }else{
                this.ngrxstore.dispatch(new TriggerModal({
                    content:{
                        title:'Are you sure?',
                        message:'Are you sure you want to save the changes ?',
                        approveLabel: "Save",
                        cancelLabel: "Cancel"
                    },
                    onCancel:()=>{
                    
                    },
                    onApprove:()=>{
                        this.refresh('about');
                        this.ngrxstore.dispatch( new RequestAppealStatusChange({
                            reference_id:this.appealDetails.reference_id,
                            slug:this.formGroupAppeal.controls.action.value,
                            callback:(appealData)=>{
                                this.appealDetails = Object.assign({},this.appealDetails,{
                                    action:appealData.action
                                });
                                let _appealSnapshot = this.createFormGroupControlsPayload(this.formGroupAppeal)
                                _appealSnapshot.lender = _appealSnapshot.lender_id;
                                _appealSnapshot.new_filing_date = _appealSnapshot.appeal_date;
                                this.onRequestSave.emit({snapshot:updatedFormValues,request_type:'patch',allDetails:_appealSnapshot});
                                this.buildForm();
                                this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal));
                                this.onStatusChange.emit(appealData.action);
                                this.completeRefresh('about');
                                this.showAppealInput=false;
                                this.currentStatus = _selectedStatus;
                            },
                            statusLabel:statusLabel[0].name
                        }));
                    }
                }));
            }
        }else{
            this.ngrxstore.dispatch(new TriggerModal({
                content:{
                    title:'Are you sure?',
                    message:'Are you sure you want to save the changes ?',
                    approveLabel: "Save",
                    cancelLabel: "Cancel"
                },
                onCancel:()=>{
                
                },
                onApprove:()=>{
                    this.refresh('about');
                    this.appealDetails = Object.assign({},this.appealDetails,{
                        action:this.currentStatus
                    });
                    let _appealSnapshot = this.createFormGroupControlsPayload(this.formGroupAppeal)
                    _appealSnapshot.lender = _appealSnapshot.lender_id;
                    _appealSnapshot.new_filing_date = _appealSnapshot.appeal_date;
                    // console.log("_appealSnapshot >> ", _appealSnapshot);
                    if(updatedFormValues.hasOwnProperty('appeal_date')){
                        updatedFormValues['new_filing_date'] = updatedFormValues['appeal_date'];
                    }
                    this.onRequestSave.emit({snapshot:updatedFormValues,request_type:'patch',allDetails:_appealSnapshot});
                    this.buildForm();
                    this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal));
                    this.onStatusChange.emit(this.currentStatus);
                    this.completeRefresh('about');
                    this.showAppealInput=false;
                }
            }));
            
        }
        
        
    }

    confirmReassignJudge($event){
        this.refresh('about');
        this.onAssignUser.emit({
            type:'judge',
            user:this.formGroupAppeal.controls.judge_id.value
        });
        this.showJudgeInput= false;
    }

    confirmReassignAttorney($event){
        this.refresh('about');
        this.onAssignUser.emit({
            type:'attorney',
            user:this.formGroupAppeal.controls.attorney_id.value
        });
        this.showAttorneyInput= false;
    }

    editSection(section:string){
        switch(section){
            case 'judge':
                if(this.showJudgeInput){
                    this.buildForm();
                    this.judgeOutput= this.judgeAssignee;
                    this.showJudgeInput=false;
                }else{
                    this.showJudgeInput=true;
                }
            break;
            case 'attorney':
                if(this.showAttorneyInput){
                    this.buildForm();
                    this.attorneyOutput= this.attorneyAssignee;
                    this.showAttorneyInput=false;
                }else{
                    this.showAttorneyInput=true;
                }
            break;
            case 'ogc':
                if(this.showOgcInput){
                    this.buildForm();
                    this.showOgcInput=false;
                }else{
                    this.showOgcInput=true;
                }
            break;
            case 'loan':
                if(this.showLoanInput){
                    this.buildForm();
                    this.showLoanInput=false;
                }else{
                    this.showLoanInput=true;
                }
            break;
            case 'appeal':
                if(this.showAppealInput){
                    this.buildForm();
                    this.showAppealInput=false;
                }else{
                    this.showAppealInput=true;
                }
            break;
            case 'appellant' :
                if(this.showAppellantInput){
                    this.buildForm();
                    this.showAppellantInput=false;
                    this.inviteUser = false;
                }else{
                    this.showAppellantInput=true;
                }
            break;
        }
    }

    refresh(target:string){
        this.refreshing = Object.assign({},this.refreshing,{
          [target]:true
        })
    }
    
    completeRefresh(target:string){
        let _refreshing = {}
        Object.keys(this.refreshing).map((key)=>{
            if(key !== target){
            _refreshing['key'] = true;
            }
        });
        this.refreshing = Object.assign({},_refreshing)
    }

    trackStatusSelections(){

        if(this.formGroupAppeal.controls.disposition_dismissed_reason.value != this.appealDetails.disposition_dismissed_reason ||
            this.formGroupAppeal.controls.disposition.value != this.appealDetails.disposition ||
            this.formGroupAppeal.controls.action.value != this.appealDetails.action.name ||
            this.formGroupAppeal.controls.appeal_date.value != this.appealDetails.appeal_date){
                this.appealEditButtonDisabled= true;
        }else{
            if(this.formGroupAppeal.controls.title.value === this.appealDetails.title)
                this.appealEditButtonDisabled= false;
        }

        if(this.formGroupAppeal.controls.action.value === "Complete"){
            this.showDisposition=true;
        }else{
            this.showDisposition=false;
            this.showDismissed=false;
        }
        if(this.showDisposition && this.formGroupAppeal.controls.disposition.value === "dismissed"){
            this.showDisposition=true;
            this.showDismissed=true;
        }else{
            this.showDismissed=false;
        }
        
        if((this.formGroupAppeal.controls.action.value != "Complete")){
            this.disableStatusButton= false;
        }else if((this.showDisposition && this.showDismissed) && this.formGroupAppeal.controls.action.value != "" && this.formGroupAppeal.valid){
            this.disableStatusButton= false;
        }else if((this.showDisposition && !this.showDismissed) && this.formGroupAppeal.controls.action.value != "" && this.formGroupAppeal.valid){
            this.disableStatusButton= false;
        }else{
            this.disableStatusButton= true;
        }

        this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal));
        
    }

    updateAppellantFullName(){
        this.formGroupAppeal.patchValue({
            'primary_contact_value': this.formGroupAppeal.controls.primary_contact_first_name.value+" "+this.formGroupAppeal.controls.primary_contact_last_name.value
        });
    }

    saveFormChange(type:string){
        if( type !== 'appellant'){
            switch(type){
                case 'loan': this.showLoanInput= false;
                case 'ogc': this.showOgcInput=false;
                case 'appeal': this.showAppealInput=false; 
            }
            const updatedFormValues = {};
                this.formGroupAppeal['_forEachChild']((control, name) => {
                if (control.dirty) {
                    updatedFormValues[name] = control.value;
                }
            });
            let _appealSnapshot = this.createFormGroupControlsPayload(this.formGroupAppeal)
            _appealSnapshot.lender = _appealSnapshot.lender_id;
            _appealSnapshot.ogc = _appealSnapshot.ogc_name;
            if(updatedFormValues.hasOwnProperty('ogc')){
                updatedFormValues['primary_contact_ogc'] = _appealSnapshot['primary_contact_ogc'];
            }
            this.onRequestSave.emit({snapshot:updatedFormValues,request_type:'patch',allDetails:_appealSnapshot});

        }else{
            this.showAppellantInput=false; 
            var _data= this.appellantformGroupAppeal.value;
            _data['name'] = _data.first_name + " " + _data.last_name;
            _data['reference_id']= this.appealDetails.primary_contact.reference_id;
            _data['app_reference_id']= this.activatedRoute.snapshot.paramMap.get('id');
            _data['phone'] = (_data.phone !== null) ? ((_data.phone.indexOf('+1') > -1) ? _data.phone : "+1"+_data.phone): '';
            let _appealSnapshot = this.createFormGroupControlsPayload(this.appellantformGroupAppeal)
            this.onRequestAppellantSave.emit({_data,_appealSnapshot});
        }
    }

    clearPreviousValue(event:any){
        event.target.value="";
    }

    captureFieldChange(event, field, type){
        switch(type){
            case 'loan': 
                if(event.target.value != this.formGroupAppeal.controls[field].value){
                    this.loanEditButtonDisabled=true;
                }
            break;
            case 'appeal': 
                if(event.target.value != this.appealDetails[field]){
                    this.appealEditButtonDisabled=true;
                }
            break; 
        }
    }

    setUserName($event){
        this.formGroupAppeal.patchValue({
            username: this.formGroupAppeal.controls.first_name.value+" "+this.formGroupAppeal.controls.last_name.value,
            name: this.formGroupAppeal.controls.first_name.value+" "+this.formGroupAppeal.controls.last_name.value,
        });
    }

    userMayNeedInvitiation(){

        this.isPocEmailUpdated = false;
      
        this.isPocEmailUpdated = this.appellantformGroupAppeal.controls.submitter_email.value != this.appealDetails.primary_contact.email;
        this.isPocRoleUpdated = this.appellantformGroupAppeal.controls.point_of_contact_role.value !== (this.appealDetails.primary_contact.groups[0].name).toLowerCase() ;       

        this.validateInvite();
        return( this.isPocEmailUpdated || this.isPocRoleUpdated);
    }

    validateInvite(){
        this.isInviteUserEnabled = false;
        let _initialData = {
            phone: this.appealDetails.primary_contact.phone ? this.appealDetails.primary_contact.phone : ''
        }
        if(this.isPocRoleUpdated){
            if(this.isPocEmailUpdated && this.appellantformGroupAppeal.controls.submitter_email.valid &&
                (this.appellantformGroupAppeal.controls.phone.value !== _initialData['phone'])){
                    this.isInviteUserEnabled = true
                }
        }else{
            if(this.isPocEmailUpdated && this.appellantformGroupAppeal.controls.submitter_email.valid){
                this.isInviteUserEnabled = true
            }
        } 
    }

    onRoleChange(){
        if(this.isPocRoleUpdated){
            this.appellantformGroupAppeal.patchValue({
                'phone': "",
                'submitter_email': ""
                });
        }else{
            this.appellantformGroupAppeal.patchValue({
                'phone': this.appealDetails.primary_contact.phone ? this.appealDetails.primary_contact.phone : '',
                'submitter_email': this.appealDetails.primary_contact.email
                });
        }
    }

    inviteUserToAppeal(){
        if(null === this.updatedAppealData){
            this.updatedAppealData ={...this.appellantformGroupAppeal.value,
                title: this.appealDetails.title,
                lender:this.appealDetails.lender.id
            }
        }
        //this.ngrxstore.dispatch(new FreezeNavigation() );
        this.ngrxstore.dispatch(new TriggerModal({
            type:'confirmation-input',
            inputValue:this.updatedAppealData.submitter_email,
            content: {
                title: "Confirm User's Email Address",
                message: "Please enter the email one more time",
                approveLabel: "Save",
                cancelLabel: "Cancel"
            },
            onCancel: () => {},
            onApprove: () => {
                this.isInviteUserEnabled = false;
                let _role = "";
                if(this.updatedAppealData.point_of_contact_role === 'appellant counsel'){
                    _role = 'Attorney'
                }else{
                    _role = "Appellant"
                }
                this.ngrxstore.dispatch(
                    new InviteUser({
                        first_name: this.updatedAppealData.first_name,
                        last_name: this.updatedAppealData.last_name,
                        name:this.updatedAppealData.username,
                        email:this.updatedAppealData.submitter_email,
                        ticket:this.activatedRoute.snapshot.paramMap.get('id'),
                        group:_role,
                        phone:this.updatedAppealData.phone,
                        address_line_1:this.appealDetails.address_line_1,
                        address_line_2:this.appealDetails.address_line_2,
                        address_state:this.appealDetails.address_state,
                        address_city:this.appealDetails.address_city,
                        address_zip:this.appealDetails.address_zip,
                        invitation_url:AUTH_PATHS.INVITE_USER
                    }, (isInviteSent)=>{
                        if(isInviteSent){
                            this.inviteUser = false;
                            this.showAppellantInput = false;
                            this.onInviteSent.emit(true);
                        }
                       
                    })
                );
                this.buildForm();
            }
        }))
    }

    matchJudge = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketsService.getAssigneeList(searchText,"Judge") 
        )              
    );

    matchAttorney = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketsService.getAssigneeList(searchText,"Attorney") 
        )              
    );

    matchOGC= (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketsService.getAssigneeList(searchText,"OGC") 
        )              
    );

    matchLender= (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.ticketsService.getLenderList(searchText) 
        )              
    );

    resultFormatAssignedValue(value: any) {            
        return value.first_name + " " + value.last_name + " ( Assigned Appeals: " + value["appeals_assigned"] + " Remaining Appeals: " + (value["appeals_assignment_max"] - value["appeals_assigned"])  + ")";
    }

    resultFormatAssignedValueOGC(value: any) {            
        return value.first_name + " " + value.last_name ;
    }

    resultFormatLenderValue(value: any) {            
        return value.name ;
    }

    inputFormatLenderValue(value: any)   {
        if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.name ;
            if(_matchedItem)
                return _matchedItem;
        }else{
            return value;
        }
    }

    inputFormatAssignedValue(value: any)   {
        if(typeof value === 'string'){
            if(value)
                return value;
        }else if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.first_name + " " + value.last_name + " ( Assigned Appeals: " + value["appeals_assigned"] + " Remaining Appeals: " + (value["appeals_assignment_max"] - value["appeals_assigned"])  + ")";
            if(_matchedItem)
                return _matchedItem;
        }
    }

    inputFormatAssignedValueOGC(value: any) {
        if(typeof value === 'string'){
            if(value)
                return value;
        }else if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.first_name + " " + value.last_name ;
            if(_matchedItem)
                return _matchedItem;
        }
    }

    assigneeLabelChange(event:any, group:string){
        let _matchedItem = null;

        switch(group){
            case 'Judge':
                _matchedItem = event.item.first_name + " " + event.item.last_name + " ( Assigned Appeals: " + event.item["appeals_assigned"] + " Remaining Appeals: " + (event.item["appeals_assignment_max"] - event.item["appeals_assigned"])  + ")";
                this.judgeOutput= event.item;
                this.formGroupAppeal.patchValue({
                    'judge_id':event.item.reference_id,
                    'judge':_matchedItem
                });
                this.judgeEditButtonDisabled= true;
                this.validJudge = _matchedItem != null ? true :false;  
                break;

            case 'Attorney':
                _matchedItem = event.item.first_name + " " + event.item.last_name + " ( Assigned Appeals: " + event.item["appeals_assigned"] + " Remaining Appeals: " + (event.item["appeals_assignment_max"] - event.item["appeals_assigned"])  + ")";
                this.attorneyOutput= event.item;
                this.formGroupAppeal.patchValue({
                    'attorney_id':event.item.reference_id,
                    'attorney':_matchedItem
                });
                this.attorneyEditButtonDisabled= true;
                this.validAttorney = _matchedItem != null ? true :false;  
                break;
            
            case 'OGC':
                _matchedItem = event.item.first_name + " " + event.item.last_name ;
                this.ogcEditButtonDisabled= true;

                this.formGroupAppeal.patchValue({
                    'primary_contact_ogc':event.item,
                    'ogc_name':event.item.name,
                    'ogc':event.item.name,
                    'ogc_cc': event.item.email,
                    'ogc_phone_number': event.item.phone,
                    'ogc_format_phone' : (event.item.phone != null ? convertToPhoneFormat(event.item.phone) : null)
                });
                this.validOgc = true;
                break;
            case 'Lender':
                _matchedItem = event.item;
                this.loanEditButtonDisabled= true;
                this.formGroupAppeal.patchValue({
                    'lender_id':_matchedItem['id'],
                    'lender':_matchedItem
                });
                 this.validLender = _matchedItem != null ? true :false;
                break;
        }
        
        
        this.onSummaryChange.emit(this.createFormGroupControlsPayload(this.formGroupAppeal));
    }
    
}