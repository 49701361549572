import { Component,ViewEncapsulation,OnInit, Inject } from '@angular/core';
import { AppService } from './app.service';
import {
  Router,
  Event,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  NavigationCancel,
} from '@angular/router';


import { WindowRef, NavigationService, CloseNavigationDrawer, ThawNavigation, FreezeNavigation } from './platform/modules/navigation'
import { LogoutAction, RequestLogoutAction } from './platform/modules/auth';
import { ApiConfig, API_CONFIG } from './app.interface';
import { take, pairwise, filter } from 'rxjs/operators';
import { AuthEffects } from './store/effects/auth.effects';
import { TICKETS_PATHS } from './features/tickets/constants/tickets.constants';
import { Store } from '@ngrx/store';

export const ROOT_SELECTOR = 'app';

@Component({
  selector: 'body',
  styles: [require('../assets/sass/main.scss')],
  encapsulation: ViewEncapsulation.None,
  template: require('./app.component.html'),
})

export class AppComponent{

  public loadingRoute:boolean = false;
  public showBackdrop = true;
  public drawerIsOpen:boolean = null;
  
  constructor(
    public appService:AppService,
    public navigationService:NavigationService,
    public router:Router,
    public authEffects:AuthEffects,
    public ngrxstore:Store<any>,
    @Inject(API_CONFIG) public api:ApiConfig
  ){

    // this.authEffects.onLoginComplete$.subscribe((result)=>{
    //   console.log(result);
    // })

    this.router.events.subscribe((routerEvent:Event)=>{
      // console.log(this.router.config)
      this.checkRouterEvent(routerEvent);
    })

    this.navigationService.configureNavigation({
      pages:[
      {
        name :'Your Appeals',
        icon:'fa fa-folder-open',
        url: this.navigationService.Routes['YOUR_APPEALS']
      },
      {
        name:"Appeals",
        icon:'fa fa-folder-open',
        url: this.navigationService.Routes['APPEAL_APPLICATIONS_LIST']
      },
      ],
    });

    this.navigationService.configureActionItems({
      key:'seconday-navigation',
      pageActions:[
        {
          // OnClick is a store action instance, 
          // because the store module has not yet been instantiated at this point
          onClickExternalLink:this.api.endpoints.DJANGO_ADMIN,
          icon:'fal fa-cog',
          title:'Administration',
          extraClasses:'btn-link'
        },
        {
          // OnClick is a store action instance, 
          // because the store module has not yet been instantiated at this point
          onClickRoute:this.navigationService.Routes['RESOURCES_PAGE'],
          icon:'fal fa-question-circle',
          title:'Resources',
          extraClasses:'btn-link'
        },
        {
          // OnClick is a store action instance, 
          // because the store module has not yet been instantiated at this point
          onClickRoute:this.navigationService.Routes['ACCOUNT_PROFILE'],
          icon:'fal fa-user',
          title:'My Profile',
          extraClasses:'btn-link'
        },
        {
          // OnClick is a store action instance, 
          // because the store module has not yet been instantiated at this point
          onClickRoute:this.navigationService.Routes['USER_LIST'],
          icon:'fal fa-users',
          title:'Users',
          extraClasses:'btn-link'
        },
        // {
        //   // OnClick is a store action instance, 
        //   // because the store module has not yet been instantiated at this point
        //   onClickRoute:this.navigationService.Routes['MESSAGE_INBOX'],
        //   icon:'fa fa-envelope-open-text',
        //   title:'Messages',
        //   extraClasses:'btn-link'
        // },
        {
          // OnClick is a store action instance, 
          // because the store module has not yet been instantiated at this point
          onClick:new RequestLogoutAction(),
          icon:'fal fa-sign-out',
          title:"Sign Out",
          extraClasses:'btn-link'
        },
      ],
    });
  }

  ngOnInit(){
    this.appService.$HasBackdrop.subscribe((res)=>{
      this.showBackdrop = res;
    })
  }

  closeDrawer(){
    return this.ngrxstore.dispatch(new CloseNavigationDrawer());
  }

  hasSideNav(){
    return this.navigationService.hasSideNav();
  }

  checkRouterEvent(event:Event):void{
    if (event instanceof NavigationStart){
      this.loadingRoute = true;
      this.ngrxstore.dispatch( new FreezeNavigation() );
    }
    if (event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel){
      this.loadingRoute = false;
      this.ngrxstore.dispatch( new ThawNavigation() );
    }
  }
}
