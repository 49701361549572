import { Component, Input, Output } from '@angular/core';

@Component({
    selector:'ticket-cta',
    template:require('./ticket-cta.component.html')
})
export class TicketCTA{

    public hasPdf:boolean = false;
    public showPdf:boolean = false;
    public pdfSource:string = null;

    @Input() status:string = null;
    @Input() set pdf(source:string){
        this.hasPdf = true;
        this.refreshPdf(source);
    }

    constructor(){

    }

    refreshPdf(source){
        this.pdfSource = source;
    }

    showDocumentPreview(){
        this.showPdf = !this.showPdf;
    }
}