import {map,take} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationService } from '../navigation.service';
import { Observable } from 'rxjs';
import { LogoutAction } from '../../auth';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    @Inject(NavigationService) public navigationService:NavigationService,
    public router: Router,
    public ngrxstore:Store<any>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    return this.ngrxstore.pipe(take(1),map((storeState)=>{
      switch(storeState.auth.status){
        case "signedIn":
            // Check if you can navigate to that page
            if(this.navigationService.isPermitted(
                  state.url,
                  storeState.auth.roles,
                  this.navigationService.Permissions,
                  '/'+this.navigationService.Routes.SHELL+'/'
            )) {
              return true;
            }else{
              this.navigationService.navigateToLastRoute();
              return false;
            }
          break;
          default:
            // Keep unauthorized users out
            this.ngrxstore.dispatch(new LogoutAction());
            return true;
          break;
        }
      }
    ))
  }

}
