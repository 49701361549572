
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, Output, Renderer2, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common'
import { getDateFromUTC } from '../../../../utilities/helpers';

export const DATE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputDateTime),
    multi: true
  };

@Component({
    selector:'input-datetime',
    template:`
    <style>
    [type="date"] {
        color: transparent;
    }
    input:before{
        content: attr(placeholder) !important;
        color: #000;
        width: 90%;
        background: #FFF;
    }
    </style>
    <input  
            #datetimeInput
            [id]='id'
            class='form-control'
            type="datetime-local"
            (change)="onChange($event)"
            (blur)="touchCallback()" 
            name = "{{name}}"
            [attr.title]="title"
            [attr.aria-label]='title' 
            [attr.disabled]="disabled?true:null"
            [ngClass]='classes'
            class='form-control'
            [placeholder]="inputVar"
            [attr.max]="maxDate ? todaysDate : ''"
        >
        
    `,
    providers:[DATE_ACCESSOR]
})
export class InputDateTime implements ControlValueAccessor{

    @ViewChild('datetimeInput',{static:true}) input:ElementRef;

    @Input() classes:string = '';
    @Input() set label(value){
        this.name = 'input_'+value;
        this.title = _.startCase(value);
        this.id = _.camelCase(value);
    }

    @Input() placeholder = null;
    @Input() maxDate:boolean = false;

    public model:NgbDateStruct;
    public disabled:boolean = false;
    public title:string = '';
    public name:string = '';
    public id:string = '';
    public todaysDate:string = (new Date()).toISOString().split('.')[0];
    

    
    @Output() onDateChangeEmitter:EventEmitter<any> = new EventEmitter();

    constructor( 
        @Inject(LOCALE_ID) public _locale: string,
        public renderer:Renderer2,
        public datepipe: DatePipe
    ){
       
    }
    public inputVar:any = getDateFromUTC(new Date().toUTCString());

    changeCallback=(data:any)=>{};
    touchCallback=()=>{};

    writeValue(value: any){
        let _datePipe = new DatePipe('en-US');
        var dateValue = new  Date (value);
        this.inputVar  = _datePipe.transform(dateValue,'medium');
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled=isDisabled;
    }

    onChange(event){
        let _datePipe = new DatePipe('en-US');
        var dateValue = new  Date (event.target.value);
        this.inputVar  = _datePipe.transform(dateValue,'medium')
        this.onDateChangeEmitter.emit(dateValue);  
        this.changeCallback(dateValue.toISOString());        
    }
}