import { APPEAL_WIZARD_PATHS } from '../appeal-wizard.constants';

const STEP = APPEAL_WIZARD_PATHS.APPEAL_POINT_OF_CONTACT;
export const APPEAL_POINT_OF_CONTACT={
    APPEAL_ROLE:{
        label:'Point of Contact Role',
        storeKey:'point_of_contact_role',
        required:true,
        step:STEP
    },
    POC_NAME:{
        label:'Full Name',
        storeKey:'name',
        required:false,
        step:STEP
    },
    POC_USER_NAME:{
        label:'User Name',
        storeKey:'primary_contact',
        required:true,
        step:STEP
    },
    POC_ADDRESS_LINE_1:{
        label:'Address Line 1',
        storeKey:'address_line_1',
        required:true,
        step:STEP
    },
    POC_ADDRESS_LINE_2:{
        label:'Address Line 2',
        storeKey:'address_line_2',
        required:false,
        step:STEP
    },
    POC_ADDRESS_CITY:{
        label:'City',
        storeKey:'address_city',
        required:true,
        step:STEP
    },
    POC_ADDRESS_STATE:{
        label:'State',
        storeKey:'address_state',
        required:true,
        step:STEP
    },
    POC_ADDRESS_ZIP:{
        label:'Zip Code',
        storeKey:'address_zip',
        required:true,
        step:STEP
    },
    POC_EMAIL:{
        label:'Email Address',
        storeKey:'submitter_email',
        required:true,
        step:STEP
    },
    POC_PHONE_NUMBER:{
        label:'Phone Number (US Only)',
        storeKey:'phone_number',
        required:true,
        step:STEP
    },
    POC_ROLE:{
        label:'Role in the Appeal',
        storeKey:'point_of_contact_role',
        required:true,
        step:STEP
    },
}