import { APPEAL_WIZARD_PATHS } from '../appeal-wizard.constants';

const STEP = APPEAL_WIZARD_PATHS.APPEAL_BORROWER_DETAILS;
export const APPEAL_BORROWER_DETAILS={
    ASYA_BORROWER_ADDRESS_LINE1:{
        label:"Adddress Line 1",
        storeKey:'borrower_address_line_1',
        required:true,
        step:STEP
    },
    ASYA_BORROWER_ADDRESS_LINE2:{
        label:'Address Line 2',
        storeKey:'borrower_address_line_2',
        required:false,
        step:STEP
    },
    ASYA_BORROWER_ADDRESS_CITY:{
        label:'City',
        storeKey:'borrower_address_city',
        required:true,
        step:STEP
    },
    ASYA_BORROWER_ADDRESS_STATE:{
        label:'State',
        storeKey:'borrower_address_state',
        required:true,
        step:STEP
    },
    ASYA_BORROWER_ADDRESS_ZIP:{
        label:'Zip Code',
        storeKey:'borrower_address_zip',
        required:true,
        step:STEP
    },
    ASYA_BORROWER_INDUSTRY:{
        label:'Borrower Industry',
        storeKey:'borrower_industry',
        required:false,
        step:STEP
    },
    ASYA_BORROWER_INDUSTRY_CODE:{
        label:'Industry',
        storeKey:'borrower_industry_code',
        required:false,
        step:STEP
    },
    ASYA_EMP_AT_LOAN:{
        label:'Number of Employees at the time of Loan Application',
        storeKey:'employee_count_at_time_of_loan_application',
        required:false,
        step:STEP
    },
    ASYA_EMP_AT_FORGIVENESS:{
        label:'Number of Employees at the time of Forgiveness Application',
        storeKey:'employee_count_at_time_of_forgiveness_application',
        required:false,
        step:STEP
    },
    ASYA_DEMOGRAPHICS:{
        label:'Demographics',
        storeKey:'application_demographics',
        required:false,
        step:STEP,
        isCollection:true
    }
}