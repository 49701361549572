import { Injectable, Inject } from '@angular/core';
import * as changeCase from 'change-case';
import { API_CONFIG, ApiConfig } from '../../app.interface';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MOCK_USERS } from '../../constants/mock.data';
import { createColumns , traverseJsonArray } from '../../platform/utilities/helpers';

const MOCK_MESSAGES = [
    {
        id:'0',
        entries:[
            {
                sent_at:Date.now().toString(),
                read_at:Date.now().toString(),
                replied_at:Date.now().toString(),
                sender:{
                    name:'Dave',
                    email:'dave@sba.gov'
                },
                lastMessage:true,
                subject:'RE: Question About Loan',
                body:`
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada varius dui, at iaculis lacus volutpat a. Mauris eleifend, quam varius varius fringilla, tellus ligula egestas massa, iaculis vehicula arcu neque id lacus. Fusce tempus vulputate urna ac dignissim. Maecenas sed varius justo. Phasellus in dapibus purus, at luctus metus. Donec leo metus.
                    
                    pellentesque id tempus efficitur, sollicitudin blandit justo. Aenean id sem augue. Phasellus mollis luctus congue. Sed non orci quis eros varius bibendum. Suspendisse potenti. Sed id libero lacinia, auctor felis non, rhoncus mi. Nam malesuada lorem sapien, a semper quam pellentesque ut. Pellentesque suscipit varius sapien a ullamcorper. Aliquam placerat lorem nec mauris pharetra, in interdum quam maximus. Etiam varius vel nulla ut lobortis.
                `
            },
            {
                sent_at:Date.now().toString(),
                read_at:Date.now().toString(),
                replied_at:Date.now().toString(),
                sender:{
                    name:'Dave',
                    email:'dave@sba.gov'
                },
                lastMessage:false,
                subject:'Question About Loan',
                body:`
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada varius dui, at iaculis lacus volutpat a. Mauris eleifend, quam varius varius fringilla, tellus ligula egestas massa, iaculis vehicula arcu neque id lacus. Fusce tempus vulputate urna ac dignissim. Maecenas sed varius justo. Phasellus in dapibus purus, at luctus metus. Donec leo metus.
                `
            }
        ]
    }
]

@Injectable()
export class AccountService{
    
    constructor(
        @Inject(API_CONFIG) public api:ApiConfig,
        public http:HttpClient
    ){}

    getMessages(user_id:string,limit?:number,offset?:number):Observable<any>{
        return new Observable((obs)=>{
            obs.next(MOCK_MESSAGES)
            obs.complete();
        });
    }

    getMessage(user_id:string,message_id:string):Observable<any>{
        return new Observable((obs)=>{
                obs.next(MOCK_MESSAGES.filter((message)=>{
                    return message.id === message_id || {}; 
                })[0])
                obs.complete();
        });
    }

    getCurrentUser():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['CURRENT_USER']).pipe(take(1)).subscribe((response) =>{
                let _response = response["results"];
                _response[0]['is_current']= true;
                obs.next(_response[0]);
                obs.complete();
            }) 
        });
    }

    getUser(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['USERS'] + "/" + id ).pipe(take(1)).subscribe((response) =>{
                obs.next(response);
                obs.complete();
            }) 
        });
    }

    getGroups():Observable<any>{
        console.log("new fn");
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['GROUPS']).pipe(take(1)).subscribe((response) =>{
                obs.next(response);
                obs.complete();
            }) 
        });
    }

    addUser(user: any):Observable<any>{
        return this.http.post(
            this.api.endpoints.USERS,
            user,{observe:'response'}
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    removeUser(obj: any, id:any):Observable<any>{
        return this.http.put(
            this.api.endpoints.USERS+'/'
            +id,obj
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }
       
    getUsersList(offset?:number,limit?:number,ordering?:any,search?:any):Observable<any>{
        let _params = {};

        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();
        if(ordering) _params['ordering'] = ordering.toString();
        if(search) _params['search'] = search.toString();
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.USER,{
                params:_params
            }).pipe(
                take(1),
                catchError(err => throwError(err))
            ).subscribe((response)=>{
                let _totCount= response['count'];
                let _columnList = {
                    "User Name":['username'],
                    "Email":['email'],
                   // "Organization":['org'],
                   "Role":['groups','0','name']
                }
                let _columns = createColumns(_columnList);
                // _columns.push({
                //     name:"Actions",
                //     prop:"actions"
                // });
                let _rows = traverseJsonArray(_columnList,response["results"]);
               // let  _rows = response["results"];
                obs.next({
                    totalCount: _totCount,
                    columns:_columns,
                    rows:_rows
                });
                obs.complete();
            })

        //     this.http.get(this.api.endpoints.USERS)
        //         .pipe(take(1))
        //         .subscribe((response)=>{
        //             let _columns= Object.keys(response['results'][0]).map((key)=>{
        //                 let _name = null;
        //                 switch(key){
        //                     case 'organization_admin':
        //                         _name = "Admin"
        //                     break;
        //                     case 'read_only_ppp':
        //                         _name = 'Read Only'
        //                     break;
        //                     case 'use_twofa_sms':
        //                         _name='2fa';
        //                     break;
        //                     default:
        //                         _name = changeCase.titleCase(key)
        //                     break;
        //                 }
        //                 return {
        //                     name:_name,
        //                     prop:key
        //                 }
        //             })
        //             _columns.push({
        //                 name:"Actions",
        //                 prop:"actions"
        //             });
        //             let  _rows = response['results'];
        //             obs.next({
        //                 columns:_columns,
        //                 rows:_rows
        //             });
        //             obs.complete();
                // });
        });
    }
}
