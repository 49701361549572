
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, Output, Renderer2, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common'
import { getDateFromUTC } from '../../../../utilities/helpers';

export const DATE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputDate),
    multi: true
  };

@Component({
    selector:'input-date',
    template:`
    <style>
    [type="date"] {
        color: transparent;
    }
    input:before{
        content: attr(placeholder) !important;
        color: #000;
        width: 90%;
        background: #FFF;
    }
    </style>
    <input  
            #dateInput
            [id]='id'
            class='form-control'
            style="width: 16rem;"
            type="date"
            (change)="onChange($event)"
            (blur)="touchCallback()" 
            name = "{{name}}"
            [attr.title]="title"
            [attr.aria-label]='title' 
            [attr.disabled]="disabled?true:null"
            [ngClass]='classes'
            class='form-control'
            [placeholder]="inputVar"
            [attr.max]="maxDate ? todaysDate : ''"
            [attr.min]="minDate ? todaysDate : ''"
        >
        
    `,
    providers:[DATE_ACCESSOR]
})
export class InputDate implements ControlValueAccessor{

    @ViewChild('dateInput',{static:true}) input:ElementRef;

    @Input() classes:string = '';
    @Input() set label(value){
        this.name = 'input_'+value;
        this.title = _.startCase(value);
        this.id = _.camelCase(value);
    }

    @Input() placeholder = null;
    @Input() maxDate:boolean = false;
    @Input() minDate:boolean = false;

    public model:NgbDateStruct;
    public disabled:boolean = false;
    public title:string = '';
    public name:string = '';
    public id:string = '';
    public todaysDate:string = this.datepipe.transform(new Date(),"yyy-MM-dd");
    
    @Output() onDateChangeEmitter:EventEmitter<any> = new EventEmitter();

    constructor( 
        @Inject(LOCALE_ID) public _locale: string,
        public renderer:Renderer2,
        public datepipe: DatePipe
    ){
       
    }
    public inputVar:any = getDateFromUTC(new Date().toUTCString());

    changeCallback=(data:any)=>{};
    touchCallback=()=>{};

    writeValue(value: any){
        if(value != null){
            var dateValue = new  Date (value);
            var dateValueUTC = dateValue.toUTCString();
            this.inputVar  = getDateFromUTC(dateValueUTC);
            // this.inputVar  =this.datepipe.transform(dateValue, 'mediumDate');
            this.changeCallback(dateValue.toISOString());
            //this.renderer.setProperty(this.input.nativeElement,'value',value);
        }else{
            this.inputVar = "Mon dd, yyyy";
        }
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled=isDisabled;
    }

    onChange(event){
        var dateValue = new  Date (event.target.value);
        var dateValueUTC = dateValue.toUTCString();
        this.inputVar  = getDateFromUTC(dateValueUTC);
        
        this.onDateChangeEmitter.emit(dateValueUTC);
        this.changeCallback(dateValue.toISOString());        
    }
}