import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { NavigationService } from '../navigation.service';

import { NavigationConfig, NAVIGATION_CONFIG } from '../navigation.interface';
import { getWindowState } from '../store/navigation.selectors';

interface NavigationConfiguration{
  key:String,
  disabledWhen?:any[];
  homeUrl?:string;
}

@Component({
  selector:'page-view',
  template:``
})
export class PageView{

  public topNavConfiguration:TopNavConfiguration;
  public navigationConfiguration:NavigationConfiguration;
  public pageName:string = '';
  public routePaths = {};
  public fullRoutes = {};
  public lastNavigationState:any = {};

  public isMobile:boolean = false;
  public isTablet:boolean = false;
  public isDesktop:boolean = false;
  public isWidescreen:boolean = false;
  public isFullHD:boolean = false;

  public refreshing:any = {};
  public frozen:any = {};
  public hidden:any = {};

  get LastNavigationState(){
    return this.lastNavigationState;
  }

  get Paths(){
    return this.routePaths;
  }
  get Routes(){
    return this.fullRoutes;
  }

  set TopNav(configuration:TopNavConfiguration){
    this.topNavConfiguration = configuration;
    this.navigationService.configureActionItems(configuration);
  }

  get TopNav(){
    return this.topNavConfiguration;
  }

  set Navigation(configuration:NavigationConfiguration){
    this.navigationConfiguration = configuration;
    this.navigationService.configureNavigationForPage(configuration);
  }

  get Navigation(){
    return this.navigationConfiguration;
  }

  set Name(name:string){
    this.pageName = name;
  }

  get Name(){
    return this.pageName;
  }

  constructor(
    @Inject(NAVIGATION_CONFIG) protected config:NavigationConfig,
    public navigationService:NavigationService){
    this.fullRoutes = config.routes;
    this.routePaths = config.paths;
    this.lastNavigationState = this.navigationService.CurrentNavigationState || {};
  }

  refresh(target:string){
    this.refreshing = Object.assign({},this.refreshing,{
      [target]:true
    })
  }

  completeRefresh(target:string){
    let _refreshing = {}
    Object.keys(this.refreshing).map((key)=>{
      if(key !== target){
        _refreshing['key'] = true;
      }
    });
    this.refreshing = Object.assign({},_refreshing)
  }

  thaw(target:string){
    let _frozen = {}
    Object.keys(this.frozen).map((key)=>{
      if(key !== target){
        _frozen['key'] = true;
      }
    });
    this.frozen = Object.assign({},_frozen)
  }

  freeze(target:string){
    this.frozen = Object.assign({},this.frozen,{
      [target]:true
    })
  }

  hide(target:string){
    this.hidden = Object.assign({},this.hidden,{
      [target]: true
    });
  }

  show(target:string){
    let _hidden = {}
    Object.keys(this.hidden).map((key)=>{
      if(key !== target){
        _hidden['key'] = true;
      }
    });
    this.hidden = Object.assign({},_hidden)
  }
}
