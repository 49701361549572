import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LogoutAction } from './store/auth.actions';
import { AUTH_PATHS } from './constants/auth.constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, public ngrxstore:Store<any>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.auth.Token){
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${this.auth.Token}`
        }
      });
    }

    return next.handle(request).pipe(
      tap((evt)=>{
        // console.log('token interceptor',evt)
        return of(evt);
      }),
      catchError((err:any)=>{
        if(err instanceof HttpErrorResponse) {
          // If Unauthorized
          if(err.status === 401 && !new RegExp(AUTH_PATHS.RESET_PASSWORD).test(err.url) && !err.url.includes("smartystreets.com/lookup") ){
            this.ngrxstore.dispatch(new LogoutAction());
          }
          // If Forbidden
          if(err.status === 403 && !new RegExp(AUTH_PATHS.RESET_PASSWORD).test(err.url) ){
            this.ngrxstore.dispatch(new LogoutAction());
          }
        }
        return throwError(err);
      })
    );
  }
}
