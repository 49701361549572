import { Action } from '@ngrx/store';
import { APPEAL_LIST_COLUMNS_SORTING } from '../../appeals-applications/constants/appeals-applications.constants';
import { APPEAL_WIZARD_ACTIONS } from '../constants/appeal-wizard.constants';


// Wizard Navigation State Actions 
// ---------------------------------
export class AppealCompleteStep implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.COMPLETE_STEP;
    constructor() {}
}

export class AppealPreviouStep implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.PREVIOUS_STEP;
    constructor() {}
}

export class AppealJumpToStep implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.JUMP_TO_STEP;
    constructor(public payload: string) {}
}

export class NavigatedFromSummary implements Action{
    readonly type = APPEAL_WIZARD_ACTIONS.IS_NAVIGATED_FROM_SUMMARY;
    constructor(public payload:boolean = false) {}
}

// Wizard Setup Actions 
// ---------------------------------
export class AppealSetup implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.SETUP_STEPS;
    constructor(public payload: {
        stepNames: string[],
        steps: string[]
        startOn: string,
        reference_id: string
    }) {
        // console.log('appeal setup')
    }
}

export class ReportWizardError implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.REPORT_WIZARD_ERROR;
    constructor(public payload: any) {}
}

export class StartNewAppealWizard implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.START_NEW_WIZARD;
    constructor(public payload: any) {}
}

export class RequestNewAppeal implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.REQUEST_NEW_APPEAL;
    constructor(public payload: {
        values: any
    }) {}
}

export class ContinueAppealApplication implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.CONTINUE_APPEAL_APPLICATION;
    constructor(public reference_id: string) {}
}

// Wizard Value State Actions 
// ---------------------------------
export class StoreAppealValues implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.STORE_VALUES;
    constructor(public payload: any) {}
}

export class RecordUnsavedValueKeys implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.RECORD_UNSAVED_VALUE_KEY;
    constructor(public payload:any){}
}

export class WipeAppealStoreValues implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.WIPE_STORE_VALUES;
    constructor() {}
}

// Wizard API Actions
export class SaveAppeal implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.SAVE_APPEAL;
    constructor(public payload:{
        initialSave:boolean,
        callback?:any,
        user_ref?:string,
        submitSaveFlag?:boolean
    } = { initialSave:false, submitSaveFlag:false }) {}
}

export class SaveAppealAndSignCOS implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.SAVE_APPEAL_AND_SIGN_COS;
    constructor(public payload: string) {}
}

export class AfterSignedCertificateOfService implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.SUBMIT_APPEAL_SUCCESS;
    constructor(){}
}

export class SignCertificateOfService implements Action {
    readonly type =  APPEAL_WIZARD_ACTIONS.SIGN_APPEAL_COS;
    constructor(public payload:{
        signature_for_electronic_filing:string,
        documents:any[]      
    }, public docketFilingRef:string){}
}

export class AppealWizardComplete implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.APPEAL_WIZARD_COMPLETE;
    constructor(){}
}

export class CreateReconsiderationAppeal implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.CREATE_RECONSIDERATION_APPEAL;
    constructor(public payload:{
        type:string,
        reconsideration_petition_reason?:string,
        parent_ticket:string,
        signature_for_electronic_filing?:string,
        lender:string,
        title:string,
        action:any,
        submitter:string,
        callback?:any
    }) {}
}

export class CreatePFRAppeal implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.CREATE_PFR_APPEAL;
    constructor(public payload:{
        type:string,
        reconsideration_petition_reason?:string,
        parent_ticket:string,
        signature_for_electronic_filing?:string,
        lender:string,
        title:string,
        action:any,
        submitter:string,
        callback?:any
    }) {}
}

export class FileReconPFRAppeal implements Action {
    readonly type = APPEAL_WIZARD_ACTIONS.FILE_RECON_PFR_APPEAL;
    constructor(public appealId:string , public payload:{
        lender:string,
        title:string,
        initial_attachment_reference_id_for_filing:string,
        callback?:any
    }) {}
}