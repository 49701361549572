import { Component, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { FreezeNavigation, getWindowState, NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView, ThawNavigation, WindowRef } from '../../../platform/modules/navigation';
import { PageViewAnchors } from '../../../platform/modules/navigation/components/page-view-with-anchors.component';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { AppealsApplicationsService } from '../appeals-applications.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { FilePetition, TriggerModal, TriggerToast } from '../../../platform/modules/notification';
import { MOCK_STATES } from '../../../constants/mock.data';
import { AuthConfiguration, AUTH_CONFIG, AUTH_PATHS, InviteUser, SaveAppealProfileChanges } from '../../../platform/modules/auth';
import { SaveAppealTicket } from '../../tickets/store/ticket.actions';
import { DatePipe } from '@angular/common';
import { API_ENDPOINTS, BASE_URL } from '../../../constants/rest_api.constants';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { take } from 'rxjs/operators';
import { BUCKET_CONFIG } from '../../../platform/modules/shared/modules/bucket/bucket.interface';
import { ValidEmailValidator } from '../../../platform/utilities/validators/valid-email.validator';
import { FileItem } from '@wkoza/ngx-upload';
import { convertToPhoneFormat, getDateFromUTC, createColumns,traverseJsonArray } from '../../../platform/utilities/helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { selectFilePetitionDetails } from '../../../platform/modules/notification/store/notification.selector';
import { CreatePFRAppeal, CreateReconsiderationAppeal, FileReconPFRAppeal } from '../../appeal-wizard/store/appeal-wizard.actions';
import { APPEAL_ACTION_TYPES } from '../../../constants/app.constants';

@Component({
    selector:'you-appeal',
    template:require('./your-appeal.component.html')
})
export class YourAppeal extends PageViewAnchors{

    public bucketItems:BucketItemConfiguration[] = [];
    public appealStatus = 'null';
    public canUploadDocuments:boolean = true;
    public appealDetails:any = {};
    //public appealDocuments:any = [];
    public appealDocumentsColumnsForDisplay:any[] = [];
    public appealForm:FormGroup = new FormGroup({});
    public columnMode = ColumnMode;
    public appealDocket:any = {};
    public appealDocketRows: any = [];
    public appealDocketColumns: any = [];
    public formGroupAppeal = new FormGroup({});
    
    public ticketDocuments = {rows:[],columns:[]};
    public ticketDraftDocuments = {rows:[],columns:[]};
    public ticketDocumentsColumnsForDisplay = [];
    public ticketDocumentsPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
    public docsPerPage: any= this.appDefaults.ui.LIST_PAGE_LIMIT;
    public docketPageOffset:any = 0;
    public totalDocketCount = 0;
    public currentOrderedBy:any = {ordering:'-created'};
    

    public selectedDoc = [];
    public enableAssignDocket = false;
    public cosEnabled = false;
    public signCOSForm:FormGroup = new FormGroup({});
    public certificateUrl:string = null;
    public showCertificates:boolean = false;

    public updatedAppealData:AppellantContactDetails = null;

    public hasRecentlyUploadedDocument:boolean = false;

    
    public uploadedDoc :any =[];
    public stateOptions = [];
    public roleOptions = [];

    public inviteUser:boolean = false;
    public isPocRoleUpdated:boolean = false;
    public isPocEmailUpdated: boolean = false;
    public isInviteUserEnabled :boolean = false;
    public isSaveEnabled:boolean = false;

    public judgeAssignee:any ={};
    public attorneyAssignee:any ={};
    public showAdditionalDoc:boolean = true;
    public docUploadSpinner:boolean= false;

    public uploadFn = this.uploadDocumentsCallback.bind(this);
    public anchors:any = [];

    public isAddressInput:boolean = true;
    public threadLabels = { message:'Questions' };
    public ticketNotesList = [];

    public bucketConfiguration:BucketConfiguration = {
        title:"",
        classes:'no-border-left no-border-right no-border-bottom',
        categories:[],
        labels:{
            drag_and_drop_valid:'You can now drop files here.',
            drag_and_drop_invalid:'You must Name your file and select a Type before dropping files here.'
        }
    }

    public originalAppealRows:any = [];
    public originalAppealColumns: any = [];
    public reconsiderationAppealRows:any = [];
    public reconsiderationAppealColumns: any = [];
    public openNewTicket:boolean = false;
    public currentUser:any={};
    public isNotEligibleForPetition:boolean = false;
    public isDisabledPetitionOverRecon:boolean = false;
    public isNotEligibleForReconsideration:boolean = false;
    public isEligibleForPetitionOverRecon: boolean = false;
    public isDayLightSavingsOn= false;

    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration, 
        public activatedRoute:ActivatedRoute,
        public el:ElementRef,
        public windowRef:WindowRef,
        public ngrxstore:Store<any>,
        public formBuilder:FormBuilder,
        public navigationService:NavigationService,
        public appealsApplicationService:AppealsApplicationsService,
        public router:Router
    ){
        super(
            configuration,
            el,
            windowRef,
            ngrxstore,
            navigationService
        );

        this.Name = 'Forgiveness Appeal';
        this.appealDetails = this.activatedRoute.snapshot.data.appeal.details;

        this.appealDetails.type =  this.appealDetails.type.toUpperCase();

        this.roleOptions = Object.keys(this.authConfig.roles).map((key)=>{
            return this.authConfig.roles[key];
        }).filter((option)=>{
            return (
                option.slug === this.authConfig.roles.APPELLANT.slug || 
                option.slug === this.authConfig.roles.APPELLANT_COUNCEL.slug
            );
        });

        this.stateOptions = MOCK_STATES.map((obj)=>{
            obj['value'] = obj['abbreviation']
            obj['label'] = obj['name']
            return obj;
        });

        this.cosEnabled = this.activatedRoute.snapshot.data.appeal.cosSettings
        this.signCOSForm = this.formBuilder.group({
            docketId:[null,Validators.required],
            signature:[null,Validators.required],
            documents:[null,Validators.required]
        })

        this.bucketConfiguration = Object.assign({},this.bucketConfiguration,{
            categories:this.activatedRoute.snapshot.data.appeal.documentCategories
        });

        this.ticketNotesList = this.activatedRoute.snapshot.data.appeal.notes['results'];
        

        this.ticketDocuments = this.activatedRoute.snapshot.data.appeal.documents;
       
        this.appealDetails.typeToDisplay = this.getAppealTypeLabel(this.appealDetails.type, this.appealDetails.submitter);

        var _final_loan_review_decision_received_dateUTC = new Date(this.appealDetails.final_loan_review_decision_received_date).toUTCString(); 
        this.appealDetails.final_loan_review_decision_received_dateFormatted = getDateFromUTC(_final_loan_review_decision_received_dateUTC);

        var _filing_dateUTC = new Date(this.appealDetails.filing_date).toUTCString(); 
        this.appealDetails.filing_dateFormatted = getDateFromUTC(_filing_dateUTC);
        
        this.appealDocket = this.activatedRoute.snapshot.data.appeal.appealDocket;
        this.totalDocketCount = this.appealDocket.totalCount;
        this.appealStatus = this.appealDetails.action.name;

        this.currentUser = this.activatedRoute.snapshot.data.appeal.currentUser;

        if((this.appealStatus === this.authConfig.status.COMPLETE.name) ){
                this.showAdditionalDoc = false;
                this.anchors= [
                    {label:'About',id:'#about',scrollOffset:200},
                    {label:'Docket',id:'#docket',scrollOffset:200},
                    {label:'Appellant/SBA OGC Questions',id:'#appellantQuestions',scrollOffset:220},
                ];
            }else{
                this.showAdditionalDoc = true;
                this.anchors= [
                    {label:'About',id:'#about',scrollOffset:200},
                    {label:'Docket',id:'#docket',scrollOffset:200},
                    {label:'File Documents',id:'#documentsUploadContainer',scrollOffset:200},
                    {label:'Appellant/SBA OGC Questions',id:'#appellantQuestions',scrollOffset:220},
                ];
            }

            if(this.appealDetails.type == this.appDefaults.ui.RECONSIDERATION_SLUG.toUpperCase() ||
            this.appealDetails.type == this.appDefaults.ui.PFR_SLUG.toUpperCase() ||
            this.appealDetails.type == this.appDefaults.ui.PFR_ON_RECONSIDERED_SLUG.toUpperCase()){
                this.anchors.push( {label:'Original Appeal',id:'#original',scrollOffset:220});
                this.getOriginalAppeal();
            }

            if((this.appealDetails.type == appDefaults.ui.ORIGINAL_APPEAL_SLUG.toUpperCase() || 
            this.appealDetails.type == appDefaults.ui.RECONSIDERATION_SLUG.toUpperCase()) && 
            this.appealDetails.child_tickets.length > 0){
                this.anchors.push( {label:'Reconsideration Appeal',id:'#reconsideration',scrollOffset:220});
                this.getReconsiderationAppeal();
            }


            this.anchors.push({label:'Questions?',id:'#questions',scrollOffset:220});
        
        if(this.appealDetails.assignees.length > 0){
            this.appealDetails.assignees.map((item => {
                if(item["groups"][0].name === this.authConfig.roles.JUDGE.name){
                    this.judgeAssignee = Object.assign({},item);
                    this.judgeAssignee.formatPhone = null;
                    if(this.judgeAssignee.phone != null){
                        this.judgeAssignee.formatPhone = convertToPhoneFormat(this.judgeAssignee.phone);
                    }
                }
                if(item["groups"][0].name === this.authConfig.roles.ATTORNEY.name){
                    this.attorneyAssignee =  Object.assign({},item);
                    this.attorneyAssignee.formatPhone = null;
                    if(this.attorneyAssignee.phone != null){
                        this.attorneyAssignee.formatPhone = convertToPhoneFormat(this.attorneyAssignee.phone);
                    }
                }
            }));
        }

        this.appealDetails.poc_role = this.appealDetails.primary_contact.groups.map((group)=>{
            return group.name;
        }).join(', ');
        
        this.appealForm = this.formBuilder.group({
            lender_name:[this.appealDetails.lender_name]
        });

        this.buildForm();

        this.bucketItems = [
            {
                filename:'Tax Statement',
                reference_id:'012221'
            }
        ];

        this.navigationService.scrollToTopPostion({
            top:'220px'
        })

        this.refreshDocketTable();
        this.refreshDocumentsTable();

        this.validatePetitionFile();

        let _windowState = this.ngrxstore.select(getWindowState()).subscribe((state)=>{
            this.isTablet = ( state.size === 'isTablet' || state.size === 'isMobile' || state.size === 'isDesktop' ) ;
        });
        this.storeZombieByKey(
            'wizards_window_state',_windowState
        );
        this.isDayLightSavingsOn = this.isDST(new Date());
    }

    isDST(d) {
        let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();    
    }

    getAppealTypeLabel(type,submitter){
        let _type_to_display = "";
        switch (type.toLowerCase()){
            case 'reconsideration':
                _type_to_display = 'RECONSIDERED DECISION'; 
                break;
            case 'pfr':
                _type_to_display = 'PETITION FOR RECONSIDERATION';
                break;
	        case 'pfr-on-reconsidered':
                _type_to_display = 'PFR of the RECONSIDERED DECISION';
                break;
            case 'ppp':
                _type_to_display = 'PPP Appeal';
                break;
            default:
                _type_to_display = 'PPP Appeal';
        }
        return _type_to_display;
    }

    refreshDocketTable(){
        let _datePipe = new DatePipe('en-US');
        this.appealDocketColumns = new Array();
        this.appealDocketRows = this.appealDocket.docketRows;
        this.appealDocketRows.map((i) =>{
           // i.date =  _datePipe.transform(i.date,'MMMM d, y');
            i.file = BASE_URL + i.file;
        });

        this.appealDocket.docketColumns.map((i) => {
            if(!this.cosEnabled){
                if(i.prop !== 'file' && i.prop !== 'certificate_of_service'){
                    this.appealDocketColumns.push(i);
                }
            }else{
                if(i.prop !== 'file'){
                    this.appealDocketColumns.push(i);
                }
            }
        });
        this.completeRefresh('docket');

    }

    refreshDocumentsTable(){
        this.ticketDocumentsColumnsForDisplay = new Array();
        
        this.ticketDocuments['columns'].map((value)=>{
            if(value.prop !== 'file'){
                this.ticketDocumentsColumnsForDisplay.push(value);
			}
        });
        this.ticketDraftDocuments = Object.assign({},{
            columns:this.ticketDocuments.columns,
            rows:this.ticketDocuments.rows.filter((document)=>{
                return (document.isMultiple) ? document.data[0].is_in_docket_filing === false : document.data.is_in_docket_filing === false ;
            })
        });

        let _datePipe = new DatePipe('en-US');

        this.ticketDraftDocuments.rows.map((i) =>{
            i.date = _datePipe.transform(i.date,'MMMM d, y');
        })
        this.completeRefresh('documents');

    }

    buildForm(){

        this.killTheseZombies(['formWatchSub']);

        let _ogcFormatPhone = "";
        if(this.appealDetails.primary_contact_ogc != null && this.appealDetails.primary_contact_ogc.phone !=null){
            _ogcFormatPhone = convertToPhoneFormat(this.appealDetails.primary_contact_ogc.phone.replace("+1",""));
        }

        this.appealForm = this.formBuilder.group({
            judgeAssignee_username:[(this.judgeAssignee != null) ? this.judgeAssignee.username : "" ],
            judgeAssignee_email:[(this.judgeAssignee != null) ? this.judgeAssignee.email : "" ],
            judgeAssignee_phone:[(this.judgeAssignee != null) ? this.judgeAssignee.phone : "" ],
            attorneyAssignee_username:[(this.attorneyAssignee != null) ? this.attorneyAssignee.username : "" ],
            attorneyAssignee_email:[(this.attorneyAssignee != null) ? this.attorneyAssignee.email : "" ],
            attorneyAssignee_phone:[(this.attorneyAssignee != null) ? this.attorneyAssignee.phone : "" ],
            ogc_username:[(this.appealDetails.primary_contact_ogc != null ) ? this.appealDetails.primary_contact_ogc.username : ""],
            ogc_email:[(this.appealDetails.primary_contact_ogc != null ) ? this.appealDetails.primary_contact_ogc.email : ""],
            ogc_phone:[(this.appealDetails.primary_contact_ogc != null ) ? this.appealDetails.primary_contact_ogc.phone : ""],
            ogc_format_phone: _ogcFormatPhone,
            loan_amount_in_cents: [(this.appealDetails.loan_amount_in_cents)/100],
            eidl_advance_amount_in_cents:[(this.appealDetails.eidl_advance_amount_in_cents)/100],
            forgiveness_amount_received_in_cents:[(this.appealDetails.forgiveness_amount_received_in_cents)/100],
            forgiveness_amount_requested_in_cents: [(this.appealDetails.forgiveness_amount_requested_in_cents)/100],
            forgiveness_amount_requested_by_lender_in_cents: [(this.appealDetails.forgiveness_amount_requested_by_lender_in_cents)/100]

        });

        this.isAddressInput = this.appealDetails.address_line_1 !== '' || this.appealDetails !== null;

        let _formGroup = {
            username:[this.appealDetails.primary_contact.username, [Validators.required,Validators.maxLength(80)]],
            name:[this.appealDetails.primary_contact.name, [Validators.required,Validators.maxLength(80)]],
            first_name:[this.appealDetails.primary_contact.first_name, [Validators.required,Validators.maxLength(80)]],
            last_name:[this.appealDetails.primary_contact.last_name, [Validators.required,Validators.maxLength(80)]],
            address_line_1:[this.appealDetails.address_line_1,[Validators.required,Validators.maxLength(80)]],
            address_line_2:[this.appealDetails.address_line_2,[Validators.maxLength(80)]],
            address_city:[this.appealDetails.address_city,[Validators.required,Validators.maxLength(40)]],
            address_state:[this.appealDetails.address_state,Validators.required],
            address_zip:[this.appealDetails.address_zip,[Validators.required,Validators.maxLength(5)]],
            phone_number:[this.appealDetails.phone_number,[Validators.required,Validators.minLength(12),Validators.maxLength(12)]],
            phone:[this.appealDetails.primary_contact.phone,[Validators.required]],
            submitter_email:[this.appealDetails.primary_contact.email,[Validators.required,Validators.email,ValidEmailValidator(),Validators.maxLength(80)]],
            point_of_contact_role:[this.appealDetails.primary_contact.groups[0].name.toLowerCase(),Validators.required],
        } 


        this.formGroupAppeal = new FormGroup({});
        this.formGroupAppeal = this.formBuilder.group(_formGroup);

        this.formGroupAppeal.valueChanges.subscribe((changes)=>{
            this.inviteUser = this.userMayNeedInvitiation();
            this.updatedAppealData ={...this.formGroupAppeal.value,
                title: this.appealDetails.title,
                lender:this.appealDetails.lender.id
            }
        }); 

    }

    setUserName($event){
        this.formGroupAppeal.patchValue({
            username: this.formGroupAppeal.controls.first_name.value+" "+this.formGroupAppeal.controls.last_name.value,
            name: this.formGroupAppeal.controls.first_name.value+" "+this.formGroupAppeal.controls.last_name.value,
        });
    }

    userMayNeedInvitiation(){
        this.isPocRoleUpdated = false;
        this.isPocEmailUpdated = false;
      
        this.isPocEmailUpdated = this.formGroupAppeal.controls.submitter_email.value != this.appealDetails.primary_contact.email;
        this.isPocRoleUpdated = this.formGroupAppeal.controls.point_of_contact_role.value !== (this.appealDetails.primary_contact.groups[0].name).toLowerCase() ;       

        this.validateInvite();
        this.validateSave();
        return( this.isPocEmailUpdated || this.isPocRoleUpdated);
    }

    validateSave(){
        this.isSaveEnabled = false;
        let _changed:boolean[] = [];
        let _initialData = {
            name:this.appealDetails.primary_contact.name,
            first_name:this.appealDetails.primary_contact.first_name,
            last_name:this.appealDetails.primary_contact.last_name,
            phone: this.appealDetails.primary_contact.phone ? this.appealDetails.primary_contact.phone : '',
            address_line_1:this.appealDetails.address_line_1,
            address_line_2:this.appealDetails.address_line_2,
            address_city:this.appealDetails.address_city,
            address_state:this.appealDetails.address_state,
            address_zip:this.appealDetails.address_zip,
        }

        Object.keys(this.formGroupAppeal.controls).map((key)=>{
            if(_initialData[key] !== undefined && _initialData[key] !== null){
                if(this.formGroupAppeal.controls[key].value !== _initialData[key]){
                    _changed.push(true);
                }
            }
        });

        this.isSaveEnabled =  Object.keys(_changed).length > 0 ;
        
    }


    validateInvite(){
        this.isInviteUserEnabled = false;
        let _initialData = {
            phone: this.appealDetails.primary_contact.phone ? this.appealDetails.primary_contact.phone : ''
        }
        if(this.isPocRoleUpdated){
            if(this.isPocEmailUpdated && this.formGroupAppeal.controls.submitter_email.valid &&
                (this.formGroupAppeal.controls.phone.value !== _initialData['phone'])){
                    this.isInviteUserEnabled = true
                }
        }else{
            if(this.isPocEmailUpdated && this.formGroupAppeal.controls.submitter_email.valid){
                this.isInviteUserEnabled = true
            }
        } 
    }

    onRoleChange(){
        if(this.isPocRoleUpdated){
            this.formGroupAppeal.patchValue({
                'phone': "",
                'submitter_email': ""
                });
        }else{
            this.formGroupAppeal.patchValue({
                'phone': this.appealDetails.primary_contact.phone ? this.appealDetails.primary_contact.phone : '',
                'submitter_email': this.appealDetails.primary_contact.email
                });
        }
    }

    backToMyAppeals(){
        this.navigationService.navigate([this.Routes['YOUR_APPEALS']]);
    }

    goToResources(){
        this.navigationService.navigate([this.Routes['RESOURCES_PAGE']]);
    }

    onBucketUpdate($event){
        this.docUploadSpinner= true;
        //console.log('onBucketUpdate: ',$event.file);
    }


    onBucketError($event){
        if($event.type === 'size_exceeded'){
            this.ngrxstore.dispatch(new TriggerToast({
                style:'danger',
                message:"That File is too Large!"
            }));
        }
        this.docUploadSpinner= false;
    }

    onBucketDeleteItem(slug:string){
        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Are you sure you want to Delete the Document?",
              approveLabel: "Delete",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                this.appealsApplicationService.removeDocumentFromAppeal(
                    this.activatedRoute.snapshot.paramMap.get('id'),
                    slug
                ).pipe(take(1)).subscribe((response)=>{
                    this.uploadedDoc = this.uploadedDoc.filter((doc) =>{
                        return doc.reference_id != slug
                    });
                    this.bucketConfiguration = Object.assign({},this.bucketConfiguration,{
                        items:this.uploadedDoc
                    });
                    this.ngrxstore.dispatch( new TriggerToast(
                        {
                            message:"Removed ",
                            style:'warning'
                        }
                    ) )
                    this.refreshDocuments();
                    this.refreshDocket();
                })
            }
          }))
    }

    

    refreshDocuments(){
        this.refresh('documents');
        this.appealsApplicationService.getAppealDocuments(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((documents)=>{
            this.ticketDocuments = documents;
            this.refreshDocumentsTable();
        })  
    }


    refreshDocket(){
        this.refresh('docket');
        this.appealsApplicationService.getAppealDocket(
            this.activatedRoute.snapshot.paramMap.get('id'),this.docketPageOffset,
            this.docsPerPage,{ordering:'-created'}
        ).pipe(take(1)).subscribe((docketItems)=>{
            this.appealDocket = docketItems;
            this.totalDocketCount = this.appealDocket.totalCount;
            this.refreshDocketTable();
        })  
    }

    onBucketUploadSuccess(){
        this.bucketConfiguration = Object.assign({},this.bucketConfiguration,{
             items:this.uploadedDoc
         });
         this.hasRecentlyUploadedDocument = true;
         this.refreshDocuments();
         this.docUploadSpinner= false;
         // this.refreshDocket();
     }

     uploadDocumentsCallback(packet:{filePacket?:FileItem[],fileItem?:FileItem,formGroupData:any}){
        return new Observable((obs:any)=>{
            
            this.appealsApplicationService.uploadAppealDocument(
                this.activatedRoute.snapshot.paramMap.get('id'),
                //this.updatedAppealData.action,
                packet.fileItem
            ).pipe(take(1)).subscribe((response)=>{
                this.uploadedDoc.push(response);
                obs.next(true);
                obs.complete();
            },(err)=>{
                if(err instanceof HttpErrorResponse){
                    this.ngrxstore.dispatch(new TriggerModal({
                        content:{
                            title:"Unable to Upload",
                            message:err.error.file[0]
                        }
                    }));
                    this.docUploadSpinner=false;
                    obs.next(false,err);
                    obs.complete();
                }
            })
        });
     }

     inviteUserToAppeal(){
        if(null === this.updatedAppealData){
            this.updatedAppealData ={...this.formGroupAppeal.value,
                title: this.appealDetails.title,
                lender:this.appealDetails.lender.id
            }
        }
        //this.ngrxstore.dispatch(new FreezeNavigation() );
        this.ngrxstore.dispatch(new TriggerModal({
            type:'confirmation-input',
            inputValue:this.updatedAppealData.submitter_email,
            content: {
                title: "Confirm User's Email Address",
                message: "Please enter the email one more time",
                approveLabel: "Save",
                cancelLabel: "Cancel"
            },
            onCancel: () => {},
            onApprove: () => {
                this.isInviteUserEnabled = false;
                let _group = this.updatedAppealData.point_of_contact_role;
                if(_group === 'appellant') _group = 'Appellant';
                if(_group === 'appellant counsel') _group = 'Attorney';
                this.ngrxstore.dispatch(
                    new InviteUser({
                        first_name: this.updatedAppealData.first_name,
                        last_name: this.updatedAppealData.last_name,
                        name:this.updatedAppealData.username,
                        email:this.updatedAppealData.submitter_email,
                        ticket:this.activatedRoute.snapshot.paramMap.get('id'),
                        group:_group,
                        phone:this.updatedAppealData.phone,
                        address_line_1:this.updatedAppealData.address_line_1,
                        address_line_2:this.updatedAppealData.address_line_2,
                        address_city:this.updatedAppealData.address_city,
                        address_state:this.updatedAppealData.address_state,
                        address_zip:this.updatedAppealData.address_zip,
                        invitation_url:AUTH_PATHS.INVITE_USER
                    })
                );
                
            }
        }))
    }

    saveAppeal(){
        if(null === this.updatedAppealData){
            this.updatedAppealData ={...this.formGroupAppeal.value,
                title: this.appealDetails.title,
                lender:this.appealDetails.lender.id
            }
        }
        this.updatedAppealData['reference_id']= this.appealDetails.primary_contact.reference_id;
        this.updatedAppealData['phone'] = (this.updatedAppealData.phone !== null) ? ((this.updatedAppealData.phone.indexOf('+1') > -1) ? this.updatedAppealData.phone : "+1"+this.updatedAppealData.phone): '';
        this.updatedAppealData['app_reference_id']= this.activatedRoute.snapshot.paramMap.get('id');
        //this.ngrxstore.dispatch(new FreezeNavigation() );
        this.ngrxstore.dispatch(new TriggerModal({
            content: {
                title: "Are you sure you want to save the changes?",
                approveLabel: "Save",
                cancelLabel: "Cancel"
            },
            onCancel: () => {},
            onApprove: () => {

                this.ngrxstore.dispatch( new SaveAppealProfileChanges(this.updatedAppealData,
                    (dataFromServer,isProfileUpdated)=>{
                        
                        if(isProfileUpdated){
                            this.updatedAppealData = Object.assign({},this.updatedAppealData,{
                                address_city : this.updatedAppealData.address_city,
                                address_line_1: this.updatedAppealData.address_line_1,
                                address_line_2: this.updatedAppealData.address_line_2,
                                address_state: this.updatedAppealData.address_state,
                                address_zip: this.updatedAppealData.address_zip
                            });
        
        
                            this.ngrxstore.dispatch(
                                new SaveAppealTicket(
                                    this.updatedAppealData, 
                                    this.activatedRoute.snapshot.paramMap.get('id')
                                )
                            );
                        }               
                    }));
                
            }
        }))
    }

    selectDocumentForFiling( selected, row ) {
        let _addSelected = selected.target.checked;
        let _selectedUID = row.reference_id;
        let _noMatches = this.selectedDoc.indexOf(_selectedUID) < 0;
        
        if(_addSelected){
            if(_noMatches){
                this.selectedDoc.push(_selectedUID);
            }
        }else{
            if(!_noMatches){
                this.selectedDoc.splice(this.selectedDoc.indexOf(_selectedUID), 1);
            }
        }

        this.enableAssignDocket = this.selectedDoc.length > 0;

        this.signCOSForm.patchValue({
            documents:this.selectedDoc
        });
    }

    

    onAddressFinal(content){
        let _addressObj= content[0];
        this.formGroupAppeal.patchValue({
            'address_line_1': _addressObj.delivery_line_1,
            'address_line_2': _addressObj.last_line,
            'address_city': _addressObj.components.city_name,
            'address_state': _addressObj.components.state_abbreviation,
            'address_zip': _addressObj.components.zipcode
         });
    }

    confirmFile(){
        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Next Steps",
              message: "Please confirm the accuracy and completion of the document(s) you are filing with OHA before final submission.",
              approveLabel: "File Selected Documents",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{
                
            },
            onApprove:()=>{
                if(this.cosEnabled){
                    this.generateCertificate();
                }
                else {
                    this.signWithoutCertificateOfService();
                }
            },}));
    }
    
    generateCertificate(){
        this.appealsApplicationService.generateCertificate(
            this.activatedRoute.snapshot.paramMap.get('id')
        ).pipe(take(1)).subscribe((response)=>{
            this.certificateUrl = BASE_URL+response.url;
            this.showCertificates = true;
            this.signCOSForm.patchValue({
                docketId:response.reference_id
            });
        },(err)=>{
            this.certificateUrl = null;
        })
    }

    signCertificateOfService(){
        this.appealsApplicationService.signCOSAndAttachDocuments(
            this.activatedRoute.snapshot.paramMap.get('id'),
            {
                docketId:this.signCOSForm.controls['docketId'].value,
                documents:this.signCOSForm.controls['documents'].value,
                signature:this.signCOSForm.controls['signature'].value
            }
        ).pipe(take(1)).subscribe((response)=>{
            //console.log(response);
            this.refreshDocuments();
            this.refreshDocket();
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Successfully Filed Document with Office of Hearings and Appeals",
                style:'success'
            }));
            this.certificateUrl = null;
            this.selectedDoc = [];
            this.showCertificates = false;
            this.signCOSForm.patchValue({
                signature:null
            }); 
        })
    }

    signWithoutCertificateOfService(){
        this.freeze('signCOSButton');
        let _allDocuments= [];
        this.selectedDoc.map((packet)=>{
            _allDocuments.push(packet);
        });
        this.appealsApplicationService.attachDocumentsWithoutCOS(
            this.activatedRoute.snapshot.paramMap.get('id'),
            {"documents": _allDocuments},
        ).pipe(take(1)).subscribe((response)=>{
            this.refreshDocuments();
            this.refreshDocket();
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Successfully Attached Document.",
                style:'success'
            }));
            this.selectedDoc = [];
        },((err) =>{
            if(err){
                this.ngrxstore.dispatch(new TriggerToast({
                    message:err.error['detail'],
                    style:'danger'
                }));
                this.thaw('signCOSButton');
            }
        }))
    }

    getViewDocument(url){
        this.appealsApplicationService.getDocketAsset(url).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    getViewDocket(url){
        this.appealsApplicationService.getUploadedDocketAsset(url).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }

    onSort(event) {
		this.ngrxstore.dispatch( new FreezeNavigation() );

		let _orderBy = {};
		

		let _columnDict = {
			"document":"filename",
			"document_type":"category",
			"date":'created',
			"submitted_by":'created_by__name'
		}

		let _serverColumn = Object.keys(_columnDict).filter((columnName) =>  {  	
			return columnName === event.sorts[0].prop
		})

		if(event.sorts.length > 0){
			switch(event.sorts[0].dir){
				case 'asc':
					_orderBy =  {ordering: _columnDict[_serverColumn[0]]};
				break;
				case 'desc':
					_orderBy =  {ordering:"-" +  _columnDict[_serverColumn[0]]};
				break;
				default:
				break;					
			}
		}

		
        this.appealsApplicationService.getAppealDocket( this.activatedRoute.snapshot.paramMap.get('id'),this.docketPageOffset,
        this.docsPerPage,_orderBy ).pipe(take(1)).subscribe((response)=>{
                //console.log(response);
                this.appealDocket = response;
                this.currentOrderedBy = _orderBy;
                this.refreshDocketTable()
                this.ngrxstore.dispatch( new ThawNavigation() );
        })
	}

    updateRows(pageInfo) {
		this.ngrxstore.dispatch( new FreezeNavigation() );

		let _offsetValue = 0;

		if(pageInfo.offset){
			_offsetValue= pageInfo.offset * this.docsPerPage;
		}
        if(this.totalDocketCount  < _offsetValue){
            _offsetValue = 0;
        }
       
        this.appealsApplicationService.getAppealDocket( this.activatedRoute.snapshot.paramMap.get('id'),_offsetValue,
        this.docsPerPage,this.currentOrderedBy ).pipe(take(1)).subscribe((response)=>{
                //console.log(response);
                this.appealDocket = response;
                this.docketPageOffset = _offsetValue != 0 ? pageInfo.offset : 0 ;
                this.refreshDocketTable()
                this.ngrxstore.dispatch( new ThawNavigation() );
        })
	}

    onUserInputAddressLine1(content){
        if(content != ""){
            this.isAddressInput = true;
            this.formGroupAppeal.controls.address_line_1.markAsDirty();
            console.log(this.formGroupAppeal);
        }
    }

    onEmptyAddress(){
        this.isAddressInput = false;
    }

    onSubmitThreadContent(content){
        if(content.text == ""){
            this.ngrxstore.dispatch(new TriggerToast({
                message:"Please provide a question to submit.",
                style:'danger'
            }));
            return false;
        }
        this.refresh('appellantNotes');
        this.appealsApplicationService.addTicketNotes(
            this.activatedRoute.snapshot.paramMap.get('id'),
            content
        ).pipe(take(1)).subscribe((res) => {
            this.appealsApplicationService.getPublicNotes(
                this.activatedRoute.snapshot.paramMap.get('id')
            ).pipe(take(1)).subscribe((response) => {
                this.ticketNotesList = response["results"];
                this.completeRefresh('appellantNotes');
            })
            this.ngrxstore.dispatch(new TriggerToast({
                message: "Successfully added Question",
                style: 'success'
            }));
        }, (err) => {
            if (err) {
                this.ngrxstore.dispatch(new TriggerModal({
                    content: {
                        title: "Unable to add Question",
                        message: "Unable to add Question",
                    }
                }));
               this.completeRefresh('appellantNotes');
            }
        })
    }

    openPetitionModel(type){
        this.ngrxstore.dispatch(new TriggerModal({
            type:'petition-input',
            content: {
                title: "File Petition for Reconsideration",
                message: "OHA cannot modify the deadline to file a petition for reconsideration. 13 CFR § 134.1202(c)(3)(i)(A).",
                checkboxItems:[
                    {
                        label:"Upload a document (pleading) that explains a clear error of fact or law in the initial decision"
                    }
                ],
                is_ogc: false,
            },
            onCancel:()=>{
                this.ngrxstore.dispatch(new FilePetition({file:null , signature:''}))
            },
            onApprove:()=>{
                this.ngrxstore.select(selectFilePetitionDetails()).pipe(take(1)).subscribe((response)=>{
                    let _uploaded_file = response['uploaded_file'];
                    let _digital_signature = response['signature'];
                    this.openNewTicket = true;
                    this.ngrxstore.dispatch( new CreatePFRAppeal({
                        type:type,
                        parent_ticket:this.activatedRoute.snapshot.paramMap.get('id'),
                        signature_for_electronic_filing:_digital_signature,
                        lender:this.appealDetails.lender.id,
                        title: this.appealDetails.title,
                        action: {
                            "slug": "Draft",
                            "display_name": "Draft"
                        },
                        submitter:this.currentUser.reference_id,
                        callback:(response)=>{
                            if(response.hasOwnProperty('error')){
                                this.openNewTicket = false;
                            } else {
                                if(_uploaded_file != null) {
                                    this.uploadPetitionDocument(response,_uploaded_file);
                                }else{
                                    this.appealsApplicationService.getAppeal(
                                        this.activatedRoute.snapshot.paramMap.get('id')
                                    ).pipe(take(1)).subscribe((response)=>{
                                        this.appealDetails.has_user_filed_pfr_appeal = response["has_user_filed_pfr_appeal"];
                                        this.appealDetails.has_user_filed_pfr_on_reconsidered_appeal = response["has_user_filed_pfr_on_reconsidered_appeal"];
                                        this.appealDetails.has_user_filed_reconsideration_appeal = response["has_user_filed_reconsideration_appeal"];
                                        this.appealDetails.is_contest_eligible = response["is_contest_eligible"];
                                        this.validatePetitionFile();
                                    }) 
                                    this.goToTicket(response.reference_id);
                                }
                            }
                            
                        }
                    })
                    )
                });
            }
        }));
    }

    uploadPetitionDocument(reconDetail,fileItem){
        this.appealsApplicationService.uploadPetitionDocument(
            reconDetail.reference_id,fileItem
        ).pipe(take(1)).subscribe((res1)=>{
			this.appealsApplicationService.getUploadedDocuments(
				reconDetail.reference_id
			).subscribe((res2) => {
				let _document = [];
				_document.push(res2[0]["documents"][0].reference_id) //to be updated if backend updates response
				// _document.push(res1["documents"][0].reference_id) 
				this.appealsApplicationService.attachDocumentsWithoutCOS(
					reconDetail.reference_id,
					{"documents": _document},
				).pipe(take(1)).subscribe((response)=>{
                    this.ngrxstore.dispatch( new FileReconPFRAppeal(
						reconDetail.reference_id,
						{
							lender:reconDetail.lender.id,
							title: reconDetail.title,
							initial_attachment_reference_id_for_filing: res2[0]["documents"][0].reference_id,
							callback:(response)=>{
								this.appealsApplicationService.getAppeal(
                                    this.activatedRoute.snapshot.paramMap.get('id')
                                ).pipe(take(1)).subscribe((response1)=>{
                                    this.appealDetails.has_user_filed_pfr_appeal = response1["has_user_filed_pfr_appeal"];
                                    this.appealDetails.has_user_filed_pfr_on_reconsidered_appeal = response1["has_user_filed_pfr_on_reconsidered_appeal"];
                                    this.appealDetails.has_user_filed_reconsideration_appeal = response1["has_user_filed_reconsideration_appeal"];
                                    this.appealDetails.is_contest_eligible = response1["is_contest_eligible"];
                                    this.validatePetitionFile();
                                    this.goToTicket(reconDetail.reference_id);
                                })
							}
						})
					) 
				},((err) =>{
					if(err){
						this.ngrxstore.dispatch(new TriggerToast({
							message:err.error['detail'],
							style:'danger'
						}));
					}	
				}))
			})
            
        },(err)=>{
            if(err instanceof HttpErrorResponse){
                 this.ngrxstore.dispatch(new TriggerModal({
                     content:{
                         title:"Unable to Upload",
                         message:err.error.file[0]
                     }
                 }));
            }
        });
    }


    goToTicket(id:string){
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`${this.Routes['YOUR_APPEAL']}/${id}`])
        );
        window.open(url, '_blank', '');
        this.openNewTicket = false;
	}

    getOriginalAppeal(){
        let _originalAppealData = [];
        if(_originalAppealData.length == 0){
            _originalAppealData.push(this.appealDetails['parent_ticket']) ;
        }
        let _columnList = {
            "Appellant":['title'],
            "Status":['action','name'],
            "SBA PPP Loan Number":['loan_number'], 
            "Appeal Filed":['filing_date']
        }
    
        this.originalAppealColumns = createColumns(_columnList);
        this.originalAppealRows = traverseJsonArray(_columnList,_originalAppealData);
        this.originalAppealRows.total = _originalAppealData.length;
    }

    getReconsiderationAppeal(){
        let _reconAppealData = this.appealDetails['child_tickets']; 
        let _columnList = {
            "Appellant":['title'],
            "Status":['action','name'],
            "SBA PPP Loan Number":['loan_number'], 
            "Appeal Filed":['filing_date'],
            "Reconsideration":['type'],
            "Filed By":['submitter','name']
        }
    
        this.reconsiderationAppealColumns = createColumns(_columnList);
        this.reconsiderationAppealRows = traverseJsonArray(_columnList,_reconAppealData);
        this.reconsiderationAppealRows.total = _reconAppealData.length;
    }

    validatePetitionFile(){
        this.isNotEligibleForPetition = this.appealDetails.has_user_filed_pfr_appeal["primary_contact"];
        this.isNotEligibleForReconsideration = this.appealDetails.has_user_filed_reconsideration_appeal["primary_contact"];
        this.isDisabledPetitionOverRecon = this.appealDetails.has_user_filed_pfr_on_reconsidered_appeal["primary_contact"];
        if(this.appealDetails.submitter != null){
            this.isEligibleForPetitionOverRecon = [this.authConfig.roles.OHA_ADMIN.slug,
                this.authConfig.roles.OHA.slug,
                this.authConfig.roles.JUDGE.slug,
                this.authConfig.roles.ATTORNEY.slug].includes(this.appealDetails.submitter.groups[0].name.toLowerCase());
        }
    }
    
}