import { Component, OnInit, Output, OnDestroy, EventEmitter, Inject, Input } from  '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

// Platform
import { NavigationService } from '../navigation.service';

// Store
import { selectNavigationDrawerState, getWindowState } from '../store/navigation.selectors';
import { OpenNavigationDrawer, CloseNavigationDrawer, SetDrawerType } from '../store/navigation.actions';
import { Store } from '@ngrx/store';

@Component({
  selector:'drawer-component',
  template:require('./drawer.component.html')
})

export class DrawerComponent implements OnDestroy{

  public ROUTE_PATHS:any = null;
  public FULL_ROUTES:any = null;
  public ROUTE_PERMISSIONS = null;

  @Output() onLogout = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<string>();
  @Input() set open(isOpen:boolean){
    this.$collapsed.next(!isOpen);
    this.drawerOpen = isOpen;
  }

  public ngUnsubscribe: Subject<any> = new Subject();

  public drawerOpen:any = false;
  public drawerType:string = 'navigation';
  public currentRoles:any = null;

  public $collapsed:BehaviorSubject<boolean> = new BehaviorSubject(true);
  public $type:BehaviorSubject<string> = new BehaviorSubject('navigation');

  public pages:any = [];
  public isMobile:boolean = false;

  get $Collapsed(){
    return this.$collapsed;
  }

  get $Type(){
    return this.$type;
  }

  get Store(){
    return this.ngrxstore;
  }

  constructor(
    public navigationService:NavigationService,
    public ngrxstore:Store<any>
    ){

      this.ROUTE_PATHS = navigationService.Paths;
      this.FULL_ROUTES = navigationService.Routes;
      this.ROUTE_PERMISSIONS = navigationService.Permissions;

      this.ngrxstore.select(selectNavigationDrawerState()).subscribe((state)=>{
        this.drawerOpen = !state.collapsed;
        this.$collapsed.next(state.collapsed);
        this.drawerType = state.type;
        this.$type.next(state.type);
        this.currentRoles = state.roles;
      });

      this.ngrxstore.select(getWindowState()).subscribe((state)=>{
        this.isMobile = (state.size === 'isTablet' || state.size === 'isMobile');
      })

      this.navigationService.$DrawerNavigationConfiguration.subscribe((configuration)=>{
        this.pages = configuration.items;
      });
  }

  // Lifecycle Hooks
  //------------------
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(){
    this.onClose.emit();
  }

  routeIsPermitted(route:string){
    return this.navigationService.isPermitted(route,this.currentRoles,this.ROUTE_PERMISSIONS,'/'+this.ROUTE_PATHS.SHELL+'/');
  }

}
