import { createSelector } from '@ngrx/store';
import { ShellState, getShellState } from './shell.reducer';

export const getActionPanelStates = createSelector(
  getShellState,
  (state:ShellState)=>{
    return {
      shell:{
        collapsed:state.action_panel_collapsed_on_shell
      }
    }
  }
)

export const selectActionPanelState = (page:string) => createSelector(
  getActionPanelStates,
  (states)=>{
    return states[page];
  }
)

export const getShellFrozenState = createSelector(
  getShellState,
  (state)=>{
    return state.view_frozen;
  }
)
