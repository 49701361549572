import { Directive, EventEmitter, Input, Output, ElementRef, HostBinding, Renderer2, Host, AfterViewInit, OnInit } from '@angular/core';

@Directive({
  selector:'[a11yFirstFocus]'
})
export class FirstFocus implements AfterViewInit{

    @HostBinding('attr.tabindex') public tabIndex = '0';
    @HostBinding('class.a11y_first_focus') public first_focus = true;

  constructor(public el:ElementRef){
  }

  ngAfterViewInit(){
    this.el.nativeElement.focus();
  }

}
