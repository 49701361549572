import { ElementRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { take, takeUntil, filter, skip } from 'rxjs/operators';
import { NAVIGATION_CONSTANTS } from '../constants/navigation.constants';

function listenToWindow(boundSubject:BehaviorSubject<WindowState>){
  window.addEventListener('load',()=>{
    boundSubject.next({
      width:window.innerWidth,
      height:window.innerHeight,
      scrollY:window.pageYOffset,
      maxScrollY:document.body.scrollHeight-window.innerHeight
    });
  })

  window.addEventListener('resize',()=>{
    boundSubject.next({
      width:window.innerWidth,
      height:window.innerHeight,
      scrollY:window.pageYOffset,
      maxScrollY:document.body.scrollHeight-window.innerHeight
    });
  })
  window.addEventListener('scroll',()=>{
    boundSubject.next({
      width:window.innerWidth,
      height:window.innerHeight,
      scrollY:window.pageYOffset,
      maxScrollY:document.body.scrollHeight-window.innerHeight
    });
  })
  window.dispatchEvent(new Event('load'))
}

function refreshWindow(){
  window.location.reload();
}

interface WindowState{
  width?:number;
  height?:number;
  scrollY?:number;
  maxScrollY?:number;
}

@Injectable({
  providedIn:'root'
})
export class WindowRef {

  public nativeWindowState:BehaviorSubject<WindowState> = new BehaviorSubject({
    width:0,
    height:0,
    scrollY:0,
    maxScrollY:0
  });

  get $size(): BehaviorSubject<WindowState> {
    return this.nativeWindowState;
  }

  constructor(
    public ngrxstore: Store<any>
  ) {

    listenToWindow(this.nativeWindowState);

    this.nativeWindowState.subscribe((state)=>{
      this.ngrxstore.dispatch({
        type:NAVIGATION_CONSTANTS.SET_BROWSER_STATE,
        payload:state
      })
    })

  }

  refresh(){
    refreshWindow();
  }

  getYOffsets(el:ElementRef,selectors:any,offsets?:any):Observable<any>{
    return new Observable((obs)=>{
      let _offsets = [];
      this.nativeWindowState.pipe(skip(1),take(1)).subscribe((state)=>{
        selectors.map((selector,index)=>{
          let _offset = (offsets) ? offsets[index] : 0;
          _offsets.push({
            selector:selector,
            offset:parseInt(el.nativeElement.querySelector(selector).getBoundingClientRect().top + state.scrollY + _offset,10)
          });
        })
        obs.next(_offsets);
        obs.complete();
      });
    })
  }
}
