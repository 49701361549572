import { ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, HostBinding, Injector, Input, OnChanges, OnDestroy, Output, Renderer2, ViewRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import * as _ from 'lodash';
import { OuterSubscriber } from 'rxjs/internal/OuterSubscriber';
import { removeNulls } from '../../../../utilities/helpers';

@Component({
    selector:'input-card',
    template:require('./input-card.component.html')
})
export class InputCard implements OnDestroy, OnChanges{

    @Input() set classes(classString:string){
        //console.log(classString);
        let classes = classString.split(' ');
        classes.map((className)=>{
            let _className = className.trim();
            this.renderer.addClass(this.elementRef.nativeElement,_className);
        })
    }

    public iConfigurations:any = []
    public tConfiguration:any = {};
    public loading:boolean = true;
    public cardId:string = '';
    public cardCollectionLabel:string = '';
    public collapsed:boolean = false;

    public saveButtonLabel = "Save";
    public removeButtonLabel = "Remove";
    public titleIsNull:boolean = false;

    public inputCardModel:any = {};

    public ngUnsubscribe: Subject<any> = new Subject();
    public $configuration:BehaviorSubject<InputCardConfiguration> = new BehaviorSubject({
        name:null,
        title:{
            name:''
        },
        inputs:[]
    });

    @Input() set config(configuration:any){
        this.loading = true;
        this.$configuration.next(configuration);
    }
  
    @Input() consumer = (values:any)=>{};

    @Input() id:string = null;

    @Output() onRemove:EventEmitter<any> = new EventEmitter();
    @Output() onSave:EventEmitter<any> = new EventEmitter();

    get config(){
        return this.$configuration.getValue();
    }


    constructor(
        public changeDetector:ChangeDetectorRef,
        public elementRef: ElementRef,
        public renderer: Renderer2
    ){
        this.$configuration.pipe(skip(1),takeUntil(this.ngUnsubscribe)).subscribe((config)=>{

            let _newIConfigs = [];

            
            // Title Input
            if(config.title.options) config.title.type = 'select';
            this.inputCardModel.name = config.title.value;
            this.inputCardModel[_.snakeCase(config.title.name)] = config.title.value;
            
            if(config.labels){
                this.removeButtonLabel = config.labels.remove || "Remove";
                this.saveButtonLabel = config.labels.save || "Save";
            }
            
            let _newTConfig = {
                name:config.title.name,
                type:config.title.type || 'text',
                options:config.title.options,
                multiple:config.title.multiple || false,
                //placeholder:config.title.name || null,
                placeholder:config.title.placeholder || null,
                value:config.title.value || null,
                maxLength:40
            }
            
            // Detail Inputs
            config.inputs.map((iconfig)=>{
                if(iconfig.options) iconfig.type = 'select';
                let _thisIConfig:InputConfiguration = {
                   name:iconfig.name || null,
                    placeholder:iconfig.name || null,
                   // placeholder:iconfig.placeholder || null,
                    type:iconfig.type || 'text',
                    multiple:iconfig.multiple || false,
                    options:iconfig.options || [],
                    value:iconfig.value || null
                };
                _newIConfigs.push(_thisIConfig);
                this.inputCardModel[_.snakeCase(iconfig.name)] = iconfig.value;
            });

            this.iConfigurations = _newIConfigs;
            this.tConfiguration = _newTConfig;
            this.cardId = _.snakeCase(this.config.name)+'_'+this.id;
            this.cardCollectionLabel = this.config.name;
            this.titleIsNull = this.titleIsInvalid();
            this.loading = false;
            this.changeDetector.detectChanges();

        })
    }

    ngOnChanges(){
        this.titleIsNull = this.titleIsInvalid();
    }

    toggleCollapse(){
        this.collapsed = !this.collapsed;
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    removeSelf(){
        this.onRemove.emit({id : this.cardId, values: removeNulls(this.inputCardModel)});
    }

    saveSelf(){
        this.onSave.emit(removeNulls(this.inputCardModel));
    }

    titleIsInvalid(){
        this.inputCardModel.name = this.inputCardModel[_.snakeCase(this.tConfiguration.name)];
        //console.log(this.inputCardModel);
        return (
            this.inputCardModel.name === null || 
            this.inputCardModel.name === undefined || 
            this.inputCardModel.name === '' 
        )
    }

    onInputChange(input:any,name:string){
        this.inputCardModel[_.snakeCase(name)]= input;
        this.titleIsNull = this.titleIsInvalid()
        this.consumer(removeNulls(this.inputCardModel));
    }
}