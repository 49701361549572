import { ActivatedRoute } from '@angular/router';
import { Action } from '@ngrx/store';
import { APPEALS_APPLICATIONS_ACTIONS } from '../../constants/appeals-applications.constants';

  export class StartNewAppealWizard implements Action {
    readonly type = APPEALS_APPLICATIONS_ACTIONS.START_NEW_WIZARD;
    constructor(public payload:any){}
}

export class RequestNewAppeal implements Action{
    readonly type = APPEALS_APPLICATIONS_ACTIONS.REQUEST_NEW_APPEAL;
    constructor(public payload:{
      values:any
    }){}
  }

export class CurrentFilterValues implements Action{
  readonly type = APPEALS_APPLICATIONS_ACTIONS.CURRENT_FILTER_VALUES;
  constructor(public payload:any){}
}