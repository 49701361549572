import { ActivatedRoute } from '@angular/router';
import { Action } from '@ngrx/store';
import { AUTH_ACTIONS } from '../constants/auth.constants';

export class LoginAction implements Action {
  readonly type = AUTH_ACTIONS.LOGIN;
  constructor(public payload:{
    origin:string,
    redirect:string,
    email:string,
    password:string,
    toastConfig?:any,
    inviteToken?:string
  }){}
}

export class RequestLogoutAction implements Action {
  readonly type = AUTH_ACTIONS.REQUEST_LOGOUT;
  constructor(){}
}

export class LogoutAction implements Action {
  readonly type = AUTH_ACTIONS.LOGOUT;
  constructor(public toastConfig?:ToastConfiguration){}
}

export class SetOrigin implements Action{
  readonly type= AUTH_ACTIONS.SET_ORIGIN;
  constructor(public payload:string){}
}

export class InviteUser implements Action{
  readonly type = AUTH_ACTIONS.INVITE_USER;
  constructor(public payload:{
    first_name:string,
    last_name:string,
    name:string,
    email:string,
    ticket:string,
    group:string,
    phone:string,
    address_line_1:string,
    address_line_2:string,
    address_state:string,
    address_city:string,
    address_zip:string,
    invitation_url:string
  },public callback?:any){}
}

export class RegisterUser implements Action {
  readonly type = AUTH_ACTIONS.REGISTER_USER;
  constructor(public payload:{
    origin:string,
    redirect:string,
    name:string,
    first_name:string,
    last_name:string,
    role:string,
    email:string,
    password:string,
    confirm_password:string,
    inviteToken?:string
  }){}
}

export class LoginWithInviteToken implements Action {
  readonly type = AUTH_ACTIONS.LOGIN_WITH_INVITE;
  constructor(public payload:{
    origin:string,
    redirect:string,
    email:string,
    inviteToken?:string
  }){}
}

export class LoginWithAppealID implements Action {
  readonly type = AUTH_ACTIONS.LOGIN_WITH_APPEAL_ID;
  constructor(public payload:{
    origin:string,
    redirect:string,
    email:string,
    password:string,
    appealId:string
  }){}
}

export class DirectLoginWithAppealID implements Action {
  readonly type = AUTH_ACTIONS.DIRECT_LOGIN_WITH_APPEAL_ID;
  constructor(public payload:{
    appealId:string,
    name:string,
    role:string[],
    id:string,
    access:string,
    refresh:string,
    origin:string
  }){}
}

export class ResetPasswordAction implements Action {
  readonly type = AUTH_ACTIONS.RESET_PASSWORD;
  constructor(public payload:{
    resetToken:string,
    password:string,
    confirm_password:string,
  }){}
}

export class RequestResetPasswordAction implements Action {
  readonly type = AUTH_ACTIONS.REQUEST_RESET_PASSWORD;
  constructor(public payload:{
    email:string,
    redirect:string
  }){}
}

export class RequestResetMFAAction implements Action {
  readonly type = AUTH_ACTIONS.REQUEST_RESET_MFA;
  constructor(public payload:{
    ref:string,
    redirect:string
  }){}
}

export class RegisterPhoneWithTwoFactor implements Action {
  readonly type = AUTH_ACTIONS.TWO_FA_REGISTER_PHONE;
  constructor(public payload:{
    phone:string,
    email:string,
    password:string,
    origin:string,
    redirect:string,
    auth_type:string,
    inviteToken?:string
  }){}
}

export class RegisterPhone implements Action {
  readonly type = AUTH_ACTIONS.REGISTER_PHONE;
  constructor(public payload:{
    auth_type:string,
    origin:string,
    redirect:string,
    email:string,
    password:string,
    inviteToken?:string
  }){}
}

export class SelectSMSTwilio2fa implements Action {
  readonly type = AUTH_ACTIONS.SELECT_SMS_TWILIO_2FA;
  constructor(public payload:{
    auth_type:string,
    origin:string,
    redirect:string,
    email:string,
    password:string,
    inviteToken?:string
  }){}
}

export class ScanQRCode implements Action {
  readonly type = AUTH_ACTIONS.TWO_FA_SCAN_CODE;
  constructor(public payload:{
    auth_type:string,
    qr_code:string,
    origin:string,
    redirect:string,
    email:string,
    password:string,
    inviteToken?:string
  }){}
}

// Provides an untyped generic 'Login' action whose payload is 
// intended to be analyzed and acted upon by top level store effects.  
export class RequestAmbientSignInAction implements Action {
  readonly type = AUTH_ACTIONS.REQUEST_AMBIENT_LOGIN;
  constructor(public payload:{
    method:'link-loan-wizard',
    values:any
  }){};
}

export class AmbientSignInAction implements Action {
  readonly type = AUTH_ACTIONS.AMBIENT_LOGIN;
  constructor(public payload:any){};
}

export class RejectAmbientSignInAction implements Action{
  readonly type= AUTH_ACTIONS.REJECT_AMBIENT_LOGIN
}

export class LoginWithApplicationAction implements Action{
  readonly type = AUTH_ACTIONS.LOGIN_WITH_APPLICATION;
  constructor(public payload:{
    application_id:number,
    token:string
  }){}
}

export class LoginWithMFATokenAction implements Action{
  readonly type = AUTH_ACTIONS.TWO_FA_PROVIDE;
  constructor(public payload:{
    origin:string,
    redirect:string,
    token:string,
    email:string,
    password:string,
    toastConfig?:any,
    auth_type:string,
    inviteToken?:string,
    appealID?:string
  }){}
}

export class TwilioLoginWithMFATokenAction implements Action{
  readonly type = AUTH_ACTIONS.TWILIO_TWO_FA_PROVIDE;
  constructor(public payload:{
    origin:string,
    redirect:string,
    token:string,
    email:string,
    password:string,
    verification_id:string,
    toastConfig?:any,
    auth_type:string,
    inviteToken?:string,
    appealID?:string,
    callback?:any
  }){}
}

export class RegisterPhoneWithTwilio implements Action {
  readonly type = AUTH_ACTIONS.TWILIO_REGISTER_PHONE;
  constructor(public payload:{
    phone:string,
    email:string,
    password:string,
    origin:string,
    redirect:string,
    auth_type:string,
    inviteToken?:string
  }){}
}

export class SaveProfileChanges implements Action{
  readonly type= AUTH_ACTIONS.SAVE_PROFILE_CHANGES;
  constructor(public payload:any, public callback?:any, public msgBool?:boolean){}
}

export class SaveAppealProfileChanges implements Action{
  readonly type= AUTH_ACTIONS.SAVE_APPEAL_PROFILE_CHANGES;
  constructor(public payload:any, public callback?:any, public msgBool?:boolean){}
}

export class ListPageLimit implements Action{
  readonly type= AUTH_ACTIONS.LIST_PAGE_LIMIT;
  constructor(public payload:any){}
}
