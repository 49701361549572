import {Inject, Injectable} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { getSessionState } from '../../../platform/modules/auth';
import { AccountService } from '../account.service';

@Injectable()
export class UsersResolver implements Resolve<any> {

  constructor(
    @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    @Inject(Store) public ngrxstore:Store,
    @Inject(AccountService) public accountService:AccountService
  ){}

  resolve(route:ActivatedRouteSnapshot){
    return this.accountService.getUsersList(0,this.appDefaults.ui.LIST_PAGE_LIMIT,"full_name");
  }

}
