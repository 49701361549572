import { AfterContentInit, Component, ElementRef, forwardRef, Inject, Input, LOCALE_ID, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { transform } from 'lodash';
import { Z_NEED_DICT } from 'zlib';

export const NUMERIC_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumeric),
    multi: true
  };
  
@Component({
    template:`
        <input 
            #numericInput
            [id]='id'
            (change)="onChange($event)"
            (keyup)="onKeyUp($event)" 
            (input)="onInput($event)"
            (blur)="touchCallback()" 
            type="text"
            name = "{{name}}"
            [attr.title]="title"
            [attr.aria-label]='title' 
            [attr.readonly]="readonly?true:null"
            class="form-control"
            [ngClass]='classes' 
            [class.invisible] = 'readonly && readOnlyTemplateProvided'
        >
        <div class='template-wrapper' #readOnlyTemplate [ngClass]="{'invisible':!readonly || !readOnlyTemplateProvided}">
            <ng-content></ng-content>
        </div>`,
    selector:"input-numeric",
    providers:[NUMERIC_ACCESSOR]
})
export class InputNumeric implements ControlValueAccessor, AfterContentInit{

    @ViewChild('numericInput',{static:true}) input:ElementRef;
    @ViewChild('readOnlyTemplate',{static:true}) readOnlyTemplate:ElementRef;

    @Input() classes:string = '';
    @Input() set label(value){
        this.name = 'input_'+value;
        this.title = _.startCase(value);
        this.id = _.camelCase(value);
    }
    @Input() maxLength:number = 100;

    @Input() fieldType:string = '';
    @Input() set digitsInfo(info:string){
        // if(info.match())
        // TODO make sure it's a vliad digits info
        this.digitsInfoValue = info;
        let _decimals = info.substring(info.indexOf('.')+1);
        let _lower = _decimals.split('-')[0];
        let _upper = _decimals.split('-')[1] || '0';
        this.maxDecimals = parseInt(_upper,10);
        this.minDecimals = parseInt(_lower,10);
        this.decimalMatchRegExp = new RegExp("^\\d+\\.?\\d{0,"+this.maxDecimals+"}",'g');
    }
    @Input() negatives:boolean = false;
    @Input() commas:boolean = true;
    
    @Input() readonly:boolean = false;
    public digitsInfoValue:string = '1.2-2';
    public decimalMatchRegExp:RegExp = new RegExp(/^\d+\.?\d{0,2}/,'g');
    public placeholder:string = null;
    public readOnlyTemplateProvided:boolean = false;
    public title:string = '';
    public name:string = '';
    public id:string = '';

    public minDecimals:number = 2;
    public maxDecimals:number = 2;
    public limitMatchRegExpNumber:RegExp = new RegExp(/^\d{0,10}/,'g');
    public limitMatchRegExpAmount:RegExp = new RegExp(/^[0-9]{0,8}((\.)[0-9]{0,2}){0,1}/,'g');

    constructor(
        @Inject(LOCALE_ID) public _locale: string,
        public renderer:Renderer2
    ){
    }

    ngAfterContentInit() {
        this.readOnlyTemplateProvided = this.readOnlyTemplate.nativeElement.childNodes.length > 0;
    }

    toggleDisabled(){
        this.readonly = !this.readonly;
    }
    
    changeCallback=(data:any)=>{};
    touchCallback=()=>{};

    convertToDecimal(value):string{
        let _valueAsString = value.replace(/([^0-9.])+/g,'');
        let _newValue = new DecimalPipe(this._locale).transform( parseFloat(_valueAsString),this.digitsInfoValue) || '0';

        let _integerOnly = (_newValue.indexOf('.')>-1) ? _newValue.substring(0,_newValue.indexOf('.')) : _newValue;
        let _withoutFormatting = _integerOnly.replace(/\,/g,'');
        let _integerLength = _withoutFormatting.length;

        // Respect MaxLength, ignoring fractions
        if(_integerLength > this.maxLength){
            let _v = _newValue.substring(0,this.maxLength+1).replace(/([^0-9])+/g,'')+_newValue.substring(_newValue.indexOf('.'));
            _newValue = new DecimalPipe(this._locale).transform( parseFloat(_v),this.digitsInfoValue);
        }

        if(!this.commas){
            _newValue = _newValue.replace(/\,/g,'');
        }

        return _newValue;
    }

    onInput(event){
        let _newValue = '';
        if(this.fieldType === 'LoanNumber'){
             _newValue = event.target.value.match(this.limitMatchRegExpNumber);
        }else if(this.fieldType === 'LoanAmount'){
            _newValue = event.target.value.match(this.limitMatchRegExpAmount);
        }else{
            //retrict input to only 2 places of decimal
            _newValue = event.target.value.match(this.decimalMatchRegExp);
        }
        // let _newValue = this.convertToDecimal(event.target.value);
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }
    
    onChange(event){
        let _newValue = this.convertToDecimal(event.target.value);
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
        
        // Store as a Float
        this.changeCallback(
            parseFloat(
                _newValue.replace(/([^0-9.])+/g,''))
                .toFixed(this.minDecimals)
            );
    }

    onKeyUp(event){
        // Allow Decmial Symbols
        let _newValue = event.target.value.match(/([0-9.,])+/g);
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }

    writeValue(value: any){
        // Convert back to Decimal String
        let _newValue = this.convertToDecimal(value+'');
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.readonly=isDisabled;
    }
}