import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from '../../app.interface';
import { MOCK_TICKET, MOCK_TICKET_NOTES} from '../../constants/mock.data';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { createColumns , traverseJsonArray} from '../../platform/utilities/helpers';
import { FileItem } from '@wkoza/ngx-upload';
import { act } from '@ngrx/effects';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { titleCase } from 'change-case';
import { DOCUMENT_CATEGORIES } from '../../constants/app.constants';

@Injectable({
    providedIn:'root'
})
export class TicketsService{
    constructor(
        @Inject(API_CONFIG) public api:ApiConfig,
        public http:HttpClient,
        public datepipe: DatePipe
    ){}

    interpretErrorsFromAPI(err):any{
        let col = err.error;
        let title = 'An Error Occured';
        let message = 'An unexpected error occured, check to make sure you submitted information is correct and valid.';

        if(Object.values(col).length>0){

            title = 'Invalid Submission'
            message = '';

            if(col.phone_number){
                message += 'You have supplied an invalid U.S. Phone Number. '
            }

            if(col.title){
                message += '"Appeal Title" field cannot be blank '
            }

            if(message.length < 1){
                message += 'Please ensure all your information is correct and valid.'
            }
        }

        return {
            title,
            message
        }
    }

    getNotes(appeal_id:string):Observable<any>{

        let _params = {};

        _params['offset'] = "0"
        _params['limit'] = "100"

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT']+'/'+appeal_id+this.api.endpoints['APPEAL_NOTE'],{
                params:_params
            }).pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getCurrentUser():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['CURRENT_USER']).pipe(take(1)).subscribe((response) =>{
                let _response = response["results"];
                _response[0]['is_current']= true;
                obs.next(_response[0]);
                obs.complete();
            }) 
        });
    }

    getAppealActions():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ACTION']).pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response['results']);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDispositionOptions():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_DISPOSITION']).pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getDismissedOptions():Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_DISPOSITION_DISMISSED']).pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    addTicketNotes(appealId:string, content:any){
        if(appealId !== undefined){
            let _callBody = {
                'title': content.title,
                'comment': content.text
            };
            return this.http.post(
                this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_NOTE'],
                _callBody
            ).pipe(take(1))
        }
    }

    signCOSAndAttachDocuments(id:string,payload:any){
        return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+id+
        this.api.endpoints['APPEAL_DOCKETS']+"/"+payload.docketId,{
            documents:payload.documents,
            signature:payload.signature,
        }).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    attachDocumentsWithoutCOS(appealId:string, documents:any){
        if(appealId !== undefined){
            return this.http.post(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+this.api.endpoints['APPEAL_DOCKETS'],documents).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    generateCertificate(id:string,is_privileged:boolean = false):Observable<any>{
        let _body = (is_privileged) ? { generate_privileged_certificate_of_service:true } : '';
        return this.http.post(this.api.endpoints['APPEAL_ROOT']+'/'+id+
        this.api.endpoints['APPEAL_CERTIFICATE_OF_SERVICE'], _body).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    queryTicket(queryType:'Reviewer' | 'Judge' | 'DocketManager', params:any){
        return new Observable((obs)=>{
            obs.next(MOCK_TICKET);
            obs.complete();
        });
    }

    getTicketDocuments(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT + '/' + id + this.api.endpoints.APPEAL_DOCUMENTS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    let _response =  response['results'];

                    Object.keys(_response).map((element) =>{
                        _response[element].documents.map((document) => {
                            document.category = titleCase(document.category);
                        })
                    })

                    let _notesList = _response;
                    let _serverData = [];

                    let _multiplePerNote = [];
                    let _singlePerNote = [];

                    if(_notesList){
                        _notesList.map((note)=>{
                            if(note.documents.length > 1){
                                _multiplePerNote.push(note);
                            }else{
                                _singlePerNote.push(note);
                            }
                        });

                        _multiplePerNote = _multiplePerNote.map((note)=>{
                            return Object.assign({},note,{
                                isMultiple:true,
                                set:note.documents,
                                file_url:note.documents[0].file,
                                document:note.documents[0].filename,
                                category:note.documents[0].category,
                                reference_id:note.documents[0].reference_id,
                                documents:null
                            });
                        });
    
                        _singlePerNote = _singlePerNote.filter((note)=>{
                            return note.documents.length > 0;
                        }).map((note)=>{
                            return Object.assign({},note.documents[0],{
                                file_url:note.documents[0].file,
                                document:note.documents[0].filename,
                                category:note.documents[0].category,
                                reference_id:note.documents[0].reference_id,
                            })
                        });
    
                        _serverData = [..._serverData].concat(_multiplePerNote,_singlePerNote)
                    }

                    let _columnList = {
                        "Document":['filename'],
                        "Date":['created'],
                        "Submitted By":['created_by'],
                        "Document Type":['category'],
                       // "Submitted On":['created'],
                        "Actions":['actions']
                        
                    }
                    let _columns = createColumns(_columnList);
                    let _rows = traverseJsonArray(_columnList,_serverData);

                    if(_rows){
                        _rows = _rows.map((row)=>{
                            // console.log(row);
                            if(Array.isArray(row.data)){
                                return Object.assign({},row,{
                                    isMultiple:true,
                                    file_url:row.data[0].file,
                                    document:row.data[0].filename,
                                    category:row.data[0].category,
                                    reference_id:row.data[0].reference_id,
                                });
                            }else{
                                return Object.assign({},row,{
                                    file_url:row.data.file,
                                    document:row.data.filename,
                                    category:row.data.category,
                                    reference_id:row.data.reference_id,
                                })
                            }
                        });
                    }

                    // console.log(_rows,_columns);

                    obs.next({
                        result :response['results'],
                                columns:_columns,
                                rows:_rows
                            });
                    obs.complete();
                })
        });
    }

    removeDocumentFromAppeal(appealSlug:string,documentSlug:string){
        return this.http.delete(this.api.endpoints.APPEAL_ROOT+'/'+appealSlug+this.api.endpoints.APPEAL_DOCUMENTS+'/'+documentSlug).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getAppeal(appealId:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+'/').pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    getAppealTicket(appealId:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+'/').pipe(
                take(1))
            .subscribe((response)=>{
                obs.next(response);
                obs.complete();
            },(err)=>{
                obs.next(err["error"]);
                obs.complete();
            });
        })
    }

    getAppealDocket(appealId:string,offset?:number,limit?:number,ordering?: {ordering?:string}){
        let _params = {};

        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();
        if(ordering){
            if(ordering.ordering){
                _params['ordering'] = ordering.ordering.toString();
            }
        } 

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/' + appealId+this.api.endpoints['DOCKET_FILING_DOCUMENT'],{
                params:_params
            }).pipe(
                take(1))
            .subscribe((response)=>{
                let _totCount= response['count'];
                let _serverData = response['results'];
                let _columnList = {
                    "file":['file'],
                    "Document":['filename'],
                    "Document Type":['category'],
                    "Date":['filed_to_docket_datetime'],
                    "Submitted By":['created_by','name'],
                    "Certificate of Service": ['certificate_of_service']
                }

                let _docketRowsData = [];
                let _docketColumns = createColumns(_columnList);
                _serverData.map((element) =>{
                    element.category = titleCase(element.category);
                    _docketRowsData.push(element);
                })
                let _docketRows = traverseJsonArray(_columnList,_docketRowsData);
                obs.next({totalCount: _totCount , docketColumns : _docketColumns,
                    docketRows: _docketRows});
                obs.complete();
            },(err)=>{
                console.log(err);
            });
        })
    }

    updateApplication(payload:any, slug:string){
        if(slug !== undefined){
            let _payload = this.formatPayload(payload);
            return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+slug, _payload).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    patchApplication(payload:any, slug:string){
        if(slug != undefined){
            let _payload = this.formatPayload(payload);
            return this.http.patch(this.api.endpoints['APPEAL_ROOT']+'/'+slug, _payload).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    updateApplicationAssignee(username:any, slug:string){
        if(slug != undefined){
            return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+slug 
            + this.api.endpoints['ASSIGNEE_REASSIGN'] + '/' + username,"").pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    removeApplicationAssignee(username:any, slug:string){
        return this.http.delete(this.api.endpoints['APPEAL_ROOT']+'/'+slug 
        + this.api.endpoints['ASSIGNEE_USER'] + '/' + username).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getLenders(offset?:number,limit?:number){
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_LENDERS,{
                params:_params 
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getOgcList(appealId:string){
        if(appealId !== undefined){
            let _params = {};
            _params['offset'] = "0";
            _params['limit'] = "1000";
    
            return new Observable((obs)=>{
                this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/'+appealId+this.api.endpoints['APPEAL_PRIMARY_CONTACT_OGC'],{
                    params:_params 
                }).pipe(
                        take(1),
                        catchError(err => throwError(err))
                    ).subscribe((response)=>{
                        obs.next(response['results']);
                        obs.complete();
                    })
            });
        }
    }

    getUsersList(filter:string):Observable<any>{
        let _params = {};
            if(filter !== undefined) _params['groups__name'] = filter.toString();
            _params['offset'] = "0";
            _params['limit'] = "1000";
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.USER,{
                params:_params 
                })
            .pipe(
                take(1),
                catchError(err => throwError(err))
            ).subscribe((response)=>{
                obs.next(response['results']);
                obs.complete();
            })
        });
    }

   

    getAssigneeList(search:string, group_name:string):Observable<any>{
        let _params = {};
        if(search !== undefined) _params['search'] = search.toString();
        if(group_name !== undefined)_params['groups__name'] = group_name.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.USER,{
                params:_params
            }).pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getLenderList(search:string):Observable<any>{
        let _params = {};
        if(search !== undefined) _params['search'] = search.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_LENDERS,{
                params:_params
            }).pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getAuditTrail(id:string,limit:number = 1):Observable<any>{
        let _params = {};
        
        _params['offset'] = "0";
        _params['limit'] = "100000";
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['APPEAL_ROOT'] +'/'+id + this.api.endpoints['APPEAL_AUDIT'],{
                params:_params 
                })
            .pipe(
                take(1),
                catchError(err => throwError(err))
            ).subscribe((response)=>{
                obs.next(response);
                obs.complete();
            })
        });
    }

    uploadAppealDocument(appealId:string,document:FileItem,
        details:{
            reference_id?:string,
            is_privileged?:boolean,
        }){

        let _formData = new FormData();
        
        _formData.append('filename',document.file['given_name']);
        _formData.append('category',document.file['slug']);
        _formData.append('name',document.file['name']);
        _formData.append('file',document.file);

        if(details.is_privileged){
            _formData.append('is_privileged','true');
        }

        if(details.reference_id){
            _formData.append('attach_to_note_reference_id',details.reference_id);
        }
        
        return this.http.post(
            this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENTS'],
            _formData
        ).pipe(take(1))
    }

    uploadPetitionDocument(appealId:string,document:any){
        if(appealId !== undefined){
            const _formData = new FormData();
            // console.log(">>" , document);
            _formData.append('filename',document['name']);
            _formData.append('category',"petition-for-reconsideration");
            _formData.append('name',document['name']);
            _formData.append('file',document);
            return this.http.post(
                this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENTS'],
                _formData
            ).pipe(take(1))
        }
    }

    getUploadedDocuments(id:string):Observable<any>{
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.APPEAL_ROOT + '/' + id + this.api.endpoints.APPEAL_DOCUMENTS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next( response['results'] );
                    obs.complete();
                })
        });
    }

    changeAppealState(appealSlug:string,action:any){
        if(appealSlug !== undefined){
            return this.http.put(
                this.api.endpoints.APPEAL_ROOT+'/'
                +appealSlug
                +this.api.endpoints.APPEAL_ACTIONS+'/'
                +action.slug,{}
            ).pipe(
                take(1),
                catchError(err => throwError(err))
            );
        }
    }

    assignJudgeToAppeal(appealSlug:string,user:string){
        // console.log(user,appealSlug);
        return new Observable((obs)=>{
            obs.next(true);
            obs.complete();
        })
    }

    assignAttorneyToAppeal(appealSlug:string,user:string){
        // console.log(user,appealSlug);
        return new Observable((obs)=>{
            obs.next(true);
            obs.complete();
        })
    }

    formatPayload(payload:any){
        // Some values need to be adjusted
        let _payload = Object.assign({},payload);
        let _lAmount = _payload['loan_amount_in_cents'];
        let _lForgive = _payload['forgiveness_amount_received_in_cents'];
        let _lForgiveReq = _payload['forgiveness_amount_requested_in_cents'];
        let _lForgiveReqLender = _payload['forgiveness_amount_requested_by_lender_in_cents'];
        // let _action = _payload['action'].slug;
        // let _lender = _payload['lender'].id;
        // let _primary_contact = _payload['primary_contact'].username;

        if(_lAmount){
            _payload['loan_amount_in_cents'] = (_lAmount * 100).toFixed(0);
        }

        if(_lForgive){
            _payload['forgiveness_amount_received_in_cents'] = (_lForgive * 100).toFixed(0);
        }

        if(_lForgiveReq){
            _payload['forgiveness_amount_requested_in_cents'] = (_lForgiveReq * 100).toFixed(0);
        }
        if(_lForgiveReqLender){
            _payload['forgiveness_amount_requested_by_lender_in_cents'] =  (_lForgiveReqLender * 100).toFixed(0);
        }

        // _payload['action'] = _action;
        // _payload['lender'] = _lender;
        // _payload['primary_contact'] = _primary_contact;

        return _payload;
    }

    getDocumentCategories(types?:string[],offset?:number,limit?:number):Observable<any>{
        let _params = {};
        
        if(offset !== undefined) _params['offset'] = offset.toString();
        if(limit) _params['limit'] = limit.toString();

        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['DOCUMENT_CATEGORIES'],{
                params:_params 
            }).pipe(
                take(1),
            ).subscribe((categories)=>{
                categories['results'].push({slug: "motion-for-extension-of-the-close-of-record", display_name: "Motion for Extension of the Close of Record", type: "active_appeal"});
                categories['results'].push({slug: "motion-to-dismiss", display_name: "Motion to Dismiss", type: "active_appeal"})
                let _categories = [{
                    slug:null,
                    type:null,
                    display_name:"Error: No Categories Loaded"
                }];
                if(categories['results']){
                    if(types){
                        //console.log(types,categories['results'])
                        _categories = categories['results'].filter((category)=>{
                            return types.indexOf(category.type)>-1
                        });    
                    }else{
                        _categories = categories['results']
                    }

                    let _finalCategories = [];
                    _categories.map((category)=>{

                        _finalCategories.push({
                            slug:category.slug,
                            display_name:category.display_name,
                            type:category.type,
                            requiredUploads:[category.slug],
                            is_privileged:false
                        });

                        // Check for Administrative Record, make a privledged options
                        // console.log(category.slug,' === ',DOCUMENT_CATEGORIES.ADMINISTRATIVE_RECORD.slug)
                        if(category.slug === DOCUMENT_CATEGORIES.ADMINISTRATIVE_RECORD.slug){
                            _finalCategories.push({
                                slug:DOCUMENT_CATEGORIES.ADMINISTRATIVE_RECORD_PRIVILEDGED.slug,
                                display_name:DOCUMENT_CATEGORIES.ADMINISTRATIVE_RECORD_PRIVILEDGED.display_name,
                                type:category.type,
                                requiredUploads:[
                                    DOCUMENT_CATEGORIES.ADMINISTRATIVE_RECORD.slug
                                    ,DOCUMENT_CATEGORIES.VAUGHN_INDEX.slug
                                    ,DOCUMENT_CATEGORIES.REDACTED_ADMINISTRATIVE_RECORD.slug
                                ],
                                is_privileged:true
                            });
                            // console.log(_finalCategories)
                        }
                    })

                    //console.log(_finalCategories)
                    obs.next(_finalCategories);
                    obs.complete();
                }
            },(err)=>{
                if(err){
                    obs.next(null);
                    obs.complete();
                    console.log(err)
                }
            })
        })
    }

    generateDocument(appealId:string,payload:any){
        let _params = {}
        
        Object.keys(payload).map((key)=>{
            if(key !== 'type'){
                switch(payload[key].type){
                    case 'date':
                        _params[key] =payload[key].value !== '' ? this.datepipe.transform(payload[key].value, 'longDate','UTC'): '';
                    break;
                    default:
                        _params[key] = payload[key].value
                    break;
                }
            }
        });
        return this.http.put(this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['GENERATE_DOCUMENT']+'/'+payload.type.value,
            _params,
            {responseType: 'blob'}
           
        ).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getGeneratableDocumentsList():Observable<any>{
        return this.http.get(this.api.endpoints['GENERATABLE_DOCUMENTS']).pipe(
            take(1),
            catchError(err => throwError(err))
        );
    }

    getCOSSetting(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.SETTINGS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['certificate_of_service_enabled']);
                    obs.complete();
                })
        });
    }

    getOGCEmailSetting(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.SETTINGS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['ogc_email']);
                    obs.complete();
                })
        });
    }


    getDocketAsset(docUrl:string){
        return new Observable((obs)=>{
            this.http.get(docUrl,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }

    getUploadedAsset(appealId:string, ref_id:string){
        if(appealId !== undefined){
            return new Observable((obs)=>{
                this.http.get(this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_DOCUMENT']+ '/' + ref_id,{
                    responseType: 'blob'
                })
                    .pipe(
                        take(1),
                        catchError(err => throwError(err))
                    ).subscribe(
                        (response)=>{
                            obs.next(response);
                            obs.complete();
                    },(err)=>{
                        console.log("err", err);
                      });
            });
        }
    }

    initiateARDocument(ref_id){

        return new Observable((obs)=>{
            this.http.post(this.api.endpoints['ADMINISTRATIVE_RECORD'],{ticket:ref_id})
                .pipe(
                    take(1)
                ).subscribe((response)=>{
                    obs.next(response);
                    obs.complete();
                },((err)=>{
                    obs.next(err["error"]);
                    obs.complete();
                }))
        });

        // return this.http.post(this.api.endpoints['ADMINISTRATIVE_RECORD'],{ticket:ref_id}).pipe(take(1),
        // catchError(err => throwError(err)));
    }

    fetchARDocument(ref_id){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['ADMINISTRATIVE_RECORD']+ '/'+ref_id)
                .pipe(
                    take(1)
                ).subscribe((response)=>{
                    obs.next(response);
                    obs.complete();
                },((err)=>{
                    obs.next(err["error"]);
                    obs.complete();
                }))
        });
    }


    viewARAsset(ref_id:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['ADMINISTRATIVE_RECORD_ASSET'] + '/'+ref_id,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }
    addPublicTicketNotes(appealId:string, content:any){
        if(appealId !== undefined){
            let _callBody = {
                'category': "public_note",
                'comment': content.text
            };
            return this.http.post(
                this.api.endpoints['APPEAL_ROOT']+'/'+appealId+this.api.endpoints['APPEAL_PUBLIC_NOTE'],
                _callBody
            ).pipe(take(1))
        }
    }

    getPublicNotes(appeal_id:string):Observable<any>{
        if(appeal_id !== undefined){
            let _params = {};

            _params['offset'] = "0"
            _params['limit'] = "100"
    
            return new Observable((obs)=>{
                this.http.get(this.api.endpoints['APPEAL_ROOT']+'/'+appeal_id+this.api.endpoints['APPEAL_PUBLIC_NOTE'],{
                    params:_params
                }).pipe(
                    take(1))
                .subscribe((response)=>{
                    obs.next(response);
                    obs.complete();
                },(err)=>{
                    console.log(err);
                });
            })
        }
    }

    getSettings(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints.SETTINGS)
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    // obs.next(response['certificate_of_service_enabled']);
                    obs.next(response);
                    obs.complete();
                })
        });
    }
    
}
