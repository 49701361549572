import { APPEAL_WIZARD_PATHS } from '../appeal-wizard.constants';

const STEP = APPEAL_WIZARD_PATHS.APPEAL_CONTENT;
export const APPEAL_CONTENT={
    AC_CRITERIA1:{
        label : 'This appeal is filed within 30 calendar days after receipt of the final SBA loan review decision.',
        storeKey:'is_filed_within_30_days',
        required:true,
        step:STEP
    },
    AC_LOAN_REVIEW_RECEIVED:{
        label : 'SBA Loan Review Decision Receipt Date:',
        storeKey:'final_loan_review_decision_received_date',
        required:true,
        step:STEP
    },
    AC_CRITERIA2:{
        label : 'This appeal is being filed because the Borrower received an unfavorable decision from the SBA regarding a PPP Loan',
        storeKey:'is_filed_for_unfavorability',
        required:true,
        step:STEP
    },
    AC_CRITERIA2_REASONS:{
        label : 'Please select one or more reasons for the final SBA loan review decision :',
        storeKey:'select_reason',
        required:false,
        step:STEP
    },
    AC_CRITERIA2_1:{
        // label : 'This appeal is being filed because the Borrower received an unfavorable decision form the SBA regarding a PPP Loan',
        label : 'Ineligible for PPP Loan',
        storeKey:'is_filed_for_ppp_ineligible',
        required:true,
        step:STEP,
        isAtleastOne:true
    },
    AC_CRITERIA2_2:{
        label : 'Ineligible for Amount of PPP Loan received',
        storeKey:'is_filed_for_ineligible_ppp_amount',
        required:true,
        step:STEP,
        isAtleastOne:true
    },
    AC_CRITERIA2_3:{
        label : 'Used PPP Loan proceeds for unauthorized purpose',
        storeKey:'is_filed_for_unauthorized_use',
        required:true,
        step:STEP,
        isAtleastOne:true
    },
    AC_CRITERIA2_4:{
        label : 'Ineligible for PPP loan forgiveness in the amount determined by the lender in its full approval or partial approval decision issued to SBA',
        storeKey:'is_filed_for_lender_partial_approval',
        required:true,
        step:STEP,
        isAtleastOne:true
    },
    AC_CRITERIA2_5:{
        label : 'Is ineligible for PPP loan forgiveness in any amount when the lender has issued a full denial decision to SBA',
        storeKey:'is_filed_for_lender_issued_full_denial',
        required:true,
        step:STEP,
        isAtleastOne:true
    },
    AC_CRITERIA3:{
        label : 'This appeal is seeking the reversal or remand of the SBA Final Loan Review Decision.',
        storeKey:'is_filed_for_reversal',
        required:true,
        step:STEP
    },
    APPEAL_DOCUMENT_SUBMITTED_1:{
        label : 'An Appeal Document of type Appeal must be submitted.',
        storeKey:'appeal_document_submitted',
        required:true,
        step:STEP
    },
    APPEAL_DOCUMENT_SUBMITTED_2:{
        label : 'An Appeal Document of type Copy of Final SBA Loan Review Decision must be submitted.',
        storeKey:'review_document_submitted',
        required:true,
        step:STEP
    },
    APPEAL_DOCUMENT_SUBMITTED_3:{
        label : 'An Appeal Document of type Tax Document must be submitted.',
        storeKey:'tax_document_submitted',
        required:false,
        step:STEP
    }

}