import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { take } from 'rxjs/operators';
import { NavigationConfig, NAVIGATION_CONFIG } from '../navigation.interface';
import { PageView } from './page-view.component';
import { KillZombies } from '../kill-zombies/kill-zombies';
import { WindowRef } from '../windowRef/window-ref.service';
import { getWindowState } from '../store/navigation.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector:'page-view-with-anchors',
  template:``
})
export class PageViewAnchors extends KillZombies(PageView) implements AfterViewInit{

  public currentAnchor = null;
  public anchorYOffsets:any = [];
  public anchors:any = [];

  constructor(
    @Inject(NAVIGATION_CONFIG) protected config:NavigationConfig,
    public el:ElementRef,
    public windowRef:WindowRef,
    public ngrxstore:Store,
    public navigationService:NavigationService){
    
    super(config,navigationService);
    this.ngrxstore.select(getWindowState()).subscribe((state)=>{
      this.isMobile = (state.size === 'isMobile');
      this.isTablet = (state.size === 'isTablet');
      this.isDesktop = (state.size === 'isDesktop');
      this.isWidescreen = (state.size === 'isWidscreen');
      this.isFullHD = (state.size === 'isFullHd');
    })

  }

  ngAfterViewInit(){
    this.refreshAnchors();
  }

  moveFocus(id:string){
    this.el.nativeElement.querySelector(id).focus();
  }

  refreshAnchors(){

    // Track scroll to activate each anchor button, 
    // ... this logic is allowed in the view because it only relates to the view
    let _windowRefSub$ = null;
    
    // Ensure you dispose of the old subscription
    this.killTheseZombies(['windowRef']);

    this.anchorYOffsets = [];
    this.windowRef.getYOffsets(this.el,this.anchors.map((value)=>{
        return value.id;
    })).pipe(take(1)).subscribe((boundingRects)=>{
        this.anchorYOffsets = boundingRects.map((v:any,index)=>{
            return{ 
                id:v.selector,
                offset:v.offset - this.anchors[index].scrollOffset
            }
        });

        _windowRefSub$ = this.ngrxstore.select(getWindowState()).subscribe((windowState)=>{
            this.anchorYOffsets.map((anchor,index)=>{
                if(index === this.anchorYOffsets.length-1 && windowState.scrollY === windowState.scrollYMax){
                    this.currentAnchor = anchor.id
                }else{
                    if(
                        (windowState.scrollY > anchor.offset-50) 
                            && ( (this.anchorYOffsets[index+1]) 
                            ? windowState.scrollY < this.anchorYOffsets[index+1].offset 
                            : true )
                    ){
                        // adds a class of 'active' to the anchor button in the template
                        this.currentAnchor = anchor.id;
                    }
                }
            });
        })

        this.storeZombieByKey('windowRef',_windowRefSub$);
    });
  }
}
