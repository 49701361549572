import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../platform/modules/shared';
import { TicketsService } from './tickets.service';
import { TicketsRouting } from './tickets.routes';
import { AppealTicket } from './appeal-ticket/appeal-ticket.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { TicketResolver } from './resolvers/ticket.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TicketSummary } from './ticket-summary/ticket-summary.component';
import { TicketCTA } from './ticket-cta/ticket-cta.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TicketsRouting,
    NgxPageScrollModule,
    SharedModule.forRoot(),
    NgxDatatableModule,
    NgxExtendedPdfViewerModule,
    NgbTypeaheadModule,
    NgbTooltipModule
  ],
  declarations:[
    AppealTicket,
    TicketSummary,
    TicketCTA
  ],
  providers:[
      TicketsService,
      TicketResolver
  ],
  exports:[
    RouterModule
  ]
})

export class TicketsModule{};
