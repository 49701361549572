import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ROUTE_PATHS_WITH_BACKGROUND } from './constants/routes.constants';

@Injectable()
export class AppService{

  public routesWithBackground = ROUTE_PATHS_WITH_BACKGROUND;
  get RoutesWithBackdrop(){
    return ROUTE_PATHS_WITH_BACKGROUND;
  }

  public $routeHasBackdrop:BehaviorSubject<boolean> = new BehaviorSubject(false);
  get $HasBackdrop():BehaviorSubject<boolean>{
    return this.$routeHasBackdrop;
  }

  constructor(public router:Router){
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(($event)=>{
      this.$routeHasBackdrop.next(this.routeHasBackdrop($event['url']));
    })
  }

  routeHasBackdrop(route:string){
    for(let path in this.routesWithBackground){
      let _withoutQueryParams = route.replace(/(\?.*)+/g,'');
      if((this.routesWithBackground[path] === _withoutQueryParams.substring(1)) 
       || (_withoutQueryParams.indexOf('login') > -1) || (_withoutQueryParams.indexOf('/appeal/') > -1) || (_withoutQueryParams.indexOf('invite-user') > -1) || (_withoutQueryParams.indexOf('reset-password') > -1)){
        return true;
      }
    }
    return false;
  }
}
