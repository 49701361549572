import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { API_CONFIG, ApiConfig } from '../../../../../app.interface';

@Injectable({
  providedIn: 'root'
})
export class SmartyStreetsService {

  constructor(
    public http:HttpClient,
    @Inject(API_CONFIG) public api:ApiConfig,
  ) { }
  AutocompleteAddress(term: string, selectedTerm: string): any {
    let _callBody= {
      "search": term,
      "selected": (selectedTerm ? selectedTerm : ""),
      "auth-id": process.env.SMARTY_STREETS_AUTH_ID
    };
     return new Observable((obs)=>{
        this.http.get(this.api.endpoints['SMARTY_STREETS_LOOKUP'], {params: _callBody}).pipe(
          take(1))
          .subscribe((response)=>{
            obs.next(response);
            obs.complete();
          },(err)=>{
            console.log(err);
        });
      })
  }

  ValidateAddress(address: any): Observable<any>{
    let _callBody= {
      "street": address.street_line + " " + address.secondary,
      "city": address.city,
      "state": address.state,
      "auth-id": process.env.SMARTY_STREETS_AUTH_ID
    };
     return new Observable((obs)=>{
        this.http.get(this.api.endpoints['SMARTY_STREETS_VALIDATE'], {params: _callBody}).pipe(
          take(1))
          .subscribe((response)=>{
            obs.next(response);
            obs.complete();
          },(err)=>{
            console.log(err);
        });
      })
  }

}