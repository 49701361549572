import { Component, OnInit, EventEmitter, Input, Output, OnChanges, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';
import * as ChangeCase from 'change-case';
import * as _ from 'lodash';

@Component({
  selector:'sortable-list',
	template:require('./sortable-list.component.html')
})

export class SortableList implements OnInit, OnChanges{

  @Input() title:string = "untitled";
  @Input() $subject:BehaviorSubject<any> = new BehaviorSubject([]);
  @Input() $companionSubject:BehaviorSubject<any> = new BehaviorSubject([]);
  @Input() listClasses:string = '';
  @Input() strictColorSet:string[] = [];
  @Input() strictOrder:string[] = [];
  @Input() max:number = 0;

  @Output() onSorted:EventEmitter<any> = new EventEmitter();
  @Output() onLimitReached:EventEmitter<any> = new EventEmitter();
  // @Output() onClear:EventEmitter<any> = new EventEmitter();

  public subject:any;
  public companionSubject:any;
  public rows:any;
  public colors:string[] = [];
  public listCollapsed:boolean = false;
  public listBtnLabel:string = 'Hide List';
  public listIsReady:boolean = false;
  public headers:string[] = [];

  constructor(){}

  ngOnInit(){
    this.$companionSubject.subscribe((res)=>{
       this.companionSubject = res;
    })

    this.$subject.subscribe((res) => {

      this.subject = res;
      this.colors = [];
      let _colorsLength = 0;
      let _rows = [];
      let _i = 0;
      let _forcedIndex = null;

      this.headers = this.strictOrder.map((item)=> ChangeCase.titleCase(item));
      if(_.size(res)>0){
        for ( let row of res){
          let _row = [];

          for ( let cell in row ){

            if(this.strictOrder.length > 0){
              _forcedIndex = this.strictOrder.indexOf(cell);
            }

            if(cell !== 'color'){
              if(this.strictOrder.length > 0){
                _row[_forcedIndex]=decodeURIComponent(row[cell])
              }else{
                _row.push(decodeURIComponent(row[cell]))
              }
            }else{
              if(this.strictColorSet.length > 0){
                _colorsLength = this.strictColorSet.length
                if(_i>(_colorsLength-1)){
                  this.colors.push(this.strictColorSet[_i-(_colorsLength-1)]);
                }else{
                  this.colors.push(this.strictColorSet[_i]);
                }
              }else{
                this.colors.push(row[cell]);
              }
            }
          }
          _i++;
          _rows.push(_row)
        }
        if(_rows.length > this.max-1){
          this.onLimitReached.emit(true);
        }else{
          this.onLimitReached.emit(false);
        }

        this.rows = _rows;
        this.listIsReady = true;
      }
    });
  }

  ngOnChanges(changes){
    // this.$rows.next(this.subject);
  }

  removeItemFromList(id:number){
    let _sorted = _.clone(this.rows);
    let _subject = _.clone(this.subject);
    let _companion = _.clone(this.companionSubject);

    _sorted = _.remove(_sorted,(partial)=>{
      return partial !== _sorted[id];
    });

    _subject = _.remove(_subject,(partial)=>{
      return partial !== _subject[id];
    })

    _companion = _.remove(_companion,(partial)=>{
      return partial !== _companion[id];
    })

    this.onSorted.emit({
      list:_sorted,
      subject:_subject,
      companion:_companion
    });
  }

  sendItemToTop(id:number){
    let _sorted = _.clone(this.rows);
    let _subject = _.clone(this.subject);
    let _companion = _.clone(this.companionSubject);
    let _thisItem = _sorted[id];
    let _thisSubject = _subject[id];
    let _thisCompanion = _companion[id];

    _sorted = _.remove(_sorted,(partial)=>{
      return partial !== _sorted[id];
    });
    _subject = _.remove(_subject,(partial)=>{
      return partial !== _subject[id];
    });
    _companion = _.remove(_companion,(partial)=>{
      return partial !== _companion[id];
    })

    _sorted.unshift(_thisItem);
    _subject.unshift(_thisSubject);
    _companion.unshift(_thisCompanion);

    this.onSorted.emit({
      list:_sorted,
      subject:_subject,
      companion:_companion
    });
  }

  sendItemToBottom(id:number){
    let _sorted = _.clone(this.rows);
    let _subject = _.clone(this.subject);
    let _companion = _.clone(this.companionSubject);
    let _thisItem = _sorted[id];
    let _thisSubject = _subject[id];
    let _thisCompanion = _companion[id];

    _sorted = _.remove(_sorted,(partial)=>{
      return partial !== _sorted[id];
    });
    _subject = _.remove(_subject,(partial)=>{
      return partial !== _subject[id];
    });
    _companion = _.remove(_companion,(partial)=>{
      return partial !== _companion[id];
    })

    _sorted.push(_thisItem);
    _subject.push(_thisSubject);
    _companion.push(_thisCompanion);

    this.onSorted.emit({
      list:_sorted,
      subject:_subject,
      companion:_companion
    });
  }

  collapse(){
    this.listCollapsed = !this.listCollapsed;
    this.listBtnLabel = (this.listCollapsed) ? 'Show List' : 'Hide List' ;
  }

}

function createObservable(value:any){
  return new Observable(observer => {
    observer.next(value);
  })
}
