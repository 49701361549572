import { Component, ElementRef, forwardRef, Inject, Input, LOCALE_ID, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { transform } from 'lodash';

export const PHONE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPhone),
    multi: true
  };
  
@Component({
    template:`
        <input 
            #phoneInput
            [id]='id'
            (change)="onChange($event)"
            (keyup)="onKeyUp($event)" 
            (input)="onInput($event)"
            (blur)="touchCallback()" 
            type="text"
            name = "{{name}}"
            [attr.title]="title"
            [attr.aria-label]='title' 
            [attr.disabled]="disabled?true:null"
            class="form-control"
            [ngClass]='classes' 
            [placeholder] = 'placeholder'
        >`,
    selector:"input-phone",
    providers:[PHONE_ACCESSOR]
})
export class InputPhone implements ControlValueAccessor{

    @ViewChild('phoneInput',{static:true}) input:ElementRef;

    @Input() classes:string = '';
    @Input() set label(value){
        this.name = 'input_'+value;
        this.title = _.startCase(value);
        this.id = _.camelCase(value);
    }
    @Input() ext:boolean = false;
    @Input() countryCode:string = '+1';

    public disabled:boolean = false;
    public placeholder:string = 'Phone Number (US Only)';
    public title:string = '';
    public name:string = '';
    public id:string = '';
    public limitMatchRegExp:RegExp = new RegExp(/^\d{0,10}/,'g');

    constructor(
        @Inject(LOCALE_ID) public _locale: string,
        public renderer:Renderer2
    ){}
    
    changeCallback=(data:any)=>{
        return data;
    };
    touchCallback=()=>{};

    convertToPhone(event):string{
        //console.log(this.countryCode)
        var ph = event.target.value.replace(/\D/g,'').substring(0,30);
        // Backspace and Delete keys
        var deleteKey = (event.keyCode == 8 || event.keyCode == 46);
        var len = ph.length;
        if(len==0){
            ph=ph;
        }else if(len<3){
            ph='('+ph;
        }else if(len==3){
            ph = '('+ph + (deleteKey ? '' : ') ');
        }else if(len<6){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6);
        }else if(len==6){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+ (deleteKey ? '' : '');
        }else if(len<10){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+ph.substring(10);
        }else if(len==10){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+(deleteKey ? '' : '');
        }else{
            if(this.ext){
                ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+' ext. '+ph.substring(10);
            }else{
                ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10);
            }
        }
        return ph;
    }

    onInput(event){
        let _newValue = event.target.value.match(this.limitMatchRegExp);
        _newValue = this.writeConvertToPhone(event.target.value);
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }

    writeConvertToPhone(value):string{
        var ph = value.replace(/\D/g,'').substring(0,30);
        var len = ph.length;
        if(len==0){
            ph=ph;
        }else if(len<3){
            ph='('+ph;
        }else if(len==3){
            ph = '('+ph + ') ';
        }else if(len<6){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6);
        }else if(len==6){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+ '';
        }else if(len<10){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+ph.substring(10);
        }else if(len==10){
            ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+'';
        }else{
            if(this.ext){
                ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10)+' ext. '+ph.substring(10);
            }else{
                ph='('+ph.substring(0,3)+') '+ph.substring(3,6)+'-'+ph.substring(6,10);
            }
        }
        return ph;
    }

    convertToFlatIntPhone(value){
        return this.countryCode+value.replace(/\D/g,'');
    }

    onChange(event){
        let _newValue = null;
        if(event.target.value){
            // render as a formatted us phone number
            _newValue = this.convertToPhone(event);
            // emit as an international number
            this.changeCallback(
                this.convertToFlatIntPhone(_newValue)
            );
        }else{
            this.changeCallback(null);
        }
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
        
    }

    onKeyUp(event){
        // Allow Decmial Symbols
        let _newValue = event.target.value;
        let _value = null
        if(event.target.value){
            // render as a formatted us phone number
            _value = this.convertToPhone(event);
            // emit as an international number
            this.changeCallback(
                this.convertToFlatIntPhone(_value)
            );
        }else{
            this.changeCallback(null);
        }
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }

    writeValue(value: any){
        let _newValue = null;
        if(value){
            // remove any single digit number prefix
            _newValue = value.replace(/\+[1-9]/,'');

             // render as a formatted us phone number
             _newValue = this.writeConvertToPhone(_newValue);
        }
        this.renderer.setProperty(this.input.nativeElement,'value',_newValue);
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled=isDisabled;
    }
}