import { Component, Input } from '@angular/core';

@Component({
    selector:'status-card',
    template:require('./status-card.component.html')
})
export class StatusCard{
    
    public statusSet:any[] = [];
    public boardClasses:string = null;

    @Input() set config(config:StatusCardStatus[]){
        this.statusSet = config;

        let _hasActive = this.statusSet.filter((value,index)=>{
            return value.active;
        })

        if(!_hasActive){
            this.statusSet[0].active = true;
        }  
    }

    @Input() set classes(classes:string){
        this.boardClasses = classes;
    }

    constructor(){

    }
}