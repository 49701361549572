import { Action } from '@ngrx/store';
import { SHELL_ACTIONS } from '../constants/shell.constants';
import * as _ from 'lodash';

export class OpenActionPanel implements Action {
  readonly type = SHELL_ACTIONS.TOGGLE_ACTION_PANEL_SHELL;
  constructor(public page:string, public collapsed:boolean = false){
    this.type = String(SHELL_ACTIONS['TOGGLE_ACTION_PANEL'+'_'+_.upperCase(page)]);
  }
}

export class CloseActionPanel implements Action {
  readonly type = SHELL_ACTIONS.TOGGLE_ACTION_PANEL_SHELL;
  constructor(public page:string, public collapsed:boolean = true){
    this.type = String(SHELL_ACTIONS['TOGGLE_ACTION_PANEL'+'_'+_.upperCase(page)]);
  }
}

export class ThawView implements Action {
  readonly type= SHELL_ACTIONS.THAW_VIEW;
  constructor(){}
}

export class FreezeView implements Action {
  readonly type= SHELL_ACTIONS.FREEZE_VIEW;
  constructor(){}
}
