import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, RoutesRecognized, GuardsCheckEnd, NavigationError, NavigationExtras, UrlTree } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Subject, Observable, combineLatest, config, throwError} from 'rxjs';
import { pairwise, filter, take} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUrlState } from './store/navigation.selectors';

import { CloseNavigationDrawer, StoreCurrentURL, StorePreviousURL } from './store/navigation.actions';
import { WindowRef } from './windowRef/window-ref.service';

import { NavigationConfig, NAVIGATION_CONFIG } from './navigation.interface';

import * as _ from 'lodash';
import * as changeCase from 'change-case';
import { KillZombies } from './kill-zombies/kill-zombies';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';

class DisabledWhen{
  constructor(initial = false){
    return { disabledWhen:[]};
  }
}

interface DisabledObservable{
  name:string;
  obs:Observable<any>;
}

interface NamedSubscription{
  name:string,
  sub:any;
}

@Injectable({
  providedIn:'root'
})
export class NavigationService extends KillZombies(){

  public routePaths:any = null;
  public fullRoutes:any = null;
  public pageNames:any = null;
  public routePermissions:any = null;
  public externalLinkPermissions:any = null;
  public currentRoles:any = [];
  public $currentRoute:BehaviorSubject<string> = new BehaviorSubject(null);
  public navigationRoot:string = null;
  public rolesSelector:string = null;
  public navigationSections:any = null;
  public unSubscribeOnNavigate:string[] = [];
  public sideNavRoles:any = null;
  public drawerNavRoles:any = null;
  public currentRouteIsWizard:boolean = false;
  public currentRouteHasFixedHeader:boolean = false;
  public currentRoute:string = null;
  public previousRoute:string = null;
  public revertSecondaryOnNavigate:boolean = false;
  public previousSecondaryTopNav:any = {};

  get CurrentNavigationState(){
    if(this.router.getCurrentNavigation()){
      return this.router.getCurrentNavigation().extras.state;
    }else{
      return {};
    }
  }

  get CurrentRoles(){
    return this.currentRoles;
  }

  get Paths(){
    return this.routePaths;
  }

  get Routes(){
    return this.fullRoutes;
  }

  get Permissions(){
    return this.routePermissions;
  }

  get ExternalLinkPermissions(){
    return this.externalLinkPermissions;
  }
  
  get CurrentRoute(){
    return this.currentRoute;
  }

  get $CurrentRoute(){
    return this.$currentRoute;
  }

  get RouteIsWizard(){
    return this.currentRouteIsWizard;
  }

  get RouteHasFixedHeader(){
    return this.currentRouteHasFixedHeader;
  }


  public $scrollToTopPosition:BehaviorSubject<any> = new BehaviorSubject({});
  get $ScrollToTopPosition(){
    return this.$scrollToTopPosition;
  }

  public $navigationConfiguration:BehaviorSubject<any> = new BehaviorSubject({
    sections:[],
    links:[],
    pages:[],
    sideNav:[],
    disabled:false,
    homeUrl:null
  })
  get $NavigationConfiguration(){
    return this.$navigationConfiguration;
  }

  public $topNavConfiguration:BehaviorSubject<TopNavConfiguration> = new BehaviorSubject({
    key:'untitled',
    pageActions:[]
  })
  get $TopNavConfiguration(){
    return this.$topNavConfiguration;
  }

  public $drawerNavigationConfiguration:BehaviorSubject<any> = new BehaviorSubject({
    items:[],
    disabled:false
  })
  get $DrawerNavigationConfiguration(){
    return this.$drawerNavigationConfiguration;
  }

  

  constructor(
      @Inject(NAVIGATION_CONFIG) public config:NavigationConfig,
      public ngrxstore:Store<any>,
      public router:Router,
      public windowRef: WindowRef,
      public activatedRoute:ActivatedRoute
  ){

    super();

    this.routePaths = config.paths;
    this.fullRoutes = config.routes;
    this.routePermissions = config.permissions;
    this.externalLinkPermissions = config.external_link_permissions;
    this.navigationRoot = config.navigationRoot;
    this.sideNavRoles = config.sideNavRoles;

    if(config.rolesSelector){
      this.rolesSelector = config.rolesSelector;
      this.ngrxstore.select(this.rolesSelector).subscribe((roles)=>{
        let _cr = this.currentRoles;
        this.currentRoles = roles;
        // Trigger a refresh of the navigation when roles change.
        if(_cr[0] !== roles[0]){
          this.refreshNavigation();
        }
      })
    }else{
      this.currentRoles = [];
    }


    this.ngrxstore.select(selectUrlState).pipe(take(1)).subscribe((state)=>{
      //console.log(state)
      this.currentRoute = state.current;
      this.$currentRoute.next(state.current);
      this.previousRoute = state.previous;
      this.detectNavigation()
    })
  }

  refreshNavigation(){
    zip(
      this.$drawerNavigationConfiguration,
      this.$navigationConfiguration,
      this.$topNavConfiguration
    ).pipe(map(([$drawer,$primary,$toolbar])=>(
      {$drawer,$primary,$toolbar}
    )),take(1)).subscribe((currentConfig)=>{
      this.configureDrawerNavigation(currentConfig.$drawer);
      this.configureNavigation(currentConfig.$primary);
      this.configureActionItems(currentConfig.$toolbar);
    });
  }

  detectNavigation(){

    // console.log(this.router.config);

    // Catch Refresh and Initial Page Landing
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      take(1)
    ).subscribe(($event:NavigationStart)=>{
      this.ngrxstore.dispatch( new CloseNavigationDrawer() );
    },(err)=>{

    });

    // Catch Subsequent Navigation Start Events
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      pairwise()
    ).subscribe(($event)=>{
      this.ngrxstore.dispatch( new CloseNavigationDrawer() );
    },(err)=>{

    });

    // Catch First Navigation End Events
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      take(1)
    ).subscribe(($event)=>{
      this.killTheseZombies(this.unSubscribeOnNavigate);
      this.unSubscribeOnNavigate = [];
      this.ngrxstore.dispatch(new StoreCurrentURL($event['url']));
    },(err)=>{

    });

    // Catch Subsequent Navigation End Events
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      pairwise())
    .subscribe(($event) => {
      this.killTheseZombies(this.unSubscribeOnNavigate);
      this.ngrxstore.dispatch(new StorePreviousURL($event[0]['url']));
      this.ngrxstore.dispatch(new StoreCurrentURL($event[1]['url']));
    },(err)=>{

    });

    // Catch Errors
    this.router.events.pipe(
      filter(e => e instanceof NavigationError)
    ).subscribe(($event)=>{
    },(err)=>{

    });

    this.ngrxstore.select(selectUrlState).subscribe((state)=>{
      this.currentRoute = state.current;
      this.previousRoute = state.previous;
      this.$currentRoute.next(state.current);
      this.currentRouteIsWizard = this.routeIsWizard(state.current);
    });
    
  }

  routeIsWizard(route:string){
    let _matches = 0;
    Object.values(this.config.wizardRoutes).map((wroute:string)=>{
      if(route.search(new RegExp('/'+wroute+'+/','i')) > -1){
        _matches+=1
      };
    })
    return _matches > 0;
  }

  routeIsPermitted(route:string){
    if(route){
      let roles = this.currentRoles; //Migrate to redux
      return this.isPermitted(route,roles);
    }
    return true;
  }

  externalLinkIsPermitted(link:string){
    if(link){
      let permissions = this.externalLinkPermissions; //Migrate to redux
      let roles = this.currentRoles; //Migrate to redux
      return this.isPermitted(link,roles,permissions);
    }
    return true;
  }

  navigateByUrl(url:string | UrlTree, extras?:NavigationExtras){
    return this.router.navigateByUrl(url,extras);
  }

  navigateToLastRoute(){
    this.ngrxstore.select(selectUrlState).pipe(take(1)).subscribe((state)=>{
      this.router.navigateByUrl(state.previous)
    })
  }

  navigateToExternal(url:string){
    window.open(url);
    // this.router.navigate(['/externalRedirect',{externalUrl:url}],{skipLocationChange: true});
  }

  createUrlTree(params:any[]){
    return this.router.createUrlTree(params).toString();
  }

  navigate(path:any[],extras?:any){
    //console.log(path,extras)
    this.router.navigate(path,extras);
  }

  scrollToTopPostion(pos:any){
    this.$scrollToTopPosition.next(pos);
  }

  configureDrawerNavigation(configuration:any){
    let prefix:string = configuration.key;
    let _drawerNavPage = [];

    if(configuration.items){
      _drawerNavPage = configuration.items.map((page,index)=>{
        return{
          name:page.name,
          icon:page.icon,
          id:changeCase.camelCase(page.name.replace(/[^A-Za-z0-9]/g,''))+'DrawerLink'.replace(' ',''),
          url:page.url
        }
      })
    }

    this.$drawerNavigationConfiguration.next({
      disabled:false,
      items:_drawerNavPage
    })

  }

  configureNavigation(configuration:any,killOnNavigate:Boolean = false){

    let prefix:string = configuration.key;
    let _sections = [];
    let _links = [];
    let _pages = [];
    let _sideNavPages = [];

    if(configuration.sections){
      _sections = configuration.sections.map((section,section_index)=>{
        return{
          section:section.section,
          section_id:changeCase.camelCase(section.section.replace(/[^A-Za-z0-9]/g,''))+'SectionLink',
          index:section_index,
          pages:section.pages.map((page,page_index)=>{
            page.index = (section_index*100)+page_index;
            page.id=changeCase.camelCase(section.section.replace(/[^A-Za-z0-9]/g,''))+changeCase.titleCase(page.label.replace(/[^A-Za-z0-9]/g,'')).replace(' ','')+'PageLink'.replace(' ','');
            return page;
          })
        }
      });
    }

    if(configuration.links){
      _links = configuration.links.map((link,index)=>{
        return {
          name:link.name,
          id:changeCase.camelCase(link.name.replace(/[^A-Za-z0-9]/g,''))+'Link'.replace(' ',''),
          url:link.url
        }
      })
    }

    if(configuration.pages){
      _pages = configuration.pages.map((page,index)=>{
        return{
          name:page.name,
          id:changeCase.camelCase(page.name.replace(/[^A-Za-z0-9]/g,''))+'Page'.replace(' ',''),
          url:page.url
        }
      })
    }

    if(configuration.sideNav){
      _sideNavPages = configuration.sideNav.map((page,index)=>{
        return{
          name:page.name,
          icon:page.icon,
          id:changeCase.camelCase(page.name.replace(/[^A-Za-z0-9]/g,''))+'SideNavLink'.replace(' ',''),
          url:page.url
        }
      })
    }

    

    this.$navigationConfiguration.next( {
      sections:_sections,
      links:_links,
      pages:_pages,
      sideNav:_sideNavPages,
      disabled:false,
      homeUrl:configuration.homeUrl
    });

    this.watchForDisableConditions(configuration,(checks)=>{
      this.$navigationConfiguration.pipe(take(1)).subscribe((current)=>{
        this.$navigationConfiguration.next(Object.assign({},current,{
          disabled:checks.indexOf(true)>-1 || checks.length > 0
        }));
      })
    }).then((subscriptions)=>{
      if(killOnNavigate){
        Object.keys(subscriptions).map((key)=>{
          this.unSubscribeOnNavigate.push(key)
          this.storeZombieByKey(key,subscriptions[key]);
        })
      }
    })
  }

  configureNavigationForPage(configuration:any){
    let _config = configuration;
    this.$navigationConfiguration.pipe(take(1)).subscribe((conf)=>{
      this.configureNavigation(Object.assign({},conf,configuration),true);
    })
    // Check the Navigations Conditions
    this.watchForDisableConditions(_config,(checks)=>{
      if(checks.length > 0){
        _config['disabledState'] = true;
      }else{
        _config['disabledState'] = false;
      }
    }).then((subscriptions)=>{
      Object.keys(subscriptions).map((key)=>{
        this.unSubscribeOnNavigate.push(key)
        this.storeZombieByKey(key,subscriptions[key]);
      })
    })
  }

  configureActionItems(configuration:any,revertOnNavigate:boolean = false){
    let _config = {
      key:configuration.key,
      pageActions:configuration.pageActions
    };
    // Set the local configuration to the passed values
    _config.pageActions = configuration.pageActions.map((value,index)=>{
      return (!value.disabledWhen) ? Object.assign({},value,new DisabledWhen()) : value;
    });

    _config.pageActions = _config.pageActions.map((value,index)=>{
      return Object.assign({},value,{
        id:changeCase.camelCase(value.title)
      })
    })

    // Check the Page Actions Conditions
    _config.pageActions.map((value,index)=>{
      this.watchForDisableConditions(value,(checks)=>{
        (checks)=>{
          let _index = _.findIndex(_config.pageActions,{order:value['order']})

          if(checks.length > 0){
            _config.pageActions[_index] = Object.assign({},
              _config.pageActions[_index],{
              disabledState:true
            })
          }else{
            _config.pageActions[_index] = Object.assign({},
              _config.pageActions[_index],{
              disabledState:false
            })
          }
        }
        if(revertOnNavigate){
          this.previousSecondaryTopNav = this.$topNavConfiguration.value;
        }else{
          this.$topNavConfiguration.next(_config);
        }
      }).then((subscriptions)=>{
        Object.keys(subscriptions).map((key)=>{
          this.unSubscribeOnNavigate.push(key)
          this.storeZombieByKey(key,subscriptions[key]);
        })
      })});
  }

  configureActionItemsForPage(configuration:any){
    let _config = configuration;
    this.$topNavConfiguration.pipe(take(1)).subscribe((conf)=>{
      let _oldItems = [];
      conf.pageActions.map((confItem)=>{
        let _differs = 0;
        configuration.pageActions.map((pageAction)=>{
          // console.log(confItem.order,pageAction.order)
          if(pageAction.order !== confItem.order){
            _differs +=1;
          }
        })
        if(_differs > 0){
          _oldItems[confItem.order] = confItem;
        }else{
          _oldItems[confItem.order] = configuration.pageActions.filter((configItem)=>{
            return configItem.order === confItem.order;
          })[0];
          // console.log(_oldItems)
        }
      });
      this.configureActionItems({
        key:conf.key,
        pageActions:_oldItems
      },true);
    });
    this.revertSecondaryOnNavigate = true;
  }

  watchForDisableConditions(configuration,callback:any=()=>{}){
    // This assigns an observable to each passed object in disabledWhen values,
    // that observable will listen for changes in the applications store
    // that reflect the provided value for each condtion/tirgger
    return new Promise((resolve)=>{
      let subscriptions = {};
      let disableActionWhen = configuration['disabledWhen'];
      if(_.isArray(disableActionWhen)){
        let _storeSubjects:DisabledObservable[] = [];
        let _subscriptions:any = {};
        disableActionWhen.map((storeConditions)=>{
          let _name:string = storeConditions['store'];
          storeConditions['conditions'].map((condition)=>{
            _name = _name.concat('-'+condition.key);
          })
          _name = configuration.key+'-'+_name;
          _storeSubjects.push({
            name:_name,
            obs:this.ngrxstore.select(storeConditions['store'])
          });
        })
        _storeSubjects.forEach((store,index)=>{
          let _sub = store.obs.subscribe((state)=>{
              let _conditionChecks = [];
              let _conditions = disableActionWhen[index]['conditions'];
              for ( let condition of _conditions){
                if(state[condition.key] === condition.value) _conditionChecks.push(true);
              }
              callback(_conditionChecks);
          });
          _subscriptions[store.name] = _sub;
        })
        if(_storeSubjects.length < 1){
          callback([]);
        }
        resolve(_subscriptions);
      }else{
        callback([]);
        resolve(null);
      }
    })
  }

  isPermitted(path: string, roles: any = [], permissionsList:any = this.routePermissions, omit:string = this.navigationRoot): boolean {
    let permissions = [];
    if(roles.indexOf('None') < 0){
      for (let role of roles) {
        if(permissionsList[role]){
  
          // ignore the primary portal path
          let _exp = permissionsList[role].filter((_path)=>{
            return _path.length>1;
          });
          // omit the root url
          _exp = _exp.map((_path)=>{
            return _path.replace(omit,'');
          });
  
          // match exact path string
          _exp = _exp.toString().replace(new RegExp(',', 'g'),'|');
          let _matchThese = new RegExp('('+_exp+')','g');
          let _matched = path.match(_matchThese) || [];
  
          // push index of found matches
          permissions.push(_matched.length > 0);
        }
      }
      // if any matches found return true
      return permissions.indexOf(true) > -1;
    }
    return false;
  }

  hasSideNav(){
    let _approved = [];
    if(this.currentRoles){
      this.currentRoles.map((role,index)=>{
        if(this.sideNavRoles[role]){
          _approved.push(true);
        }
      })
    }
    return _approved.length>0;
  }

}
