import { APPEAL_WIZARD_PATHS } from '../appeal-wizard.constants';

const STEP = APPEAL_WIZARD_PATHS.APPEAL_START_APPEAL;
export const APPEAL_START_APPEAL={
    ASYA_TITLE:{
        label:"Borrower Name",
        storeKey:'title',
        required:true,
        step:STEP
    },
    ASYA_LENDER_NAME:{
        label:'Lender Name',
        storeKey:'lender',
        required:true,
        step:STEP
    }
}