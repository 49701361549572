import { Component, ElementRef, Inject } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { RESOURCES_PAGE_NAMES } from '../constants/resources.constants';
import { ResourcesService } from '../resources.service';

@Component(
    {
        selector : 'resources-page',
        template: require('./resources-page.component.html')
    }
)

export class ResourcesPage extends KillZombies(PageView){
    selectedResourceItem: string = "";
    selectedResourceData: string = "";
    videoLinks : any = ""
    faqList : any = ""
    resolvedData : any = null;
    selectedVideo : any = "";
    safeSelectedVideo: any = "";
    resourceLink :any = '';

    public userGuide:any = null;
    public additionalResources:any = null;

    constructor(
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        navigationService:NavigationService,
        public resourcesService: ResourcesService,
        public activatedRoute: ActivatedRoute,
        public sanitizer : DomSanitizer,
        private elementRef:ElementRef
    ){
        super(configuration, navigationService); 
        this.Name = RESOURCES_PAGE_NAMES.RESOURCES_PAGE;
        this.resolvedData = this.activatedRoute.snapshot.data.pageData.resourceTabData;
        this.userGuide = this.resolvedData[0].user_guide;
        this.videoLinks = this.sanitizer.bypassSecurityTrustHtml(this.resolvedData[0].home_video);
        this.additionalResources = this.resolvedData[0].home_additional_resources;
        this.faqList = this.activatedRoute.snapshot.data.pageData.faqList;

        //console.log("this.videoLinks " , this.videoLinks);
    }

    ngOnInit(){
        this.showResourceItem('User Guide');
    }

    showResourceItem(selectedTab:string){
        switch(selectedTab){
            case "User Guide":
                this.selectedResourceItem = 'User Guide';
                break;
            case "Videos" :
                this.selectedResourceItem  = 'Videos';
                break;
            case "FAQs" :
                this.selectedResourceItem  = 'Frequently Asked Questions';
                break;
            case "Additional Resources" :
                this.selectedResourceItem  = 'Additional Resources';
                break;
        }

        setTimeout(()=>{
            document.querySelectorAll(".resourceId").forEach(element => {
                    element.addEventListener('click', this.onUrlClick.bind(this))
                }
            )
        },100);
    }

    onUrlClick(event) {
        var resourceType = event.target.attributes.type.value;

        this.resourcesService.getResourceAsset(resourceType).subscribe((data)=>{
            var url = window.URL.createObjectURL(data);
            window.open(url, '_blank', '');
        },(err)=>{
            console.log(err);
          });
    }


    showFaq(id){

    }

    // showVideo(value){
    //     this.selectedVideo = this.videoLinks.filter(v => v.label === value)[0].url;
    //     this.safeSelectedVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedVideo);
    //     console.log(this.selectedVideo);
    // }
}
