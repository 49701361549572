import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap,take } from 'rxjs/operators';
import { FreezeNavigation, NavigationService, ThawNavigation } from '../../../platform/modules/navigation';
import { TriggerModal, TriggerToast } from '../../../platform/modules/notification';``
import { AuthService } from '../../../platform/modules/auth';
import { TicketsService } from '../tickets.service';
import { SaveAppealTicket, RequestAppealStatusChange, AssignUserToTicket, AssignUsersToTicket, UnAssignUserToTicket } from './ticket.actions';
import { TICKETS_ACTIONS } from '../constants/tickets.constants';
import { callbackify } from 'util';
import { FreezeView, ThawView } from '../../../shell';

@Injectable()
export class TicketEffects {
  // Listen for the 'LOGIN' action
  constructor(
    public actions$: Actions,
    public navigationService:NavigationService,
    public ticketService:TicketsService,
    public authService:AuthService,
    public ngrxstore:Store<any>) {
    }

    triggerErrorAsModal(err:any){
      if(err){
        this.ngrxstore.dispatch( new ThawNavigation() );
        this.ngrxstore.dispatch( new ThawView() );
        let formattedError = this.ticketService.interpretErrorsFromAPI(err);
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:formattedError.title,
            message:formattedError.message,
          },
          type:'message'
        }))
        throw err;
      }
    }

    triggerErrorAsToast(err:any, message:string = 'Error'){
      this.ngrxstore.dispatch( new ThawNavigation() );
      this.ngrxstore.dispatch( new ThawView() );
      this.ngrxstore.dispatch( new TriggerToast({
        message:message+': '+err.statusText,
        style:'danger',
        ttl:6
      }));
    }

    triggerSuccessAsToast(message:any){
      this.ngrxstore.dispatch( new ThawNavigation() );
      this.ngrxstore.dispatch( new ThawView() );
      this.ngrxstore.dispatch( new TriggerToast({
        message:message,
        style:'success',
        ttl:4
      }));
    }

    @Effect({dispatch:false})
    onRequestAppealStatusChange$: Observable<RequestAppealStatusChange> = this.actions$.pipe(
      ofType<RequestAppealStatusChange>(TICKETS_ACTIONS.REQUEST_APPEAL_STATUS_CHANGE),
      tap((action)=>{
          this.ngrxstore.dispatch( new FreezeNavigation() );
          this.ticketService.changeAppealState(
              action.payload.reference_id,{
                slug:action.payload.slug
              }
          ).pipe(take(1)).subscribe((appeal:TicketSummaryDetails)=>{
              this.ngrxstore.dispatch( new ThawNavigation() );
              // console.log(action)
              if(action.payload.callback){
                action.payload.callback(appeal);
              }
          });
        })      
    );

    @Effect({dispatch:false})
    onAssignUserToTicket$: Observable<AssignUserToTicket> = this.actions$.pipe(
      ofType<AssignUserToTicket>(TICKETS_ACTIONS.ASSIGN_USER_TO_TICKET),
      tap((action)=>{
        // console.log(action)
        this.ngrxstore.dispatch( new FreezeNavigation() );
        this.ticketService.updateApplicationAssignee(action.payload.user, action.payload.appealId).pipe(take(1)).subscribe((response)=>{
          //console.log(response);
          this.ngrxstore.dispatch( new ThawNavigation() );
          if(action.payload.callback){
            action.payload.callback(response);
          }
        });
      })
    )
    @Effect({dispatch:false})
    onUnAssignUserToTicket$: Observable<UnAssignUserToTicket> = this.actions$.pipe(
      ofType<UnAssignUserToTicket>(TICKETS_ACTIONS.UNASSIGN_USER_TO_TICKET),
      tap((action)=>{
        // console.log(action)
        this.ngrxstore.dispatch( new FreezeNavigation() );
        this.ticketService.removeApplicationAssignee(action.payload.user, action.payload.appealId).pipe(take(1)).subscribe((response)=>{
          //console.log(response);
          this.ngrxstore.dispatch( new ThawNavigation() );
          if(action.payload.callback){
            action.payload.callback(response);
          }
        });
      })
    )

    @Effect({dispatch:false})
    onAssignUsersToTicket$: Observable<AssignUsersToTicket> = this.actions$.pipe(
      ofType<AssignUsersToTicket>(TICKETS_ACTIONS.ASSIGN_USERS_TO_TICKET),
      tap((action)=>{
        // console.log(action)
        this.ngrxstore.dispatch( new FreezeNavigation() );
        let _numberOfUsers = action.payload.users.length;
        let _completedAssignments = 0; 
        action.payload.users.map((user)=>{
          this.ticketService.updateApplicationAssignee(user, action.payload.appealId).pipe(take(1)).subscribe((response)=>{
            _completedAssignments += 1;
            this.ngrxstore.dispatch( new ThawNavigation() );
            if(action.payload.callback && _completedAssignments === _numberOfUsers){
              action.payload.callback(response);
            }
          });
        })

      })
    )


    @Effect({dispatch:false})
    onSaveAppealTicket$: Observable<SaveAppealTicket> = this.actions$.pipe(
      ofType<SaveAppealTicket>(TICKETS_ACTIONS.SAVE_APPEAL_TICKET),
      tap((action) =>{
        //console.log(action)
        this.ngrxstore.dispatch( new FreezeNavigation() );
        this.ngrxstore.dispatch( new FreezeView() );
        let _payloadValues = {};        
        Object.keys(action.payload).map((controlKey)=>{
          if(action.payload[controlKey].value === undefined){
            _payloadValues[controlKey] = action.payload[controlKey];
          }else{
            _payloadValues[controlKey] = action.payload[controlKey].value;
          }
        });
        if(action.request_type === 'patch'){
          this.ticketService.patchApplication(_payloadValues, action.id).pipe(take(1)).subscribe((response)=>{
            this.ngrxstore.dispatch( new ThawNavigation() );
            this.ngrxstore.dispatch( new ThawView() );
            if(action.callback){
              action.callback(response);
            }
          },(err)=>{
            if(err) this.triggerErrorAsModal(err);
          });

        }else{
          this.ticketService.updateApplication(_payloadValues, action.id).pipe(take(1)).subscribe((response)=>{
            this.ngrxstore.dispatch( new ThawNavigation() );
            this.ngrxstore.dispatch( new ThawView() );
            if(action.callback){
              action.callback(response);
            }
          },(err)=>{
            if(err) this.triggerErrorAsModal(err);
          });
        }
        
      })
    );

}
