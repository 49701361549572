import { Action, createFeatureSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { NOTIFICATION_ACTION_CONSTANTS } from '../constants/notification.constants';
import { MODAL_TYPES, MODAL_THEMES } from '../constants/modal.constants';
import { TOAST_TYPES, TOAST_THEMES, TOAST_DEFAULT_TIMEOUT } from '../constants/toast.constants';
import { FileItem } from '@wkoza/ngx-upload';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

let _currentLocalNotificationState = {};
if(localStorage.getItem('notification')){
  _currentLocalNotificationState = JSON.parse(localStorage.getItem('ui'))
}

export interface NotificationState{
  'uploaded_file':FileItem,
  'text':string,
  'signature':string
}

const initialUiState:NotificationState = {
  'uploaded_file':null,
  'text': null,
  'signature': ''
}

export function NotificationReducer(state:NotificationState = initialUiState, action: ActionWithPayload<any>):NotificationState{

    let _value = null;

    switch (action.type) {

        case NOTIFICATION_ACTION_CONSTANTS.TRIGGER_MODAL:
          _value = Object.assign({},state,{});
          return _value;

        case NOTIFICATION_ACTION_CONSTANTS.REMOVE_MODAL:
          _value = Object.assign({},state,{});
          return _value;

        case NOTIFICATION_ACTION_CONSTANTS.TRIGGER_TOAST:
          _value = Object.assign({},state,{});
          return _value;

        case NOTIFICATION_ACTION_CONSTANTS.REMOVE_TOAST:
          _value = Object.assign({},state,{});
          return _value;

        case NOTIFICATION_ACTION_CONSTANTS.FILE_PETITION:
          _value = Object.assign({},state,{
            'uploaded_file':action.payload.file,
            'text':action.payload.text,
            'signature':action.payload.signature
          });
          return _value;

        default:
          return state;
    }
}

export const getNotificationState = createFeatureSelector<NotificationState>('notifications');
