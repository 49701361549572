import { Action, createFeatureSelector } from '@ngrx/store';
import { APPEALS_APPLICATIONS_ACTIONS } from '../../constants/appeals-applications.constants';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export interface AppealApplicationState{
        'filter_values':any
}

export const initialAppealApplicationState:AppealApplicationState = {
    'filter_values':{}
}
  
export function AppealApplicationReducer(state:AppealApplicationState = initialAppealApplicationState, action: ActionWithPayload<any>):AppealApplicationState{

    let _value = null;

    switch (action.type) {
        case APPEALS_APPLICATIONS_ACTIONS.CURRENT_FILTER_VALUES:
          _value = Object.assign({},state,{
            'filter_values':action.payload
          })
          return _value;

        case APPEALS_APPLICATIONS_ACTIONS.CLEAR_FILTER_VALUE:
          _value = Object.assign({},state,{
            filter_values:{},
          });
          return _value;

        default:
          return state;
    }
}
  
  export const getAppealApplicationState = createFeatureSelector<AppealApplicationState>('appeal_applications');