import { Component, Inject, OnInit } from '@angular/core';
import { PORTAL_PATHS } from '../constants/portal.constants';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { StartLinkLoanAction } from '../../../../features/forgiveness-applications/store/actions/forgiveness-applications.actions';
import { Store } from '@ngrx/store';
import { RequestAmbientSignInAction } from '../store/auth.actions';
import { AuthConfiguration, AuthService, AUTH_CONFIG, RegisterUser, RegisterPhoneWithTwoFactor, AUTH_PATHS, LoginWithInviteToken, getSessionState, LoginWithAppealID, DirectLoginWithAppealID, ListPageLimit  } from '../../../platform/modules/auth';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView } from '../../../platform/modules/navigation';
import { MatchValidator } from '../../../platform/utilities/validators/match.validator';
import { ValidEmailValidator } from '../../../platform/utilities/validators/valid-email.validator';
import { StrongPasswordValidator } from '../../../platform/utilities/validators/strong-password.validator';
import { take } from 'rxjs/operators';

@Component({
  selector: 'user-portal',
  template:require('./user-portal.component.html')
})
export class UserPortal extends KillZombies(PageView){

  public title:string = '';
  public body:string = '';
  public validEmail:boolean = false;
  public landingAction:string = 'cta';

  public authOrigin = null;
  public authRedirect = null;
  
  public showPassword:boolean = false;
  public roleOptions = [];

  public registerForm:FormGroup = new FormGroup({});
  public registerFormValues:any = {};
  public inviteUserData:any ={};

  public twoFactorAuthenticationForm:FormGroup = new FormGroup({});
  public twoFactorAuthenticationFormValues:any = {};

  public hideAccessGateway:boolean = false;
  public hideRegistration: boolean = false;
  public isRegistartionDisabled:boolean = false;
  public isRoleEmpty = false;
  public signinStatus =  false;
  public currentSessionDetails = {};
  
  public loginUrl = AUTH_PATHS.LOGIN;

  public default_results_per_page = 30;
  public homeBannerText: string = '';
  public showBanner: boolean = true;
  
	constructor(
    public formBuilder:FormBuilder,
    public authService:AuthService,
    @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
    @Inject(NAVIGATION_CONFIG) protected navigationConfig:NavigationConfig,
    public navigationService:NavigationService,
    public ngrxstore:Store<any>,
    public activatedRoute:ActivatedRoute
  ){

    super(navigationConfig,navigationService);

    this.inviteUserData = (this.activatedRoute.snapshot.data.inviteDetails) ?
     this.activatedRoute.snapshot.data.inviteDetails.invite : {token :null};
    if(this.inviteUserData && this.inviteUserData.token !== null){
      this.landingAction= 'register_user';
    } 
    this.roleOptions = Object.keys(this.authConfig.roles).map((key)=>{
      return this.authConfig.roles[key];
    }).filter((option)=>{
      return (
        option.slug === this.authConfig.roles.APPELLANT.slug || 
        option.slug === this.authConfig.roles.ATTORNEY.slug
      );
    });

    this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
      this.currentSessionDetails = session;
      this.signinStatus = session.status === 'signedIn' ? true : false;
    });


    if(this.inviteUserData.token === null){
      this.isRegistartionDisabled = (this.activatedRoute.snapshot.data.userPortal) ? 
      this.activatedRoute.snapshot.data.userPortal.settings['disable_borrower_registration']:this.isRegistartionDisabled ;

      this.default_results_per_page = this.activatedRoute.snapshot.data.userPortal ? 
      this.activatedRoute.snapshot.data.userPortal.settings['default_results_per_page'] : 30;
      this.homeBannerText = this.activatedRoute.snapshot.data.userPortal.settings['banner_information_text'];
    }  else{

      this.default_results_per_page = this.activatedRoute.snapshot.data.userPortal ? 
      this.activatedRoute.snapshot.data.inviteDetails.settings['default_results_per_page']:30 ;
      this.homeBannerText = this.activatedRoute.snapshot.data.inviteDetails.settings['banner_information_text'];
    }

    this.ngrxstore.dispatch(new ListPageLimit( this.default_results_per_page));

    
    this.showBanner = this.homeBannerText !== '';

    this.registerForm = this.formBuilder.group({
      first_name:[(this.inviteUserData.token != null ? this.inviteUserData.first_name : ''),[
        Validators.required
      ]],
      last_name:[(this.inviteUserData.token != null ? this.inviteUserData.last_name : ''),[
        Validators.required
      ]],
      
      yourRole:[(this.inviteUserData.token != null ? this.inviteUserData.group.name.toLowerCase() : '')],
      email:[(this.inviteUserData.token != null ? this.inviteUserData.email : ''),[
        Validators.required,
        Validators.email,
        ValidEmailValidator()
      ]],
      password:['',[
        Validators.required
      ]],
      confirm_password:['',[
        Validators.required
      ]],
    },{ 
      validator: MatchValidator('password', 'confirm_password')
    });

    this.twoFactorAuthenticationForm = this.formBuilder.group({
      phone:['',Validators.required]
    });
    
    let _route = "/"+this.activatedRoute.snapshot.routeConfig.path;
    let _urlToken = (this.activatedRoute.snapshot.url[1]) ? this.activatedRoute.snapshot.url[1].path : '' ;
    let emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    this.authOrigin = (this.authService.Origins.user) ? this.authService.Origins.user : '' ;
    this.authRedirect = (this.authService.Redirects.user) ? this.authService.Redirects.user : null ;

    if(this.signinStatus && _route == this.Routes['LOGIN_WITH_APPEAL_ID']){
      this.directloginWithAppealID(this.currentSessionDetails);
    }else{
      switch(_route){

        case this.Routes['FORGOT_PASSWORD']:
        this.title = 'I forgot my password..';
        this.body = `
            No Problem, please give us your email
            address, you'll get an email that
            will foward you onward!`;
        break;
  
        case this.Routes['RESET_PASSWORD']:
        this.title = 'Reset Password';
        this.body = `
            To reset your password simply enter in a new password and confirm.`;
        break;
  
        case this.Routes['TWO_FA_PROVIDE']:
        this.title = 'Welcome Back!';
        this.body = `
            Please use your authenticator app and provide the proper code to finish logging in.`
        break;
  
        case this.Routes['INVITE_USER_WITH_TOKEN']:
        this.hideAccessGateway = true;
        break;
  
        case this.Routes['LOGIN_WITH_APPEAL_ID']:
          this.hideRegistration = true;
          this.title = 'Pick up where you left off';
          this.body = `
              Login here to view your appeal.`
        break;
  
        default:
          this.title = 'Pick up where you left off';
          this.body = `If you've already submitted an appeal, you can login here.`;
        break;
      }
    }
  }

  directloginWithAppealID(session){
    this.ngrxstore.dispatch(new DirectLoginWithAppealID({
      appealId:this.activatedRoute.snapshot.paramMap.get('id'),
      name:session['user'],
      role:session['roles'],
      id:session['user_id'],
      access:session['token'],
      refresh:session['refresh_token'],
      origin:'"/"+authOrigin'
    }))
  }

  onChangeRole($event:any){
    if(this.registerForm.controls.yourRole.value != '' ||  this.registerForm.controls.yourRole.value != null){
        this.isRoleEmpty = false;
    }
  }

  register($event:any){
    let _inviteToken = this.inviteUserData.token != null ? this.inviteUserData.token : this.activatedRoute.snapshot.paramMap.get('id') ;

    if(this.registerForm.controls.yourRole.value == '' ||  this.registerForm.controls.yourRole.value == null){
      this.isRoleEmpty = true;
    }else{
      this.isRoleEmpty = false;
      this.ngrxstore.dispatch(new RegisterUser({
        name:this.registerForm.controls.first_name.value + " " + this.registerForm.controls.last_name.value,
        first_name: this.registerForm.controls.first_name.value,
        last_name: this.registerForm.controls.last_name.value,
        role:this.registerForm.controls.yourRole.value,
        password:this.registerForm.controls.password.value,
        confirm_password:this.registerForm.controls.confirm_password.value,
        email:this.registerForm.controls.email.value,
        redirect:this.authRedirect,
        origin:this.Routes['USER_PORTAL'],
        inviteToken:_inviteToken
      }))
    }
    
  }

  switchLandingAction(action:string){

    this.landingAction = action;
  }

  togglePasswordVisibility(){
    this.showPassword = !this.showPassword;
  }

  goToLogin(){
    this.ngrxstore.dispatch(new LoginWithInviteToken({
      email:this.inviteUserData.token != null ? this.inviteUserData.email : '',
      redirect:this.authRedirect,
      origin:this.Routes['USER_PORTAL'],
      inviteToken:this.inviteUserData.token != null ? this.inviteUserData.token : ''
    }))
  }
}
