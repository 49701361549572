import { Directive, EventEmitter, Input, Output, ElementRef, HostBinding, Renderer2, Host } from '@angular/core';

@Directive({
  selector:'[collapsable]'
})
export class Collapsable{

  @Output() onToggle:EventEmitter<boolean> = new EventEmitter();
  @Input() set open(val:boolean){
    this.isOpen = val;
    this.toggle();
  }
  public isOpen:boolean = false;

  @HostBinding('class.collapsable') public baseClass = true;
  @HostBinding('class.collapsed') get Open(){ 
    return !this.isOpen; 
  }

  constructor(public el: ElementRef, public renderer:Renderer2){
  }

  toggle(){
    let _scrollHeight = this.el.nativeElement.scrollHeight;
    this.renderer.setStyle(
      this.el.nativeElement,
      'max-height',
      (this.isOpen) ? _scrollHeight+'px' : '0px' 
    );
    this.onToggle.emit(this.isOpen);
  }

}
