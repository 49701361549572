import {Injectable,Inject} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { take, map} from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../app.interface';
import { APPEAL_ACTION_TYPES } from '../../../constants/app.constants';
import { AuthService } from '../../../platform/modules/auth';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { getPPPAppealWizardState } from '../store/appeal-wizard.reducer';
import { selectPPPAppealWizardStatus } from '../store/appeal-wizard.selectors';

@Injectable({
  providedIn:'root'
})
export class PPPAppealWizardResolver implements Resolve<any> {

  constructor(
    @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
    @Inject(Store) public store:Store<any>,
    @Inject(AuthService) public authService:AuthService,
    @Inject(PPPAppealWizardService) public appealWizardService:PPPAppealWizardService
  ){}

  resolve(route:ActivatedRouteSnapshot){
    
    let appealId$ = new Observable((obs)=>{
      obs.next({action:APPEAL_ACTION_TYPES.DRAFT});
      obs.complete();
    });

    let appealDocs$ = new Observable((obs)=>{
      obs.next([]);
      obs.complete();
    });

    // console.log(route.paramMap.get('id'))
    if( route.paramMap.get('id') !== this.appDefaults.ui.NEW_APPEAL_SLUG ){
      appealId$ = this.appealWizardService.getAppealApplication(route.paramMap.get('id')),
      appealDocs$ = this.appealWizardService.getUploadedDocuments(route.paramMap.get('id'))
    }

    return zip(
      appealId$,
      this.authService.getCurrentUser(),
      appealDocs$
    ).pipe(
      map(([
        $appealData,$user, $appealDocs
      ])=>({
        data:$appealData,
        user:$user,
        appealDocs:$appealDocs
      })),
      take(1)
    );
  }

}
