import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { mergeMap, every, skipWhile } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { MODAL_TYPES, MODAL_THEMES, MODAL_ICONS, MODAL_CONFIGURATIONS } from '../constants/modal.constants';
import { NOTIFICATION_ANIMATIONS } from '../constants/animations.constants';
import { Store } from '@ngrx/store';
import { FilePetition, TriggerToast } from '../store/notification.actions';

export interface ModalData {
  type: string;
  name: string;
  description: string;
}

@Component({
  selector: 'bulma-modal',
  template: require('./modal.component.html'),
  animations: NOTIFICATION_ANIMATIONS
})
export class ModalComponent {

  @ViewChild('info',{static:true}) info;

  public formatOfModal:string = '';
  public typeOfModal:string = null;
  public showModal:boolean = false;
  public modalIsActive:boolean = false;

  public hasApproveAction:boolean = false;
  public hasRejectAction:boolean = false;
  public hasCancelAction:boolean = false;

  public approveLabel:String = "Yes";
  public rejectLabel:String = "No";
  public cancelLabel:String = "Cancel";

  public MODAL_TYPES = MODAL_TYPES;
  public MODAL_ICONS = MODAL_ICONS;
  public MODAL_THEMES = MODAL_THEMES;
  public MODAL_CONFIGURATIONS = MODAL_CONFIGURATIONS;

  public confirmationValue:string = null;
  public confirmationValueExpectedValue:string = null;
  public confirmationValueValid:boolean = false;
  public confirmationInputTouched:boolean = false;
  public petition_text:string = null;
  public validDoc:boolean = false;
  public isDocUploaded:boolean = false;
  public digital_signature:string = "";
  public uploaded_doc = null;
  public is_modal_from_ogc = false;

  constructor(
    public notificationService:NotificationService,
    public ngrxstore:Store,
  ){
    this.notificationService.$OnRequestModal
      .pipe(skipWhile(val=>!val))
      .subscribe(([type,actionsDefined,modelValue])=>{

        this.hasApproveAction = actionsDefined.approve !== null;
        this.hasRejectAction = actionsDefined.reject !== null;
        this.hasCancelAction = actionsDefined.cancel !== null;

        if(type !== 'confirmation-input'){
          if(type !== 'petition-input'){
            if(actionsDefined.approve && !actionsDefined.reject){
              this.formatOfModal = 'binary'
            }else if(actionsDefined.approve && actionsDefined.reject){
              this.formatOfModal = 'action'
            }else{
              this.formatOfModal = 'message'
            }
          }else{
            this.formatOfModal = 'petition-input'
          }
        }else{
          this.formatOfModal = 'confirmation-input'
        }

        this.confirmationValue = null;
        this.confirmationValueExpectedValue = modelValue;

        this.typeOfModal = type;
        this.modalIsActive = true;
        this.is_modal_from_ogc = this.notificationService.ModalContent.is_ogc;
      })

      
    this.notificationService.$OnDismissModal
      .subscribe(()=>{
        this.typeOfModal = null;
        this.modalIsActive = false;
        this.confirmationValueExpectedValue = null;
        this.confirmationInputTouched = false;
        this.confirmationValueValid = false;
        this.petition_text = null;
        this.digital_signature = '';
        this.isDocUploaded = false;
        this.validDoc = false;
        this.uploaded_doc = null;
    })
  }

  onModelChange($event){
    this.confirmationInputTouched = true;
    this.confirmationValueValid = ($event === this.confirmationValueExpectedValue);
  }

  onFileSelected(event){
    // this.digital_signature = '';
    this.isDocUploaded = false;
    this.validDoc = false;
    this.uploaded_doc = null;
    if(event.target.files.length > 0){
      this.uploaded_doc = event.target.files[0];
      if(this.uploaded_doc.size/(1024*1024) > 100){
        this.validDoc = false;
      }else{
        this.ngrxstore.dispatch(new FilePetition({
          file: this.uploaded_doc,
          signature:this.digital_signature
        }))
        this.validDoc = true;
      }
      this.isDocUploaded = event.target.files.length > 0;
    }
  }

  onSignatureChange(val){
    this.ngrxstore.dispatch(new FilePetition({
      file: this.uploaded_doc,
      signature:this.digital_signature
    }))
  }

  isPetitionInputValid() {
    if (this.is_modal_from_ogc) {
      if(this.isDocUploaded && this.validDoc ) return false;
        else return true;
    } else {
      if(this.isDocUploaded && this.validDoc && this.digital_signature !== '' ) return false;
      else return true;
    }
  }

}
