import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResourcesPageResolver } from './resolvers/resources-page.resolver';
import { ResourcesPage } from './resources-page/resources-page.component';
import { resourcesRoutes, ResourcesRouting } from './resources.routes';
import { ResourcesService } from './resources.service';


@NgModule({
    imports:[
      CommonModule,
      ResourcesRouting,
      NgbModule
    ],
    declarations:[
      ResourcesPage
    ],
    providers:[
      ResourcesService,
      ResourcesPageResolver
    ],
    exports:[
        RouterModule
    ]
  })

  export class ResourcesModule {}
  