import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { AccountProfile } from './profile/profile.component';
import { AccountRouting } from './account.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShellModule } from '../../shell';
import { UserList } from './user-list/user-list.component';
import { AccountService } from './account.service';
import { SharedModule } from '../../platform/modules/shared';
import { InboxMessage } from './messages/message/inbox-message.component';
import { Inbox } from './messages/inbox/inbox.component';
import { InboxResolver } from './resolvers/inbox.resolver';
import { UsersResolver } from './resolvers/users.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserResolver } from './resolvers/user.resolver';
import { ProfileResolver } from './resolvers/profile.resolver';
import { CreateNewUser } from './create-new/create-new.component';
import { GroupResolver } from './resolvers/group.resolver';

@NgModule({
  imports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShellModule,
    AccountRouting,
    SharedModule,
    NgxDatatableModule
  ],
  declarations:[
    AccountProfile,
    UserList,
    CreateNewUser,
    InboxMessage,
    Inbox
  ],
  providers:[
    AccountService,
    InboxResolver,
    UsersResolver,
    UserResolver,
    ProfileResolver,
    GroupResolver
  ],
  exports:[RouterModule]
})

export class AccountModule {}
