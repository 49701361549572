import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap ,  catchError, map, mergeMap } from 'rxjs/operators';

import * as pageActions from '../../shell/store/shell.actions';
import { SHELL_ACTIONS } from '../../shell';
import { FreezeNavigation, NavigationService, ThawNavigation } from '../../platform/modules/navigation';
import { AfterSignedCertificateOfService, SignCertificateOfService } from '../../features/appeal-wizard/store/appeal-wizard.actions';
import { APPEAL_WIZARD_ACTIONS } from '../../features/appeal-wizard/constants/appeal-wizard.constants';

@Injectable()
export class ShellEffects {
  // Listen for the 'LOGIN' action
  constructor(
    public actions$: Actions,
    public navigationService:NavigationService,
    public ngrxstore:Store<any>) {
    }

  @Effect({dispatch:false})
  onActionPanelClose$: Observable<pageActions.CloseActionPanel> = this.actions$.pipe(
    ofType<pageActions.CloseActionPanel>(SHELL_ACTIONS.TOGGLE_ACTION_PANEL_SHELL),
    tap((action) =>{
      //console.log(action)
      this.navigationService.navigateToLastRoute();
    })
  );
}
