import { Component, EventEmitter, Inject, Input, Output, OnInit, forwardRef, ViewChild, ElementRef, Renderer2, ChangeDetectorRef  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { SmartyStreetsService } from './ss-input.service';

export const SSINPUT_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SmartyStreetsInput),
  multi: true
};

@Component({
    selector:'smarty-streets-input',
    template:require('./ss-input.component.html'),
    providers:[SSINPUT_ACCESSOR],
    host: {
      '(document:click)': 'cancelDropDown($event)',
    }
})
export class SmartyStreetsInput implements ControlValueAccessor {
    @ViewChild('ssInput',{static:true}) input:ElementRef;
    
    addressOptions = <any>[];
    address: string;
    showLookUp: boolean = false;

    @Output() onFinal: EventEmitter<any> = new EventEmitter();
    @Output() onAddressLineInput: EventEmitter<any> = new EventEmitter();
    @Output() onClear: EventEmitter<any> = new EventEmitter();

    @Input() set value(value){
      this.address= value;
    }
  
    constructor(
      private smartyService: SmartyStreetsService,
      public renderer:Renderer2,
      public changeDetector:ChangeDetectorRef,
    ) { 
      
    }
  
    ngOnInit() {}

    changeCallback=(data:any)=>{};
    touchCallback=(data:any)=>{
      console.log("data", data.target.value);
      this.onAddressLineInput.emit( data.target.value);

    };

    writeValue(value: any){
        this.renderer.setProperty(this.input.nativeElement,'value',value);
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }  

    onChange(term: any) {
      if(term.length > 0){
        this.smartyService.AutocompleteAddress(term, null).pipe(take(1)).subscribe((response)=>{
          this.addressOptions = response.suggestions;
          this.showLookUp = true;
          this.changeDetector.detectChanges();
        });
      }else{
        this.onClear.emit(term);
      }
        
    }

    cancelDropDown(){
      this.showLookUp = false;
    }

    onSelection(index: any){
      let _selectedAddress= this.addressOptions[index];   
      let selectedTerm: string= _selectedAddress.street_line + " " + 
                                _selectedAddress.secondary + " (" + 
                                _selectedAddress.entries + ") " + 
                                _selectedAddress.city + " " +
                                _selectedAddress.state+ " " +
                                _selectedAddress.zipcode;
      
     if(_selectedAddress.entries > 1){
        this.smartyService.AutocompleteAddress(_selectedAddress.street_line, selectedTerm).pipe(take(1)).subscribe((response)=>{
          this.addressOptions = response.suggestions;
          this.showLookUp = true;
          this.changeDetector.detectChanges();
        });
      }else{
        this.smartyService.ValidateAddress(_selectedAddress).pipe(take(1)).subscribe((response)=>{
          _selectedAddress= response;
          this.showLookUp= false;
          this.address= _selectedAddress[0].delivery_line_1;
          this.onFinal.emit(_selectedAddress);
        });
      }
    }
  
  }