import { Action, createFeatureSelector } from '@ngrx/store';
import { APPEAL_WIZARD_ACTIONS, APPEAL_WIZARD_ROOT_PATH } from '../constants/appeal-wizard.constants';
import { setgroups } from 'process';
import { Actions } from '@ngrx/effects';
import { values } from 'lodash';
import { ActivationEnd } from '@angular/router';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

let _currentLocalPPPAppealWizardState:PPPAppealWizardState;
if(localStorage.getItem('appeal_wizard')){
 _currentLocalPPPAppealWizardState = JSON.parse(localStorage.getItem('appeal_wizard'))
}else{
  _currentLocalPPPAppealWizardState = {
    fw_current_step:null,
    fw_previous_step:null,
    fw_next_step:null,
    fw_next_step_name:null,
    fw_previous_step_name:null,
    fw_current_loan_id:null,
    fw_last_submitted:null,
    fw_steps:[],
    fw_step_names:[],
    fw_status:'New',
    fw_banker:null,
    fw_values:{},
    fw_unsaved_values:[],
    fw_complete:false,
    reported_error:null,
    submittedAppeal:false,
    is_navigated_from_summary:false
  }
}

export interface PPPAppealWizardState{
    fw_current_step:string;
    fw_previous_step:string;
    fw_next_step:string;
    fw_next_step_name:null,
    fw_previous_step_name:null,
    fw_current_loan_id:string,
    fw_last_submitted:string;
    fw_steps:any[];
    fw_step_names:any[];
    fw_status:string;
    fw_banker:string;
    fw_values:any;
    fw_unsaved_values:string[];
    fw_complete:boolean;
    reported_error:any;
    submittedAppeal:boolean;
    is_navigated_from_summary:boolean;
}

export const initialState:PPPAppealWizardState = {
    fw_current_step:_currentLocalPPPAppealWizardState.fw_current_step || null,
    fw_previous_step: _currentLocalPPPAppealWizardState.fw_previous_step || null,
    fw_next_step: _currentLocalPPPAppealWizardState.fw_next_step || null,
    fw_next_step_name:_currentLocalPPPAppealWizardState.fw_next_step_name || null,
    fw_previous_step_name:_currentLocalPPPAppealWizardState.fw_next_step_name || null,
    fw_current_loan_id: _currentLocalPPPAppealWizardState.fw_current_loan_id || null,
    fw_last_submitted: _currentLocalPPPAppealWizardState.fw_last_submitted || null,
    fw_step_names:_currentLocalPPPAppealWizardState.fw_step_names || null,
    fw_steps: _currentLocalPPPAppealWizardState.fw_steps || [],
    fw_status:_currentLocalPPPAppealWizardState.fw_status || 'New',
    fw_banker:_currentLocalPPPAppealWizardState.fw_banker || null,
    fw_values: _currentLocalPPPAppealWizardState.fw_values || [],
    fw_unsaved_values:_currentLocalPPPAppealWizardState.fw_unsaved_values || [],
    fw_complete: _currentLocalPPPAppealWizardState.fw_complete || false,
    reported_error: _currentLocalPPPAppealWizardState.reported_error || null,
    submittedAppeal: _currentLocalPPPAppealWizardState.submittedAppeal || false,
    is_navigated_from_summary : _currentLocalPPPAppealWizardState.is_navigated_from_summary || false
}


export function PPPAppealWizardReducer(state:PPPAppealWizardState = initialState, action: ActionWithPayload<any>):PPPAppealWizardState{

    let _value = null;
    let _currentStepIndex = null;
    let _values = null;

    switch (action.type) {

        case APPEAL_WIZARD_ACTIONS.SETUP_STEPS:
            
            _value = Object.assign({},state,{
                fw_steps:action.payload.steps,
                fw_step_names:action.payload.stepNames
            });

            _currentStepIndex = _value.fw_steps.indexOf(action.payload.startOn); 

            _value = Object.assign({},_value,{
                fw_current_step:_value.fw_steps[_currentStepIndex],
            });
          
            _value = Object.assign({},_value,{
                fw_previous_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)+1],
                fw_previous_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)+1],
                fw_current_loan_id:action.payload.loan_id
            });

            _value = Object.assign({},_value,{
                fw_values:Object.assign({},state.fw_values,{
                    reference_id:action.payload.reference_id
                })
            })

            return _value;

        case APPEAL_WIZARD_ACTIONS.COMPLETE_STEP:

            _currentStepIndex = state.fw_steps.indexOf(state.fw_current_step); 
            
            _value = Object.assign({},state,{
                fw_current_step:(_currentStepIndex < state.fw_steps.length-1) ? state.fw_steps[_currentStepIndex+1] : state.fw_steps[_currentStepIndex]
            });

            _value = Object.assign({},_value,{
                fw_previous_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)+1],
                fw_previous_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)+1]
            });

            return _value;

        case APPEAL_WIZARD_ACTIONS.PREVIOUS_STEP:

            _currentStepIndex = state.fw_steps.indexOf(state.fw_current_step); 

            _value = Object.assign({},state,{
                fw_current_step:(_currentStepIndex > 0) ? state.fw_steps[_currentStepIndex-1] : state.fw_steps[_currentStepIndex]
            });
          
            _value = Object.assign({},_value,{
                fw_previous_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)+1],
                fw_previous_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)+1]
            });

            return _value;

        case APPEAL_WIZARD_ACTIONS.JUMP_TO_STEP:

            _currentStepIndex = state.fw_steps.indexOf(action.payload); 

            _value = Object.assign({},state,{
                fw_current_step:state.fw_steps[_currentStepIndex]
            });
            
            _value = Object.assign({},_value,{
                fw_previous_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step:_value.fw_steps[_value.fw_steps.indexOf(_value.fw_current_step)+1],
                fw_previous_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)-1],
                fw_next_step_name:_value.fw_step_names[_value.fw_steps.indexOf(_value.fw_current_step)+1]
            });

            return _value;

        case APPEAL_WIZARD_ACTIONS.RECORD_UNSAVED_VALUE_KEY:
            _value = Object.assign({},state,{
                fw_unsaved_values:[... action.payload].reduce((others,value)=>{
                    return others.includes(value) ? others : [...others, value];
                },[])
            });
            return _value;

        case APPEAL_WIZARD_ACTIONS.REPORT_WIZARD_ERROR:
            _value = Object.assign({},state,{
                reported_error:action.payload
            });
            return _value;

        case APPEAL_WIZARD_ACTIONS.STORE_VALUES:
            _value = Object.assign({},state,{
                fw_values:Object.assign({},state.fw_values,action.payload)
            });
            return _value;

        case APPEAL_WIZARD_ACTIONS.START_NEW_WIZARD:
            _value = Object.assign({},state,{
                fw_last_submitted:null,
                fw_complete:false
            });
            return _value;

        case APPEAL_WIZARD_ACTIONS.WIPE_STORE_VALUES:
            _value = Object.assign({},state,{
                fw_values:{},
                submittedAppeal:false
            });
            return _value;

        case APPEAL_WIZARD_ACTIONS.APPEAL_WIZARD_COMPLETE:
            _value = Object.assign({},state,{
                fw_complete:true
            })
            return _value;
        case APPEAL_WIZARD_ACTIONS.SUBMIT_APPEAL_SUCCESS:
            _value = Object.assign({},state,{
                submittedAppeal:true
            })
            return _value;
        
        case APPEAL_WIZARD_ACTIONS.IS_NAVIGATED_FROM_SUMMARY:
            _value = Object.assign({},state,{
                is_navigated_from_summary:action.payload
            });

            return _value;

        default:
          return state;
    }
}

export const getPPPAppealWizardState = createFeatureSelector<PPPAppealWizardState>('appeal_wizard');
