export const TICKETS_ACTIONS = {
    SAVE_APPEAL_TICKET:'SAVE_APPEAL_TICKET',
    REQUEST_APPEAL_STATUS_CHANGE:'REQUEST_APPEAL_STATUS_CHANGE',
    ASSIGN_USER_TO_TICKET:'ASSIGN_USER_TO_TICKET',
    UNASSIGN_USER_TO_TICKET:'UNASSIGN_USER_TO_TICKET',
    ASSIGN_USERS_TO_TICKET:'ASSIGN_USERS_TO_TICKET'
}

export const TICKETS_PATHS = {
    APPEALS_TICKET:"appeal-ticket"
}

export const TICKETS_ROOT_PATH = 'tickets';

export const TICKETS_PAGE_NAMES = {
    APPEALS_TICKET:"Appeal Review"
}

export const APPEAL_REASONS = {
    PPP_INELIGIBLE: {
        slug :  "is_filed_for_ppp_ineligible",
        name : "Ineligible for PPP Loan"
    },
    AMOUNT_INELIGIBLE: {
        slug :  "is_filed_for_ineligible_ppp_amount",
        name : "Ineligible for Amount of PPP Loan received"
    },
    UNAUTHORIZED_USE: {
        slug :  "is_filed_for_unauthorized_use",
        name : "Used PPP Loan proceeds for unauthorized purpose"
    },
    PARTIAL_APPROVAL: {
        slug :  "is_filed_for_lender_partial_approval",
        name : "Ineligible for PPP loan forgiveness in the amount determined by the lender in its full approval or partial approval decision issued to SBA"
    },  
    FULL_DENIAL: {
        slug :  "is_filed_for_lender_issued_full_denial",
        name : "Is ineligible for PPP loan forgiveness in any amount when the lender has issued a full denial decision to SBA"
    },
}