import {Component, Inject} from '@angular/core';
import { Store } from '@ngrx/store'
import { selectUrlState } from '../store/navigation.selectors';
import { NavigationService } from '../navigation.service';
import { NavigationConfig, NAVIGATION_CONFIG } from '../navigation.interface';


@Component({
  selector: 'footer-component',
  template:require('./footer.component.html')
})
export class FooterComponent{

    public hasSideNav:boolean = false;

    constructor(
        @Inject(NAVIGATION_CONFIG) public config:NavigationConfig, 
        public ngrxstore:Store<any>,
        public navigationService:NavigationService
    ){
        let windowState = this.ngrxstore.select(selectUrlState).subscribe((state)=>{
            this.hasSideNav = this.navigationService.hasSideNav()
        })  
    }

}
