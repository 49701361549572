import { NgModule, ModuleWithProviders } from '@angular/core';
import { NavigationModule } from '../platform/modules/navigation';
import { SharedModule } from '../platform/modules/shared';
import { CommonModule } from '@angular/common';
import { ShellComponent } from './components/shell/shell.component';
import { AuthModule } from '../platform/modules/auth';
import { RouterModule } from '@angular/router';

@NgModule({
    imports:[
        CommonModule,
        RouterModule,
        AuthModule,
        NavigationModule,
        SharedModule,
    ],
    declarations:[
        ShellComponent,
    ],
    providers:[
    ],
})
export class ShellModule{
    static forRoot(): ModuleWithProviders<ShellModule> {
        return {
          ngModule: ShellModule,
          providers: []
        };
      }
}