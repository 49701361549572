import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { NavigatedFromSummary, StoreAppealValues } from '../../store/appeal-wizard.actions';
import { WizardStep } from '../wizard-step.component';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { selectNavigationFlag, selectPPPAppealWizardValue, selectPPPAppealWizardValues } from '../../store/appeal-wizard.selectors';
import { MOCK_INDUSTRY, MOCK_STATES } from '../../../../constants/mock.data';
import { InputCard } from '../../../../platform/modules/shared/components/input-card/input-card.component';
import { TriggerModal, TriggerToast } from '../../../../platform/modules/notification';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../../app.interface';

@Component({
    changeDetection:ChangeDetectionStrategy.OnPush,
    selector:'start-your-appeal',
    template:require('./start-your-appeal.component.html'),
})
export class StartYourAppeal extends WizardStep implements OnInit{

    public lenderLabelModel:string = null;
    public validLender = false;
    public isNavigatedFromSummary:boolean = false;

    

    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        public changeDetector:ChangeDetectorRef,
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService,
        public componentFactoryResolver:ComponentFactoryResolver,
        public router:Router
    ){
        super(
            "Start Your Appeal",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );

        let _initialValues = this.activatedRoute.snapshot.data.state.storedValues;
        this.ngrxstore.select(selectNavigationFlag()).subscribe((isNavigated)=>{
            this.isNavigatedFromSummary = isNavigated;
        });

        this.formGroup = this.formBuilder.group({
            [this.fields.ASYA_TITLE.storeKey]:[_initialValues[
                this.fields.ASYA_TITLE.storeKey
            ],[Validators.required,Validators.maxLength(80)]],
            // This will be assigned via patchValue in the lenerLableChange method
            [this.fields.ASYA_LENDER_NAME.storeKey]:[_initialValues[
                this.fields.ASYA_LENDER_NAME.storeKey
            ],Validators.required],
            'lender_name':_initialValues['lender_name']
        });

        // This is a method in the WizardStep class which watches value changes on formGroup, simply call it and the ngrx store will start saving values as a user types.
        this.watchChanges();

    }

    ngOnInit(){
        let _refreshSub = this.appealWizardService.$RefreshTrigger.pipe(take(1)).subscribe((refresh)=>{
            //console.log('refreshing')
            if(refresh){
                this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe(
                (value)=>{
                    //console.log(value,value[this.fields.ASYA_TITLE.storeKey]);
                    this.formGroup.patchValue({
                        [this.fields.ASYA_TITLE.storeKey]:value[this.fields.ASYA_TITLE.storeKey],
                        [this.fields.ASYA_LENDER_NAME.storeKey]:value[this.fields.ASYA_LENDER_NAME.storeKey] ,
                        'lender_name':value['lender_name']                      
                    });
                    this.lenderLabelModel = value['lender_name'];

                    this.validLender = (!!this.lenderLabelModel);
                    this.changeDetector.detectChanges();
                })
            }
        })

        this.storeZombieByKey('refresh_sub',_refreshSub);
    }

    ngOnDestroy(){
        this.ngrxstore.dispatch(new NavigatedFromSummary(false))
    }
    
    lenderLabelChange(event:Object){
        let _matchedLender = event['item'];      

        if(_matchedLender){
            this.formGroup.patchValue({
                [this.fields.ASYA_LENDER_NAME.storeKey]:_matchedLender['id'],
                'lender_name':_matchedLender['name']
            });
            this.validLender = _matchedLender['id'];
        }
    }

    lenderKeyup(event:any){
        // console.log(">>>>>>>>",this.refId);
        if((event.target.value.length === 1 || event.target.value.length === 0 || event.keyCode == 8 || event.keyCode == 46) && event.keyCode != 13){
            this.formGroup.patchValue({
                [this.fields.ASYA_LENDER_NAME.storeKey]:null
            });
            
            this.changeDetector.detectChanges();
        }
    }

    matchLender = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap( (searchText) => this.appealWizardService.getLendersList(searchText) 
        )              
    );

    resultFormatLenderValue(value: any) {            
        return value.name ;
    }

    inputFormatLenderValue(value: any)   {
        if(typeof value === 'object' && Object.keys(value).length !== 0){
            let _matchedItem = value.name ;
            if(_matchedItem)
                return _matchedItem;
        }else{
            return value;
        }
    }
}