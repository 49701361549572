import { Action, createFeatureSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { NAVIGATION_CONSTANTS } from '../constants/navigation.constants';

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

let _currentLocalNavigationState = {};
if(localStorage.getItem('navigation')){
  _currentLocalNavigationState = JSON.parse(localStorage.getItem('navigation'))
}

export interface NavigationState{
  'current_url':string,
  'previous_url':string,
  'current_page_name':string,
  'current_page_index':number,
  'current_section_name':number,
  'current_section_index':number,
  'current_page_tab':string,
  'drawer_type':string,
  'drawer_collapsed':boolean,
  'roles':any,
  'is_tablet':boolean,
  'is_desktop':boolean,
  'is_mobile':boolean,
  'is_widescreen':boolean,
  'is_full_hd':boolean,
  'scroll_y':number,
  'scroll_height':number,
  'window_width':number,
  'frozen':boolean
}

export const initialNavigationState:NavigationState = {
  'current_url':(_currentLocalNavigationState['current_url']) ? _currentLocalNavigationState['current_url'] : '',
  'previous_url':(_currentLocalNavigationState['previous_url']) ? _currentLocalNavigationState['previous_url'] : '',
  'current_page_name':(_currentLocalNavigationState['current_page_name']) ? _currentLocalNavigationState['current_page_name']:'',
  'current_page_index':(_currentLocalNavigationState['current_page_index']) ? _currentLocalNavigationState['current_page_index']:'',
  'current_section_name':(_currentLocalNavigationState['current_section_name']) ? _currentLocalNavigationState['current_section_name']:'',
  'current_section_index':(_currentLocalNavigationState['current_section_index']) ? _currentLocalNavigationState['current_section_index']:'',
  'current_page_tab':(_currentLocalNavigationState['current_page_tab']) ? _currentLocalNavigationState['current_page_tab']:'',
  'drawer_collapsed':true,
  'drawer_type':null,
  'roles':(_currentLocalNavigationState['roles']) ? _currentLocalNavigationState['roles'] : [],
  'is_tablet':false,
  'is_mobile':false,
  'is_desktop':false,
  'is_widescreen':false,
  'is_full_hd':false,
  'scroll_y':0,
  'scroll_height':0,
  'window_width':0,
  'frozen':false
}

export function NavigationReducer(state:NavigationState = initialNavigationState, action: ActionWithPayload<any>):NavigationState{

    let _value = null;

    switch (action.type) {
        case NAVIGATION_CONSTANTS.SET_ROLES:
          _value = Object.assign({},state,{
            'roles':action.payload
          })
          return _value;

        case NAVIGATION_CONSTANTS.SET_CURRENT_ROUTE_URL:
          _value = Object.assign({},state,{
            'current_url':action.payload
          })
          return _value;

        case NAVIGATION_CONSTANTS.SET_PREVIOUS_ROUTE_URL:
          _value = Object.assign({},state,{
            'previous_url':action.payload
          })
          return _value;

        case NAVIGATION_CONSTANTS.SET_PAGE:
          _value = Object.assign({},state,{
            current_section_name:action.payload.section_name,
            current_section_index:action.payload.section_index,
            current_page_name:action.payload.page_name,
            current_page_index:action.payload.page_index,
          });
          return _value;

        case NAVIGATION_CONSTANTS.SET_DRAWER_COLLAPSE:
          _value = Object.assign({},state,{
            drawer_collapsed:action.payload
          });
          return _value;

        case NAVIGATION_CONSTANTS.SET_DRAWER_TYPE:
          //console.log(action.payload)
          _value = Object.assign({},state,{
            drawer_type:action.payload
          });
          return _value;

        case NAVIGATION_CONSTANTS.SET_CURRENT_PAGE_TAB:
          _value = Object.assign({},state,{
            current_page_tab:action.payload
          });
          return _value;

        case NAVIGATION_CONSTANTS.SET_BROWSER_STATE:
            _value = Object.assign({},state,{
              window_width:action.payload.width,
              is_full_hd:(action.payload.width >= 1200),
              is_widescreen:(action.payload.width < 1200 && action.payload.width >= 992),
              is_desktop:(action.payload.width < 992 && action.payload.width >= 768),
              is_tablet:(action.payload.width < 768 && action.payload.width >= 576),
              is_mobile:(action.payload.width < 576),
              scroll_y:action.payload.scrollY,
              max_scroll_y:action.payload.maxScrollY
            })
            return _value;

        case NAVIGATION_CONSTANTS.FREEZE_NAVIGATION:
            _value = Object.assign({},state,{
              frozen:true
            });
            return _value;
        
        case NAVIGATION_CONSTANTS.THAW_NAVIGATION:
            _value = Object.assign({},state,{
              frozen:false
            });
            return _value;

        default:
          return state;
    }
}

export const getNavigationState = createFeatureSelector<NavigationState>('navigation');
