import { Component, Inject } from '@angular/core';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig } from '../../../../platform/modules/navigation';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { KillZombies } from '../../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { AccountService } from '../../account.service';

@Component({
    selector:'inbox-message',
    template:require('./inbox-message.component.html'),
})
export class InboxMessage extends KillZombies(PageView){

    public threadLabels = {
        message:'Message'
    };    

    public message:any = [];

    constructor(
        public activatedRoute:ActivatedRoute,
        public ngrxstore:Store<any>,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        navigationService:NavigationService,
        public accountService:AccountService
    ){
        // Provide PageView's dependencies
        super(configuration,navigationService);
    }
}