import {Injectable,Inject} from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, map  } from 'rxjs/operators';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { DOCUMENT_CATEGORIES } from '../../../constants/app.constants';

@Injectable({
    providedIn: 'root'
})
export class PPPAppealWizardDocumentsResolver implements Resolve < any > {

    public route: ActivatedRouteSnapshot = null;

    constructor(
        @Inject(Store) public ngrxstore: Store,
        @Inject(PPPAppealWizardService) public appealWizardService
    ) {}

    // Provides the stored wizard values before inital step render.
    resolve(route: ActivatedRouteSnapshot) {
        this.route = route;
        let appealData$ = new Observable((obs)=>{
            obs.next([]);
            obs.complete();
          });

        let documentCategories = new Observable((obs) => { 
          obs.next([]); 
          obs.complete(); 
        });

        if( route.paramMap.get('id') !== 'new-appeal' ){
            appealData$ = this.appealWizardService.getUploadedDocuments(route.paramMap.get('id'));
        }

        documentCategories = new Observable(
          (obs) => {
            this.appealWizardService.getDocumentCategories(['wizard'],0,100).pipe(take(1)).subscribe(
              (response) => { 
                let _response = response.filter((item) => {
                  return (item.slug !== DOCUMENT_CATEGORIES.TAXES.slug &&
                  item.slug !== DOCUMENT_CATEGORIES.PAYROLL.slug && 
                  item.slug !== DOCUMENT_CATEGORIES.WIZARD_OTHERS.slug)
                })

                _response.push((response.filter((item) => {
                  return (item.slug === DOCUMENT_CATEGORIES.WIZARD_OTHERS.slug);
                }))[0])
                obs.next(_response);
                obs.complete();
            })
          }
        )

        return zip(
          appealData$,
          documentCategories
          ).pipe(
            map(([
              $appealData,
              $documentCategories
            ])=>({
              data:$appealData,
              documentCategories:$documentCategories
            })),
            take(1)
          );
    }

}