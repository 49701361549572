// Root Declarations
// ----------------------------------------------------
export const APPEAL_WIZARD_ACTIONS = {
    COMPLETE_STEP:'COMPLETE_STEP',
    PREVIOUS_STEP:'PREVIOUS_STEP',
    SAVE_APPEAL_AND_SIGN_COS:'SAVE_APPEAL_AND_SIGN_COS',
    SUBMIT_APPEAL_SUCCESS:'SUBMIT_APPEAL_SUCCESS',
    SIGN_APPEAL_COS:'SIGN_APPEAL_COS',
    SETUP_STEPS:'SETUP_STEPS',
    JUMP_TO_STEP:'JUMP_TO_STEP',
    STORE_VALUES:'STORE_VALUES',
    START_NEW_WIZARD:'START_NEW_WIZARD',
    REQUEST_NEW_APPEAL:'REQUEST_NEW_APPEAL',
    SAVE_APPEAL: 'SAVE_APPEAL' ,
    CONTINUE_APPEAL_APPLICATION:'CONTINUE_APPEAL_APPLICATION',
    WIPE_STORE_VALUES:'WIPE_STORE_VALUES',
    APPEAL_WIZARD_COMPLETE:'APPEAL_WIZARD_COMPLETE',
    RECORD_UNSAVED_VALUE_KEY:'RECORD_UNSAVED_VALUE_KEY',
    REPORT_WIZARD_ERROR:'REPORT_WIZARD_ERROR',
    IS_NAVIGATED_FROM_SUMMARY: 'IS_NAVIGATED_FROM_SUMMARY',
    CREATE_RECONSIDERATION_APPEAL:'CREATE_RECONSIDERATION_APPEAL',
    FILE_RECON_PFR_APPEAL: 'FILE_RECON_PFR_APPEAL',
    CREATE_PFR_APPEAL: 'CREATE_PFR_APPEAL',
}

export const APPEAL_WIZARD_PATHS = {
    APPEAL_WIZARD:'wizard',
    APPEAL_START_APPEAL: 'start-your-appeal',
    APPEAL_POINT_OF_CONTACT : 'point-of-contact',
    APPEAL_LOAN_INFORMATION : 'loan-information',
    APPEAL_CONTENT : 'appeal-content',
    APPEAL_CERTIFICATE_OF_SERVICE : 'certificate-of-service',
    APPEAL_SUMMARY:'appeal-summary',
    APPEAL_BORROWER_DETAILS:'borrower-details'
}

export const APPEAL_WIZARD_ROOT_PATH = 'appeal-wizard';