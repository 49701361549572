import {Inject, Injectable} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { getSessionState } from '../../../platform/modules/auth';
import { AccountService } from '../account.service';

@Injectable()
export class UserResolver implements Resolve<any> {

  constructor(
    @Inject(Store) public ngrxstore:Store,
    @Inject(AccountService) public accountService:AccountService
  ){}

  resolve(route:ActivatedRouteSnapshot){
    return this.accountService.getUser(route.paramMap.get('id'));
  }

}
