import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap ,  catchError, map, mergeMap } from 'rxjs/operators';

import { NotificationService } from '../notification.service';
import * as notificationActions from './notification.actions';
import { NOTIFICATION_ACTION_CONSTANTS } from '../constants/notification.constants';

@Injectable()
export class NotificationEffects {
  // Listen for the 'LOGIN' action
  constructor(
    public notificationService:NotificationService,
    public actions$: Actions,
    public ngrxstore:Store<any>) {}

  @Effect({dispatch:false})
  onTriggerModal$: Observable<notificationActions.TriggerModal> = this.actions$.pipe(
    ofType<notificationActions.TriggerModal>(NOTIFICATION_ACTION_CONSTANTS.TRIGGER_MODAL),
    tap((action) =>{
      this.notificationService.CreateModal(action.configuration);
    })
  );

  @Effect({dispatch:false})
  onApproveModalAction$: Observable<notificationActions.ApproveModalAction> = this.actions$.pipe(
    ofType<notificationActions.ApproveModalAction>(NOTIFICATION_ACTION_CONSTANTS.APPROVE_MODAL_ACTION),
    tap((action) => {
      this.ngrxstore.dispatch({
        type:NOTIFICATION_ACTION_CONSTANTS.REMOVE_MODAL
      });
    })
  );

  @Effect({dispatch:false})
  onRejectModalAction$: Observable<notificationActions.RejectModalAction> = this.actions$.pipe(
    ofType<notificationActions.RejectModalAction>(NOTIFICATION_ACTION_CONSTANTS.REJECT_MODAL_ACTION),
    tap((action) => {
      this.ngrxstore.dispatch({
        type:NOTIFICATION_ACTION_CONSTANTS.REMOVE_MODAL
      });
    })

  );

  @Effect({dispatch:false})
  onCancelModalAction$: Observable<notificationActions.CancelModalAction> = this.actions$.pipe(
    ofType<notificationActions.CancelModalAction>(NOTIFICATION_ACTION_CONSTANTS.CANCEL_MODAL_ACTION),
    tap((action) => {
      this.ngrxstore.dispatch({
        type:NOTIFICATION_ACTION_CONSTANTS.REMOVE_MODAL
      });
    })

  );

  @Effect({dispatch:false})
  onTriggerToastAction$: Observable<notificationActions.TriggerToast> = this.actions$.pipe(
    ofType<notificationActions.TriggerToast>(NOTIFICATION_ACTION_CONSTANTS.TRIGGER_TOAST),
    tap((action)=>{
      this.notificationService.CreateToast(action.payload)
    })
  )

  @Effect({dispatch:false})
  onDismissToasts$: Observable<notificationActions.DismissToasts> = this.actions$.pipe(
    ofType<notificationActions.DismissToasts>(NOTIFICATION_ACTION_CONSTANTS.DISMISS_TOASTS),
    tap((action=>{
      this.notificationService.DismissToasts();
    }))
  )
}
