// Angular and Third Party
import { Component, Inject, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit} from '@angular/core';
import { Observable, Subject, BehaviorSubject, forkJoin, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { PageScrollService } from 'ngx-page-scroll-core';
import * as _ from 'lodash';

// Platform
import { NavigationService } from '../navigation.service';
import { NavigationConfig, NAVIGATION_CONFIG } from '../navigation.interface';

// Store
import { Store } from '@ngrx/store';
import { selectUrlState, selectNavigationDrawerState, getWindowState, navigationIsFrozen } from '../store/navigation.selectors';
import { OpenNavigationDrawer, CloseNavigationDrawer, SetDrawerType } from '../store/navigation.actions';
import { KillZombies } from '../kill-zombies/kill-zombies';


@Component({
  selector: 'top-nav',
  template:require('./top-nav.component.html')
})

export class TopNavComponent extends KillZombies(){

  @ViewChild('scrollToTop') public scrollToTopButton:ElementRef;

  public $burgerMenuOpen:BehaviorSubject<boolean> = new BehaviorSubject(false);
  get $BurgerMenuOpen():BehaviorSubject<boolean>{
    return this.$burgerMenuOpen;
  }

  public $burgerVisible:BehaviorSubject<boolean> = new BehaviorSubject(false);
  get $BurgerVisible():BehaviorSubject<boolean>{
    return this.$burgerVisible;
  }

  public showBurger:boolean = false;
  public hasDrawer:boolean = false;
  public isMobile:boolean = false;
  public hasPrimaryNavigation:boolean = false;
  public hasWizardBar:boolean = false;
  public showBackToTop:boolean = false;
  public topNavTitle = null;

  public currentRoute:string = '/';
  public currentSectionIndex = null;
  public burgerOpen:boolean = false;
  public roles:Array<string> = [];
  public signedIn:boolean = false;
  public configuration:TopNavConfiguration = {
    key:'toolbar-toolbar',
    navigation:{homeUrl:null},
    pageActions:[]
  }
  public url:string = '';

  public frozen:boolean = false;
  public navigationMap:any = null;
  public linksMap:any = null;
  public soloPages:any = null;

	constructor(
    @Inject(NAVIGATION_CONFIG) public config:NavigationConfig,
    public changeDetector:ChangeDetectorRef,
    public navigationService:NavigationService,
    public ngrxstore:Store<any>,
    public renderer:Renderer2,
    public pageScrollService: PageScrollService
  ){

    super();

    this.navigationService.$CurrentRoute.subscribe((currentRoute)=>{
      this.currentRoute = currentRoute
    })

    this.navigationService.$DrawerNavigationConfiguration.subscribe((configuration)=>{
      this.hasDrawer = configuration.items.length>0;
    })
    
    this.navigationService.$NavigationConfiguration.subscribe((configuration)=>{
      this.navigationMap = configuration.sections;
      this.soloPages = configuration.pages;
      this.linksMap = configuration.links;
      this.frozen = configuration.disabled;
      this.topNavTitle = "";
      
      let _hasPrimaryNavigation = [];

      this.navigationMap.map((section,index)=>{
        if( this.navigationService.routeIsPermitted(section.url) ){
          _hasPrimaryNavigation.push(true);
        }
      });

      this.soloPages.map((page,index)=>{
        if( this.navigationService.routeIsPermitted(page.url) ){
          _hasPrimaryNavigation.push(true);
        }
      });

      this.linksMap.map((link,index)=>{
        if( this.navigationService.routeIsPermitted(link.url) ){
          _hasPrimaryNavigation.push(true);
        }
      });

      this.hasPrimaryNavigation = _hasPrimaryNavigation.length > 0;
    
    });

    this.navigationService.$TopNavConfiguration.subscribe((config)=>{
      let _pageActions = config.pageActions.filter(
        (action)=>{
          if(!!action.onClickRoute || !!action.onClickExternalLink){
            if(action.onClickRoute) return navigationService.routeIsPermitted(action.onClickRoute);
            if(action.onClickExternalLink)  return navigationService.externalLinkIsPermitted(action.onClickExternalLink);
          }else{
            return true;
          }
        }
      ).map((action)=>{
        action['click'] = () =>{
          
          if(action.onClick){
            if(action.onClick.apply){
              action.onClick();
            }else{
              this.ngrxstore.dispatch(action.onClick);
            }
          }

          if(action.onClickRoute){
            navigationService.navigate([action.onClickRoute]);
          }

          if(action.onClickExternalLink){
            navigationService.navigateToExternal(action.onClickExternalLink);
          }

          this.closeBurgerMenu();
        }
        return action;
      })
      this.configuration = Object.assign({},this.configuration,config,{pageActions:_pageActions});
    })

    ngrxstore.select(getWindowState()).subscribe((state)=>{
      let _burgerIsVisible = (state.size === 'isTablet' || state.size === 'isMobile');
      this.$BurgerVisible.next(_burgerIsVisible);
      this.isMobile = ( state.size === 'isMobile' );
      this.showBackToTop = (state.scrollY > 10);
    });

    ngrxstore.select(selectUrlState).subscribe((state)=>{
      this.url = state.current;
    });

    this.ngrxstore.select(navigationIsFrozen).subscribe((isFrozen:boolean)=>{
      this.frozen = isFrozen;
    })

    this.$BurgerMenuOpen.subscribe((state)=>{
      this.burgerOpen = state;
    })
    this.$BurgerVisible.subscribe((state)=>{
      this.showBurger = state;
    })
  }

  ngAfterViewInit(){
    let _stts = this.navigationService.$ScrollToTopPosition.subscribe((position:any)=>{
      let _style = '';
      if(position.top) _style = _style+'top:'+position.top;
      this.renderer.setAttribute(this.scrollToTopButton.nativeElement,'style',_style);
    })
    this.storeZombieByKey('scrollToTop',_stts);
  }

  toggleBurger(){
    this.$BurgerMenuOpen.next(!this.burgerOpen)
  }

  logout(){
    this.$BurgerMenuOpen.next(false)
  }

  closeBurgerMenu(){
    this.$burgerMenuOpen.next(false);
  }

  openBurgerMenu(){
    this.$burgerMenuOpen.next(true);
  }

  scrollToTop(){
    window.scroll(0,0);
  }

  hasRoles(){
    return this.navigationService.CurrentRoles.indexOf('None') < 0;
  }

  goToHome(){
    this.navigationService.navigate(['/'])
  }

  toggleDrawer(type:string = 'navigation'){
    this.ngrxstore.select(selectNavigationDrawerState()).pipe(take(1)).subscribe((state)=>{
      //console.log(state)
      if(state.collapsed){
        this.ngrxstore.dispatch(new OpenNavigationDrawer())
      }else{
        this.ngrxstore.dispatch(new CloseNavigationDrawer())
      }
    });
  }
}
