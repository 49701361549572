import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from "rxjs";


@Component({
    selector:'thread',
    template:require('./thread.component.html'),
})
export class MessageThread{

    public showLastMessageOnTop:boolean = true;
    public listSize= 0;
    public noteTitle="";
    public notesText = "";
    public notesTextMessage = "";
    public readOnlyNote = false;

    @Input() type:'message'|'note' = 'message'
    @Input() set labels(value:MessageThreadLabelOptions){
        this.historyLabel = "All past "+value.message.toLowerCase()+"'s";
        this.messageLabel = value.message.toLowerCase();
    }
    @Input() set message(messageContent){
        this.listSize = messageContent.length;
        this.entireMessageThread = messageContent;
    }

    @Input() set isReadonly(bool){
       this.readOnlyNote = (bool) ? true : false;
    }

    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    @Output() onInput: EventEmitter<any> = new EventEmitter();
    
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();

    public lastMessage:any = {};
    public entireMessageThread:any = [];
    public notesContent: any;
    public notesContentMessage: any;

    public historyLabel:string = 'History';
    public showEntireThread:boolean = false;
    public messageLabel:string = 'Item';

    constructor(
        public activatedRoute:ActivatedRoute,
    ){
        
    }

    
    ngOnInit(){
        this.resetFormSubject.subscribe(response => {
           if(response){
            this.notesTextMessage = "";
         }
        })
    }

    toggleEntireThread(){
        this.showEntireThread = !this.showEntireThread; 
    }
    updateNotesBox(content){
        this.notesContent= content;
    }

    updateNotesBoxMessage(content){
        this.notesContentMessage = content;
        this.onInput.emit(this.notesContentMessage.text);
    }
    onSubmitThread(content){
        if(this.notesContent){
            this.notesContent.text = this.notesContent.text.replace(/(\n)/gm, "");
            this.notesContent.title = this.noteTitle;
            this.onSubmit.emit(this.notesContent);
            this.notesText = "";
        }else{
            this.onSubmit.emit({text:""});
        }
       
    }
}