export const AUTH_ACTIONS = {
  LOGIN:'LOGIN',
  LOGOUT:'LOGOUT',
  REQUEST_LOGOUT:'REQUEST_LOGOUT',
  COMPLETE_LOGIN:'COMPLETE_LOGIN',
  COMPLETE_LOGOUT:'COMPLETE_LOGOUT',
  REQUEST_AMBIENT_LOGIN:'REQUEST_AMBIENT_LOGIN',
  REQUEST_RESET_PASSWORD:'REQUEST_RESET_PASSWORD',
  RESET_PASSWORD:'RESET_PASSWORD',
  REQUEST_RESET_MFA:'REQUEST_RESET_MFA',
  AMBIENT_LOGIN:'AMBIENT_LOGIN',
  REJECT_AMBIENT_LOGIN:'REJECT_AMBIENT_LOGIN',
  COMPLETE_AMBIENT_LOGIN:'COMPLETE_AMBIENT_LOGIN',
  LOGIN_WITH_APPLICATION:'LOGIN_WITH_APPLICATION',
  TWO_FA_REGISTER_PHONE:'TWO_FA_REGISTER_PHONE',
  REGISTER_PHONE:'REGISTER_PHONE',
  TWO_FA_SCAN_CODE:'TWO_FA_SCAN_CODE',
  TWO_FA_METHOD:'TWO_FA_METHOD',
  TWO_FA_PROVIDE:'TWO_FA_PROVIDE',
  REGISTER_USER:'REGISTER_USER',
  SET_ORIGIN:'SET_ORIGIN',
  REFRESH_TOKEN:'REFRESH_TOKEN',
  SAVE_PROFILE_CHANGES: 'SAVE_PROFILE_CHANGES',
  SAVE_APPEAL_PROFILE_CHANGES : 'SAVE_APPEAL_PROFILE_CHANGES',
  INVITE_USER:'INVITE_USER',
  LOGIN_WITH_INVITE:'LOGIN_WITH_INVITE',
  LOGIN_WITH_APPEAL_ID:'LOGIN_WITH_APPEAL_ID',
  DIRECT_LOGIN_WITH_APPEAL_ID:'DIRECT_LOGIN_WITH_APPEAL_ID',
  LIST_PAGE_LIMIT:'LIST_PAGE_LIMIT',
  SELECT_SMS_TWILIO_2FA:'SELECT_SMS_TWILIO_2FA',
  TWILIO_TWO_FA_PROVIDE:'TWILIO_TWO_FA_PROVIDE',
  TWILIO_REGISTER_PHONE:'TWILIO_REGISTER_PHONE',
}

export const AUTH_PATHS = {
  LOGIN:'login',
  REGISTER:'register',
  REGISTER_WITH_EMAIL:'register/:token',
  REGISTER_WITH_TOKEN:'finish-register/:token',
  ONE_LOGIN_WITH_TOKEN:'onelogin-token/:token',
  FORGOT_PASSWORD:'forgot-password',
  FORGOT_USERNAME:'forgot-email_address',
  RECOVER_USERNAME:'recover-email_address/:token',
  RESET_PASSWORD:'reset-password/:token',
  RESET_PASSWORD_WITHOUT_TOKEN:'reset-password',
  TWO_FA_REGISTER_PHONE:'2fa-registration',
  TWO_FA_PROVIDE:'2fa-token',
  TWO_FA_KEYS:'2fa-keys',
  TWO_FA_METHOD:'2fa-select-method',
  TWO_FA_SCAN_QR:'2fa-scan-qr',
  INVITE_USER:'invite-user',
  INVITE_USER_WITH_TOKEN:'invite-user/:id',
  LOGIN_WITH_INVITE:'login/:id',
  LOGIN_WITH_APPEAL_ID:'appeal/:id',
  APPEALS_APPLICATIONS_ROOT_PATH :'appeals-applications',
  YOUR_APPEAL : 'your-appeal',
  TICKETS: 'tickets',
  APPEAL_TICKETS: 'appeal-ticket'
}
