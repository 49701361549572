import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }  from '@angular/forms';
import { InfiniteScrollHandler } from "./handlers/infinite-scroll.handler";
import { QuillModule } from 'ngx-quill';

// Directives
import { Collapsable } from './directives/collapsable/collapsable.directive'

// Panels
import { QueryPanel } from './components/panels/query-panel.component';
import { SlidingPanel } from './components/panels/sliding-panel.component';

// Lists
import { SortableList } from './components/lists/sortable-list.component';

// Page Components
import { FooterComponent } from './components/page-components/footer.component';

// Unique
import { MessageThread } from './components/thread/thread.component';
import { SmartyStreetsInput } from './components/smarty-streets-input/ss-input.component';
import { InputCard } from './components/input-card/input-card.component';
import { StatusCard } from './components/status-card/status-card.component';
import { StatusBadge } from './directives/status-badge/status-badge.directive';
import { StatusBubble } from './directives/status-bubble/status-bubble.directive';

// Inputs
import { InputNumeric } from './components/inputs/input-numeric.component';
import { InputPhone } from './components/inputs/input-phone.component';
import { InputSelect } from './components/inputs/input-select.component';
import { InputCommon } from './components/input-card/input-common.component';
import { NgInputSelect } from './components/inputs/ng-input-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FirstFocus } from './directives/first-focus/first-focus.directive';
import { BucketModule } from './modules/bucket/bucket.module';
import { Bucket } from './modules/bucket/bucket.component';
import { BucketItem } from './modules/bucket/bucket-item/bucket-item.component';
import { NgbDate, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InputDate } from './components/inputs/input-date.component';
import { InputDateTime } from './components/inputs/input-datetime.component';
import { InputGuard } from './directives/input-guard/input-guard.directive';

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        QuillModule.forRoot(),
        NgSelectModule,
        BucketModule,
        NgbDatepickerModule
    ],
    declarations:[
        Collapsable,
        QueryPanel,
        SortableList,
        SlidingPanel,
        MessageThread,
        SmartyStreetsInput,
        InputNumeric,
        InputPhone,
        NgInputSelect,
        InputCard,
        InputCommon,
        InputSelect,
        StatusCard,
        FirstFocus,
        StatusBadge,
        StatusBubble,
        InputDate,
        InputDateTime,
        InputGuard
    ],
    providers:[
        InfiniteScrollHandler,
    ],
    exports:[
        CommonModule,
        Collapsable,
        QueryPanel,
        SortableList,
        SlidingPanel,
        MessageThread,
        SmartyStreetsInput,
        InputNumeric,
        InputPhone,
        NgInputSelect,
        InputCard,
        InputCommon,
        InputSelect,
        StatusCard,
        FirstFocus,
        Bucket,
        BucketItem,
        StatusBadge,
        StatusBubble,
        InputDate,
        InputDateTime,
        InputGuard
    ]
})
export class SharedModule{
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
          ngModule: SharedModule,
          providers: [ InfiniteScrollHandler ]
        };
      }
}
