import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthService } from '../../../platform/modules/auth/auth.service';

@Injectable({
    providedIn:'root'
  })
  export class UserPortalResolver implements Resolve<any> {
  
    constructor(
      @Inject(AuthService) public authService:AuthService
    ){}
  
    // Provides the loans before inital page render.
    resolve(route:ActivatedRouteSnapshot){

        let inviteData$ = new Observable((obs)=>{
            obs.next({token :null});
            obs.complete();
          });

        if( route.paramMap.get('id')){
            inviteData$ = this.authService.getInviteUserDetails(route.paramMap.get('id'))
        }

        return zip(
                inviteData$,
                this.authService.getSettings()
            ).pipe(
            map(([
              $invite,
              $settings
            ])=>({
                invite:$invite,
                settings:$settings
            })),
            take(1)
          );
    }
  
  }