import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';

@Component({

  selector: 'health-component',

  template: require('./health.component.html')

})

export class HealthComponent {

  build_start_time = process.env.CODEBUILD_START_TIME;
  build_number = process.env.CODEBUILD_BUILD_NUMBER;
  commit_id = process.env.CODEBUILD_RESOLVED_SOURCE_VERSION;

  getformattedDate(date){
    let _datePipe = new DatePipe('en-US');
    return _datePipe.transform(date,'dd MMM yyyy hh:mm:ss') + ' UTC';
  }

}