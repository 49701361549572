import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap ,  take, catchError, map, mergeMap } from 'rxjs/operators';
import { StoreAppealValues, SaveAppealAndSignCOS, AfterSignedCertificateOfService, SignCertificateOfService,ContinueAppealApplication, RequestNewAppeal, SaveAppeal, StartNewAppealWizard, WipeAppealStoreValues, AppealWizardComplete, AppealSetup, ReportWizardError, CreateReconsiderationAppeal, FileReconPFRAppeal, CreatePFRAppeal} from '../store/appeal-wizard.actions';
import { APPEAL_WIZARD_ACTIONS, APPEAL_WIZARD_PATHS, APPEAL_WIZARD_ROOT_PATH } from '../constants/appeal-wizard.constants';
import { PPPAppealWizardService } from '../appeal-wizard.service';
import { FreezeNavigation, NavigationService, ThawNavigation } from '../../../platform/modules/navigation';
import { selectPPPAppealWizardStatus, selectPPPAppealWizardValues } from './appeal-wizard.selectors';
import { TriggerModal, TriggerToast } from '../../../platform/modules/notification';``
import { AuthService } from '../../../platform/modules/auth';
import { APPEAL_ACTION_TYPES, APP_DEFAULTS } from '../../../constants/app.constants';
import { FULL_ROUTES } from '../../../constants/routes.constants';

@Injectable()
export class AppealWizardEffects {
  // Listen for the 'LOGIN' action
  constructor(
    public actions$: Actions,
    public navigationService:NavigationService,
    public appealWizardService:PPPAppealWizardService,
    public authService:AuthService,
    public ngrxstore:Store<any>) {
    }

    triggerErrorAsModal(err:any){
      if(err){
        this.ngrxstore.dispatch( new ThawNavigation() );
        let formattedError = this.appealWizardService.interpretErrorsFromAPI(err);
        this.ngrxstore.dispatch(new ReportWizardError(formattedError));
        this.ngrxstore.dispatch(new TriggerModal({
          content:{
            title:formattedError.title,
            message:formattedError.message,
          },
          type:'message'
        }))
        throw err;
      }
    }

    triggerErrorAsToast(err:any, message:string = 'Error'){
      this.ngrxstore.dispatch( new ThawNavigation() );
      this.ngrxstore.dispatch( new TriggerToast({
        message:message+': '+err.statusText,
        style:'danger',
        ttl:6
      }));
    }

    triggerSuccessAsToast(message:any){
      this.ngrxstore.dispatch( new ThawNavigation() );
      this.ngrxstore.dispatch( new TriggerToast({
        message:message,
        style:'success',
        ttl:4
      }));
    }

    refreshWizardWithReferenceId(appealFromServer:AppealResponse){
      this.ngrxstore.dispatch( new ThawNavigation() );
      this.ngrxstore.dispatch( new StoreAppealValues({
        reference_id:appealFromServer.reference_id
      }));
      this.ngrxstore.select(selectPPPAppealWizardStatus).pipe(take(1)).subscribe((wizardStatus)=>{
          this.navigationService.navigateByUrl(
          APPEAL_WIZARD_ROOT_PATH+'/'+
          APPEAL_WIZARD_PATHS.APPEAL_WIZARD + '/' +
          appealFromServer.reference_id +  '/' + 
          wizardStatus.currentStep
        )
      });
    }

    @Effect({dispatch:false})
    onAppealSetup$: Observable<AppealSetup> = this.actions$.pipe(
      ofType<AppealSetup>(APPEAL_WIZARD_ACTIONS.SETUP_STEPS),
      tap((action)=>{
        this.appealWizardService.$RefreshTrigger.next(true);
      })
    )


    // @Effect({dispatch:false})
    // onRequestNewAppeal$: Observable<RequestNewAppeal> = this.actions$.pipe(
    //   ofType<RequestNewAppeal>(APPEAL_WIZARD_ACTIONS.REQUEST_NEW_APPEAL),
    //   tap((action) =>{
    //       this.appealWizardService.resetPostStepTrigger();
    //   })
    // );
  
    @Effect({dispatch:false})
    onStartNewAppealWizard$: Observable<StartNewAppealWizard> = this.actions$.pipe(
        ofType<StartNewAppealWizard>(APPEAL_WIZARD_ACTIONS.START_NEW_WIZARD),
        tap((action)=>{
          this.authService.getCurrentUser().subscribe((response)=>{
            this.ngrxstore.dispatch( new WipeAppealStoreValues() );
            this.appealWizardService.requestNewAppeal(action.payload).pipe(take(1)).subscribe((response)=>{
                this.navigationService.navigateByUrl(
                    APPEAL_WIZARD_ROOT_PATH+'/'+
                    APPEAL_WIZARD_PATHS.APPEAL_WIZARD+'/'+
                    response.id+'/'+
                    APPEAL_WIZARD_PATHS.APPEAL_START_APPEAL
                );
            });
          })
        })
    );


    @Effect({dispatch:false})
    onContinueAppealApplication$: Observable<ContinueAppealApplication> = this.actions$.pipe(
      ofType<ContinueAppealApplication>(APPEAL_WIZARD_ACTIONS.CONTINUE_APPEAL_APPLICATION),
      tap((action)=>{
        // Here we use routes only accessible to this feature set.
        // We are navigating with the effect because it affects the applications global state
        this.navigationService.navigateByUrl(
          APPEAL_WIZARD_ROOT_PATH+'/'+
          APPEAL_WIZARD_PATHS.APPEAL_WIZARD + '/' +
          action.reference_id +  '/' + 
          APPEAL_WIZARD_PATHS.APPEAL_START_APPEAL
        );
      }
    ));

    @Effect({dispatch:false})
    onSaveAppeal$: Observable<SaveAppeal> = this.actions$.pipe(
      ofType<SaveAppeal>(APPEAL_WIZARD_ACTIONS.SAVE_APPEAL),
      tap((action) =>{
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
          // FORCING lender for now, TEMPORARY
          // wizardValues = Object.assign({},wizardValues,{
          //   lender:1,
          // });
          //------------------------------------

          // If the Appeal is new, save and then update the action to draft.
          // the payload is a boolean, if it is evaluated as true it will assume this is a new appeal.
          if(action.payload.initialSave){
            this.appealWizardService.saveApplication(wizardValues).subscribe(
              (appeal:AppealResponse)=>{
                this.appealWizardService.changeAppealState(
                  appeal.reference_id,
                  APPEAL_ACTION_TYPES.DRAFT
                ).subscribe((appealFromServer:AppealResponse)=>{
                  this.refreshWizardWithReferenceId(appealFromServer);
                  this.triggerSuccessAsToast('New Appeal Started!');
                },(err)=>{
                  if(err) this.triggerErrorAsModal(err);
                }),
              (err)=>{
                if(err) this.triggerErrorAsModal(err);
              }
            });
          }else{

            // If it is not new, save to a slug and do not change the action state
            this.appealWizardService.updateApplication(wizardValues,wizardValues.reference_id,action.payload.user_ref).subscribe(
              (appeal:AppealResponse)=>{
                this.appealWizardService.updateAppellantGroup(wizardValues.point_of_contact_role, action.payload.user_ref).subscribe(
                  (response)=>{
                    if(!action.payload.submitSaveFlag){
                      let filed_message = "Your appeal has not been filed. You must file your appeal within 30 calendar days after receipt of the final SBA loan review decision.";
                      let sba_invalid_msg = "Also note, the SBA loan number you entered may not be correct, however you may proceed with your appeal."
                      this.ngrxstore.dispatch(new TriggerModal({
                        content:{
                          title:'Please Note',
                          message:filed_message,
                          message1:(!appeal['is_forgiveness_completed'] && appeal['loan_number'] != null) ? sba_invalid_msg : null
                        },
                        type:'message'
                      }));
                    }
                    this.triggerSuccessAsToast('Changes Saved!');
                    if(action.payload.callback){
                      action.payload.callback();
                    }
                },
                (err)=>{
                  if(err) this.triggerErrorAsModal(err);
                });
              },
              (err)=>{
                if(err) this.triggerErrorAsModal(err);
            });
          }
        })
      })
    );
        
  
    @Effect({dispatch:false})
    onSaveAppealAndSignCOS$: Observable<SaveAppealAndSignCOS> = this.actions$.pipe(
      ofType<SaveAppealAndSignCOS>(APPEAL_WIZARD_ACTIONS.SAVE_APPEAL_AND_SIGN_COS),
      tap(()=>{
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
          // If it's been saved before
          if(!!wizardValues.reference_id && wizardValues.reference_id !== APP_DEFAULTS.NEW_APPEAL_SLUG){
            this.appealWizardService.updateApplication(wizardValues,wizardValues.reference_id).subscribe(
              (appeal:AppealResponse)=>{
                this.ngrxstore.dispatch( new AppealWizardComplete() )
            },(err)=>{
              if(err) this.triggerErrorAsModal(err);
            })
          }else{
            // If it has never been saved
            this.appealWizardService.saveApplication(wizardValues).subscribe(
              (appeal:AppealResponse)=>{
                this.refreshWizardWithReferenceId(appeal);
                this.ngrxstore.dispatch( new AppealWizardComplete() )
            },(err)=>{
              if(err) this.triggerErrorAsModal(err);
            })
          }
        })
      })
    );

    @Effect({dispatch:false})
    onAppealWizardComplete$: Observable<AppealWizardComplete> = this.actions$.pipe(
      ofType<AppealWizardComplete>(APPEAL_WIZARD_ACTIONS.APPEAL_WIZARD_COMPLETE),
      tap(()=>{
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
          this.navigationService.navigateByUrl(
            APPEAL_WIZARD_ROOT_PATH+'/'+
            APPEAL_WIZARD_PATHS.APPEAL_WIZARD + '/' +
            wizardValues.reference_id +  '/' + 
            APPEAL_WIZARD_PATHS.APPEAL_CERTIFICATE_OF_SERVICE
          );
        });
      })
    )

    @Effect({dispatch:false})
    onAfterSignedCertificateOfService$: Observable<AfterSignedCertificateOfService> = this.actions$.pipe(
      ofType<AfterSignedCertificateOfService>(APPEAL_WIZARD_ACTIONS.SUBMIT_APPEAL_SUCCESS),
      tap(()=>{
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{

          this.appealWizardService.updateApplication(wizardValues,wizardValues.reference_id).subscribe(
            (appeal:AppealResponse)=>{
              this.appealWizardService.changeAppealState(
                appeal.reference_id,
                APPEAL_ACTION_TYPES.APPEAL_FILED
              ).subscribe((appealFromServer:AppealResponse)=>{
                
                this.ngrxstore.dispatch( new ThawNavigation() );
                // this.ngrxstore.dispatch( new TriggerModal({
                //   content:{
                //     title:"Appeal Submitted",
                //     message:"Your appeal has been submitted succesfully."
                //   },
                //   onCancel:()=>{
                    
                //     // this.navigationService.navigateByUrl(
                //     //   FULL_ROUTES['YOUR_APPEALS']
                //     // );
                //   }
                // }))

              },(err)=>{
                if(err) this.triggerErrorAsModal(err);
              }),
            (err)=>{
              if(err) this.triggerErrorAsModal(err);
            }
          },(err)=>{
            if(err) this.triggerErrorAsModal(err);
          });
        })
      })
    );
    
    @Effect({dispatch:false})
    onSignCertificateOfService$: Observable<SignCertificateOfService> = this.actions$.pipe(
      ofType<SignCertificateOfService>(APPEAL_WIZARD_ACTIONS.SIGN_APPEAL_COS),
      tap((action)=>{

        this.ngrxstore.dispatch( new FreezeNavigation() );

        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
          this.appealWizardService.signCertificateOfService(
            action.payload.signature_for_electronic_filing,
            wizardValues.reference_id,
            action.payload.documents,
            action.docketFilingRef
          ).subscribe((response)=>{
            // console.log(response);
            this.ngrxstore.dispatch( new AfterSignedCertificateOfService() )
          },(err)=>{
            if(err) this.triggerErrorAsModal(err);
          })

        },(err)=>{
          if(err) this.triggerErrorAsModal(err);
        });

      })
    );

    @Effect({dispatch:false})
    onCreateReconsiderationAppeal$: Observable<CreateReconsiderationAppeal> = this.actions$.pipe(
      ofType<CreateReconsiderationAppeal>(APPEAL_WIZARD_ACTIONS.CREATE_RECONSIDERATION_APPEAL),
      tap((action) =>{
        this.appealWizardService.saveApplication(action.payload).pipe(take(1)).subscribe(
          (appeal:AppealResponse)=>{
            this.appealWizardService.changeAppealState(
              appeal.reference_id,
              APPEAL_ACTION_TYPES.APPEAL_FILED
            ).subscribe((appealFromServer:AppealResponse)=>{
              if(action.payload.callback){
                action.payload.callback(appealFromServer); // pass new id
              }
            },(err)=>{
              if(err) {
                if(action.payload.callback){
                  action.payload.callback(err);
                }
                this.triggerErrorAsModal(err);
              }
            })
        },(err)=>{
          if(err) {
            if(action.payload.callback){
              action.payload.callback(err);
            }
            this.triggerErrorAsModal(err);
          }
        })
      })
    );


    @Effect({dispatch:false})
    onCreatePFRAppeal$: Observable<CreatePFRAppeal> = this.actions$.pipe(
      ofType<CreatePFRAppeal>(APPEAL_WIZARD_ACTIONS.CREATE_PFR_APPEAL),
      tap((action) =>{
        this.appealWizardService.saveApplication(action.payload).pipe(take(1)).subscribe(
          (appeal:AppealResponse)=>{
            if(action.payload.callback){
                  action.payload.callback(appeal);
              }
        },(err)=>{
          if(err) {
            if(action.payload.callback){
              action.payload.callback(err);
            }
            this.triggerErrorAsModal(err);
          }
        })
      })
    );

    @Effect({dispatch:false})
    onFileReconPFRAppeal$: Observable<FileReconPFRAppeal> = this.actions$.pipe(
      ofType<FileReconPFRAppeal>(APPEAL_WIZARD_ACTIONS.FILE_RECON_PFR_APPEAL),
      tap((action) =>{
        this.appealWizardService.updateReconApplication(action.payload,action.appealId).pipe(take(1)).subscribe(
          (appeal:AppealResponse)=>{
            this.appealWizardService.changeAppealState(
              appeal.reference_id,
              APPEAL_ACTION_TYPES.APPEAL_FILED
            ).subscribe((appealFromServer:AppealResponse)=>{
              if(action.payload.callback){
                action.payload.callback(appealFromServer); // pass new id
              }
            },(err)=>{
              if(err) {
                if(action.payload.callback){
                  action.payload.callback(err);
                }
                this.triggerErrorAsModal(err);
              }
            })
        },(err)=>{
          if(err) {
            if(action.payload.callback){
              action.payload.callback(err);
            }
            this.triggerErrorAsModal(err);
          }
        })
      })
    );


}
