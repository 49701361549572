import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../../platform/modules/shared';

import { LoansRouting } from './appeals-applications.routes';
import { ShellModule } from '../../shell';
import { AccountModule } from '../account/account.module';
import { LinkedAppealsResolver } from './resolvers/linked-appeals.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppealsApplicationsService } from './appeals-applications.service';
import { YourAppealsList } from './your-appeals/your-appeal-page.component';
import { YourAppealsResolver } from './resolvers/your-appeals.resolver';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppealApplicationsList } from './appeal-applications-list/appeal-applications-list.component';
import { YourAppeal } from './your-appeal/your-appeal.component';
import { YourAppealResolver } from './resolvers/your-appeal.resolver';
import { EffectsModule } from '@ngrx/effects';
import { AppealsApplicationsEffects } from './store/appeals-applications.effects';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BUCKET_CONFIG } from '../../platform/modules/shared/modules/bucket/bucket.interface';
import { API_ENDPOINTS } from '../../constants/rest_api.constants';

@NgModule({
  imports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    InfiniteScrollModule,
    LoansRouting,
    ShellModule,
    AccountModule,
    NgxDatatableModule,
    NgbPopoverModule,
    NgxPageScrollModule,
    NgbTypeaheadModule,
    NgxExtendedPdfViewerModule,
    NgbTooltipModule
  ],
  declarations:[
    YourAppealsList,
    YourAppeal,
    AppealApplicationsList
  ],
  providers:[
    AppealsApplicationsService,
    LinkedAppealsResolver,
    YourAppealsResolver,
    YourAppealResolver
  ],
  exports:[
    RouterModule
  ]
})

export class AppealsApplicationsModule{}
