export const APP_DEFAULTS= {
    LIST_PAGE_LIMIT:5,
    ROWS_PER_PAGE_OPTIONS:
    [
        {label:'5 Appeals',value:5},
        {label:'10 Appeals',value:10},
		{label:'20 Appeals',value:20},
		{label:'30 Appeals',value:30}
    ],
    USERS_PER_PAGE_OPTIONS:
    [
        {label:'5 Users',value:5},
        {label:'10 Users',value:10},
		{label:'20 Users',value:20},
		{label:'30 Users',value:30}
    ],
    DOCS_PER_PAGE_OPTIONS:
    [
        {label:'5 Documents',value:5},
        {label:'10 Documents',value:10},
		{label:'15 Documents',value:15},
		{label:'20 Documents',value:20}
    ],
    APPEAL_TYPE_OPTIONS:[
        {label:'PPP Appeal',value:"ppp", long:'Appeal of SBA Loan Review Decision und 13 C.F.R. 134.1201(b)'},
    ],
    NEW_APPEAL_SLUG:'new-appeal',
    ORDER_COLUMN:'title',
    RECONSIDERATION_SLUG:'reconsideration',
    PFR_SLUG:'pfr',
    ORIGINAL_APPEAL_SLUG: 'ppp',
    PFR_ON_RECONSIDERED_SLUG:'pfr-on-reconsidered',
    APPEAL_TYPE_OPTIONS_FILTER:[
        {label:'Original Appeal',value:"ppp"},
        {label:'Reconsidered Decision',value:"reconsideration"},
        {label:'Petition for Reconsideration',value:"pfr"},
        {label:'PFR on a Reconsidered Decision',value:"pfr-on-reconsidered"},
    ],
}

export const DOCUMENT_CATEGORIES = {
    APPEAL:{ slug:'appeal', display_name:'Appeal' },
    TAXES:{ slug:'taxes', display_name:'Tax Documents' },
    ADMINISTRATIVE_RECORD:{ slug:'administrative-record', display_name:'Administrative Record'},
    ADMINISTRATIVE_RECORD_PRIVILEDGED:{
        slug: "administrative-record-priviledged",
        display_name: "Administrative Record - Priviledged"
    },
    VAUGHN_INDEX:{
        slug:'vaughn-index',
        display_name:'Vaughn Index'
    },
    REDACTED_ADMINISTRATIVE_RECORD:{
        slug:'redacted-administrative-record',
        display_name:'Redacted Administrative Record'
    },
    REVIEW:{ slug:'review', display_name:'Copy/Description of Final SBA Loan Review Decision' },
    PAYROLL:{ slug:'payroll', display_name:'Payroll' },
    OTHERS:{ slug:'other', display_name:'Other' },
    WIZARD_OTHERS:{ slug:'wizard-other', display_name:'Other' }
}

export const APPELLANT_DOCUMENT_CATEGORIES = {
    APPEAL_RESPONSE:{
        "value": "appeal-response",
        "name": "Appeal Response"
    },
    SUPPLEMENTARY_APPEAL:{
        "value": "supplemental-appeal",
        "name": "Supplementary Appeal"
    },
    MOTION_TO_DISMISS:{
        "value": "motion-to-dismiss",
        "name": "Motion to Dismiss"
    },
    ADMINISTRATIVE_RECORD:{
        "value": "administrative-record",
        "name": "Administrative Record"
    },
    OBJECTION_TO_ADMINISTRATIVE_RECORD:{
        "value": "objection-to-administrative-record",
        "name": "Objection to Administrative Record"
    },
    RESPONSE_TO_OBJECTION_TO_ADMINISTRATIVE_RECORD:{
        "value": "response-to-objection-to-administrative-record",
        "name": "Response to Objection to Administrative Record"
    },
    SUPPLEMENTAL_OF_ADMINISTRATIVE_RECORD:{
        "value": "supplemental-of-administrative-record",
        "name": "Supplemental of Administrative Record"
    }
}

export const APPEAL_ACTION_TYPES = {
    APPEAL_FILED:{
        "slug": "Appeal Filed",
        "display_name": "Appeal Filed"
    },
    DRAFT:{
        "slug": "Draft",
        "display_name": "Draft"
    },
    SUBMITTED:{
        "slug": "submitted",
        "display_name": "Submitted"
    },
    NOTICE_AND_ORDER:{
        "slug": "notice-and-order",
        "display_name": "Notice and Order"
    },
    ORDER_TO_SHOW_CAUSE:{
        "slug": "order-to-show-cause",
        "display_name": "Order to Show Cause"
    },
    ADMINISTRATIVE_RECORD:{
        "slug": "administrative-record",
        "display_name": "Administrative Record"
    },
    APPEAL_RESPONSE:{
        "slug": "appeal-response",
        "display_name": "Appeal Response"
    },
    MOTION_TO_DISMISS:{
        "slug": "motion-to-dismiss",
        "display_name": "Motion to Dismiss"
    },
    OBJECTION_TO_ADMINISTRATIVE_RECORD:{
        "slug": "objection-to-administrative-record",
        "display_name": "Objection to Administrative Record"
    },
    RESPONSE_TO_OBJECTION_TO_ADMINISTRATIVE_RECORD:{
        "slug": "response-to-objection-to-administrative-record",
        "display_name": "Response to Objection to Administrative Record"
    },
    SUPPLEMENTAL_APPEAL:{
        "slug": "supplemental-appeal",
        "display_name": "Supplemental Appeal"
    },
    ORDER_FOR_ORAL_HEARING:{
        "slug": "order-for-oral-hearing",
        "display_name": "Order for Oral Hearing"
    },
    ORDER_TO_SUPPLEMENT_ADMINISTRATIVE_RECORD:{
        "slug": "order-to-supplement-administrative-record",
        "display_name": "Order to Supplement Administrative Record"
    },
    SUPPLEMENTAL_OF_ADMINISTRATIVE_RECORD:{
        "slug": "supplemental-of-administrative-record",
        "display_name": "Supplemental of Administrative Record"
    },
    MOTION_FOR_EXTENSION_OF_THE_CLOSE_OF_RECORD:{
        "slug": "motion-for-extension-of-the-close-of-record",
        "display_name": "Motion for Extension of the Close of Record"
    },
    ORDER_OF_DISMISSAL:{
        "slug": "order-of-dismissal",
        "display_name": "Order of Dismissal"
    },
    ORDER_GRANTING_MOTION:{
        "slug": "order-granting-motion",
        "display_name": "Order Granting Motion"
    },
    ORDER_FOR_RESPONSE:{
        "slug": "order-for-response",
        "display_name": "Order for Response"
    },
    ORDER_EXTENDING_CLOSE_OF_RECORD:{
        "slug": "order-extending-close-of-record",
        "display_name": "Order Extending Close of Record"
    },
    ORDER_STAYING_APPEAL:{
        "slug": "order-staying-appeal",
        "display_name": "Order Staying Appeal"
    },
    ORDER_LIFTING_STAY:{
        "slug": "order-lifting-stay",
        "display_name": "Order Lifting Stay"
    },
    SUPPLEMENTARY_APPEAL:{
        "slug": "supplementary-appeal",
        "name": "Supplementary Appeal"
    },
    MOTION:{
        display_name: "Motion", 
        slug: "motion"
    },
    ORDER_DENYING_MOTION:{
        slug:'order-denying-motion',
        display_name:'Order Denying Motion'
    },
    DECISION:{
        display_name: "Decision",
        slug: "decision"
    }
}