import { InjectionToken } from '@angular/core';

export const API_CONFIG = new InjectionToken<ApiConfig>('API_CONFIG');
export const APP_DEFAULT_CONFIG = new InjectionToken<AppDefaultConfig>('APP_DEFAULT_CONFIG');

export interface ApiConfigInterface {
  url?:string;
  endpoints?:any;
}

export interface AppDefaultConfigInterface {  
  ui?:any;
  appealActions?:any;
  documentCategories?:any;
}

export class ApiConfig implements ApiConfigInterface {
  public url:any;
  public endpoints:any;

  constructor(config: ApiConfigInterface = {}) {
    this.assign(config);
  }

  public assign(config: ApiConfigInterface = {}) {
    for (const key in config) {
      this[key] = config[key];
    }
  }
}

export class AppDefaultConfig implements AppDefaultConfigInterface {
  public ui:any;
  public appealActions:any;
  public documentCategories:any;
  public appellantDocCategory:any;

  constructor(config: AppDefaultConfigInterface = {}) {
    this.assign(config);
  }

  public assign(config: AppDefaultConfigInterface = {}) {
    for (const key in config) {
      this[key] = config[key];
    }
  }
}

