export const MODAL_TYPES = {
  CONFIRM:'CONFIRMATION',
  MESSAGE:'MESSAGE'
}

export const MODAL_THEMES = {
  DEFAULT: 'DEFAULT',
  DANGER:'DANGER',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO'
}

export const MODAL_ICONS = {
  SKULL:"SKULL",
  DEFAULT_CONFIRM:"DEFAULT_CONFIRM"
}

export const MODAL_CONFIGURATIONS = {
  [MODAL_TYPES.CONFIRM]:{
    [MODAL_THEMES.DEFAULT]:<ModalConfirmTheme>{
      background:'',
      confirmButton:'is-outlined',
      cancelButton:'is-dashed is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.DANGER]:<ModalConfirmTheme>{
      background:'has-background-danger',
      confirmButton:'is-danger is-inverted is-outlined',
      cancelButton:'is-dashed is-danger is-inverted is-outlined',
      icon:MODAL_ICONS.SKULL
    },
    [MODAL_THEMES.WARNING]:<ModalConfirmTheme>{
      background:'has-background-warning',
      confirmButton:'is-warning is-inverted is-outlined',
      cancelButton:'is-dashed is-warning is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.SUCCESS]:<ModalConfirmTheme>{
      background:'has-background-success',
      confirmButton:'is-success is-inverted is-outlined',
      cancelButton:'is-dashed is-success is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.INFO]:<ModalConfirmTheme>{
      background:'has-background-info',
      confirmButton:'is-info is-inverted is-outlined',
      cancelButton:'is-dashed is-info is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    }
  },
  [MODAL_TYPES.MESSAGE]:{
    [MODAL_THEMES.DEFAULT]:<ModalMessageTheme>{
      background:'',
      buttonClass:'is-dashed is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.DANGER]:<ModalMessageTheme>{
      background:'has-background-danger',
      buttonClass:'is-dashed is-danger is-inverted is-outlined',
      icon:MODAL_ICONS.SKULL
    },
    [MODAL_THEMES.WARNING]:<ModalMessageTheme>{
      background:'has-background-warning',
      buttonClass:'is-dashed is-warning is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.SUCCESS]:<ModalMessageTheme>{
      background:'has-background-success',
      buttonClass:'is-dashed is-success is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    },
    [MODAL_THEMES.INFO]:<ModalMessageTheme>{
      background:'has-background-info',
      buttonClass:'is-dashed is-info is-inverted is-outlined',
      icon:MODAL_ICONS.DEFAULT_CONFIRM
    }
  }
}
