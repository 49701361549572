import { Component, Inject } from '@angular/core';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig } from '../../../platform/modules/navigation';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ACCOUNT_PAGE_NAMES } from '../constants/account.constants';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatchValidator } from '../../../platform/utilities/validators/match.validator';
import { AuthConfiguration, AUTH_CONFIG, getSessionState, RequestResetMFAAction, RequestResetPasswordAction, SaveProfileChanges } from '../../../platform/modules/auth';
import { FULL_ROUTES } from '../../../constants/routes.constants';
import { USER_ROLES } from '../../../constants/permissions.constants';
import { ValidEmailValidator } from '../../../platform/utilities/validators/valid-email.validator';
import { take } from 'rxjs/operators';
import { convertToPhoneFormat } from '../../../platform/utilities/helpers';

@Component({
    selector:'account-profile',
    template:require('./profile.component.html'),
})
export class AccountProfile extends KillZombies(PageView){

    public profile:any = {};
    public updatedProfile:any = {};
    public editing:boolean = false;
    public profileForm:FormGroup = new FormGroup({});
    public type: any= "";
    public modify:boolean= false;
    public roleKeys: any= null;
    public roleValues: any= null;
    public roleDisabled: any= null;
    public modifiedRoles= [];
    public isOhaInfo:boolean = false;
    public userRole: any= null;

    constructor(
        public activatedRoute:ActivatedRoute,
        public ngrxstore:Store<any>,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        @Inject(AUTH_CONFIG) protected authConfig:AuthConfiguration,
        navigationService:NavigationService,
        public formBuilder:FormBuilder
    ){
        // Provide PageView's dependencies
        super(configuration,navigationService);
        
        this.type= this.activatedRoute.snapshot.paramMap.get('type');
        if(this.type === 'modify'){
            this.editing= true;
            this.modify= true;
            this.Name = ACCOUNT_PAGE_NAMES.MODIFY;
        }else{
            this.Name = ACCOUNT_PAGE_NAMES.PROFILE;
        }
        this.profile = this.activatedRoute.snapshot.data.profile;
        this.profile.formatPhone = null;
        if(this.profile.phone && this.profile.phone != null){
            this.profile.formatPhone = convertToPhoneFormat(this.profile.phone.replace("+1",""));
        }
        
        this.ngrxstore.select(getSessionState).pipe(take(1)).subscribe((session)=>{
            this.userRole  = session.roles[0];
        });

        if ( this.userRole === this.authConfig.roles.OHA_ADMIN.slug) {
            this.isOhaInfo = true;
        }

        //this.activatedRoute.snapshot.data.GroupResolver.results = this.activatedRoute.snapshot.data.GroupResolver.results.filter(({ name }) => name !== 'Appellant' && name != 'Appellant Counsel');        
        let _groups= this.activatedRoute.snapshot.data.GroupResolver.results;
        this.roleKeys= _groups.map((key)=>{
            let _temp= key.name;
            return _temp.toLowerCase().replace(' ','_');
        });
        this.roleValues= _groups.map((key)=>{
            return key;
        });
        this.roleDisabled= _groups.map((key)=>{
            if(key.name==='Appellant' || key.name=== 'Appellant Counsel'){
                return true;
            }else{
                return false;
            }
        });
        
        if(this.profile.groups){
            this.modifiedRoles= this.profile.groups.map((item) => {
                return item.name;
            });
        }
        this.updatedProfile = Object.assign({}, this.activatedRoute.snapshot);
        // Name is Inherited from PageView. It should be assigned a constant in the module's constants file
        this.profileForm = this.formBuilder.group({
            username:[this.profile.email],
            name:[this.profile.name],
            email:[this.profile.email, [ValidEmailValidator()]],
            phone:[this.profile.phone],
            appeals_assignment_max:[this.profile.appeals_assignment_max],
            first_name:[this.profile.first_name],
            last_name:[this.profile.last_name],
            is_active:[this.profile.is_active],
            is_staff:[this.profile.is_staff],
            is_superuser:[this.profile.is_superuser],            
        });
        _groups.map((key)=>{
            let _temp= key.name;
            if(this.profile.groups && this.profile.groups !== undefined){
                this.profileForm.addControl(_temp.toLowerCase().replace(' ','_'), new FormControl((this.profile.groups).some(function(value){ return value.name === key.name})));
            }else{
                this.profileForm.addControl(_temp.toLowerCase().replace(' ','_'), new FormControl(false));
            }
        });
       
        // Live updating the header
        let _profileFormSub =  this.profileForm.valueChanges.subscribe((changes)=>{
            this.updatedProfile = Object.assign({},this.profile,{
                name:changes.name,
                email:changes.email,
                phone: changes.phone,
                formatPhone: (changes.phone != null ? convertToPhoneFormat(changes.phone) : null )
            })
        }); 
        
        this.storeZombieByKey('form_values',_profileFormSub);
    }

    get formContols() { return this.profileForm.controls; }

    startEditing(){
        this.editing = true;
        this.profile.is_current= true;
        this.profileForm.patchValue({
            username:this.profile.email,
            name:this.profile.name,
            email:this.profile.email,
            phone:this.profile.phone,
            appeals_assignment_max:this.profile.appeals_assignment_max,
            first_name:this.profile.first_name,
            last_name:this.profile.last_name,
            is_active:this.profile.is_active,
            is_staff:this.profile.is_staff,
            is_superuser:this.profile.is_superuser, 
        });
    }

    cancelEditing(){
        this.updatedProfile = Object.assign({},this.profile);
        // this.profileForm.controls["confirmPassword"].setErrors(null);
        this.editing = false;
    }

    saveChanges(){
        if (this.profileForm.invalid) {
            return;
        }
        let _groupDicts = [];
        var _data= this.profileForm.value;
        this.modifiedRoles= [];
        this.roleKeys.map((key,index) => {
            if(this.profileForm.controls[key].value){
                this.modifiedRoles.push(this.roleValues[index].name); 
                _groupDicts.push(this.roleValues[index].id)
            }
        });
        if(!this.profile.is_current){
            _data['groups']=_groupDicts;
        }
        _data['reference_id']= this.profile.reference_id;
        _data['phone']= (_data['phone'] !== null) ? (_data['phone'].indexOf('+1') > -1 ? _data['phone']  : ("+1" + _data['phone']) ) : '';
        this.ngrxstore.dispatch( new SaveProfileChanges(_data,
            (profileDataFromServer,isProfileUpdated)=>{
                if(isProfileUpdated){
                    this.profile = profileDataFromServer;
                    this.profile.formatPhone = (this.profile.phone != null ? convertToPhoneFormat(this.profile.phone) : null);
                    this.profile.is_current = false;
                    this.editing = false;
                }else{
                    this.profile.is_current = true;
                    this.editing = true;
                }
               
            }));
    }

    passwordReset(){
        this.ngrxstore.dispatch( new RequestResetPasswordAction({
            email:this.profile.email,
            redirect: "/account/users/"+this.profile.reference_id
        }));
    }

    mfaReset(){
        this.ngrxstore.dispatch( new RequestResetMFAAction({
            ref:this.profile.reference_id,
            redirect: "/account/users/"+this.profile.reference_id
        }));
    }

    assignUserName($event){
        this.profileForm.patchValue({
            username: $event.target.value
        });
    }
    updateFullName($event){
        this.profileForm.patchValue({
            username: this.profileForm.controls.first_name.value+" "+this.profileForm.controls.last_name.value,
            name: this.profileForm.controls.first_name.value+" "+this.profileForm.controls.last_name.value,
        });
    }

    
}