import { Inject, Component, AfterViewInit, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginAction, LoginWithMFATokenAction, LogoutAction, RegisterPhoneWithTwoFactor, RegisterPhone, RequestResetPasswordAction, ResetPasswordAction, ScanQRCode , LoginWithAppealID, SelectSMSTwilio2fa, TwilioLoginWithMFATokenAction, RegisterPhoneWithTwilio } from '../store/auth.actions';
import { ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { AUTH_ACTIONS, AUTH_PATHS } from '../constants/auth.constants';
import { TriggerModal } from '../../notification';
import { MatchValidator } from '../../../utilities/validators/match.validator';
import { StrongPasswordValidator } from '../../../utilities/validators/strong-password.validator';
import { ValidEmailValidator } from '../../../utilities/validators/valid-email.validator';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector:'access-gateway',
  template:require('./access-gateway.component.html')
})

export class AccessGatewayComponent implements OnInit{

  @Input() set origin(value){
    this.redirectSpace = (value) ? value : '';
  }

  @Input() set redirect(value){
    this.redirectHome = (value) ? value: '';
  }

  @Input() set initialValues(values:any){
    this.extraData = values || {};
  }

  @Input() set qrCode(value){
    this.qr_code = (value) ? value: '';
    this.safe_qr_code = this.sanitizer.bypassSecurityTrustUrl(this.qr_code);
  }

  public AUTH_PATHS = AUTH_PATHS;

  public signUpForm: FormGroup;
  public signInForm: FormGroup;
  public signInFormAppealID: FormGroup;
  public registerForm: FormGroup;
  public emailRequestForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public twoFATokenForm:FormGroup;
  public twoFARegisterPhoneForm:FormGroup;
  public twoFAAuthTypeForm:FormGroup;
  public twoFAQRTokenForm:FormGroup;

  public twoFARegisterPhoneFormSubmitted:boolean = false;
  public twoFATokenFormSubmitted:boolean = false;

  public twoFAAuthType:boolean = false;

  public twoFASecret:string;

  public resetToken:string = null;

  public requestType:string;

  public redirectSpace = '';
  public redirectHome = '';
  public extraData = {};
  public portalOrigin = '';
  public qr_code :any = '';
  public safe_qr_code:any = '';

  public authRedirect = null;

  title:string = '';
  body:string = '';
  validEmail:boolean = false;

  constructor(
    public fb: FormBuilder,
    public router:Router,
    public authService:AuthService,
    public activatedRoute:ActivatedRoute,
    public ngrxstore:Store<any>,
    public sanitizer : DomSanitizer
  ){

  }

  ngOnInit(){
    
    let _route = this.activatedRoute.snapshot.routeConfig.path;

    let _suffix = (
      this.redirectSpace.substring(1).length > 0  && !this.redirectSpace.match(/\/login/g)
    ) ? this.redirectSpace.substring(1)+'/' : '';

    let _urlToken = (this.activatedRoute.snapshot.url[1]) ? this.activatedRoute.snapshot.url[1].path : '' ;
    this.resetToken = _urlToken;

    //console.log(this.extraData)

    // determine which form template to show
    // -------------------------------------------------
    switch(_route){
      case _suffix + AUTH_PATHS.REGISTER:
        this.requestType = 'newUser';
      break;

      case _suffix + AUTH_PATHS.FORGOT_PASSWORD:
        this.requestType = 'forgotPassword';
      break;

      case _suffix + AUTH_PATHS.RESET_PASSWORD:
        this.requestType = 'resetPassword';
      break;

      case _suffix + AUTH_PATHS.LOGIN_WITH_APPEAL_ID:
        this.requestType = 'loginWithAppeal';
      break;

      // case _suffix + AUTH_PATHS.TWO_FA_PROVIDE:
      case AUTH_PATHS.TWO_FA_PROVIDE:
        this.requestType = '2faProvideToken';

      // If no state data is present, logout
      // -------------------------------------------------
        if(this.extraData["authType"] === 'sms' ){
          if (!this.extraData["phone"]) {
            //console.log(this.extraData)
            this.ngrxstore.dispatch(new LogoutAction({
              style: 'warning',
              message: 'You must complete Two Factor Authentication',
              ttl: 6
            }));
          }
        }
      break;

      // case _suffix + AUTH_PATHS.TWO_FA_REGISTER_PHONE:
      case AUTH_PATHS.TWO_FA_REGISTER_PHONE:
        this.requestType = '2faRegisterPhone';

      // If no state data is present, logout
      // -------------------------------------------------
      if (!this.extraData["password"]) {
        //console.log(this.extraData)
        this.ngrxstore.dispatch(new LogoutAction({
          style: 'warning',
          message: 'You must complete Two Factor Authentication',
          ttl: 6
        }));
      }
      break;

      case AUTH_PATHS.TWO_FA_METHOD:
        this.requestType = '2faSelect';
        break;
      
      case AUTH_PATHS.TWO_FA_SCAN_QR:
        this.requestType = '2faQRCode';
        break;
        
      default:
      this.requestType = 'returningUser';
      break;
    }

    // forms
    // -----------------------------------------------------------
    this.signInForm = this.fb.group({
      "email":['',[Validators.required]],
      "password":['',[Validators.required]]
    });

    this.signInFormAppealID= this.fb.group({
      "email":['',[Validators.required]],
      "password":['',[Validators.required]]
    });

    this.resetPasswordForm = this.fb.group({
      "code":[''],
      "password":['',Validators.required],
      "confirmPassword":['',Validators.required]
    },{ 
      validator: MatchValidator('password', 'confirmPassword')
    });

    this.emailRequestForm = this.fb.group({
      "email":['',[Validators.required,Validators.email,ValidEmailValidator()]]
    });


    // These forms require state data to be present via routing
    // -----------------------------------------------------------
    this.twoFATokenForm = this.fb.group({
      'token':['',Validators.required],
      'email':[this.extraData["email"],[Validators.required]],
      'password':[this.extraData["password"],Validators.required]
    });

    this.twoFARegisterPhoneForm = this.fb.group({
      'phone':['',Validators.required],
      'email':[this.extraData["email"],[Validators.required]],
      'password':[this.extraData["password"],[Validators.required]]
    });

    this.twoFAAuthTypeForm = this.fb.group({
      'authType':['',Validators.required]
    });

    this.twoFAQRTokenForm = this.fb.group({
      'token':['',Validators.required]
    });

  }

  requestPasswordReset(){
    this.ngrxstore.dispatch( new RequestResetPasswordAction({
      email:this.emailRequestForm.controls['email'].value,
      redirect: this.redirectSpace
    }));
  }

  resetPassword(){
    this.ngrxstore.dispatch( new ResetPasswordAction({
     // resetToken:this.resetPasswordForm.controls['code'].value,
     resetToken: this.resetToken,
      password:this.resetPasswordForm.controls['password'].value,
      confirm_password:this.resetPasswordForm.controls['confirmPassword'].value
    }));
  }

  login(){
    this.ngrxstore.dispatch(new LoginAction({
      origin:this.router.url,
      redirect:this.redirectHome,
      email:this.signInForm.controls['email'].value,
      password:this.signInForm.controls['password'].value,
      inviteToken: this.activatedRoute.snapshot.paramMap.get('id')
    }))
  }

  // These methods require state data to be present via routing
  // -----------------------------------------------------------
  loginWithMFAToken(){
    this.twoFATokenFormSubmitted = true;
    if(this.extraData["auth_type"] === 'sms'){
      this.ngrxstore.dispatch(new TwilioLoginWithMFATokenAction({
        origin:this.extraData["origin"] || AUTH_PATHS.LOGIN,
        redirect:this.extraData["redirect"] || AUTH_PATHS.LOGIN,
        token:this.twoFATokenForm.controls['token'].value,
        email:this.twoFATokenForm.controls['email'].value,
        password:this.twoFATokenForm.controls['password'].value,
        verification_id:this.extraData["verification_id"],
        auth_type: this.extraData["auth_type"],
        inviteToken: this.extraData["inviteToken"],
        appealID: this.extraData["appealID"],
        callback:()=>{
          this.twoFATokenFormSubmitted = false;
        }
      }))
    }else{
     // this.extraData["origin"]
     this.ngrxstore.dispatch(new LoginWithMFATokenAction({
      origin:this.extraData["origin"] || AUTH_PATHS.LOGIN,
      redirect:this.extraData["redirect"] || AUTH_PATHS.LOGIN,
      token:this.twoFATokenForm.controls['token'].value,
      email:this.extraData["email"],
      password:this.extraData["password"],
      auth_type:this.extraData["auth_type"],
      inviteToken: this.extraData["inviteToken"],
      appealID: this.extraData["appealID"]
    }))
    }   
  }

  scanAndLoginWithMFAToken(){
    this.ngrxstore.dispatch(new LoginWithMFATokenAction({
      origin:this.extraData["origin"] || AUTH_PATHS.LOGIN,
      redirect:this.extraData["redirect"] || AUTH_PATHS.LOGIN,
      token:this.twoFAQRTokenForm.controls['token'].value,
      email:this.extraData["email"],
      password:this.extraData["password"],
      auth_type:this.extraData["auth_type"],
      inviteToken: this.extraData["inviteToken"]
    }))
  }

  twoFARegisterPhone(){
    this.twoFARegisterPhoneFormSubmitted = true;
    this.ngrxstore.dispatch(new RegisterPhoneWithTwilio({
        phone:this.twoFARegisterPhoneForm.controls['phone'].value,
        email:this.twoFARegisterPhoneForm.controls['email'].value,
        password:this.twoFARegisterPhoneForm.controls['password'].value,
        origin:this.extraData["origin"] || AUTH_PATHS.LOGIN,
        redirect:this.extraData["redirect"] || AUTH_PATHS.LOGIN,
        auth_type: 'sms',
        inviteToken: this.extraData["inviteToken"]
    }));
  }

  switchAuthType(){
    let _authType =  this.twoFAAuthTypeForm.controls['authType'].value;

    if(_authType === 'sms'){
        this.ngrxstore.dispatch( new SelectSMSTwilio2fa({
          auth_type:_authType,
          origin: AUTH_PATHS.LOGIN,
          redirect:AUTH_PATHS.TWO_FA_REGISTER_PHONE,
          email: this.extraData["email"],
          password: this.extraData["password"],
          inviteToken:this.extraData["inviteToken"]
      }));
    }else{
      this.ngrxstore.dispatch( new ScanQRCode({
        auth_type:_authType,
        qr_code:this.qr_code,
        origin: AUTH_PATHS.LOGIN,
        redirect:AUTH_PATHS.TWO_FA_SCAN_QR,
        email: this.extraData["email"],
        password: this.extraData["password"],
        inviteToken:this.extraData["inviteToken"]
    }));
    }
  }


  loginWithAppeal(){
    this.ngrxstore.dispatch(new LoginWithAppealID({
      redirect:this.authRedirect,
      origin:this.router.url,
      email:this.signInFormAppealID.controls['email'].value,
      password:this.signInFormAppealID.controls['password'].value,
      appealId:this.activatedRoute.snapshot.paramMap.get('id')
    }))
  }

}
