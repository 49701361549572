import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { take,map } from 'rxjs/operators';
import { getSessionState } from '../../../platform/modules/auth';
import { AppealsApplicationsService } from '../appeals-applications.service';
import { APP_DEFAULT_CONFIG, AppDefaultConfig } from '../../../app.interface';


@Injectable({
    providedIn:'root'
  })
  export class YourAppealsResolver implements Resolve<any> {
  
    constructor(
      @Inject(APP_DEFAULT_CONFIG) public appDefaults:AppDefaultConfig,
      @Inject(AppealsApplicationsService) public appealsService:AppealsApplicationsService,
      @Inject(Store) public ngrxstore:Store
    ){}
  
    // Provides the loans before inital page render.
    resolve(route:ActivatedRouteSnapshot){
      return new Observable((obs)=>{
        zip(
          this.appealsService.getDraftAppealsList(0,this.appDefaults.ui.LIST_PAGE_LIMIT,{ordering:"+title"}),
          this.appealsService.getSubmittedAppealsList('active',0,this.appDefaults.ui.LIST_PAGE_LIMIT,{ordering:"+title"}),
          this.appealsService.getAppealType(),
          this.appealsService.getAppealStatuses(),
          this.appealsService.getCurrentUser()
        ).pipe(
          map(([$drafts,$submitted,$appealType,$appealStatus,$currentUser])=>(
            {
              drafts:$drafts,
              submitted:$submitted,
              appealType:$appealType,
              appealStatus:$appealStatus,
              currentUser:$currentUser
            }
          )),
          take(1)
        ).subscribe((results)=>{
          obs.next(results);
          obs.complete();
        })
      }) ;
    }
  
  }