import { Inject, Injectable } from "@angular/core";
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MOCK_RESOUCES_PAGE_REQUEST, MOCK_RESOURCES_PAGE_FAQ, MOCK_RESOURCES_PAGE_VIDEOS } from '../../constants/mock.data';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../../app.interface';

@Injectable({
    providedIn:'root'
})
 export class ResourcesService{
     constructor(
         public http:HttpClient,
         @Inject(API_CONFIG) public api:ApiConfig,){

     }
     
     getResources(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['RESOURCES'])
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
    }

    getResourceAsset(resourceName:string){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['RESOURCES_ASSET']+ '/' + resourceName,{
                responseType: 'blob'
            })
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe(
                    (response)=>{
                        obs.next(response);
                        obs.complete();
                },(err)=>{
                    console.log("err", err);
                  });
        });
    }


    getVideoLinks(){
        return new Observable((obs)=>{
            let _serverData = MOCK_RESOURCES_PAGE_VIDEOS;
            obs.next(_serverData);
            obs.complete();
        }).pipe(take(1));
       
    }

    getFaqList(){
        return new Observable((obs)=>{
            this.http.get(this.api.endpoints['RESOURCES_FAQS'])
                .pipe(
                    take(1),
                    catchError(err => throwError(err))
                ).subscribe((response)=>{
                    obs.next(response['results']);
                    obs.complete();
                })
        });
        // return new Observable((obs)=>{
        //     let _serverData = MOCK_RESOURCES_PAGE_FAQ;
        //     let _general = _serverData.filter(t => t.topic === "General")[0].data;
        //     let _platform = _serverData.filter(t => t.topic === 'Platform')[0].data;
        //     let _institution = _serverData.filter(t => t.topic === 'Institution')[0].data;
        //     let _forgiveness = _serverData.filter(t => t.topic === 'Forgiveness')[0].data;
        //     let _decision = _serverData.filter(t => t.topic === 'Decision')[0].data;
        //     let _documentation = _serverData.filter(t => t.topic === 'Documentation')[0].data;
        //     let _monitoring = _serverData.filter(t => t.topic === 'MonitoringForgiveness')[0].data;
        //     let _additional = _serverData.filter(t => t.topic === 'AdditionalResources')[0].data;
        //     obs.next({
        //         general : _general,
        //         platform : _platform,
        //         institution : _institution,
        //         forgiveness: _forgiveness,
        //         decision: _decision,
        //         documentation: _documentation,
        //         monitoring: _monitoring,
        //         additional:_additional
        //     });
        //     obs.complete();
        // }).pipe(take(1));
       
    }

}
