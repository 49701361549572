import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute , Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FileItem } from '@wkoza/ngx-upload';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppDefaultConfig, APP_DEFAULT_CONFIG } from '../../../../app.interface';
import { APP_DEFAULTS } from '../../../../constants/app.constants';
import { TriggerModal, TriggerToast } from '../../../../platform/modules/notification';
import { BUCKET_CONFIG } from '../../../../platform/modules/shared/modules/bucket/bucket.interface';
import { PPPAppealWizardService } from '../../appeal-wizard.service';
import { NavigatedFromSummary, SaveAppeal, StoreAppealValues } from '../../store/appeal-wizard.actions';
import { selectNavigationFlag, selectPPPAppealWizardValues } from '../../store/appeal-wizard.selectors';
import { WizardStep } from '../wizard-step.component';

@Component({
    selector:'appeal-content',
    template:require('./appeal-content.component.html')
})
export class AppealContent extends WizardStep{
   
    public bucketConfiguration:BucketConfiguration = {
        title:"",
        classes:'dashed-border-top no-border-left no-border-right no-border-bottom',
        items:[],
        categories:Object.values(this.appDefaults.documentCategories),
        labels:{
            drag_and_drop_valid:'You can now drop files here.',
            drag_and_drop_invalid:'You must Name your file and select a Type before dropping files here.'
        },
        context:{
            info:''
            //info:'Do not upload a Certificate Of Service! We will provide you the opportunity to sign and download one at the end of this process.'
            
        }
    }

    public bucketIsDisabled:boolean = true;
    public hideInitialSave:boolean = false;
    public reasonIsDisabled:boolean = true;
    public dateWarning: boolean = false;
    public showDateSelect:boolean = false;
    public uploadFn = this.uploadDocumentsCallback.bind(this);
    //public uploadProgress:number = 0;
    public refresh :any;
    public referenceId = null;
    public appealDocuments:any[] = [];
    public isNavigatedFromSummary:boolean = false;
    public docUploadSpinner:boolean=false;

    constructor(
        @Inject(APP_DEFAULT_CONFIG) protected appDefaults:AppDefaultConfig,
        public ngrxstore:Store,
        public activatedRoute:ActivatedRoute,
        public router:Router,
        public formBuilder:FormBuilder,
        public appealWizardService:PPPAppealWizardService
       
    ){
        super(
            "Upload Appeal",
            ngrxstore,
            formBuilder,
            appealWizardService,
            activatedRoute
        );



        let _documentsFromServer = this.activatedRoute.snapshot.data.documents.data;
        //console.log("_documentsFromServer", _documentsFromServer );

        this.bucketConfiguration = Object.assign({},this.bucketConfiguration,{
            items:_documentsFromServer.map((file)=>{
                return Object.assign({},file,{
                    id:file.reference_id
                });
            }),
            categories:this.activatedRoute.snapshot.data.documents.documentCategories
        });
        this.referenceId = this.activatedRoute.snapshot.paramMap.get('id');
        if(this.referenceId !== this.appDefaults.ui.NEW_APPEAL_SLUG){
            this.hideInitialSave = true;
        }
       
        let _initialValues = this.activatedRoute.snapshot.data.state.storedValues;

        this.ngrxstore.select(selectNavigationFlag()).subscribe((isNavigated)=>{
            this.isNavigatedFromSummary = isNavigated;
        });

        this.formGroup = this.formBuilder.group({
            [this.fields.AC_CRITERIA1.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA1.storeKey
                ]) ? (_initialValues[
                    this.fields.AC_CRITERIA1.storeKey
                ]): false,
                Validators.requiredTrue
            ],
            [this.fields.AC_LOAN_REVIEW_RECEIVED.storeKey]:[
                _initialValues[
                    this.fields.AC_LOAN_REVIEW_RECEIVED.storeKey
                ],Validators.required
            ],
            [this.fields.AC_CRITERIA2.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA2.storeKey
                ]) != null ?
                (_initialValues[
                    this.fields.AC_CRITERIA2.storeKey
                ]) : false,
                Validators.requiredTrue
            ],
            [this.fields.AC_CRITERIA2_1.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA2_1.storeKey
                ]) != null ? 
                (_initialValues[
                    this.fields.AC_CRITERIA2_1.storeKey
                ]) : false
            ],
            [this.fields.AC_CRITERIA2_2.storeKey]:[
               ( _initialValues[
                    this.fields.AC_CRITERIA2_2.storeKey
                ]) != null ? 
                ( _initialValues[
                    this.fields.AC_CRITERIA2_2.storeKey
                ]) : false
            ],
            [this.fields.AC_CRITERIA2_3.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA2_3.storeKey
                ]) != null ? 
                (_initialValues[
                    this.fields.AC_CRITERIA2_3.storeKey
                ]) : false
            ],
            [this.fields.AC_CRITERIA2_4.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA2_4.storeKey
                ]) != null ? 
                (_initialValues[
                    this.fields.AC_CRITERIA2_4.storeKey
                ]) : false
            ],
            [this.fields.AC_CRITERIA2_5.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA2_5.storeKey
                ]) != null ?
                (_initialValues[
                    this.fields.AC_CRITERIA2_5.storeKey
                ]) : false
            ],
            [this.fields.AC_CRITERIA3.storeKey]:[
                (_initialValues[
                    this.fields.AC_CRITERIA3.storeKey
                ]) != null ?
                (_initialValues[
                    this.fields.AC_CRITERIA3.storeKey
                ]) : false,
                Validators.requiredTrue
            ]
        });

        this.bucketIsDisabled = (!this.formGroup.valid);

        if(!this.formGroup.controls[this.fields.AC_CRITERIA2.storeKey].value){
            this.reasonIsDisabled = true;
        }else {
            this.reasonIsDisabled = false;
        }

        this.showDateSelect = this.formGroup.controls[this.fields.AC_CRITERIA1.storeKey].value;
        let _statusChangeSub = this.formGroup.statusChanges.subscribe((status)=>{
            this.bucketIsDisabled = (status !== 'VALID');
        });

        this.watchChanges((changes)=>{
            
            for(let key of Object.keys(this.formGroup.controls)) {
                if((key === this.fields.AC_CRITERIA1.storeKey) || 
                (key === this.fields.AC_CRITERIA2.storeKey) || 
                (key === this.fields.AC_CRITERIA3.storeKey)){
                    if(!this.formGroup.controls[key].value){
                        this.bucketIsDisabled = true;
                        return;
                    }else {
                        this.bucketIsDisabled = false;
                    }
                }
            }
        });

        this.checkForAppealDocument();
        this.storeZombieByKey('statusChanges',_statusChangeSub);

    }

    onBucketUpdate($event){
        this.docUploadSpinner= true;
    }

    onBucketError($event){
        if($event.type === 'size_exceeded'){
            this.ngrxstore.dispatch(new TriggerToast({
                style:'danger',
                message:"That File is too Large!"
            }));
        }
        this.docUploadSpinner=false;
    }

    selectDate(){
        this.showDateSelect = !this.formGroup.controls[this.fields.AC_CRITERIA1.storeKey].value;
        this.dateWarning = this.showDateSelect ? this.dateWarning : false;
    }

    onChangeReasons(event :any){
        if(!this.formGroup.controls[this.fields.AC_CRITERIA2.storeKey].value){
            this.reasonIsDisabled = false;
        }else {
            this.reasonIsDisabled = true;
            this.formGroup.patchValue({
                is_filed_for_ppp_ineligible: false,
                is_filed_for_ineligible_ppp_amount:false,
                is_filed_for_unauthorized_use: false,
                is_filed_for_lender_partial_approval:false,
                is_filed_for_lender_issued_full_denial:false
              });
        }

    }

    compareDate(reviewDate){
        let _appealDate =  new Date().getTime();
        if (_appealDate - reviewDate.getTime()  <   + (30 * 24 * 60 * 60 * 1000) && (_appealDate >=  reviewDate.getTime())) {
            this.dateWarning = false;
        }else{
            this.dateWarning = true;
        }
        
    }

    onBucketDeleteItem(slug:string){
        this.ngrxstore.dispatch(new TriggerModal({
            content:{
              title:"Are you sure you want to Delete the Document?",
              approveLabel: "Delete",
              cancelLabel: "Cancel"
            },
            onCancel:()=>{},
            onApprove:()=>{
                this.appealWizardService.removeDocumentFromAppeal(
                    this.activatedRoute.snapshot.parent.paramMap.get('id'),
                    slug
                ).subscribe((response)=>{
                    this.ngrxstore.dispatch( new TriggerToast(
                        {
                            message:"Removed ",
                            style:'warning'
                        }
                    ) )
                    this.refreshDocuments();
                })
            }
          }))
    }

    onBucketUploadSuccess(){
        // console.log("onBucketUploadSuccess");
        this.refreshDocuments();
        this.docUploadSpinner=false;
    }

    refreshDocuments(){
        this.appealWizardService.getUploadedDocuments(
            this.activatedRoute.snapshot.parent.paramMap.get('id')
        ).subscribe((documents)=>{
            this.bucketConfiguration = Object.assign({},this.bucketConfiguration,{
                items:documents
            });
            this.checkForAppealDocument();
        })
    }

    checkForAppealDocument(){
         let _appealDocuments = [];
         //let _taxDocuments = [];
         let _reviewDocuments = [];

        this.bucketConfiguration.items.map((item)=>{
            if (item.category === this.appDefaults.documentCategories.APPEAL.slug) {
                _appealDocuments.push(item);
            }
            // if(item.category === this.appDefaults.documentCategories.TAXES.slug) {
            //     _taxDocuments.push(item);
            // }
            if(item.category === this.appDefaults.documentCategories.REVIEW.slug){
                _reviewDocuments.push(item);
            }
        })

        this.ngrxstore.dispatch( new StoreAppealValues({
            [this.fields.APPEAL_DOCUMENT_SUBMITTED_1.storeKey]:_appealDocuments.length > 0,
            [this.fields.APPEAL_DOCUMENT_SUBMITTED_2.storeKey]:_reviewDocuments.length > 0,
            // [this.fields.APPEAL_DOCUMENT_SUBMITTED_3.storeKey]:_taxDocuments.length > 0
        }));
    }
    
    uploadDocumentsCallback(packet:{filePacket?:FileItem[],fileItem?:FileItem,formGroupData:any}){
       return new Observable((obs:any)=>{
           this.appealWizardService.uploadAppealDocument(
               this.activatedRoute.snapshot.parent.paramMap.get('id'),
               packet.fileItem,
               {}
           ).subscribe((response)=>{
               this.ngrxstore.dispatch(new TriggerToast({
                   message:"Successfully Uploaded "+packet.fileItem.file['given_name'],
                   style:'success'
               }));
               obs.next(true);
               obs.complete();
           },(err)=>{
               if(err instanceof HttpErrorResponse){
                    this.ngrxstore.dispatch(new TriggerModal({
                        content:{
                            title:"Unable to Upload",
                            message:err.error.file[0]
                        }
                    }));
                    this.docUploadSpinner=false;
                    obs.next(false);
                    obs.complete();
               }
           })
       })
    }

    saveIntialAppeal(){
        this.ngrxstore.select(selectPPPAppealWizardValues()).pipe(take(1)).subscribe((wizardValues)=>{
            this.ngrxstore.dispatch( new SaveAppeal({
                initialSave:wizardValues['reference_id'] === this.appDefaults.ui.NEW_APPEAL_SLUG
            }));
        })
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }   

    ngOnDestroy(){
        this.ngrxstore.dispatch(new NavigatedFromSummary(false))
    }
}