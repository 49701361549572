import { Routes, RouterModule } from '@angular/router';

import { APPEALS_APPLICATIONS_PATHS,APPEALS_APPLICATIONS_ROOT_PATH } from './constants/appeals-applications.constants';
import { ModuleWithProviders } from '@angular/core';
import { ShellComponent } from '../../shell';
import { LinkedAppealsResolver } from './resolvers/linked-appeals.resolver';
import { AppealsApplicationsList } from './appeals-applications-list/appeals-applications-list.component';
import { AuthGuard } from '../../platform/modules/navigation';
import { LoanAmountChoicesResolver } from './resolvers/loan-amount-choices.resolver';
import { YourAppealsList } from './your-appeals/your-appeal-page.component';
import { YourAppealResolver } from './resolvers/your-appeal.resolver';
import { ShellResolver } from '../../shell/components/shell/shell.resolver';
import { AppealApplicationsList } from './appeal-applications-list/appeal-applications-list.component';
import { YourAppeal } from './your-appeal/your-appeal.component';
import { YourAppealsResolver } from './resolvers/your-appeals.resolver';


export const APPEALS_APPLICATIONS_ROUTES = [
    {
        path:APPEALS_APPLICATIONS_ROOT_PATH,
        component:ShellComponent,
        children:[
            {
                path: APPEALS_APPLICATIONS_PATHS.YOUR_APPEALS,
                canActivate:[AuthGuard],
                component : YourAppealsList,
                resolve : {
                    appealsList : YourAppealsResolver
                }
            },
            {
                path: APPEALS_APPLICATIONS_PATHS.YOUR_APPEAL+'/:id',
                canActivate:[AuthGuard],
                component : YourAppeal,
                resolve : {
                    appeal : YourAppealResolver
                }
             },
            {
                path: APPEALS_APPLICATIONS_PATHS.APPEAL_APPLICATIONS_LIST,
                canActivate:[AuthGuard],
                component : AppealApplicationsList,
                resolve : {
                    appealsList : LinkedAppealsResolver
                }
             }
        ]
    }
]

export const loansRoutes: Routes = APPEALS_APPLICATIONS_ROUTES;

export const LoansRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(loansRoutes);