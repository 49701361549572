import { Component, Inject } from '@angular/core';
import { NavigationService, NAVIGATION_CONFIG, PageView, NavigationConfig } from '../../../../platform/modules/navigation';
import { KillZombies } from '../../../../platform/modules/navigation/kill-zombies/kill-zombies';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCOUNT_PATHS, ACCOUNT_PAGE_NAMES } from '../../constants/account.constants';
import { AccountService } from '../../account.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MOCK_MESSAGES } from '../../../../constants/mock.data';

@Component({
    selector:'inbox',
    template:require('./inbox.component.html'),
})
export class Inbox extends KillZombies(PageView){

    public bulkActions = null;
    public messagesToDisplay = [];
    public searchTerm:string = null;

    constructor(
        public activatedRoute:ActivatedRoute,
        public ngrxstore:Store<any>,
        @Inject(NAVIGATION_CONFIG) protected configuration:NavigationConfig,
        public navigationService:NavigationService,
        public accountService:AccountService,
        public formBuilder:FormBuilder
    ){
        // Provide PageView's dependencies
        super(configuration,navigationService);

        this.Name = ACCOUNT_PAGE_NAMES.INBOX;

        this.messagesToDisplay = MOCK_MESSAGES;

        this.bulkActions = {
            name:'Bulk Actions',
            type:'select',
            options:[{
                value:'delete',name:'Delete'
            }],
            multiple:false,
            placeholder:'Bulk Action',
            value:''
        }
    }

    filterMessages($event:any){
        //console.log($event);
    }

    onBulkActionSelect(){

    }

    openMessageThread(id:string){
        this.navigationService.navigate([this.Routes['MESSAGE_THREAD'],id])
    }
}