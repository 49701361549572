// getLenders
import {Injectable,Inject} from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PPPAppealWizardService } from '../appeal-wizard.service';

@Injectable({
    providedIn: 'root'
})
export class PPPAppealWizardBorrowerDetailsResolver implements Resolve < any > {

    public route: ActivatedRouteSnapshot = null;

    constructor(
        @Inject(PPPAppealWizardService) public appealWizardService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        this.route = route;
        return this.getValues(route.paramMap.get('id'));
    }

    getValues(id: string): Observable < any > {
        return new Observable((obs) => {
            zip(
                //this.appealWizardService.getLenders(),
                this.appealWizardService.getIndustry(),
            ).pipe(
                map(([
                   // $lenders,
                    $industry
                ])=>({
                   // lenders:$lenders,
                    industry:$industry
                })),
                take(1)
            ).subscribe((values) => {
                obs.next(values);
                obs.complete();
            });
        });
    }


}