import { ApplicationModule, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BucketItem } from './bucket-item/bucket-item.component';
import { Bucket } from './bucket.component';
import { DropTargetOptions, MineTypeEnum, NgxUploadModule } from '@wkoza/ngx-upload';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

export const ngxDropTargetOptions: DropTargetOptions = {
    color: 'dropZoneColor',
    colorDrag: 'dropZoneColorDrag',
    colorDrop: 'dropZoneColorDrop'
};

@NgModule({
	imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxUploadModule.forRoot(ngxDropTargetOptions),
        NgbProgressbarModule
	],
	declarations: [
        Bucket,
        BucketItem
	],
    exports: [
        BucketItem,
        Bucket
    ],
    entryComponents:[
        BucketItem
    ]
})
export class BucketModule { }