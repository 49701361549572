import { APPEAL_WIZARD_PATHS } from './appeal-wizard.constants';
import { APPEAL_CONTENT } from './step_defs/appeal-content.constants';
import { APPEAL_LOAN_INFORMATION } from './step_defs/loan-information.constants';
import { APPEAL_POINT_OF_CONTACT } from './step_defs/point-of-contact.constants';
import { APPEAL_START_APPEAL } from './step_defs/start-your-appeal.constants';
import { APPEAL_BORROWER_DETAILS } from './step_defs/borrower-details.constants';

export const APPEAL_WIZARD_STEPS = {
    APPEAL_POINT_OF_CONTACT:{
        name:'Point Of Contact',
        path:APPEAL_WIZARD_PATHS.APPEAL_POINT_OF_CONTACT
    },
    APPEAL_START_APPEAL:{
        name:'Start Your Appeal',
        path:APPEAL_WIZARD_PATHS.APPEAL_START_APPEAL
    },
    APPEAL_BORROWER_DETAILS:{
        name:'Borrower Details',
        path:APPEAL_WIZARD_PATHS.APPEAL_BORROWER_DETAILS
    },
    APPEAL_LOAN_INFORMATION:{
        name:'Loan Information',
        path:APPEAL_WIZARD_PATHS.APPEAL_LOAN_INFORMATION
    },
    APPEAL_CONTENT:{
        name:'Appeal Content',
        path:APPEAL_WIZARD_PATHS.APPEAL_CONTENT
    },
    APPEAL_SUMMARY:{
        name:'Summary',
        path:APPEAL_WIZARD_PATHS.APPEAL_SUMMARY
    }
}

export const APPEAL_WIZARD_POST_TABS = {
    APPEAL_CERTIFICATE_OF_SERVICE:{
        name:'Certificate of Service',
        path:APPEAL_WIZARD_PATHS.APPEAL_CERTIFICATE_OF_SERVICE
    }
}


// This is huge, so we break it up into digestible chunks
export const WIZARD_FIELDS = Object.assign({},
    APPEAL_POINT_OF_CONTACT,
    APPEAL_BORROWER_DETAILS,
    APPEAL_START_APPEAL,
    {
        APPEAL_STATUS:{
            storeKey:'appeal_current_status'
        },
        REFERENCE_ID:{
            storeKey:'reference_id'
        }
    },
    APPEAL_LOAN_INFORMATION,
    APPEAL_CONTENT
);