import { Directive, Input, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector:'[statusBubble]'
})
export class StatusBubble implements AfterViewInit{

    public classPrefix:string = 'fa ';
    public classSuffix:string = 'white';
    public bubbleSizeClass:string = '';

    @Input() set size(sizeType:'sm'|'lg'){
        switch(sizeType){
            case 'sm':
                this.bubbleSizeClass = 'fa-sm';
            break;
            case 'lg':
                this.bubbleSizeClass = 'fa-lg';
            break;
            default:
                this.bubbleSizeClass = '';
            break;
        }
        this.renderClass();
    }

    @Input() set style(styleType:'fa'|'fal'){
        this.classPrefix = styleType+' ';
        this.renderClass();
    }

    @Input() set statusBubble(
        statusString:string
    ){
        switch(statusString){
            case 'New':
                this.classSuffix = 'text-purple'
            break;
            case 'Action Requested':
                this.classSuffix = 'text-orange'
            break;
            default:
                this.classSuffix = 'text-cyan'
            break;
        }
        this.renderClass();
    }

    constructor(
        public el:ElementRef,
        public renderer:Renderer2
    ){}

    ngAfterViewInit(){
        this.renderClass();
    }

    renderClass(){
        let _class = this.classPrefix+this.classSuffix+' '+this.bubbleSizeClass+' fa-circle';
        this.renderer.setAttribute(this.el.nativeElement,'class',_class);
    }

}
