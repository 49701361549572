import {Injectable,Inject} from '@angular/core';
import {Resolve,ActivatedRouteSnapshot} from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map, take, zipAll } from 'rxjs/operators';
import { ResourcesService } from '../resources.service';

@Injectable({
  providedIn:'root'
})
export class ResourcesPageResolver implements Resolve<any> {

  constructor(
    @Inject(ResourcesService) public resourcesService:ResourcesService
  ){}

  // Provides the loans before inital page render.
  resolve(route:ActivatedRouteSnapshot){
    return this.getValues();
  }

  getValues(): Observable < any > {
    return new Observable((obs) => {
        zip(
            this.resourcesService.getResources(),
            //this.resourcesService.getVideoLinks(),
            this.resourcesService.getFaqList()
        ).pipe(
            map(([
              $resourceTabData, 
             // $videoData, 
              $faqList
            ])=>({
              resourceTabData:$resourceTabData,
              //videoData:$videoData,
              faqList: $faqList
            })),
            take(1)
        ).subscribe((response) => {
            obs.next(response);
            obs.complete();
        });
    });
}

}
