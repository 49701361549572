import {
    transition,
    trigger,
    style,
    animate,
    query as q,
    group,
    sequence,
    animateChild,
    keyframes
  } from '@angular/animations';
  
  //https://easings.net
  
  const query = (s,a,o={optional:true})=>q(s,a,o);
  
  export const ROUTER_ANIMATIONS = {
    FADE:[
      trigger('routerTransition',[
        transition('*=>*',[
          query(':enter', animateChild()),
          sequence([
            query(':leave',animateChild())
          ])
        ])
      ])
    ]
  }
  