import {map,take} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthConfiguration, AUTH_CONFIG } from '../auth.interface';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(
    @Inject(AUTH_CONFIG) public config:AuthConfiguration,
    public router: Router,
    public ngrxstore:Store<any>) {
  }

  // This should keep logged in users away from portals.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    return this.ngrxstore.pipe(take(1),map((storeState)=>{
        switch(storeState.auth.status){
          case "signedOut":
            return true;
          default:
            let _redirect = null;
            (storeState.auth.roles).map((key)=>{
              if(this.config.auth_origins[key] === storeState.auth.origin){
                _redirect = this.config.auth_redirects[key];
              }
            });
            if(_redirect){
              this.router.navigate([_redirect]);
            }else{
              return false;
            }
        }
    }));
  }

}
