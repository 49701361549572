import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthConfiguration, AuthService, AUTH_CONFIG } from '../../../platform/modules/auth';
import { Store } from '@ngrx/store';
import { NavigationConfig, NavigationService, NAVIGATION_CONFIG, PageView } from '../../../platform/modules/navigation';
import { FormBuilder } from '@angular/forms';
import { KillZombies } from '../../../platform/modules/navigation/kill-zombies/kill-zombies';


@Component({
  selector: 'admin-portal',
  template:require('./admin-portal.component.html')
})
export class AdminPortal extends KillZombies(PageView){

  public title:string = '';
  public body:string = '';
  public validEmail:boolean = false;

  public authOrigin = null;
  public authRedirect = null;

	constructor(
    public formBuilder:FormBuilder,
    public authService:AuthService,
    @Inject(NAVIGATION_CONFIG) protected navigationConfig:NavigationConfig,
    public navigationService:NavigationService,
    public ngrxstore:Store<any>,
    public activatedRoute:ActivatedRoute
  ){

    super(navigationConfig,navigationService);
    
    let _route = this.activatedRoute.snapshot.routeConfig.path;
    let _urlToken = (this.activatedRoute.snapshot.url[1]) ? this.activatedRoute.snapshot.url[1].path : '' ;
    let emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    
    //this.authOrigin = (this.authService.Origins.dashboard) ? this.authService.Origins.dashboard : null ;
    this.authOrigin = '/dashboard';
    this.authRedirect = (this.authService.Redirects.dashboard) ? this.authService.Redirects.dashboard : null ;

    switch(_route){
      
      
      case this.Routes['DASHBOARD_PORTAL'].substring(1)+this.Routes['FORGOT_PASSWORD']:
      this.title = 'I forgot my password...';
      this.body = `
          No Problem, please give us your email
          address, you'll get an email that
          will foward you onward!`;
      break;

      case this.Routes['DASHBOARD_PORTAL'].substring(1)+this.Routes['RESET_PASSWORD']:
      this.body = `
          To reset your password simply enter in a new password and confirm.`;
      break;

      default:
      this.title = 'Login';
      this.body = ``;
      break;
    }
  }
}
