
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Directive, ElementRef, forwardRef, Input, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const DATE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputGuard),
    multi: true
  };

const VALUE_TEMPLATE:string = `
    <span>{{value}}</span>
`

@Directive({
    selector:'[inputGuard]',
    providers:[DATE_ACCESSOR]
})
export class InputGuard implements ControlValueAccessor{

    public model:NgbDateStruct;
    public disabled:boolean = false;
    public guard:boolean = false;
    public value:string = null;
    
    @Input() set inputGuard(condition:boolean){
        if (condition) {
            // this.viewContainer.clear();
            
            let _value = this.renderer.appendChild(
                this.renderer.createElement('span'),
                this.renderer.createText('Value')
            );
            this.renderer.appendChild(this.templateRef.elementRef,_value);

            this.guard = true;
        } else if (!condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.guard = false;
        }
    }

    constructor(
        public templateRef: TemplateRef<any>,
        public el: ElementRef, 
        public renderer:Renderer2,
        public viewContainer: ViewContainerRef
    ){
        //console.log(this.templateRef.elementRef.nativeElement)
    }

    changeCallback=(data:any)=>{};
    touchCallback=()=>{};

    onChange(event){
        //console.log(event)
        this.changeCallback(event.target.value);
    }

    writeValue(value: any){
        //console.log(value);
        //console.log(this.templateRef);
        //console.log(this.viewContainer);
    }

    registerOnChange(fn: any){
        this.changeCallback=fn;
    }

    registerOnTouched(fn: any){
        this.touchCallback=fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled=isDisabled;
    }
}